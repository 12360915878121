import { Component, Input } from '@angular/core';
import {
  InvoicesTab,
  Page,
  ProposalTab,
  ReportsCommissionsTab,
  ReportsPaymentsTabs,
  ReportsTechPartsTab,
  ScheduleTab,
  Tab
} from '../../models/navigation.model';

type StatusFilter = 'All' | 'Canceled' | 'Pending';

function _scheduleMessage(tab: ScheduleTab, upcoming?: boolean, statusFilter?: StatusFilter) {
  switch (tab) {
    case "Leads":
      throw new Error('When the Leads list is empty, it shouldn\'t be shown in the tabs' );
    case 'All':
    case 'Unavailable':
      return `Your ${upcoming ? 'upcoming ' : ''}schedule is empty`;
    case 'Estimates':
      if (statusFilter === 'Canceled') {
        return upcoming ? 'You have no canceled estimates in upcoming' : 'You have no canceled estimates';
      } else {
        return upcoming ? 'You have no upcoming estimates' : 'No estimates in your schedule';
      }
    case 'Jobs':
      if (statusFilter === 'Canceled') {
        return upcoming ? 'Upcoming schedule has no job cancellations' : 'No jobs have been canceled';
      }
      if (statusFilter === 'Pending') {
        return upcoming ? 'Upcoming schedule has no pending jobs' : 'No pending jobs';
      }
      return upcoming ? 'You have no upcoming jobs' : 'No jobs in your schedule';
  }
}

function _proposalsMessage(tab: ProposalTab) {
  return {
    'Won': "No proposals won yet",
    'Pending': "No pending proposals",
    'Declined': "No proposals declined",
    'Canceled': "No proposals canceled",
    'Drafts': "No proposals in drafts",
  }[tab]!;
}

function _invoicesMessage(tab: InvoicesTab) {
  return {
    'Paid': "No paid invoices yet",
    'Pending': "No pending invoices",
    'Canceled': "No canceled invoices",
    'Drafts': "No invoices in drafts",
  }[tab]!;
}

function _reportsPaymentsMessage(tab: ReportsPaymentsTabs) {
  return {
    'All': 'No pending or paid jobs',
    'Paid': 'No jobs completed',
    'Pending': 'No jobs pending payment'
  }[tab]!;
}

function _reportsCommissionsMessage(tab: ReportsCommissionsTab) {
  return {
    'Pending': 'No commissions pending',
    'Paid': 'No commissions paid'
  }[tab]!;
}

function _reportsTechPartsMessage(tab: ReportsTechPartsTab) {
  return {
    'Pending': 'No tech parts pending',
    'Paid': 'No tech parts paid'
  }[tab]!;
}

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent {

  @Input() filter?: 'Search' | 'Range';
  @Input() page?: Exclude<Page, 'Settings'>;
  @Input() tab?: Tab;
  @Input() statusFilter?: StatusFilter;
  @Input() upcoming?: boolean;

  constructor() {
  }

  getMessage() {
    if (this.filter) {
      // console.log(this.filter);
      if (this.page === 'Items') {
        return "No items found";
      }

      return {
        'Search': "No results found",
        'Range': "No results for\nthis time range",
      }[this.filter]!;
    }
    switch (this.page) {
      case 'Schedule':
        return _scheduleMessage(this.tab as ScheduleTab, this.upcoming, this.statusFilter);
      case 'Proposals':
        return _proposalsMessage(this.tab as ProposalTab);
      case 'Invoices':
        return _invoicesMessage(this.tab as InvoicesTab);
      case 'Payments':
        return _reportsPaymentsMessage(this.tab as ReportsPaymentsTabs);
      case 'Commissions':
        return _reportsCommissionsMessage(this.tab as ReportsCommissionsTab);
      case 'Tech Parts':
        return _reportsTechPartsMessage(this.tab as ReportsTechPartsTab);
      case 'Assigned':
        return 'There are no pending assignments from you'
      case 'Assignment':
        return 'There are no pending assignments for you'
      case 'Items':
        return 'No items added yet';
    }

    throw `${this.page} is not set up for empty state`;
  }

}
