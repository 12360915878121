import { Component } from '@angular/core';
import { FormControl } from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent {

  passwordControl = new FormControl<string>('');
  showPassword = false;
  loading = false;

  constructor(
      private authService: AuthService,
      private router: Router
  ) {}

  async changePassword() {
    this.loading = true;
    const value = this.passwordControl.value;
    this.passwordControl.setErrors(null);
    if(!value || value.length === 0) {
      this.passwordControl.setErrors({
        required: true
      });
      this.loading = false;
      return;
    }

    if(value.length < 6) {
      this.passwordControl.setErrors({
        minlength: true
      });
      this.loading = false;
      return;
    }

    try {
      await this.authService.changePassword(value);
      this.router.navigate(['/']);
    } catch (e) {
      if(e === 'New password should be different from the old password.') {
        this.passwordControl.setErrors({
          different: true
        });
        this.loading = false;
      }
    }

    this.loading = false;
  }

}
