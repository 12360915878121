import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TechPartsReportItem } from "../../models/reports.model";
import { TechPartsReportTileComponent } from "../tech-parts-report-tile/tech-parts-report-tile.component";
import { ReportsService } from "../../services/reports.service";
import { BusinessService } from "../../services/business.service";
import { SupabaseService } from "../../services/supabase.service";
import { ViewAsService } from "../../services/view-as.service";
import { shareReplay, take, tap } from "rxjs/operators";
import { Pagination } from "../../services/pagination.service";
import { BehaviorSubject } from "rxjs";
import { DynamicListComponent } from "../dynamic-list/dynamic-list.component";
import { dateString } from "../../../../../common/src/lib/services";
import { BusinessProfile } from "../../../../../common/src/lib/models/business-profile.model";
import { limitStep } from "../../services/reports-service-extras";

function techPartItemsToPaginationList(items: TechPartsReportItem[], kind: 'Pending' | 'Paid') {
  return items.map(item => ({
    componentClass: TechPartsReportTileComponent,
    header: dateString(item.createdAt),
    args: { item, kind }
  }));
}

@Component({
  selector: 'app-tech-parts-report-items',
  templateUrl: './tech-parts-report-items.component.html',
  styleUrls: ['./tech-parts-report-items.component.scss']
})
export class TechPartsReportItemsComponent implements OnInit, OnDestroy {

  @Input() current!: 'Pending' | 'Paid';

  @ViewChild('pendingList') pendingList!: DynamicListComponent<TechPartsReportTileComponent>;
  @ViewChild('pendingDateFilterList') pendingDateFilterList!: DynamicListComponent<TechPartsReportTileComponent>;
  @ViewChild('paidList') paidList!: DynamicListComponent<TechPartsReportTileComponent>;
  @ViewChild('paidDateFilterList') paidDateFilterList!: DynamicListComponent<TechPartsReportTileComponent>;

  pendingPagination!: Pagination<TechPartsReportItem>;
  pendingDateFilterPagination!: Pagination<TechPartsReportItem>;
  paidPagination!: Pagination<TechPartsReportItem>;
  paidDateFilterPagination!: Pagination<TechPartsReportItem>;

  loading$ = new BehaviorSubject<boolean>(true);

  dateFilter$ = this.reportsService.selectedDateRangeForEvent$.pipe(
    tap(async dates => {
      if (dates) {
        this.loadItems('Pending', 0, undefined, dates, true);
        this.loadItems('Paid', 0, undefined, dates, true);
      } else {
        this.pendingDateFilterList?.clear();
        this.paidDateFilterList?.clear();
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true})
  );

  constructor(
    private reportsService: ReportsService,
    private businessService: BusinessService,
    private supabaseService: SupabaseService,
    private viewAsService: ViewAsService,
    private cd: ChangeDetectorRef
  ) { }

  async ngOnInit() {
    const business = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!;

    this.pendingPagination = this.createPagination(business.businessId, 'tech_parts_pending_report', 'tpr_pen')
      .on(
        '*',
        'other',
        _ => this.handleChange(this.pendingList, 'Pending'),
        'type=eq.tech_part'
      );
    this.pendingDateFilterPagination = this.createPagination(business.businessId, 'tech_parts_pending_report', 'tpr_pen_d')
      .on(
        '*',
        'other',
        _ => this.handleChange(this.pendingDateFilterList, 'Pending'),
        'type=eq.tech_part'
      );
    this.paidPagination = this.createPagination(business.businessId, 'tech_parts_paid_report', 'tpr_paid')
      .on(
        '*',
        'other',
        _ => this.handleChange(this.paidList, 'Paid'),
        'type=eq.tech_part'
      );
    this.paidDateFilterPagination = this.createPagination(business.businessId, 'tech_parts_paid_report', 'tpr_paid_d')
      .on(
        '*',
        'other',
        _ => this.handleChange(this.paidDateFilterList, 'Paid'),
        'type=eq.tech_part'
      );

    await this.loadItems('Pending', 0, business, undefined, false);
    await this.loadItems('Paid', 0, business, undefined, false);
    this.loading$.next(false);
  }

  ngOnDestroy() {
    this.pendingPagination?.destroy();
    this.pendingDateFilterPagination?.destroy();
    this.paidPagination?.destroy();
    this.paidDateFilterPagination?.destroy();
  }

  async loadItems(
    type: 'Pending' | 'Paid',
    inOffset = 0,
    business?: BusinessProfile,
    dates?: [Date, Date] | null,
    dateList?: boolean
  ) {
    if (!business)
      business = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!;

    if (dateList && !dates)
      dates = await this.dateFilter$.pipe(take(1)).toPromise();
    if (!dates)
      dates = [business.createdAt, new Date()];

    const selectedUsers = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();

    const inLimit = limitStep;

    const pagination = type === 'Pending'
      ? !dateList
        ? this.pendingPagination
        : this.pendingDateFilterPagination
      : !dateList
        ? this.paidPagination
        : this.paidDateFilterPagination;

    const list = type === 'Pending'
      ? !dateList
        ? this.pendingList
        : this.pendingDateFilterList
      : !dateList
        ? this.paidList
        : this.paidDateFilterList;

    const items = await pagination.get({
      startTime: dates[0],
      endTime: dates[1],
      rowLimit: inLimit,
      offsetCount: inOffset,
      selectedUsers: selectedUsers ?? []
    });

    if (items.length < inLimit && list.canLoadMoreBottom)
      list.canLoadMoreBottom = false;

    list?.addAtBottom(techPartItemsToPaginationList(items, type));
  }

  async handleChange(list: DynamicListComponent<TechPartsReportTileComponent>, type: string) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();

    list.clear();
    this.cd.detectChanges();
    this.loadItems(
      type as 'Pending' | 'Paid',
      list.getBottomCount,
      business!
    );
  }

  createPagination(businessId: string, fn: string, cname: string) {
    return new Pagination<TechPartsReportItem>(
      this.supabaseService,
      fn,
      businessId,
      cname
    );
  }
}
