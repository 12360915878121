import { Component, Input } from '@angular/core';
import { Proposal } from '../../models/proposal.model';
import { Invoice } from '../../models/invoice.model';
import { ClientItemsResult } from "../../services/clients.service";
import { Job } from "../../models/jobs.model";
import { Estimate } from "../../models/estimate.model";
import { Router } from '@angular/router';

@Component({
  selector: 'app-customer-tile',
  templateUrl: './customer-tile.component.html',
  styleUrls: ['./customer-tile.component.scss']
})
export class CustomerTileComponent {

  @Input() item!: ClientItemsResult<Estimate | Job | Proposal | Invoice>;
  @Input() showUser = false;

  constructor(
    private router: Router
  ) { }

  get users() {
    if (this.item.itemType === 'job')
      return this.item.assigneeCount && this.item.assigneeCount > 1 ? `${this.item.assigneeCount} assignees` : `${this.item.assigneeInfo[0].firstName} ${this.item.assigneeInfo[0].lastName[0]}.`;

    return this.item.ownerFirstName + ' ' + this.item.ownerLastName[0] + '.';
  }

  get assigneeNames() {
    return this.item.assigneeInfo.map(assignee => `${assignee.firstName} ${assignee.lastName[0]}.`).join('\n');
  }

  onClick() {
    this.router.navigate([`/${this.item.itemType}s`, this.item.workflowId, this.item.id], { queryParams: { from : 'customer-list' } });
  }

}
