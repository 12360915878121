import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MatDatepicker, MatDateRangePicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Moment } from 'moment';
import { LobbyPage, ProposalTab, proposalsTabs } from '../../models/navigation.model';
import { ModalBehavior, ModalsService } from '../../../../../common/src/lib/services/modals.service';
import { RequestInvitationModal } from '../../modals/request-invitation/request-invitation.component';
import { ProposalsService, TiledProposal } from "../../services/proposals.service";
import { NavigationService } from '../../services/navigation.service';
import { dateString } from 'projects/common/src/public-api';
import { DateFilter } from '../../models/date-filter.model';
import { LobbyService } from '../../services/lobby.service';
import { EditClientComponent } from "../../modals/edit-client/edit-client.component";
import { Client } from "../../../../../common/src/lib/models";

@Component({
  selector: 'app-proposals',
  templateUrl: './proposals.component.html',
  styleUrls: ['./proposals.component.scss']
})
export class ProposalsComponent {

  tabs = Array.from(proposalsTabs);
  showCreateButton = true;

  user$ = this.authService.user$;

  disableFilter = false;

  loadingProposals = false;

  @ViewChild('heightTester') heightTester: any;
  @ViewChild('picker') datePicker!: MatDateRangePicker<any>;

  datePickerOpen = false;

  page$ = this.navigationService.page$;
  tab$ = this.navigationService.tab$;

  actualSelectedDate!: [Date, Date] | null;

  // proposals$ = this.tab$.pipe(
  //   switchMap(tab => {
  //     switch (tab) {
  //       case 'Won':
  //         return this.proposalsService.wonProposals$.pipe(tiledProposalsForType(tab));
  //       case 'Pending':
  //         return this.proposalsService.pendingProposals$.pipe(tiledProposalsForType(tab));
  //       case 'Declined':
  //         return this.proposalsService.declinedProposals$.pipe(tiledProposalsForType(tab));
  //       case 'Canceled':
  //         return this.proposalsService.canceledProposals$.pipe(tiledProposalsForType(tab));
  //       case 'Drafts':
  //         return this.proposalsService.draftsProposals$.pipe(tiledProposalsForType(tab));
  //     }
  //   }),
  //   // separateItemsByDate(proposal => dateString(proposal.createdAt))
  // );

  dateFilter$ = this.lobbyService.selectedDateRangeForEvent$;
  itemsScrolling = false;

  constructor(
    private authService: AuthService,
    private navigationService: NavigationService<LobbyPage, ProposalTab>,
    private router: Router,
    private route: ActivatedRoute,
    public snackbar: MatSnackBar,
    public modalsService: ModalsService,
    private proposalsService: ProposalsService,
    private lobbyService: LobbyService,
  ) {}

  onTabSelected(tab: string) {
    const useTab = tab === 'From Video' ? 'from-video' : tab.toLowerCase();
    this.router.navigate([useTab], { relativeTo: this.route });
  }

  datesFilter(d: Moment | null) {
    if(!d) {
      return false;
    }
    const now = moment().startOf('day');
    const date = d.startOf('day');
    return date.diff(now) <= 0;
  }

  keys(obj: any) {
    return Object.keys(obj);
  }

  searchResultsForTab(searchResults: any, type: string) {
    return searchResults[type];
  }

  openCreateRequestDialog() {
    this.modalsService.open(RequestInvitationModal, {
      behavior: ModalBehavior.Auto,
      breakpoint: RequestInvitationModal.BREAKPOINT,
      disableClose: true,
      data: {}
    });
  }

  async createProposal(): Promise<void> {
    await this.modalsService.open(EditClientComponent, {
      behavior: ModalBehavior.Auto,
      disableClose: true,
      data: {
        client: {} as Client,
        proposalsService: this.proposalsService,
        creation: true,
        which: 'Proposal'
      }
    });
  }

  openPicker(picker: MatDatepicker<any>) {
    if(!this.disableFilter)
      picker.open();
  }

  objectLength(obj: any) {
    return Object.keys(obj).length;
  }

  selectedRangeChange(event: [Date, Date] | null) {
    this.actualSelectedDate = event;
  }

  datePickerClose(status: boolean) {
    if (!status)
      this.lobbyService.setSelectedDateRange(this.actualSelectedDate ? DateFilter.from(this.actualSelectedDate) : null);
  }
}
