<div class="main-pay-commission">
  <div class="header">
    <h3>{{ title }} Commission</h3>
    <app-icon class="se-icon-button" src="close" (click)="close()"></app-icon>
  </div>

  <div class="content">
    <div class="payload">
      <div class="payload-row" [class.payment-error]="overAmount">
        <div class="payload-label">Amount</div>
        <div class="amount-field">
          <mat-form-field appearance="outline">
            <div class="d-flex">
              <div class="d-flex align-items-center mr--4">$</div>
              <input matInput
                     [(ngModel)]="inputAmount"
                     inputmode="decimal"
                     mask="separator.2" thousandSeparator="" decimalMarker="."
                     [allowNegativeNumbers]="false" [dropSpecialCharacters]="false"
                     (keydown)="numbersOnly($event)" onpaste="return false;"
              >
            </div>
            <div *ngIf="overAmount" class="payment-error">Amount exceeds commission</div>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="button-section">
      <div class="button close" (click)="close()">Close</div>
      <loader-button class="button add-edit" [loading]="loading"
                     [text]="actionButton" (click)="save()"
      ></loader-button>
    </div>
  </div>
</div>