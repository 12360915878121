import { Component, EventEmitter, Input, Output } from '@angular/core';
import { capitalizeFirstChar } from 'projects/common/src/public-api';
import { UserStatus } from '../../../../../../common/src/lib/models/user-profile.model';

@Component({
  selector: 'app-user-button',
  templateUrl: './user-button.component.html',
  styleUrls: ['./user-button.component.scss']
})
export class UserButtonComponent {

    @Input() name!: string;
    @Input() selected!: boolean;
    @Input() status?: UserStatus;
    @Input() type?: 'owner' | 'admin' | 'user';
    @Input() disallowRemove?: boolean;

    useStatus?: UserStatus;

    @Output() onRemove = new EventEmitter();

    ngOnInit() {
      if(this.status === 'inactive')
        this.useStatus = 'inactive';
      else if(this.status === 'inactive_suspended' || this.status === 'suspended')
        this.useStatus = 'suspended';
    }

    capitalizeFirstChar = capitalizeFirstChar;

    remove(event: Event) {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.onRemove.emit();
    }

}
