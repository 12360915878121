import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalsService } from 'projects/common/src/lib/services/modals.service';
import { UtilsService } from 'projects/common/src/public-api';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { showSnackbar } from 'projects/common/src/lib/components/snackbar/snackbar.component';
import { UsersService } from './services/users.service';
import { PlacesService } from 'projects/common/src/lib/services/places.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  
  @ViewChild('appComponent') appComponent!: ElementRef<HTMLDivElement>;

  loading = false;

  modalComponent$ = this.modalsService.modalComponent$;

  modalsResizeSub?: Subscription

  get isMobile() {
    return this.utilsService.isMobile();
  }

  get isIOS() {
    return this.utilsService.isIOS();
  }

  constructor(
    private placeService: PlacesService,
    private modalsService: ModalsService,
    private utilsService: UtilsService,
    private snackbar: MatSnackBar,
    private usersService: UsersService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  async ngOnInit() {
    if(!this.utilsService.isMobile()) {
      this.document.body.classList.add('not-touch-screen');
    }
    this.utilsService.isOnline$.subscribe(isOnline => {
      showSnackbar(this.snackbar, {
        message: isOnline ? "You're back online" : "Internet connection lost",
        duration: isOnline ? undefined : -1
      });
    });
    this.usersService.onAppLoad();

    this.loading = true;

    this.modalsService.resizeObservable$.subscribe();

    this.loading = false;
  }

  ngAfterViewInit() {
    this.placeService.init(this.appComponent.nativeElement);
  }

  ngOnDestroy(): void {
    this.modalsResizeSub?.unsubscribe();
  }

}
