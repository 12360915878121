<div class="toolbar" *ngIf="show">
    <div class="container h-100 d-flex align-items-center justify-content-between">

        <img *ngIf="!leftAction" class="logo" src="../../../assets/logo.svg" (click)="logoClick()">
        <p *ngIf="!!leftAction" class="se-button" (click)="onBackClick()">{{ leftAction }}</p>

        <ng-container *ngIf="showSearchField">
            <app-search
                [control]="searchControl"
            ></app-search>
        </ng-container>

        <ng-content></ng-content>

        <div class="d-flex align-items-center" style="gap: 12px">
            <app-select-business-button *ngIf="type === 'menu'"></app-select-business-button>
            <app-view-as-button *ngIf="type === 'menu'"></app-view-as-button>
            <app-notifications-button *ngIf="type === 'menu'"></app-notifications-button>
            <app-icon class="se-icon-button" *ngIf="type === 'menu'" [matMenuTriggerFor]="menu"
                      #menuTrigger="matMenuTrigger"
                      [hideMenuOnScreenSizeChange]="menuTrigger" src="menu"></app-icon>
        </div>

    </div>
</div>

<mat-menu #menu="matMenu" class="hide-active" xPosition="before">
    <mat-option *ngIf="showLobbyOption" routerLink="/lobby">Lobby</mat-option>
    <mat-option *ngFor="let item of (menuItems$ | async)" [routerLink]="item.link"
                routerLinkActive="active">{{ item.title }}</mat-option>
    <mat-option id="button_signOut" class="dimmed" (click)="logout()">Sign out</mat-option>
</mat-menu>
