<dynamic-list #list class="h-100" [style.display]="(dateFilter$ | async) ? 'none' : 'block'"
    (loadMoreBottom)="loadItems($event)"
    (itemEvent)="onItemEvent($event)"
    [initialBottomItems]="initialItems"
    [stayOnTop]="true"
>
    <app-empty-state
        [tab]="tab"
        [page]="type === 'Proposal' ? 'Proposals' : 'Invoices'"
    ></app-empty-state>
</dynamic-list>
<dynamic-list class="h-100" #dateFilterList
    *ngIf="dateFilter$ | async"
    (loadMoreBottom)="loadDateFilterItems($event)"
    [canLoadMoreBottom]="initialFilterCanLoadMore"
    (itemEvent)="onItemEvent($event)"
    [initialBottomItems]="initialFilterItems"
    [hasDateFilter]="true"
    [stayOnTop]="true"
>
    <app-empty-state
        class="h-100"
        [page]="type === 'Proposal' ? 'Proposals' : 'Invoices'"
        filter="Range"
    ></app-empty-state>
</dynamic-list>
