import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AddUserComponent } from 'projects/business-slick-estimates/src/app/modals/add-user/add-user.component';
import { ConfirmationDialog } from 'projects/common/src/lib/modals/confirmation-dialog/confirmation-dialog.component';
import { UserProfile } from '../../../../../../../common/src/lib/models/user-profile.model';
import { ModalBehavior, ModalsService } from 'projects/common/src/lib/services/modals.service';
import { UsersService } from 'projects/business-slick-estimates/src/app/services/users.service';
import { showSnackbar } from 'projects/common/src/lib/components/snackbar/snackbar.component';
import { UtilsService, capitalizeFirstChar } from 'projects/common/src/public-api';
import { take, map } from 'rxjs/operators';

@Component({
  selector: 'app-user-tile',
  templateUrl: './user-tile.component.html',
  styleUrls: ['./user-tile.component.scss']
})
export class UserTileComponent implements OnInit {

  @Input() user!: UserProfile;
  @Input() businessId!: string;
  @Input() hideMenu?: boolean;
  @Input() hideSuspend?: boolean;

  get label() {
    return {
      'inactive' : "Not active",
      'active' : "Active",
      'suspended' : "Suspended",
      'inactive_suspended': "Suspended"
    }[this.user.status];
  }

  get labelColor() {
    return {
      'inactive' : undefined,
      'active' : 'green',
      'suspended' : 'red',
      'inactive_suspended': 'red'
    }[this.user.status] as ('green' | 'red' | undefined);
  }

  smallScreen$ = this.utilsService.onScreenBreakpointChange('md');

  notificationsControl = new FormControl();
  notificationsState$ = this.usersService.currentUser$.pipe(
    map(currentUser => {
      const notifications = currentUser.notificationsSubscribed;
      return notifications?.includes(this.user.email) ?? false;
    })
  );

  showNotificationsToggle = true;
  checked = false;

  constructor(
    private modalsService: ModalsService,
    private usersService: UsersService,
    private utilsService: UtilsService,
    private snackbar: MatSnackBar
  ) {}

  async ngOnInit() {
    const currentUser = await this.usersService.currentUser$.pipe(take(1)).toPromise();
    this.showNotificationsToggle = currentUser.id !== this.user.id;
    if(currentUser.id !== this.user.id) {
      const notifications = currentUser.notificationsSubscribed;
      this.checked = notifications?.includes(this.user.email) ?? false;
      this.notificationsControl.setValue(this.checked);
    }
  }

  capitalizeFirstChar = capitalizeFirstChar;

  async updateUserNotifications(event: MatSlideToggleChange) {
    const currentUser = await this.usersService.currentUser$.pipe(take(1)).toPromise();
    const notifications = event.checked;
    const users = new Set(currentUser.notificationsSubscribed);
    if(event.checked) {
      users.add(this.user.userUuid);
    } else {
      users.delete(this.user.userUuid);
    }
    this.notificationsControl.setValue(notifications);
    return this.usersService.updateUser({ notificationsSubscribed: Array.from(users) }, currentUser);
  }

  editUser() {
    this.modalsService.open(AddUserComponent, {
      data: {
        userProfile: this.user
      },
      behavior: ModalBehavior.Auto
    });
  }

  suspendUser() {
    this.modalsService.open(ConfirmationDialog, {
      behavior: ModalBehavior.Dialog,
      data: {
        title: 'Suspend access',
        message: `Are you sure you want to suspend ${this.user.firstName}? `,
        actionTitle: 'Yes',
        cancelTitle: 'No',
        action: async () => {
          const res = await this.usersService.suspendUser(this.user);
          if(res) {
            showSnackbar(this.snackbar, {
              message: "User suspended"
            });
          }
          return { close: res, reopen: false };
        },
        actionColor: 'warn'
      }
    });
  }

  unsuspendUser() {
    this.modalsService.open(ConfirmationDialog, {
      behavior: ModalBehavior.Dialog,
      data: {
        title: 'Restore access',
        message: `Are you sure you want to restore access to ${this.user.firstName}? `,
        actionTitle: 'Yes',
        cancelTitle: 'No',
        action: async () => {
          const res = await this.usersService.unsuspendUser(this.user);
          if(res) {
            showSnackbar(this.snackbar, {
              message: "Access restored"
            });
          }
          return { close: res, reopen: false };
        },
        actionColor: 'primary'
      }
    });
  }

  resendInvitation() {
    this.modalsService.open(ConfirmationDialog, {
      behavior: ModalBehavior.Dialog,
      data: {
        title: 'Resend invitation',
        message: `Resend invitation to ${this.user.email}? `,
        actionTitle: 'Yes',
        cancelTitle: 'No',
        action: async () => {
          const res = await this.usersService.resendInvitation(this.user.id);
          if(res) {
            showSnackbar(this.snackbar, {
              message: "Invitation resent"
            });
          }
          return { close: res, reopen: false };
        },
        actionColor: 'primary'
      }
    });
  }

  deleteUser() {
    this.modalsService.open(ConfirmationDialog, {
      behavior: ModalBehavior.Dialog,
      data: {
        title: 'Delete user',
        message: `Are you sure you want to remove ${this.user.firstName} from your business? `,
        actionTitle: 'Yes',
        cancelTitle: 'No',
        action: async () => {
          const user = this.user;
          this.usersService.deleteUser(this.user);
          showSnackbar(this.snackbar, {
            message: "User deleted",
            actionText: "Undo",
            duration: 30000,
            action: async () => {
              await this.usersService.addUser(
                  user.email,
                  user.firstName,
                  user.lastName,
                  user.role,
                  user.permissions,
                  user.permissionsAllowedByOwner,
                  user.jobCommissionValue,
                  user.jobCommissionType,
                  user.jobCommissionAfterMaterials,
                  user.salesCommissionValue,
                  user.salesCommissionType,
                  user.salesCommissionAfterMaterials
              );
            }
          });
        },
        actionColor: 'warn'
      }
    });
  }

}
