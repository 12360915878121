import { animate, style, transition, trigger } from "@angular/animations";

export const fadeIn = trigger(
  'fadeIn', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('{{time}} ease-in-out', style({ opacity: 1 }))
    ], { params : { time: '1000ms' } }),
    transition(':leave', [
      style({ opacity: 1 }),
      animate('{{time}} ease-in-out', style({ opacity: 0 }))
    ], { params : { time: '1000ms' } }),
  ]
)
