import { Inject, Injectable } from '@angular/core';
import { SupportChatMessage, Ticket } from "../models/support-chat.model";
import { RealtimeChannel } from "@supabase/realtime-js";
import { Observable } from "rxjs";
import { FormControl } from "@angular/forms";
import { shareReplay } from 'rxjs/operators';
import { serverToFrontTranslation, SupabaseService } from './supabase.service';

@Injectable({
    providedIn: 'root'
})
export class SupportChatService {

    private readonly _supabase = this.supabaseService.supabase;

    public messageInput: FormControl = new FormControl('');

    constructor(
        @Inject('SupabaseService') private supabaseService: SupabaseService
    ) {}

    currentTicketObservable(businessId: string, userUuid: string) {
        return new Observable<Ticket | null>(observer => {
            this._supabase
                .schema('admin_panel')
                .from('tickets')
                .select('*, system_users (first_name, last_name)')
                .eq('business_uuid', businessId)
                .eq('business_user_uuid', userUuid)
                .neq('status', 'closed')
                .then(res => {
                    let ticket: Ticket;

                    if (res.error || res.data.length === 0) {
                        if (res.error)
                            console.warn(res.error);
                        observer.next(null);
                    } else {
                        ticket = serverToFrontTranslation(res.data)[0];
                        ticket.systemUserFirstName = (ticket as any).systemUsers.firstName;
                        ticket.systemUserLastName = (ticket as any).systemUsers.lastName;
                        delete (ticket as any).systemUsers;
                        observer.next(ticket);
                    }

                    this._supabase.channel(`ticket_${businessId}_${userUuid}`)
                        .on(
                            'postgres_changes',
                            {event: '*', schema: 'admin_panel', table: 'tickets', filter: `business_user_uuid=eq.${userUuid}`},
                            async (payload) => {
                                const data = serverToFrontTranslation(payload.new);
                                if (data.businessUuid !== businessId)
                                    return;
                                if (payload.eventType === 'DELETE' || payload.eventType === 'UPDATE' && payload.new.status === 'closed') {
                                    observer.next(null);
                                    return;
                                }
                                if (payload.eventType === 'INSERT') {
                                    ticket = data;
                                    const userRes = await this._supabase
                                        .schema('admin_panel')
                                        .from('system_users')
                                        .select('first_name, last_name')
                                        .eq('id', ticket.systemUserId);

                                    if (userRes.error || userRes.data.length === 0) {
                                        console.warn(userRes.error);
                                        observer.next(null);
                                        return;
                                    }
                                    const systemUser = serverToFrontTranslation(userRes.data)[0];
                                    ticket.systemUserFirstName = systemUser.firstName;
                                    ticket.systemUserLastName = systemUser.lastName;
                                } else {
                                    ticket = {...ticket, ...(data as any)};
                                }
                                observer.next(ticket);
                            }
                        )
                        .subscribe();
                });
        }).pipe(shareReplay(1));
    }

    async getInitialTicket(id: number) {
        const res = await this._supabase
            .schema('admin_panel')
            .from('tickets')
            .select()
            .eq('id', id)
            .single();
        if (res.error) {
            console.warn(res.error);
            return null;
        }
        return serverToFrontTranslation(res.data);
    }

    async getInitialMessages(chatId: number) {
        const res = await this._supabase
            .schema('admin_panel')
            .from('messages')
            .select()
            .eq('ticket_id', chatId)
            .order('created_at');
        if (res.error) {
            console.warn(res.error);
            return null;
        }
        return serverToFrontTranslation(res.data);
    }

    subscribeToTicket(id: number, callback: (ticket: Ticket) => void) {
        return this._supabase
            .channel('ticket_' + id)
            .on(
                'postgres_changes',
                {event: 'UPDATE', schema: 'admin_panel', table: 'tickets', filter: `id=eq.${id}`},
                (payload) => {
                    callback(serverToFrontTranslation(payload.new) as Ticket);
                }
            )
            .subscribe();
    }

    subscribeToMessages(chatId: number, onInsert: (messages: SupportChatMessage) => void, onUpdate: (messages: SupportChatMessage) => void) {
        return new Promise<RealtimeChannel>(resolve => {
            const channel = this._supabase
            .channel('messages_' + chatId)
            .on(
                'postgres_changes',
                {event: '*', schema: 'admin_panel', table: 'messages', filter: `ticket_id=eq.${chatId}`},
                (payload) => {

                    if (payload.eventType === 'INSERT') {
                        onInsert(serverToFrontTranslation(payload.new))
                    } else if (payload.eventType === 'UPDATE') {
                        onUpdate(serverToFrontTranslation(payload.new))
                    }
                }
            )
            .subscribe(_ => resolve(channel));
        });
    }

    async markAsSeen(id: number) {
        const res = await this._supabase.schema('admin_panel')
            .rpc('set_message_seen', { id });
        if (res.error)
            throw res.error;
    }

    async sendRating(rating: number, ticket_id: number) {
        const res = await this._supabase.schema('admin_panel')
            .rpc('rate_and_close_ticket', { rating, ticket_id });
        if (res.error)
            throw res.error;
    }

    async sendMessage(businessId: string, businessUserId: string, senderId: string, content: string, attachment?: string) {
        const data: any = {
            business_uuid: businessId,
            business_user_uuid: businessUserId,
            sender_uuid: senderId,
            content
        };
        if(attachment)
            data.attachment = attachment;
        const res = await this._supabase.schema('admin_panel')
            .rpc('send_message', data);
        if (res.error)
            throw res.error;
    }

    async getDownloadFile(fileName: string) {
        const res = await this._supabase
            .storage
            .from('support')
            .download(fileName);
        if(res.error)
            throw res.error;
        return res.data;
    }

    async uploadImage(file: File, storagePath: string) {
        const res = await this._supabase
            .storage
            .from('support')
            .upload(storagePath, file, {
                cacheControl: '3600',
                contentType: file.type,
                upsert: true
            });
        if(res.error)
            throw res.error;
    }

    async createTicket(businessId: string, userUuid: string) {
        const res = await this._supabase.schema('admin_panel').rpc('create_ticket', { business_uuid: businessId, business_user_uuid: userUuid });
        if(res.error)
            throw res.error;

        return serverToFrontTranslation(res.data) as Ticket;
    }

    unsubscribe(channel: RealtimeChannel) {
        return this._supabase.removeChannel(channel);
    }

}
