<div class="page bg">

    <app-toolbar type="login"></app-toolbar>

    <div class="d-flex flex-column flex-fill justify-content-start align-items-stretch">

        <div class="d-flex flex-fill justify-content-center container">

            <mat-card class="mat-elevation-z3">

                <mat-card-title><h2 class="text-center second-title">Reset password</h2></mat-card-title>

                <form class="d-flex flex-column align-items-stretch">
                    <h4>New password</h4>
                    <mat-form-field appearance="outline" appFormFieldFilled>
                        <input matInput #passwordInput id="input_password"
                               [formControl]="passwordControl"
                               type="password"
                               placeholder="At least 6 characters"
                               autocomplete="new-password"
                               [type]="showPassword ? 'text' : 'password'"
                               enterkeyhint="go"
                               tabindex="4"
                        />
                        <img *ngIf="passwordControl.value!.length > 0" matSuffix
                             [src]="'../../../assets/icons/password-' + (showPassword ? 'hide' : 'show') +'.svg'"
                             (click)="showPassword = !showPassword">
                        <mat-error *ngIf="passwordControl.hasError('required') && passwordControl.touched">
                            Password is required
                        </mat-error>
                        <mat-error *ngIf="passwordControl.hasError('different') && passwordControl.touched">
                            Enter a different password
                        </mat-error>
                        <mat-error *ngIf="passwordControl.hasError('minlength') && passwordControl.touched">
                            at least 6 characters
                        </mat-error>
                    </mat-form-field>

                    <loader-button class="footer" [loading]="loading" text="Submit" id="button_signup"
                        (onClick)="changePassword()"></loader-button>

                </form>

            </mat-card>
        </div>
    </div>
</div>