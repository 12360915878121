import { Component, Inject, OnDestroy, Optional, ViewChild } from '@angular/core';
import { ModalsService } from "projects/common/src/lib/services/modals.service";
import { FormControl, UntypedFormBuilder, Validators } from "@angular/forms";
import { ItemsService } from "../../services/items.service";
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { debounceTime, map, startWith, switchMap } from "rxjs/operators";
import { isEmpty, numbersOnly } from "../../helpers/forms";
import { fadeIn } from "../../helpers/animations";
import { BehaviorSubject, Subscription } from "rxjs";
import { CreateOrEditModalData, Item } from "../../models/item.model";
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";
import { MatLegacyAutocomplete } from "@angular/material/legacy-autocomplete";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";

type ModalStates = 'empty' | 'create' | 'update' | 'createNewItem';

@Component({
  selector: 'app-create-or-edit-item',
  templateUrl: './create-or-edit-item.component.html',
  styleUrls: ['./create-or-edit-item.component.scss'],
  animations: [ fadeIn ],
})
export class CreateOrEditItemComponent implements OnDestroy {

  protected readonly isEmpty = isEmpty;
  protected readonly numbersOnly = numbersOnly;

  @ViewChild('autocomplete') autocomplete!: MatLegacyAutocomplete;

  title: 'Create Item' | 'Edit Item' = 'Create Item';

  form = this.formBuilder.group({
    name: new FormControl<string>(''),
    description: new FormControl<string>(''),
    cost: new FormControl<number | null>(null,[Validators.pattern(/^\d+(\.\d{0,2})?$/)]),
    price: new FormControl<number | null>(null, [Validators.pattern(/^\d+(\.\d{0,2})?$/)]),
    taxable: new FormControl(false),
  });

  loading = false;

  protected _modalState$ = new BehaviorSubject<ModalStates>('empty');
  protected modalState$ = this._modalState$.asObservable();

  formSubscription!: Subscription;

  allowDeletion = false;

  get data(): CreateOrEditModalData {
    return this.modalsService.data;
  }

  get taxPercentage() {
    return this.data.salesTaxPercentage;
  }

  nameSuggestions$ = this.itemsService.items$.pipe(
    switchMap(items => {
      return this.form.get('name')!.valueChanges.pipe(
        startWith(''),
        debounceTime(30),
        map((name: string) => {
          if (name.length < 2)
            return [];
          const nameSuggestions: { name: string, price: string }[] = [];
          items?.forEach(item => {
            if (
              item.name?.toLocaleLowerCase().trim().includes(name.toLocaleLowerCase())
              && item.name.trim() !== ''
            )
              nameSuggestions.push({ name: item.name, price: ' ($' + item.price.toFixed(2) +')' });
          });
          return nameSuggestions;
        })
      )
    })
  );

  constructor(
    private modalsService: ModalsService,
    private formBuilder: UntypedFormBuilder,
    private itemsService: ItemsService,
    private snackbar: MatSnackBar,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: CreateOrEditModalData
  ) {
    if (this.data?.mode === 'edit') {
      this.title = 'Edit Item';
      this.form.patchValue(this.data.item!);
      this.allowDeletion = true;
    }
    this.formSubscription = this.form.valueChanges.subscribe(form => {
      this.allowDeletion = false;
      const emptyForm = isEmpty(form.name.trim())
        && isEmpty(form.description)
        && isEmpty(form.price)
        && isEmpty(form.cost);
      if (emptyForm) {
        this.data.mode = 'create';
        this.data.item = undefined;
        this.title = 'Create Item';
        this._modalState$.next('empty');
      } else {
        if (this.data.mode === 'create') {
          this.title = 'Create Item';
          this._modalState$.next('create');
        } else {
          this.title = 'Edit Item';
          const nameChanged = form.name.trim() !== this.data.item!.name.trim();
          const formPrice = parseFloat(form.price);
          const itemPrice = this.data.item!.price;
          const formCost = parseFloat(form.cost);
          const itemCost = this.data.item!.cost;
          //formPrice, itemPrice, formCost, itemCost are created for correct comparison
          // (in the earlier version type string are compared with type number)
          const contentChanged = form.description !== this.data.item!.description
            || formPrice !== itemPrice
            || formCost !== itemCost && !(isNaN(formCost) && typeof itemCost === 'number' && itemCost.toString() === '')
            || form.taxable !== this.data.item!.taxable;
          if (!nameChanged && contentChanged) {
            this._modalState$.next('update')
          } else if (nameChanged) {
            this.title = 'Create Item';
            this._modalState$.next('createNewItem');
          } else {
            this._modalState$.next('empty');
          }
        }
      }
    });
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
  }

  async close() {
    await this.modalsService.close();
  }

  getMarkup(): string {
    const price = this.form.get('price')?.value;
    const cost = this.form.get('cost')?.value;

    if (isEmpty(price) || isEmpty(cost))
      return '0';
    else {
      const fullMarkup = (+price-(+cost))/(+cost) * 100;
      const value = (Math.round(fullMarkup * 100) / 100).toFixed(2);
      return isNaN(+value) ? 'error' : value;
    }
  }

  getTaxAmount() {
    const price = this.form.get('price')?.value;

    if (isEmpty(price))
      return '0';
    else {
      const taxedPrice = ((+price)*this.taxPercentage/100);
      const value = (Math.round(taxedPrice * 100) / 100).toFixed(2);
      return isNaN(+value) ? 'error' : value;
    }
  }

  async deleteItem() {
    if (this.allowDeletion) {
      const itemData = { ...this.data.item };
      await this.itemsService.deleteItem(this.data.item!.id);
      showSnackbar(this.snackbar, {
        message: 'Item deleted',
        duration: 10000,
        actionText: 'Undo',
        action: (async () => {
          await this.itemsService.createItem(itemData! as Item, itemData.createdAt);
        }),
      });
      await this.modalsService.close();
    }
  }

  async create() {
    this.loading = true;
    if (this.validForm())
      try {
        const value = this.form.value;
        if (value.cost === '' || +(value.cost) === 0)
          value.cost = null;
        await this.itemsService.createItem(value);
        await this.modalsService.close();
        showSnackbar(this.snackbar, {
          message: 'Item created',
          duration: 2000,
        });
      } catch (e) {
        this.handleItemErrors(e);
      }
    this.loading = false;
  }

  async update() {
    this.loading = true;
    if (this.validForm()) {
      const updatedItem = {
        ...(this.form.get('description')?.value != this.data.item?.description && { description: this.form.get('description')?.value }),
        ...(this.form.get('price')?.value != this.data.item?.price && { price: this.form.get('price')?.value }),
        ...(this.form.get('cost')?.value != this.data.item?.cost && {
          cost: (+(this.form.get('cost')?.value) !== 0 && this.form.get('cost')?.value !== '')
            ? this.form.get('cost')?.value
            : null
        }),
        ...(this.form.get('taxable')?.value != this.data.item?.taxable && { taxable: this.form.get('taxable')?.value }),
      };
      try {
        await this.itemsService.updateItem(this.data.item?.id!, updatedItem);
        await this.modalsService.close();
        showSnackbar(this.snackbar, {
          message: 'Item updated',
          duration: 2000,
        });
      } catch (e) {
        this.handleItemErrors(e);
      }
    }
    this.loading = false;
  }

  private validForm(): boolean {
    const name = this.form.get('name')?.value;
    const price = this.form.get('price')?.value;

    if (isEmpty(name)) {
      this.form.get('name')!.setErrors({
        emptyNameError: true
      });
      this.form.get('name')!.markAsTouched();
    }
    if (isEmpty(price)) {
      this.form.get('price')!.setErrors({
        emptyPriceError: true
      });
      this.form.get('price')!.markAsTouched();
    }

    if (
      this.form.get('name')?.hasError('emptyNameError')
      || this.form.get('price')?.hasError('emptyPriceError')
    )
      return false;

    this.form.get('name')!.setErrors(null);
    this.form.get('price')!.setErrors(null);
    return true;
  }

  async nameSelected(event: any) {
    this.data.mode = 'edit';
    const selectedItem = await this.itemsService.itemByName(event.option.value);
    if (selectedItem) {
      this.data.item = selectedItem;
      this.form.patchValue(selectedItem);
    }
  }

  private handleItemErrors(e: any) {
    console.log(e.message)
    if(e.message.startsWith('duplicate key')) {
      this.form.get('name')!.setErrors({
        alreadyUsedNameError: true
      });
      return;
    }
    switch (e.message) {
      case 'Item price is required.':
        this.form.get('price')!.setErrors({
          emptyPriceError: true
        });
        break;
      case 'Item name is required.':
        this.form.get('name')!.setErrors({
          emptyNameError: true
        });
        break;
      default:
        alert('Something went wrong!');
    }
  }

  setTaxable(on: boolean) {
    this.form.get('taxable')?.setValue(on);
    if (on && isEmpty(this.form.get('price')?.value)) {
      this.form.get('price')!.setErrors({
        emptyPriceError: true
      });
      this.form.get('price')!.markAsTouched();
    } else
      this.form.get('price')!.setErrors(null);
  }

  getTotal(): string {
    if (this.form.get('taxable')?.value === false) {
      return (+this.form.get('price')?.value).toFixed(2);
    }

    const value = ((+this.form.get('price')?.value) + (+this.getTaxAmount())).toFixed(2);
    if (isNaN(+value))
      return 'error';

    return value;
  }

  tabHandle(event: any, nameField: any) {
    if (event.key === 'Tab') {
      event.preventDefault();
      nameField.focus();
    }
  }
}
