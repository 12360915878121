<ng-container *ngIf="{ value: dateFilter$ | async } as dateFilter">
  <ng-container *ngIf="{ value: loading$ | async } as loading">

    <dynamic-list #pendingList class="h-100"
                  [style.display]="dateFilter.value ? 'none' : 'block'"
                  [class.hide]="current === 'Paid'"
                  [canLoadMoreTop]="false"
                  (loadMoreBottom)="loadItems(current, $event)"
    >
      <app-empty-state page="Tech Parts" tab="Pending" [style.display]="loading.value ? 'none' : 'block'"></app-empty-state>
    </dynamic-list>

    <dynamic-list #pendingDateFilterList class="h-100"
                  [style.display]="dateFilter.value ? 'block' : 'none'"
                  [class.hide]="current === 'Paid'"
                  [canLoadMoreTop]="false"
                  (loadMoreBottom)="loadItems(current, $event, undefined, dateFilter.value, true)"
    >
      <app-empty-state filter="Range" [style.display]="loading.value ? 'none' : 'block'"></app-empty-state>
    </dynamic-list>

    <dynamic-list #paidList class="h-100"
                  [style.display]="dateFilter.value ? 'none' : 'block'"
                  [class.hide]="current === 'Pending'"
                  [canLoadMoreTop]="false"
                  (loadMoreBottom)="loadItems(current, $event)"
    >
      <app-empty-state page="Tech Parts" tab="Paid" [style.display]="loading.value ? 'none' : 'block'"></app-empty-state>
    </dynamic-list>

    <dynamic-list #paidDateFilterList class="h-100"
                  [style.display]="dateFilter.value ? 'block' : 'none'"
                  [class.hide]="current === 'Pending'"
                  [canLoadMoreTop]="false"
                  (loadMoreBottom)="loadItems(current, $event, undefined, dateFilter.value, true)"
    >
      <app-empty-state filter="Range" [style.display]="loading.value ? 'none' : 'block'"></app-empty-state>
    </dynamic-list>

  </ng-container>
</ng-container>
