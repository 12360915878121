export function applyPhoneNumberMask(value: string): string {
    // Remove all non-numeric characters
    let numericValue = value.replace(/\D/g, '');

    // Apply the mask
    let maskedValue = '';

    if (numericValue.length > 0) {
        maskedValue += '(' + numericValue.substring(0, 3);
    }
    if (numericValue.length >= 4) {
        maskedValue += ') ' + numericValue.substring(3, 6);
    }
    if (numericValue.length >= 7) {
        maskedValue += '-' + numericValue.substring(6, 10);
    }

    return maskedValue;
}
export function removePhoneNumberMask(value: string): string {
    return value.replace(/\D/g, '');
}