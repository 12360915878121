<ng-container *ngIf="{ value: (fullItemAccess$ | async) ?? false } as fullItemAccess">
  <div class="proposal-items-list" *ngIf="!mobileView">

    <div *ngIf="proposalVersion" cdkDropList (cdkDropListDropped)="dropAction($event)" class="items-list">
      <div *ngFor="let proposalItem of proposalVersion.items; let i = index"
           class="proposal-items-list-item" cdkDrag
           (cdkDragStarted)="dragging = true"
           (cdkDragEnded)="dragging = false"
           (click)="editItem(proposalItem, fullItemAccess.value)"
      >
        <div class="proposal-item-left flex-fill"
             (mouseenter)="itemPrice.classList.add('primary-dark-color')"
             (mouseleave)="itemPrice.classList.remove('primary-dark-color')"
             #proposalItemLeft
        >
          <app-icon class="clickable hide" src="6dots" cdkDragHandle
                    [class.hide]="(proposalVersion.items ?? []).length < 2"
                    (click)="$event.stopPropagation()"
          ></app-icon>
          <div class="item-index" [class.two-char]="i>=9" [style.opacity]="dragging ? '0' : '100'">{{i+1}}</div>
          <div class="item-name" [class.two-char]="i>=9">{{proposalItem.name}}</div>
        </div>
        <div class="proposal-item-right">
          <div class="item-qty-price" [id]="'qty-price-'+i" (click)="$event.stopPropagation()">
            <div class="item-qty">
              <h4 class="item-qty-text">Qty</h4>
              <mat-form-field appearance="outline" class="width--38 height--24">
                <input matInput style="bottom: 8px"
                       maxlength="4"
                       [defaultValue]="proposalItem.quantity"
                       (input)="qtyFieldInput($event, proposalItem, false, 'qty-price-'+i)"
                       (focusout)="qtyFocus$.next(false)"
                       (focus)="qtyFocus$.next(true)"
                       [id]="'qty-input'+i"
                       autocomplete="off"
                       class="tac fw-600 position-relative"
                       inputmode="decimal"
                       (keypress)="numbersOnly($event)"
                       onpaste="return false;"
                       ondrop="return false;"
                >
              </mat-form-field>
            </div>
            <div class="item-price" (click)="editItem(proposalItem, fullItemAccess.value)"
                 (mouseenter)="proposalItemLeft.classList.add('primary-dark-color')"
                 (mouseleave)="proposalItemLeft.classList.remove('primary-dark-color')"
            >
              <h4 class="w-100 tar" #itemPrice>
                ${{(+getItemPrice(proposalItem)).toFixed(2)}}
              </h4>
            </div>
          </div>
          <div class="item-info-edit" (click)="$event.stopPropagation()">
            <app-workflow-item-info [poIItem]="proposalItem"
                                    [index]="i"
                                    [hoveredItemIndex]="hoveredItemIndex"
                                    *ngIf="infoIconDistancesFromTop.length > 0"
                                    [distanceFromTop]="infoIconDistancesFromTop[i]"
                                    [salesTaxPercentage]="proposalVersion.clientSalesTaxPercentage!"
                                    [businessId]="(businessId$ | async)!"
            ></app-workflow-item-info>
            <app-icon class="item-info-icon" src="item-info"
                      (mouseenter)="hoveredItemIndex = i"
                      (mouseleave)="hoveredItemIndex = null"
                      [id]="'info-icon-'+i"
            ></app-icon>
            <app-icon class="clickable dots3-icon" src="3dots" [matMenuTriggerFor]="itemActionsMenu"
                      [matMenuTriggerData]="{ item: proposalItem, index: i }"
            ></app-icon>
          </div>
        </div>
      </div>
    </div>

    <div class="add-proposal-item"
         [class.no-items]="(proposalVersion.items ?? []).length === 0"
         (click)="openAddProposalItemModal(fullItemAccess.value)"
         *ngIf="proposalVersion.clientSalesTaxPercentage"
    >
      <app-icon class="se-icon-button" src="plus"></app-icon>
      <p class="add-proposal-item-button-text">Item</p>
    </div>

    <app-proposal-summary *ngIf="(proposalVersion.items ?? []).length > 0"
                          [proposal]="proposal" [proposalVersion]="proposalVersion"
                          (depositFocus)="itemsListFocus.emit($event)"
    ></app-proposal-summary>

  </div>

  <div class="proposal-items-list-mobile" *ngIf="mobileView">

    <div *ngIf="(proposalVersion.items ?? []).length > 0" class="proposal-items-mobile">
      <div *ngFor="let proposalItem of proposalVersion.items let i = index"
           class="proposal-item-mobile"
      >

        <div class="proposal-item-mobile-top" (click)="editItem(proposalItem, fullItemAccess.value)">
          <div class="name">{{proposalItem.name}}</div>
          <div class="actions" (click)="$event.stopPropagation()">
            <app-icon class="dots3-icon" src="3dots" [matMenuTriggerFor]="itemActionsMenu"
                      [matMenuTriggerData]="{ item: proposalItem, index: i }"
            ></app-icon>
            <app-icon class="item-info-icon" src="item-info" (click)="openItemInfoMobile(proposalItem)"></app-icon>
          </div>
        </div>

        <div class="divider"></div>
        <div class="proposal-item-mobile-bottom" (click)="editItem(proposalItem, fullItemAccess.value)">
          <div class="qty-block">
            <div class="label">Qty</div>
            <mat-form-field appearance="outline" class="width--38 height--24" (click)="$event.stopPropagation()">
              <input matInput style="bottom: 8px"
                     maxlength="4"
                     [defaultValue]="proposalItem.quantity"
                     (focusout)="qtyFocus$.next(false)"
                     (focus)="qtyFocus$.next(true)"
                     (input)="qtyFieldInput($event, proposalItem, true)"
                     autocomplete="off"
                     class="tac fw-600 position-relative"
                     inputmode="decimal"
                     (keypress)="numbersOnly($event)"
                     onpaste="return false;"
                     ondrop="return false;"
              >
            </mat-form-field>
          </div>
          <div class="total d-flex">
            <div>$</div>
            <div class="fw-600">{{(+getItemPrice(proposalItem)).toFixed(2)}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="add-proposal-item"
         [class.no-items]="(proposalVersion.items ?? []).length === 0"
         (click)="openAddProposalItemModal(fullItemAccess.value)"
         *ngIf="proposalVersion.clientSalesTaxPercentage"
    >
      <app-icon class="se-icon-button" src="plus"></app-icon>
      <p class="add-proposal-item-button-text">Item</p>
    </div>

    <app-proposal-summary *ngIf="(proposalVersion.items ?? []).length > 0"
                          [proposal]="proposal" [proposalVersion]="proposalVersion"
                          (depositFocus)="itemsListFocus.emit($event)"
    ></app-proposal-summary>
  </div>

  <mat-menu #itemActionsMenu xPosition="before" class="no-margin">
    <ng-template matMenuContent let-item="item" let-index="index">
      <mat-option (click)="duplicateItem(item, index)">Duplicate</mat-option>
      <mat-option (click)="deleteItem(index)">Delete</mat-option>
    </ng-template>
  </mat-menu>

</ng-container>
