import { Observable, combineLatest } from "rxjs";
import { AuthService, Role } from "../services/auth.service";
import { filter, map, startWith } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { UsersService } from "../services/users.service";

const MENU_ITEMS = ['/settings', '/reports', '/items', '/customers'] as const;
export type MenuItem = {
    title: string,
    link: typeof MENU_ITEMS[number],
}

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    private _route$ = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        startWith(this.router.url),
        map((event) => {
            const url = (typeof event === 'string') ? event : (event as NavigationEnd).url;
            const [_, page] = url.split('/');
            return page;
        }),
    );

    menuItems$ = combineLatest([
        this.usersService.currentUser$.pipe(filter(user => !!user)),
        this._route$
    ]).pipe(
        map(([user, path]) => {
            
            const items: MenuItem[] = [
                {
                    title: 'Settings',
                    link: '/settings'
                },
                {
                    title: 'Reports',
                    link: '/reports'
                }
            ];
            if(user!.role === 'admin' || user!.role === 'owner' || user!.permissions.includes('edit_items')) {
                items.push({
                    title: 'Items',
                    link: '/items'
                });
            }
            
            return items;
        })
    );

    constructor(
        private usersService: UsersService,
        private router: Router,
    ) {}

}
