import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AudioRecorderService {
    private recorder: MediaRecorder | null = null;
    private onFinish: ((file: File) => void) | null = null;
    private isRecordingSubject = new BehaviorSubject<boolean>(false);
    private canRecordSubject = new BehaviorSubject<boolean>(false);
    private mediaStream: MediaStream | null = null;

    isRecording$ = this.isRecordingSubject.asObservable();
    canRecord$ = this.canRecordSubject.asObservable();

    constructor() { }

    private stopMicrophone() {
        if (!this.mediaStream)
            return;
        for (const track of this.mediaStream.getTracks()) {
            track.stop();
            this.mediaStream.removeTrack(track);
        };
        this.mediaStream = null;
    }

    private async setupRecording() {
        if (!navigator.mediaDevices?.getUserMedia) {
            this.canRecordSubject.next(false);
            return;
        }
        try {
            this.mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
            let chunks: BlobPart[] = [];
            this.recorder = new MediaRecorder(this.mediaStream);
            this.recorder.ondataavailable = (e) => chunks.push(e.data);

            this.recorder.onstop = () => {
                const file = new File(chunks, 'recording.ogg', { type: 'audio/ogg; codecs=opus' });
                this.isRecordingSubject.next(false);
                chunks = [];
                this.onFinish?.(file);
                this.onFinish = null;
                this.stopMicrophone();
                this.recorder = null;
            }

            this.recorder.onerror = (e) => {
                console.log(e);
                this.isRecordingSubject.next(false);
                chunks = [];
                this.stopMicrophone();
                this.recorder = null;
            }

            this.canRecordSubject.next(true);
        } catch (err) {
            console.log(err);
            this.canRecordSubject.next(false);
        }
    }

    async startRecording(): Promise<boolean> {
        if (this.isRecordingSubject.value)
            return false;

        await this.setupRecording();
        if (!this.canRecordSubject.value)
            return false;

        this.isRecordingSubject.next(true);
        this.recorder?.start();
        return true;
    }

    stopRecording(): Promise<File | null> {
        if (!this.isRecordingSubject.value)
            return Promise.resolve(null);

        return new Promise((resolve) => {
            this.onFinish = resolve;
            this.recorder?.stop();
        });
    }

    cancelRecording() {
        if (this.isRecordingSubject.value) {
            this.recorder?.stop();
        }
    }
}