import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { UsersService } from "../../services/users.service";
import { map, startWith, take } from "rxjs/operators";
import { EstimatesService } from "../../services/estimates.service";
import { JobsService, JobUpdate } from "../../services/jobs.service";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { Estimate } from "../../models/estimate.model";
import { Job, JobUser } from "../../models/jobs.model";
import { AcceptanceStatus } from "../../../../../common/src/lib/models";
import { ViewAsService } from "../../services/view-as.service";
import { UserProfile } from "../../../../../common/src/lib/models/user-profile.model";
import {
  ConfirmationDialog
} from "../../../../../common/src/lib/modals/confirmation-dialog/confirmation-dialog.component";
import { ModalBehavior, ModalsService } from 'projects/common/src/lib/services/modals.service';
import { animate, style, transition, trigger } from "@angular/animations";
import { RequestInvitationFormData } from "../request-invitation/request-invitation.model";

interface AssignedInfoModalData<T extends (Estimate | Job)> {
  id: number;
  docType: T['docType'];
  initialData?: T,
  changes?: {
    unassignedUsers: Set<number>,
    selectedUserProfiles: UserProfile[]
  }
}

export type JobRequestInvitationFormData = RequestInvitationFormData & {
  unassignedUserIds: number[];
}

@Component({
  selector: 'app-assigned-dialog',
  templateUrl: './assigned-dialog.component.html',
  styleUrls: ['./assigned-dialog.component.scss'],
  animations: [
    trigger('sidePanelShowHide', [
      transition(':enter', [
        style({right: '-100%'}),
        animate(500, style({right: 0}))
      ]),
      transition(':leave', [
        animate(500, style({right: '-100%'}))
      ])
    ])
  ]
})
export class AssignedDialogComponent<T extends Estimate | Job> implements OnInit{

  readonly timeFormat = 'MM/dd/YYYY hh:mm a';

  get data(): AssignedInfoModalData<T> {
    return this.dialogData ?? this.modalsService.data;
  }

  isEstimate = this.data.docType === 'Estimate';
  isJob = this.data.docType === 'Job';

  docType = this.data.docType;
  changed = false;
  currentUser$ = this.usersService.currentUser$;

  assignedBy$!: Observable<UserProfile | null>;
  // assigneesChangesSubject = new BehaviorSubject<(UserProfile)[] | null>(null);
  jobUsers$!: Observable<JobUser[]>;

  users$ = this.usersService.users$;
  document$!: Observable<Estimate | Job>;
  // scheduleEvents$!: Observable<{ start: string, end: string }[]>;

  unassignedUserIdsSubject = new BehaviorSubject<Set<number>>(new Set());
  assignedUsersChangesSubject = new BehaviorSubject<UserProfile[]>([]);

  documentAssignees$!: Observable<{ userId: number, firstName: string, lastName: string, acceptanceStatus: AcceptanceStatus }[]>;
  assigneeIdsToHideFromAvailabilityModal$!: Observable<number[]>;
  skipUsersChange = false;

  availabilityModalVisibleSubject = new BehaviorSubject(false);

  constructor(
    private usersService: UsersService,
    private modalsService: ModalsService,
    private estimatesService: EstimatesService,
    private jobsService: JobsService,
    private viewAsService: ViewAsService,
    @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: AssignedInfoModalData<T>
  ) {}

  handleUsersAssigned(users: UserProfile[]) {
    this.assignedUsersChangesSubject.next(users);
    this.changed = true;
  }

  async ngOnInit() {
     const obs = (this.data.docType === 'Estimate'
        ? this.estimatesService.estimateObservable(this.dialogData.id)
        : this.jobsService.jobObservable(this.dialogData.id)) as Observable<Estimate | Job>;

    this.document$ = this.data.initialData ? obs.pipe(
        startWith(this.data.initialData)
    ) : obs;

    this.assignedBy$ = combineLatest([this.viewAsService.selectedUsers$, this.document$, this.users$]).pipe(
        map(([viewAsUsers, document, users]) => {
          if(document.docType === 'Estimate')
            return users!.find(user => user.id === document.ownerId)!;
          if(viewAsUsers === 'All' || viewAsUsers.length > 1)
            return null;
          const assignedBy = document.users.find(user => user.userId === viewAsUsers[0].id)!.assignedBy;
          return users!.find(user => user.id === assignedBy)!;
        })
    );

    this.documentAssignees$ = combineLatest([this.document$, this.unassignedUserIdsSubject]).pipe(
        map(([document, unassignedUserIds]) => {
          const result = [] as { userId: number, firstName: string, lastName: string, acceptanceStatus: AcceptanceStatus }[];
          if(document.docType === 'Estimate') {
            result.push({
              userId: document.assigneeId!,
              firstName: document.assigneeFirstName!,
              lastName: document.assigneeLastName!,
              acceptanceStatus: document.denied ? 'denied' : 'pending'
            });
          } else {
            for(const user of document.users) {
              if(!unassignedUserIds.has(user.id))
                result.push(user);
            }
          }
          return result.filter(user => !unassignedUserIds.has(user.userId));
        })
    );

    this.assigneeIdsToHideFromAvailabilityModal$ = this.documentAssignees$.pipe(
      map(assignees => {
        return assignees
            .filter(user => user.acceptanceStatus !== 'looped_in')
            .map(assignee => assignee.userId)
      })
    );

    if(this.isJob) {
      this.jobUsers$ = this.document$.pipe(
          map(document => {
            return (document as Job).users;
          })
      );
    }

    if(this.data.changes) {
      this.unassignedUserIdsSubject.next(this.data.changes.unassignedUsers);
      this.assignedUsersChangesSubject.next(this.data.changes.selectedUserProfiles);
      this.changed = true;
    }
  }

  async unAssignUser(assigneeId: number, fromDocument: boolean) {
    const action = async () => {
      const document = await this.document$.pipe(take(1)).toPromise();
      const users = await this.users$.pipe(take(1)).toPromise();
      const unassignedUserIds = this.unassignedUserIdsSubject.value;
      unassignedUserIds.add(assigneeId);

      let selectedUsers: UserProfile[];
      if (document.docType === 'Job') {
        selectedUsers = this.assignedUsersChangesSubject.value ?? document.users.map(user => users!.find(u => u.id === user.userId)!);
      } else {
        selectedUsers = this.assignedUsersChangesSubject.value ?? users!.filter(u => u.id === document.assigneeId!)!
      }
      selectedUsers = selectedUsers.filter(user => user.id !== assigneeId)

      return [unassignedUserIds, selectedUsers] as [Set<number>, UserProfile[]];
    }

    if(fromDocument) {
      this.openUnassignDocumentUserDialog(assigneeId, action)
    } else {
      console.log('sdagsdg')
      const [unassignedUserIds, selectedUsers] = await action();
      console.log(unassignedUserIds, selectedUsers)
      this.unassignedUserIdsSubject.next(unassignedUserIds);
      this.assignedUsersChangesSubject.next(selectedUsers);
      this.changed = true;
    }
  }

  async openUnassignDocumentUserDialog(assigneeId: number, prepAction: () => Promise<[Set<number>, UserProfile[]]>) {
    let userName;
    const currentUser = await this.currentUser$.pipe(take(1)).toPromise();
    if(currentUser.id === assigneeId) {
      userName = currentUser.firstName;
    } else {
      const users = await this.users$.pipe(take(1)).toPromise();
      const user = users!.find(user => user.id === assigneeId);
      userName = user!.firstName;
    }

    const restoreData = this.modalsService.dataToRestore();
    this.modalsService.open(ConfirmationDialog, {
      behavior: ModalBehavior.Dialog,
      prevComponentMetadata: restoreData,
      data: {
        title: 'Decline Assignment',
        message: `Are you sure you want to delete ${userName}?`,
        actionTitle: 'Yes',
        cancelTitle: 'No',
        actionColor: 'warn',
        action: async () => {
          const [unassignedUserIds, selectedUsers] = await prepAction();
          (restoreData!.data as AssignedInfoModalData<T>).changes = {
            unassignedUsers: unassignedUserIds,
            selectedUserProfiles: selectedUsers
          };
        }
      }
    });
  }

  async reassign(event: Event) {
    event.stopImmediatePropagation();
    const document = (await this.document$.pipe(take(1)).toPromise());
    const currentUser = (await this.currentUser$.pipe(take(1)).toPromise());

    if (document.docType === 'Estimate') {
      const userId = this.assignedUsersChangesSubject.value![0].id;
      await this.estimatesService.updateEstimate({
        inId: document.id,
        inAssignee: currentUser.id === userId ? null : userId,
        inDenied: false
      });

    } else {
      let keepAssignees = [
          ...(await this.assigneeIdsToHideFromAvailabilityModal$.pipe(take(1)).toPromise()),
          ...this.assignedUsersChangesSubject.value.map(user => user.id)
      ]
          .filter(assigneeId => !this.unassignedUserIdsSubject.value.has(assigneeId));

      const reassigned = this.assignedUsersChangesSubject.value.map(user => user.id)
          .filter(userId => !keepAssignees.includes(userId));
      const updateData: JobUpdate = {
        inId: document.id,
        inAssignedBy: currentUser.id,
        inJobUserIds: keepAssignees,
      };
      if(reassigned.length > 0)
        updateData.inReassignedUserIds = reassigned;

      await this.jobsService.updateJob(updateData);
    }
    this.close();
  }

  async delete(event: Event) {
    event.stopImmediatePropagation();
    const document = await this.document$.pipe(take(1)).toPromise();
    const type = this.docType;
    this.modalsService.open(ConfirmationDialog, {
      behavior: ModalBehavior.Dialog,
      data: {
        title: `Delete`,
        message: `This action will remove the assignees and all the data about this ${type.toLowerCase()}.\nAre you sure you want to delete this ${type.toLowerCase()}?`,
        actionTitle: 'Delete',
        action: async () => {
          if (type === 'Estimate')
            await this.estimatesService.deleteEstimate(document.id);
          else if (type === 'Job')
            await this.jobsService.deleteJob(document.id);
          return { close: true, reopen: false };
        },
        actionColor: 'warn',
      },
      prevComponentMetadata: this.modalsService.dataToRestore(),
    });
  }

  close() {
    this.modalsService.close();
  }

  async deleteOrDiscardClick(event: Event) {
    const documentAssignees = await this.documentAssignees$.pipe(take(1)).toPromise();
    const assignedUsersChanges = await this.assignedUsersChangesSubject.pipe(take(1)).toPromise();
    if ((documentAssignees.length + assignedUsersChanges.length) === 0) {
      this.close();
    } else {
      this.delete(event)
    }
  }

}
