<div class="main-refund-payment">
  <div class="header">
    <h3>Refund</h3>
    <app-icon class="se-icon-button" src="close" (click)="close()"></app-icon>
  </div>

  <div class="content">
    <div class="explanation">For security reasons a refund is only available on Nuvei dashboard. Copy the transaction number and paste it in the search bar to navigate easily.</div>

    <div class="transaction" (click)="copyTransactionNumber()">
      <div class="transaction-number">{{ transactionNumber }}</div>
      <div class="copy-badge">Copy</div>
    </div>

    <div class="button-section">
      <div class="button close-button" (click)="close()">Close</div>
      <div class="button go-to-nuvei" (click)="goToNuvei()">
        Go to Nuvei
      </div>
    </div>
  </div>
</div>
