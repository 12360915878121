import { Component, OnDestroy } from '@angular/core';
import {
  pageNameToPath,
  ReportsCommissionsTab,
  reportsCommissionsTab, ReportsPages,
  reportsPages
} from "../../models/navigation.model";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationDialog } from "../../../../../common/src/lib/modals/confirmation-dialog/confirmation-dialog.component";
import { ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { NavigationService } from '../../services/navigation.service';
import { ReportsService } from '../../services/reports.service';
import { DateFilter } from '../../models/date-filter.model';
import { ReportDownloadService } from "../../services/report-download.service";
import { map } from "rxjs/operators";
import { combineLatest, Observable } from "rxjs";
import { CommissionReport } from "../../models/reports.model";
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";

@Component({
  selector: 'app-reports-commissions',
  templateUrl: './reports-commissions.component.html',
  styleUrls: ['./reports-commissions.component.scss']
})
export class ReportsCommissionsComponent implements OnDestroy {

  report$: Observable<CommissionReport> = this.reportsService.report$ as Observable<CommissionReport>;

  tabs = Array.from(reportsCommissionsTab);

  dateFilter$ = this.reportsService.selectedDateRangeForEvent$;
  pages = Array.from(reportsPages);

  nav$ = combineLatest([
    this.navigationService.page$,
    this.navigationService.tab$
  ]).pipe(
    map(([page, tab]) => {
      return {
        page,
        tab
      };
    })
  );

  get selectedCommissionIds() {
    return this.reportsService.selectedCommissionIds;
  }

  constructor(
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private router: Router,
    private modalsService: ModalsService,
    private reportsService: ReportsService,
    private reportDownloadService: ReportDownloadService,
    private snackbar: MatSnackBar
  ) { }

  onTabSelected(tab: string) {
    const page = pageNameToPath(tab as ReportsCommissionsTab);
    this.reportsService.selectedCommissionIds.clear();
    this.router.navigate([page], {relativeTo: this.route});
  }

  onPageSelected(page: string) {
    const chosenPage = pageNameToPath(page as ReportsPages);
    this.reportsService.selectedCommissionIds.clear();
    this.router.navigateByUrl('reports/' + [chosenPage]);
  }

  selectedRangeChange(event: [Date, Date] | null) {
    this.reportsService.setSelectedDateRange(event ? DateFilter.from(event) : null);
  }

  getSelectedTab(selectedTab: string | null) {
    if (selectedTab) {
      if (selectedTab === 'Pending') {
        return 'commissionsPending';
      } else {
        return 'commissionsPaid';
      }
    }
    return '';
  }

  async markAsPaid() {
    await this.modalsService.open(ConfirmationDialog, {
      data: {
        title: `Mark as Paid`,
        message: `Are you sure you want to mark this as paid?`,
        cancelTitle: 'No',
        actionTitle: 'Yes',
        action: async () => {
          await this.reportsService.mark('commissions');

          showSnackbar(this.snackbar, {
            message: `Marked as Paid`,
            duration: 10000,
          })
        },
        actionColor: 'primary'
      }
    });
  }

  ngOnDestroy() {
    this.reportsService.selectedCommissionIds.clear();
  }

  checkAllSelected(checked: boolean, allIds: number[]) {
    this.reportsService.selectedCommissionIds = checked
      ? new Set<number>(allIds)
      : new Set<number>();
  }

  isAllSelected(allIds: number[]) {
    return allIds.length > 0 && allIds.length === this.selectedCommissionIds.size
      && allIds.every((x) => this.selectedCommissionIds.has(x));
  }
}
