<div class="version-picker-block" #versionPicker>
    <div *ngFor="let version of $any(versions); let i = index" class="proposal-version-button"
         (click)="selectVersion(version)"
         [class.active] = "selectedVersionId === version.id"
         [class.sent]="version.status === 'sent'"
         [class.declined]="version.status === 'declined'"
         [class.canceled]="version.status === 'canceled' || version.status === 'canceledFromPayment'"
         [class.accepted]="version.status === 'accepted' || version.status === 'paid'"
         [class.deleted]="version.status === 'deleted'"
    >
        <h4>{{ version.id === selectedVersionId ? (type + ' ' + (i+1) ) : ('# ' + (i+1)) }}</h4>
    </div>

    <div class="proposal-version-button action" (click)="actionsClick()" *ngIf="!proposal || !proposal.invoiceId">
        <div class="proposal-version-button menu-button" *ngIf="actions && actions.length > 0" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="actionsMenu">
            <h4>{{ actions.length === 1 ? actions[0] : '+ Actions' }}</h4>
        </div>
    </div>

    <mat-menu #actionsMenu="matMenu" xPosition="before" class="no-margin">
        <mat-option *ngFor="let action of actions" (click)="actionClick.emit(action)">{{ action }}</mat-option>
    </mat-menu>
</div>