import { Injectable } from '@angular/core';
import { NavigationService } from './navigation.service';
import { DateFilterService } from './date-filter.service';
import { BusinessService } from "./business.service";
import { filter, switchMap, take, tap } from "rxjs/operators";
import { BehaviorSubject, combineLatest, of } from "rxjs";
import { ViewAsService } from "./view-as.service";
import { initPaymentsAll, initPaymentsPaid, initPaymentsPending } from "../helpers/reports/payments";
import { SupabaseService } from "./supabase.service";
import { Page, Tab } from "../models/navigation.model";
import { initCommissionsPaid, initCommissionsPending } from "../helpers/reports/commissions";
import { initTechPartsPaid, initTechPartsPending } from "../helpers/reports/tech-parts";
import { initOverlook } from "../helpers/reports/overlook";
import { initCompare } from "../helpers/reports/compare";
import { BusinessProfile } from "../../../../common/src/lib/models/business-profile.model";

type ReportType = 'payments' | 'commissions' | 'techParts' | 'overlook' | 'compare';
type ReportTab = 'all' | 'pending' | 'paid';
export type ReportRange = {
  from: string;
  to: string;
};
type ReportRequest = {
  whichType: ReportType,
  whichTab?: ReportTab,
  businessId: string,
  params: {
    range?: ReportRange,
    compareRanges?: {
      left: ReportRange,
      right: ReportRange
    };
    users?: string[],
    offset?: number
  },
  extend?: boolean
};

const dateFormat = 'yyyy-MM-dd HH:mm:ss';

@Injectable({
  providedIn: 'root'
})
export class ReportsService extends DateFilterService {

  constructor(
    private supabaseService: SupabaseService,
    private businessService: BusinessService,
    private viewAsService: ViewAsService,
    private navigationService: NavigationService
  ) {
    super();
  }

  loading$ = new BehaviorSubject<boolean>(false);

  page$ = this.navigationService.page$;
  tab$ = this.navigationService.tab$;

  selectedPaymentIds = new Set<number>();
  selectedCommissionIds = new Set<number>();
  selectedTechPartIds = new Set<number>();

  downloading$ = new BehaviorSubject<boolean>(false);

  report$ = combineLatest([
    this.page$,
    this.tab$,
    this.businessService.selectedBusiness$,
    this.viewAsService.selectedUsersIds$,
    this.selectedDateRangeForEvent$,
    this.selectedDateRangeForEventCompareLeft$,
    this.selectedDateRangeForEventCompareRight$
  ]).pipe(
    switchMap(data => {
      this.loading$.next(true);
      return of({
        page: data[0],
        tab: data[1],
        business: data[2],
        users: data[3],
        singleDate: !data[4] || !((data[4] as any[])[0]) || !((data[4] as any[])[1])
            ? {
              start: (data[2] as BusinessProfile).createdAt,
              end: new Date()
            }
            : {
              start: (data[4] as any[])[0],
              end: (data[4] as any[])[1]
            },
        leftDate: {
          start: data[5] ? (data[5] as any[])[0] : undefined,
          end: data[5] ? (data[5] as any[])[1] : undefined
        },
        rightDate: {
          start: data[6] ? (data[6] as any[])[0] : undefined,
          end: data[6] ? (data[6] as any[])[1] : undefined
        }
      });
    }),
    filter(data => {
      if (data.page === 'Compare')
        return !!data.page && !!data.business && !!data.leftDate.start
            && !!data.leftDate.end && !!data.rightDate.start && !!data.rightDate.end;

      return !!data.page && !!data.business && !!data.singleDate.start && !!data.singleDate.end;
    }),
    switchMap(data => this.reportInitObservable(
        data.page as Page,
        data.tab as Tab,
        (data.business as BusinessProfile).businessId,
        data.users as (number[] | null),
        data.page !== 'Compare' ? data.singleDate.start : undefined,
        data.page !== 'Compare' ? data.singleDate.end : undefined,
        data.page === 'Compare' ? data.leftDate.start : undefined,
        data.page === 'Compare' ? data.leftDate.end : undefined,
        data.page === 'Compare' ? data.rightDate.start : undefined,
        data.page === 'Compare' ? data.rightDate.end : undefined
    )!),
    tap(_ => this.loading$.next(false))
  );

  reportInitObservable(
      page: Page,
      tab: Tab,
      businessId: string,
      selectedUsers: number[] | null,
      startTime?: Date,
      endTime?: Date,
      leftStartTime?: Date,
      leftEndTime?: Date,
      rightStartTime?: Date,
      rightEndTime?: Date,
  ) {
    switch (page) {
      case 'Payments':
        switch (tab) {
          case 'All':
            return initPaymentsAll(
                this.supabaseService,
                businessId,
                selectedUsers,
                startTime!,
                endTime!
            );
          case 'Paid':
            return initPaymentsPaid(
                this.supabaseService,
                businessId,
                selectedUsers,
                startTime!,
                endTime!
            );
          case 'Pending':
            return initPaymentsPending(
                this.supabaseService,
                businessId,
                selectedUsers,
                startTime!,
                endTime!
            );
          default:
            return null;
        }
      case 'Commissions':
        switch (tab) {
          case 'Paid':
            return initCommissionsPaid(
                this.supabaseService,
                businessId,
                selectedUsers,
                startTime!,
                endTime!
            );
          case 'Pending':
            return initCommissionsPending(
                this.supabaseService,
                businessId,
                selectedUsers,
                startTime!,
                endTime!
            );
          default:
            return null;
        }
      case 'Tech Parts':
        switch (tab) {
          case 'Paid':
            return initTechPartsPaid(
                this.supabaseService,
                businessId,
                selectedUsers,
                startTime!,
                endTime!
            );
          case 'Pending':
            return initTechPartsPending(
                this.supabaseService,
                businessId,
                selectedUsers,
                startTime!,
                endTime!
            );
          default:
            return null;
        }
      case 'Overlook':
        return initOverlook(
            this.supabaseService,
            businessId,
            selectedUsers,
            startTime!,
            endTime!
        );
      case 'Compare':
        return initCompare(
            this.supabaseService,
            businessId,
            selectedUsers,
            leftStartTime!,
            leftEndTime!,
            rightStartTime!,
            rightEndTime!
        );
      default:
        return null;
    }
  }

  async exportPaymentsForDownload(data: any) {
    // const businessId = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())?.businessId;
    // const paymentIds = ids.map(id => id.workflowId);
    //
    // if (businessId && paymentIds) {
    //   const endpoint = '/exportPayments';
    //   const headers = this.getAuthHeaders();
    //
    //   try {
    //     const res = await this.http.post(`${ apiUrl }${ endpoint }`, {
    //       paymentIds,
    //       businessId
    //     }, { headers }).pipe(take(1)).toPromise();
    //
    //     return this.fixExportedPaymentsData(res);
    //   } catch (e) {
    //     this.handleHttpError(e);
    //   }
    // }
    //
    // return {};

    return true;
  }

  async downloadExport() {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();

    return (await this.supabaseService.rpcFunc(business.businessId, 'export', {
      workflow_ids: Array.from(this.selectedPaymentIds)
    }));
  }


  async mark(type: 'commissions' | 'tech-parts', val?: boolean, ids?: number[]) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();

    if (type === 'tech-parts') {
      await this.supabaseService.rpcFunc(business.businessId, 'mark_tech_parts', {
        in_ids: ids ?? Array.from(this.selectedTechPartIds),
        val
      });

      this.selectedTechPartIds.clear();
    }

    if (type === 'commissions') {
      await this.supabaseService.rpcFunc(business.businessId, 'mark_commissions', {
        in_ids: Array.from(this.selectedCommissionIds)
      });

      this.selectedCommissionIds.clear();
    }
  }

}
