<div class="page main h-100 w-100 d-flex flex-column justify-content-start"
     style="overflow: hidden !important;"
     *ngIf="{ value: report$ | async } as report"
>

    <div class="content flex-fill overlay-scrollbar">
        <div class="container flex-fill d-flex flex-column h-100" fadeIn="500" [fadeInDelay]="200">

            <div class="dots-with-info-container">
                <div class="info-dot">
                    <div class="dot-orange"></div>
                    <p>Sales</p>
                </div>
                <div class="info-dot">
                    <div class="dot-green"></div>
                    <p>Net profit</p>
                </div>
                <div class="info-dot">
                    <div class="dot-blue"></div>
                    <p>Jobs</p>
                </div>
            </div>

            <ng-container *ngIf="{ value: datePickerLeftIsEmpty$ | async } as datePickerLeftIsEmpty">
                <ng-container *ngIf="{ value: datePickerRightIsEmpty$ | async } as datePickerRightIsEmpty">
                    <div class="diagrams-container">
                        <div class="left-block">
                            <app-date-range class="date-range-picker"
                                            [selected]="(dateFilterLeft$ | async)"
                                            (change)="selectedRangeChange($event, 'left')"
                            >
                            </app-date-range>
                            <div class="diagrams" [class.without-date]="!datePickerLeftIsEmpty.value && !datePickerRightIsEmpty.value && report.value">
                                <p *ngIf="datePickerLeftIsEmpty.value">Enter dates to compare</p>
                                <mat-spinner class="m-auto" [diameter]="40"
                                             *ngIf="!datePickerLeftIsEmpty.value && !datePickerRightIsEmpty.value && !report"
                                ></mat-spinner>
                                <div class="diagrams-columns" *ngIf="!datePickerLeftIsEmpty.value && !datePickerRightIsEmpty.value && report.value?.left">
                                    <div class="diagram">
                                        <small class="count">{{currencify(report.value!.left.sales)}}</small>

                                        <div class="column sales" *ngIf="!datePickerRightIsEmpty.value"
                                             [style.height]="calculateColumnHeight(report.value!.left.sales, report.value!.right.sales)">
                                        </div>
                                        <small class="diagram-title">Sales</small>
                                        <ng-container *ngIf="{ value: calculatePercentageDifference(report.value!.left.sales, report.value!.right.sales) } as calc">
                                            <small class="percent-difference"
                                                   *ngIf="!datePickerRightIsEmpty.value"
                                                   [class.negative-percentage]="!calc.value.isPositive"
                                            >
                                                {{calc.value.percent}}
                                            </small>
                                        </ng-container>
                                    </div>
                                    <div class="diagram">
                                        <small class="count">{{currencify(report.value!.left.np)}}</small>

                                        <div class="column net-profit" *ngIf="!datePickerRightIsEmpty.value"
                                             [style.height]="calculateColumnHeight(report.value!.left.np, report.value!.right.np)"
                                        ></div>
                                        <small class="diagram-title">Net profit</small>
                                        <ng-container *ngIf="{ value: calculatePercentageDifference(report.value!.left.np, report.value!.right.np) } as calc">
                                            <small class="percent-difference"
                                                   *ngIf="!datePickerRightIsEmpty.value"
                                                   [class.negative-percentage]="!calc.value.isPositive"
                                            >
                                                {{calc.value.percent}}
                                            </small>
                                        </ng-container>
                                    </div>
                                    <div class="diagram">
                                        <small class="count">{{report.value!.left.jobs}}</small>

                                        <div class="column jobs" *ngIf="!datePickerRightIsEmpty.value"
                                             [style.height]="calculateColumnHeight(report.value!.left.jobs, report.value!.right.jobs)"
                                        ></div>
                                        <small class="diagram-title">Jobs</small>
                                        <ng-container *ngIf="{ value: calculatePercentageDifference(report.value!.left.jobs, report.value!.right.jobs) } as calc">
                                            <small class="percent-difference"
                                                   *ngIf="!datePickerRightIsEmpty.value"
                                                   [class.negative-percentage]="!calc.value.isPositive"
                                            >
                                                {{calc.value.percent}}
                                            </small>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="arrow horizontal">
                            <div class="left"></div>
                            <div class="center"></div>
                            <div class="right"></div>
                        </div>
                        <div class="arrow vertical">
                            <div class="left"></div>
                            <div class="center"></div>
                            <div class="right"></div>
                        </div>
                        <div class="right-block">
                            <app-date-range class="date-range-picker"
                                            [selected]="(dateFilterRight$ | async)"
                                            (change)="selectedRangeChange($event, 'right')"
                            >
                            </app-date-range>
                            <div class="diagrams" [class.without-date]="!datePickerLeftIsEmpty.value && !datePickerRightIsEmpty.value && report">
                                <p *ngIf="datePickerRightIsEmpty.value">Enter dates to compare</p>
                                <mat-spinner class="m-auto" [diameter]="40"
                                             *ngIf="!datePickerLeftIsEmpty.value && !datePickerRightIsEmpty.value && !report"
                                ></mat-spinner>
                                <div class="diagrams-columns" *ngIf="!datePickerLeftIsEmpty.value && !datePickerRightIsEmpty.value && report.value?.right">
                                    <div class="diagram">
                                        <small class="count">{{currencify(report.value!.right.sales)}}</small>

                                        <div class="column sales" *ngIf="!datePickerLeftIsEmpty.value"
                                             [style.height]="calculateColumnHeight(report.value!.right.sales, report.value!.left.sales)">
                                        </div>
                                        <small class="diagram-title">Sales</small>
                                        <ng-container *ngIf="{ value: calculatePercentageDifference(report.value!.right.sales, report.value!.left.sales) } as calc">
                                            <small class="percent-difference"
                                                   *ngIf="!datePickerLeftIsEmpty.value"
                                                   [class.negative-percentage]="!calc.value.isPositive"
                                            >
                                                {{calc.value.percent}}
                                            </small>
                                        </ng-container>
                                    </div>
                                    <div class="diagram">
                                        <small class="count">{{currencify(report.value!.right.np)}}</small>

                                        <div class="column net-profit" *ngIf="!datePickerLeftIsEmpty.value"
                                             [style.height]="calculateColumnHeight(report.value!.right.np, report.value!.left.np)"
                                        ></div>
                                        <small class="diagram-title">Net profit</small>
                                        <ng-container *ngIf="{ value: calculatePercentageDifference(report.value!.right.np, report.value!.left.np) } as calc">
                                            <small class="percent-difference"
                                                   *ngIf="!datePickerLeftIsEmpty.value"
                                                   [class.negative-percentage]="!calc.value.isPositive"
                                            >
                                                {{calc.value.percent}}
                                            </small>
                                        </ng-container>
                                    </div>
                                    <div class="diagram">
                                        <small class="count">{{report.value!.right.jobs}}</small>

                                        <div class="column jobs" *ngIf="!datePickerLeftIsEmpty.value"
                                             [style.height]="calculateColumnHeight(report.value!.right.jobs, report.value!.left.jobs)"
                                        ></div>
                                        <small class="diagram-title">Jobs</small>
                                        <ng-container *ngIf="{ value: calculatePercentageDifference(report.value!.right.jobs, report.value!.left.jobs) } as calc">
                                            <small class="percent-difference"
                                                   *ngIf="!datePickerLeftIsEmpty.value"
                                                   [class.negative-percentage]="!calc.value.isPositive"
                                            >
                                                {{calc.value.percent}}
                                            </small>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div class="only-mobile-sm show-before-sm bottom tabs primary-scrollbar">
        <app-tabs
                linePosition="top"
                [tabs]="pages"
                [activeTab]="(page$ | async)!"
                (onTabSelect)="onPageSelected($event)"
        ></app-tabs>
    </div>
</div>
