// @ts-ignore
import { saveAs } from 'file-saver';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
  ConfirmationDialog
} from "../../../../../common/src/lib/modals/confirmation-dialog/confirmation-dialog.component";
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";
import { ModalBehavior, ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { ProposalsService, ProposalVersionRestore } from "../../services/proposals.service";
import { Router } from "@angular/router";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { debounceTime, filter, take } from "rxjs/operators";
import { RequestInvitationModal } from "../../modals/request-invitation/request-invitation.component";
import { UsersService } from "../../services/users.service";
import { PdfService } from "../../../../../common/src/lib/services/pdf.service";
import { Proposal, ProposalVersion } from "../../models/proposal.model";
import { BusinessService } from "../../services/business.service";
import { InvoiceCreate, InvoicesService } from "../../services/invoices.service";
import { BehaviorSubject } from "rxjs";
import { clientFromProposalVersion } from "../../../../../common/src/lib/models/client-transform";
import { WorkflowService } from "../../services/workflow.service";
import { ScheduleService } from "../../services/schedule.service";
import { MultiformErrors, RequestInvitationFormData } from "../../modals/request-invitation/request-invitation.model";

@Component({
  selector: 'app-proposal-actions',
  templateUrl: './proposal-actions.component.html',
  styleUrls: ['./proposal-actions.component.scss']
})
export class ProposalActionsComponent implements OnChanges {

  @Input() proposal!: Proposal;
  @Input() currentProposalVersion!: ProposalVersion;
  @Input() preview?: boolean = false;
  @Input() sendLoading?: boolean = false;
  @Input() depositFocused: boolean = false;
  @Output() setCurrentProposalVersionId = new EventEmitter<number>();
  @Output() previewEvent = new EventEmitter<boolean>();
  @Output() sendEvent = new EventEmitter();

  _depositFocused$ = new BehaviorSubject<boolean>(false);
  depositFocused$ = this._depositFocused$.asObservable().pipe(
    debounceTime(100)
  );

  openedActions = false;

  downloading = false;

  constructor(
    private modalsService: ModalsService,
    private proposalsService: ProposalsService,
    private businessService: BusinessService,
    private router: Router,
    private snackBar: MatSnackBar,
    private usersService: UsersService,
    private pdfService: PdfService,
    private invoicesService: InvoicesService,
    private workflowService: WorkflowService,
    private scheduleService: ScheduleService,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if ('depositFocused' in changes)
      this._depositFocused$.next(this.depositFocused);
  }

  async deleteProposal() {
    await this.modalsService.open(ConfirmationDialog, {
      behavior: ModalBehavior.Dialog,
      disableClose: true,
      data: {
        title: 'Delete Proposal',
        message: 'Are you sure you want to delete the proposal?',
        actionTitle: 'Delete',
        actionColor: 'warn',
        action: async () => {
          const currentVersion = this.currentProposalVersion;
          const currentProposal = this.proposal;
          const lastVersion = await this.proposalsService.deleteProposalVersion(currentVersion.id);

          lastVersion
            ? await this.router.navigate(['/lobby/proposals/won'])
            : this.setCurrentProposalVersionId.emit(this.proposalsService.findHighestVersionId(currentProposal.versions, [currentVersion.id]));

          showSnackbar(this.snackBar, {
            message: 'Proposal deleted',
            duration: 10000,
            actionText: 'Undo',
            action: (async () => {
              const user = await this.usersService.currentUser$.pipe(take(1)).toPromise();
              const data: ProposalVersionRestore = {
                inClient: {
                  address: currentVersion.clientAddress,
                  businessName: currentVersion.clientBusinessName,
                  email: currentVersion.clientEmail,
                  extNumber: currentVersion.clientExtNumber,
                  firstName: currentVersion.clientFirstName,
                  lastName: currentVersion.clientLastName,
                  phoneNumber: currentVersion.clientPhoneNumber,
                  salesTaxPercentage: currentVersion.clientSalesTaxPercentage!,
                  type: currentVersion.clientType,
                  unit: currentVersion.clientUnit,
                },
                inCreatedBy: user.id,
                inWorkflowId: currentProposal.workflowId,
                inId: currentProposal.id,
                inVersionId: currentVersion.id,
                inVersionCreatedAt: currentVersion.createdAt,
                inSignature: currentVersion.signature,
                inDepositType: currentVersion.depositType,
                inDiscountType: currentVersion.discountType,
              }

              if (currentVersion.note)
                data.inNote = currentVersion.note;
              if (currentVersion.depositAmount)
                data.inDepositAmount = currentVersion.depositAmount;
              if (currentVersion.depositPercent)
                data.inDepositPercent = currentVersion.depositPercent;
              if (currentVersion.items)
                data.inItems = currentVersion.items;
              const id = await this.proposalsService.restoreProposalVersion(data);
              !lastVersion
                ? this.setCurrentProposalVersionId.emit(currentVersion.id)
                : await this.router.navigate([`/proposals/${currentProposal.workflowId}/${id}`]);
            }).bind(this),
          });
        }
      }
    });
  }

  async markAsDeclined() {
    await this.modalsService.open(ConfirmationDialog, {
      behavior: ModalBehavior.Dialog,
      disableClose: true,
      data: {
        title: 'Mark as Declined',
        message: 'Are you sure you want to mark the proposal as declined?',
        actionTitle: 'Yes',
        actionColor: 'warn',
        action: async () => {
          this.currentProposalVersion.status = 'declined';
          await this.proposalsService.declineProposal(this.currentProposalVersion.id);

          showSnackbar(this.snackBar, {
            message: 'Marked as declined',
            duration: 2000,
          });
        }
      }
    });
  }

  async resendProposalVersion() {
    await this.proposalsService.sendProposalEmail(this.currentProposalVersion.clientProposalId!, this.currentProposalVersion.sentBy!);
    showSnackbar(this.snackBar, {
      message: 'Proposal resent',
      duration: 2000,
    });
  }

  async createOrGoToInvoice() {
    const currentUser = await this.usersService.currentUser$.pipe(take(1)).toPromise();
    if (!this.proposal.invoiceId) {
      const data: InvoiceCreate = {
        inCreatedBy: currentUser.id,
        inClient: {
          firstName: this.currentProposalVersion.clientFirstName,
          lastName: this.currentProposalVersion.clientLastName,
          phoneNumber: this.currentProposalVersion.clientPhoneNumber,
          address: this.currentProposalVersion.clientAddress,
          type: this.currentProposalVersion.clientType,
          email: this.currentProposalVersion.clientEmail,
          unit: this.currentProposalVersion.clientUnit,
          extNumber: this.currentProposalVersion.clientExtNumber,
          businessName: this.currentProposalVersion.clientBusinessName
        },
        inWorkflowId: this.proposal.workflowId,
        inSignature: this.currentProposalVersion.signature,
        inDiscountType: this.currentProposalVersion.discountType,
      };

      if (this.currentProposalVersion.note)
        data.inNote = this.currentProposalVersion.note;

      if (this.currentProposalVersion.items)
        data.inItems = this.currentProposalVersion.items;

      const resData = await this.invoicesService.createInvoice(data);

      await this.router.navigate([`/invoices/${this.proposal.workflowId}/${resData.invoiceId}`]);
    } else {
      await this.router.navigate([`/invoices/${this.proposal.workflowId}/${this.proposal.invoiceId}`]);
    }
  }

  async createOrGoToJob() {
    if (!!this.proposal.jobId) {
      await this.router.navigate(['/jobs', this.proposal.workflowId, this.proposal.jobId]);
    } else if (!this.proposal.jobId && !!this.proposal.invoiceId) {
      return;
    } else if (!this.proposal.jobId && !this.proposal.invoiceId) {
      const workflow = await this.workflowService.workflowObservable(this.proposal.workflowId, 'pa').pipe(take(1)).toPromise();
      await this.modalsService.open(RequestInvitationModal, {
        behavior: ModalBehavior.Auto,
        breakpoint: RequestInvitationModal.BREAKPOINT,
        disableClose: true,
        data: {
          initialData: {
            id: this.proposal.id,
            workflowId: this.proposal.workflowId,
            dialogType: 'Proposal'
          },
          changes: {
            dialogType: 'Job',
            jobType: workflow?.jobType,
            client: clientFromProposalVersion(this.currentProposalVersion),
            assignees: [],
            ranges: [],
          },
          onSubmit: this.onJobCreateFromSubmit.bind(this)
        }
      });
    }
  }

  async onJobCreateFromSubmit(data: Required<RequestInvitationFormData>): Promise<MultiformErrors | null> {
    await this.scheduleService.createDocument(data, this.proposal.workflowId);
    const workflow = await this.workflowService.workflowObservable(this.proposal.workflowId).pipe(
      filter(workflow => workflow !== null && workflow.jobId !== null),
      take(1)
    ).toPromise();
    await this.router.navigate(['/jobs', this.proposal.workflowId, workflow!.jobId]);
    return null;
  }

  declineAllowed(): boolean {
    let allowed = true;

    if (
      this.currentProposalVersion.status === 'canceled'
      || this.currentProposalVersion.status === 'declined'
      || this.currentProposalVersion.status === 'accepted'
      || this.proposal.invoiceId
      || this.proposal.jobId
    )
      allowed = false;

    if (this.proposal.sPaidAmount && this.proposal.sPaidAmount > 0)
      allowed = false;

    return allowed;
  }

  async downloadPdf() {
    if (!this.downloading) {
      this.downloading = true;
      const filename = `proposal-${this.proposal.uid}-${this.currentProposalVersion.subUid}`;
      const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
      const pdfData = {
        filename,
        type: 'proposal',
        clientDomain: 'https://dev-client-slickestimates.web.app',
        businessId: business.businessId,
        recordId: this.currentProposalVersion.clientProposalId,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };

      const blob = await this.pdfService.generatePdf(pdfData).pipe(take(1)).toPromise();
      try {
        if (blob)
          saveAs(blob, filename);
      } catch (e) {
        console.log('Error during PDF download:', e);
        alert('PDF download failed!');
      } finally {
        this.downloading = false;
        showSnackbar(this.snackBar, {
          message: 'Proposal downloaded',
          duration: 2000,
        });
      }
    }
  }

  previewClick(depositFocused: boolean) {
    if (!depositFocused && this.currentProposalVersion?.items?.length !== 0) {
      this.preview = true;
      this.previewEvent.emit(true);
    }
  }
}
