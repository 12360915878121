import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export type FormType<T> = {
  [K in keyof T]: FormControl<T[K]>;
};

export const timeSuggestions = [
  '12:00',
  '12:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
];
@Injectable({
  providedIn: 'root'
})
export class FormsService {

  validateRequired(...controls: AbstractControl[]) {
    let valid = true;
    for(const control of controls) {
      if(!control.value || control.value.length === 0) {
        valid = false;
        control.setErrors({
          required: true
        });
      }
    }

    return valid;
  }

  resetErrors(control: AbstractControl, ...errors: string[]) {
    if(control instanceof UntypedFormControl) {

      if(errors.length > 0 && control.errors) {
        for(const error of errors) {
          delete control.errors[error];
        }
        if(Object.keys(control.errors).length === 0) {
          control.setErrors(null);
        }
      } else {
        control.setErrors(null);
      }

    } else if (control instanceof UntypedFormGroup) {
      for(const child of Object.values(control.controls)) {
        this.resetErrors(child, ...errors);
      }
    }
  }

  trim(control: AbstractControl) {
    if(control instanceof UntypedFormControl) {

      if(typeof control.value === 'string') {
        const trimmed = control.value.trim();
        if(trimmed !== control.value) {
          control.setValue(trimmed, { emitEvent: true });
        }
      }

    } else if (control instanceof UntypedFormGroup) {
      for(const child of Object.values(control.controls)) {
        this.trim(child);
      }
    }
  }
}
