import {Component, Input, OnInit} from '@angular/core';
import { map } from 'rxjs/operators';
import { ProfileLogoModal } from '../../modals/profile-logo/profile-logo.component';
import { ModalsService } from '../../../../../common/src/lib/services/modals.service';
import { BusinessService } from '../../services/business.service';
import { UsersService } from '../../services/users.service';

@Component({
  selector: 'app-profile-logo',
  templateUrl: './profile-logo.component.html',
  styleUrls: ['./profile-logo.component.scss']
})
export class ProfileLogoComponent implements OnInit {

  @Input() enabled = true;
  @Input() noBorder = false;

  hover = false;

  src$ = this.businessService.selectedBusiness$.pipe(
    map(profile => {
      return profile?.logo;
    })
  );

  action$ = this.src$.pipe(
    map(src => {
      return !!src ? 'Edit' : 'Upload';
    })
  );

  enabled$ = this.usersService.currentUser$.pipe(
    map(user => user!.role === 'owner' || user!.role === 'admin')
  );

  showReminder = false;
  reminderTimeout?: NodeJS.Timeout;

  constructor(
    private modalsService: ModalsService,
    private businessService: BusinessService,
    private usersService: UsersService
  ) { }

  ngOnInit(): void {
  }

  setShowReminder() {
    if(this.reminderTimeout) {
      clearTimeout(this.reminderTimeout);
      this.reminderTimeout = undefined;
    }
    this.showReminder = true;
    this.reminderTimeout = setTimeout(() => {
      if(this.reminderTimeout)
        this.reminderTimeout = undefined;
      this.showReminder = false;
    }, 5000)
  }

  openModal() {
    this.modalsService.open(ProfileLogoModal);
  }

}
