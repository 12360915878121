<div class="page main w-100 d-flex flex-column justify-content-start" *ngIf="user$ | async let user">

    <div class="content flex-fill overlay-scrollbar">
        <div class="container flex-fill d-flex flex-column h-100" fadeIn="500" [fadeInDelay]="200">

            <div class="header">

                <div class="tabs d-flex justify-content-between align-items-center">
                    <app-tabs
                        linePosition="bottom"
                        class="not-mobile-sm"
                        alignment="start"
                        [tabs]="tabs"
                        [activeTab]="(tab$ | async)!"
                        (onTabSelect)="onTabSelected($event)"
                    ></app-tabs>
                    
                    <app-menu-button
                        class="not-mobile-sm"
                        (click)="createProposal()"
                    ></app-menu-button>
                </div>

                <div class="flex-fill d-flex justify-content-between align-items-center">

                    <app-date-range class="date-range-picker"
                        [selected]="dateFilter$ | async"
                        (change)="selectedRangeChange($event)"
                        (status)="datePickerClose($event)"
                    ></app-date-range>

                </div>

            </div>

            <div class="h-100">
                <router-outlet></router-outlet>
            </div>

<!--            <app-items-list-->
<!--                [items$]="proposals$"-->
<!--                [dateForItem]="dateForItem"-->
<!--                [dateStringForItem]="dateStringForItem"-->
<!--                [canLoadMore$]="canLoadMore$"-->
<!--                (loadMore)="loadMore()"-->
<!--                (onScroll)="itemsScrolling = $event"-->
<!--                [filter]="(dateFilter$ | async) !== null ? 'Range' : undefined"-->
<!--            ></app-items-list>-->
        </div>

    </div>
    <div class="bottom-button only-mobile-sm">
        <app-menu-button
                class="only-mobile-sm menu-button"
                (click)="createProposal()"
                [style.opacity]="itemsScrolling ? 0.5 : 1"
        ></app-menu-button>
    </div>


    <div class="only-mobile-sm bottom tabs primary-scrollbar">
        <app-tabs
            linePosition="top"
            alignment="around"
            [tabs]="tabs"
            [activeTab]="(tab$ | async)!"
            (onTabSelect)="onTabSelected($event)"
        ></app-tabs>
    </div>
</div>
