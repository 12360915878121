import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { MenuService } from 'projects/business-slick-estimates/src/app/models/menu-items-config';
import { SCREEN_BREAKPOINTS } from 'projects/common/src/lib/directives/SCREEN_BREAKPOINTS';
import { Breakpoint, UtilsService } from 'projects/common/src/public-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from "../../../../services/auth.service";

interface MenuButton {
  title: string,
  actions: string[]
}

@Component({
  selector: 'app-mobile-modal-header',
  templateUrl: './mobile-modal-header.component.html',
  styleUrls: ['./mobile-modal-header.component.scss']
})
export class MobileModalHeaderComponent implements OnInit, AfterContentChecked {
  
  @Input() titleText?: string;
  @Input() hideOnBreakpoint: Breakpoint = SCREEN_BREAKPOINTS['sm'];
  @Input() leftButton?: string;
  @Input() rightButton?: string | MenuButton;
  @Input() showMainMenu = false;

  @Output() onLeftButtonClick = new EventEmitter();
  @Output() onRightButtonClick = new EventEmitter<string | undefined>();

  show$!: Observable<boolean>;
  menuItems$ = this.menuService.menuItems$;

  get isMobile() {
    return this.utilsService.isMobile();
  }

  constructor(
    private utilsService: UtilsService,
    private menuService: MenuService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
  ) { }


  isTextButton() {
    return typeof this.rightButton === 'string';
  }

  getRightButtonTitle(): string | null {
    if(!this.rightButton)
      return null;
    if(typeof this.rightButton === 'string')
      return this.rightButton;
    return this.rightButton.title;
  }

  getActions() {
    return (this.rightButton as MenuButton).actions;
  }

  ngOnInit(): void {
    this.show$ = this.utilsService.onScreenBreakpointChange(this.hideOnBreakpoint).pipe(
      map((largeScreen) => !largeScreen)
    );
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  logout() {
    this.authService.logout();
  }
}
