import { AcceptanceStatus } from "projects/common/src/public-api";
import { AddressComponents } from "../../../../common/src/lib/models/address-comments.model";
import { TimeRange } from "../../../../common/src/lib/models/time-range.model";

export interface EstimateTimeRange {
    id: number;
    estimateId: number;
    startTime: Date;
    endTime: Date;
}

export type EstimateStatus = 'submitted' | 'canceled';

export interface Estimate {
    docType: 'Estimate';
    id: number;
    createdAt: Date;
    address: AddressComponents;
    unit: string | null;
    assigneeId: number | null;
    assigneeFirstName: string | null;
    assigneeLastName: string | null;
    businessName: string | null;
    clientId: number;
    email: string;
    firstName: string;
    lastName: string;
    jobType: string;
    ownerId: number;
    ownerFirstName: string;
    ownerLastName: string;
    clientCreatedAt: Date;
    salesTaxPercentage: number;
    phoneNumber: string;
    extNumber: string;
    type: 'personal' | 'business';
    ranges: TimeRange[];
    status: EstimateStatus;
    workflowId: number;
    denied: boolean;
    emailOpened: boolean;
}

export interface EstimateTileData {
    docType: 'Estimate';
    id: number;
    createdAt: Date;
    firstName: string;
    lastName: string;
    businessName: string | null;
    assigneeId: number | null;
    assigneeFirstName: string | null;
    assigneeLastName: string | null;
    ownerId: number;
    ownerFirstName: string;
    ownerLastName: string;
    startTime: Date;
    endTime: Date;
    jobType: string;
    phoneNumber: string;
    acceptanceStatus: AcceptanceStatus;
    rangeId: number;
    workflowId: number;
    status: 'submitted' | 'canceled';
    clientId: number;
    clientCreatedAt: Date;
    salesTaxPercentage: number;
    // loopedInFn: string;
    // loopedInLn: string;
}

export function estimateTileData(from: Estimate, rangeId: number): EstimateTileData {
    const range = from.ranges.find(r => (r as any).rangeId === rangeId)!;
    return {
        docType: 'Estimate',
        id: from.id,
        createdAt: from.createdAt,
        assigneeId: from.assigneeId,
        ownerId: from.ownerId,
        ownerFirstName: from.ownerFirstName,
        ownerLastName: from.ownerLastName,
        assigneeFirstName: from.assigneeFirstName,
        assigneeLastName: from.assigneeLastName,
        businessName: from.businessName,
        firstName: from.firstName,
        lastName: from.lastName,
        jobType: from.jobType,
        phoneNumber: from.phoneNumber,
        startTime: range.startTime,
        endTime: range.endTime,
        acceptanceStatus: estimateAcceptanceStatus(from),
        rangeId,
        workflowId: from.workflowId,
        status: from.status,
        clientId: from.clientId,
        clientCreatedAt: from.clientCreatedAt,
        salesTaxPercentage: from.salesTaxPercentage,
    };
}

export function estimateAcceptanceStatus(estimate: Estimate): AcceptanceStatus {
    if(estimate.assigneeId === null)
        return 'accepted';
    if(estimate.denied)
        return 'denied';
    return 'pending';
}
