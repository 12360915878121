<div *ngIf="(useTabs$ | async) else dropdown" class="tabs d-flex align-items-center justify-content-{{alignment}}">
    <div (click)="onSelect(tabTitle(tab))" [class]="'tab d-flex justify-content-center align-items-center ' + (linePosition === 'top' ? 'top-line' : 'bottom-line')" [class.active]="tabTitle(tab) === activeTab" *ngFor="let tab of tabs let i = index">
        <div class="d-flex">
            <p [class]="'justify-content-center ' + tabClass(tab)">
              {{ tabTitle(tab) }}{{ !!counters && !!counters[i] ? ' (' + counters![i] + ')' : '' }}<span *ngIf="tabItemsCount" [class.item-count]="tabItemsCount[i] > 0"><span class="not-mobile">&nbsp;</span>{{'(' + tabItemsCount[i] + ')'}}</span>
            </p>
            <div class="red-dot" [class.hidden]="!showRedDot(i)"></div>
        </div>
    </div>
</div>

<ng-template #dropdown>
    <mat-form-field class="dropdown" (click)="dropdownOpen ? autocompleteTrigger.closePanel() : autocompleteTrigger.openPanel()">
        <div class="d-flex justify-content-between align-items-center">
          <input
            matInput
            readonly
            class="dropdown-input"
            [value]="activeTab"
            #autocompleteTrigger="matAutocompleteTrigger"
            [matAutocomplete]="autocomplete"
          >
          <mat-icon class="suffix rotate">expand_more</mat-icon>
        </div>
        <mat-autocomplete
          #autocomplete="matAutocomplete"
          (opened)="dropdownOpen = true"
          (closed)="dropdownOpen = false"
        >
            <mat-option *ngFor="let tab of tabsWithoutSelected let i = index" [value]="tab" (click)="onSelect(tabTitle(tab))">
                {{ tabTitle(tab) }}{{ !!counters && !!counters[i] ? ' (' + counters![i] + ')' : '' }}<span *ngIf="tabItemsCount">{{'(' + tabItemsCount[i] + ')'}}</span>
            </mat-option>
        </mat-autocomplete>
      </mat-form-field>
</ng-template>
