import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { map, startWith, switchMap, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessService } from '../../services/business.service';
import { AuthService } from '../../services/auth.service';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { FormsService } from 'projects/common/src/lib/services/forms.service';
import { NavigationService } from "../../services/navigation.service";
import { combineLatest, Observable } from "rxjs";
import { UsersService } from "../../services/users.service";

@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.scss']
})
export class JoinComponent implements OnInit {

  @ViewChild("emailInput") emailInput!: ElementRef;
  @ViewChild("passwordInput") passwordInput!: ElementRef;

  params$ = this.route.params.pipe(
      map(params => {
        const split = (params['id'] as string).split('-');
        const userId = split.pop()!;
        const businessId = split.join('-');
        return [businessId, userId];
      })
  );

  business$ = this.params$.pipe(
      switchMap(([businessId, _]) => {
        return this.businessService.businessData(businessId);
      })
  );

  user$ = this.params$.pipe(
      switchMap(([businessId, userId]) => {
        return this.usersService.userObservable(+userId, businessId);
      })
  );

  authUser$ = this.authService.user$;

  state$: Observable<'ready' | 'not-found' | null> = combineLatest([this.business$, this.user$, this.navigationService.url$]).pipe(
      map(([business, user, url]) => {
        if(!business || !user) {
          return 'not-found';
        }
        return 'ready';
      }),
      startWith(null)
  );

  loading = false;

  accountForm = this.formBuilder.group({
    email: new UntypedFormControl('', [Validators.email]),
    password: new UntypedFormControl('', [Validators.minLength(6)]),
  });

  firstNameFocused = false;
  emailFocused = false;
  showPassword = false;

  login = false;
  emphasize = false;

  constructor(
    private authService: AuthService,
    private businessService: BusinessService,
    private formBuilder: UntypedFormBuilder,
    private formsService: FormsService,
    private route: ActivatedRoute,
    private router: Router,
    private usersService: UsersService,
    private navigationService: NavigationService,
  ) {}

  async ngOnInit() {
    this.accountForm.get('email')?.setValue(this.route.snapshot.queryParamMap.get('email'));
    const authUser = await this.authUser$.pipe(take(1)).toPromise();
    if(!authUser)
      return;

    const user = await this.user$.pipe(take(1)).toPromise();
    if(!user)
      return;

    if (user.status === 'inactive' && user.role === 'owner') {
      await this.accept();
    } else if (user.status !== 'inactive') {
      await this.router.navigate(['/']);
    }
  }

  async accept() {
    this.loading = true;
    const [businessId, userId] = await this.params$.pipe(take(1)).toPromise();
    if(await this.businessService.acceptInvitation(businessId, +userId)) {
      this.businessService.selectBusiness(businessId, true);
    }
    this.loading = false;
  }

  async action() {
    this.loading = true;


    if (!this.formValid()) {
      this.accountForm.markAllAsTouched();
      this.unfocusAll();
      this.loading = false;
      return;
    }

    const {email, password} = this.accountForm.value;

    try {
      if(this.login)
        await this.authService.signInWithEmailAndPassword(email, password);
      else
        await this.authService.signUpWithEmailAndPassword(email, password);
    } catch (error: any) {
      console.log(`signup.component.ts > SignUpComponent > next()`, error.code);

      if (error.code === 'auth/email-already-in-use') {
        this.emphasize = true;
        this.accountForm.get('email')!.setErrors({
          emailInUse: true
        });
      }
      
      if (error.code === 'auth/invalid-email') {
        this.accountForm.get('email')!.setErrors({
          email: true
        });
      }

      if (error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found') {
        this.accountForm.get('email')!.setErrors({
          emailPassword: true
        });
        this.accountForm.get('password')!.setErrors({
          emailPassword: true
        });
      }

      this.accountForm.markAllAsTouched();
    }



    this.loading = false;
  }

  formValid() {
    const valid = this.formsService.validateRequired(
      this.accountForm.get('email')!, this.accountForm.get('password')!
    );

    if (!this.accountForm.valid) {
      return false;
    }

    return valid;
  }

  async saveInvitation() {
    const params = await this.params$.pipe(take(1)).toPromise();
    this.businessService.saveInvitation(params[0] + '-' + params[1]);
  }

  private unfocusAll() {
    this.emailInput.nativeElement.blur();
    this.passwordInput.nativeElement.blur();
  }

  async close() {
    this.router.navigate(['lobby']);
  }

  changeView() {
    this.login = !this.login;
    this.accountForm.controls.email.setErrors(null);
    this.accountForm.controls.password.setErrors(null);
    this.emphasize = false;
  }

}
