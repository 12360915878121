import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { MatDatepicker, MatDateRangePicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Moment } from 'moment';
import { InvoicesTab, LobbyPage, Tab, invoicesTabs, pageNameToPath } from '../../models/navigation.model';
import { RequestInvitationModal } from '../../modals/request-invitation/request-invitation.component';
import { ModalBehavior, ModalsService } from '../../../../../common/src/lib/services/modals.service';
import { InvoicesService, TiledInvoice, tiledInvoicesForType } from "../../services/invoices.service";
import { NavigationService } from '../../services/navigation.service';
import { LobbyService } from '../../services/lobby.service';
import { DateFilter } from '../../models/date-filter.model';
import { dateString } from 'projects/common/src/public-api';
import { EditClientComponent } from '../../modals/edit-client/edit-client.component';
import { Client } from 'projects/common/src/public-api';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent {

  tabs = Array.from(invoicesTabs);
  user$ = this.auth.user$;
  loadingInvoices = false;

  @ViewChild('heightTester') heightTester: any;
  @ViewChild('picker') datePicker!: MatDateRangePicker<any>;

  datePickerOpen = false;

  tab$ = this.navigationService.tab$;

  actualSelectedDate!: [Date, Date] | null;

  dateFilter$ = this.lobbyService.selectedDateRangeForEvent$;
  itemsScrolling = false;
  
  constructor(
    private auth: AuthService,
    private navigationService: NavigationService<LobbyPage, InvoicesTab>,
    private router: Router,
    private route: ActivatedRoute,
    public snackbar: MatSnackBar,
    public modalsService: ModalsService,
    private invoicesService: InvoicesService,
    private lobbyService: LobbyService,
  ) {}

  onTabSelected(tab: string) {
    const useTab = pageNameToPath(tab as Tab);
    this.router.navigate([useTab], { relativeTo: this.route });
  }

  datesFilter(d: Moment | null) {
    if(!d) {
      return false;
    }
    const now = moment().startOf('day');
    const date = d.startOf('day');
    return date.diff(now) <= 0;
  }

  keys(obj: any) {
    return Object.keys(obj);
  }

  searchResultsForTab(searchResults: any, type: string) {
    return searchResults[type];
  }

  openCreateRequestDialog() {
    this.modalsService.open(RequestInvitationModal, {
      behavior: ModalBehavior.Auto,
      breakpoint: RequestInvitationModal.BREAKPOINT,
      disableClose: true,
      data: {}
    });
  }

  async createInvoice() {
    await this.modalsService.open(EditClientComponent, {
      behavior: ModalBehavior.Auto,
      disableClose: true,
      data: {
        client: {} as Client,
        invoicesService: this.invoicesService,
        creation: true,
        which: 'Invoice'
      }
    });
  }

  openInvoice(invoiceId: number) {
    this.router.navigate(['/invoices', invoiceId]);
  }

  openPicker(picker: MatDatepicker<any>) {
    picker.open();
  }

  objectLength(obj: any) {
    return Object.keys(obj).length;
  }

  selectedRangeChange(event: [Date, Date] | null) {
    this.actualSelectedDate = event;
  }

  datePickerClose(status: boolean) {
    if (!status)
      this.lobbyService.setSelectedDateRange(this.actualSelectedDate ? DateFilter.from(this.actualSelectedDate) : null);
  }
  
}
