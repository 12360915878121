<div class="chat-wrapper" [class.invisible]="initializing" (drop)="onDrop($event)" (dragover)="onDrag(true, $event)" (dragleave)="onDrag(false, $event)">
    <div [class.d-none]="!showMessages" class="list-wrapper" #scrollContainer>
        <div class="message-list" [class.do]="true">
            <div #tempView></div>
        </div>
    </div>
    <ng-content></ng-content>
    <lib-messages-indicator 
        *ngIf="unseenMessagesIds.length > 0 && !hideIndicator && !ticketsHistoryMode" 
        [messageCounter]="unseenMessagesIds.length" 
        (click)="scrollToFirstOtherUnseen(0, true)"
    ></lib-messages-indicator>
    <lib-chat-overlay [class.dragging]="draggingAttachment"></lib-chat-overlay>
</div>

<dialog #dialog>
    <div class="header">
        <h3>Attach file</h3>
        <app-icon class="se-icon-button" src="close" (click)="closeImageDialog()"></app-icon>
    </div>
    <img *ngIf="!selectedFileVideo" [src]="selectedFileImage">
    <video *ngIf="selectedFileVideo" class="video" [src]="selectedFileVideo" controls></video>
    <div class="footer">
        <div class="w-100 d-flex align-items-center gap--8">
            <textarea cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="5"
                        [formControl]="dialogMessageInput"
                        #textArea
                        #autosize="cdkTextareaAutosize"
                        placeholder="Type a reply..."
                        class="message-input hide-scrollbar"
                        (keydown)="onDialogKeyDown($event)"
            ></textarea>
                <div class="d-flex gap--8 mb--2">
                <app-icon class="send-button" src="send_message" (click)="sendAttachmentMessage()"></app-icon>
            </div>
        </div>
    </div>
</dialog>
