import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { inject } from "@angular/core";
import { UsersService } from "../services/users.service";
import { take } from "rxjs/operators";


export const canViewBusiness = async (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const usersService = inject(UsersService);
    const router = inject(Router);
    const currentUser = await usersService.currentUser$.pipe(take(1)).toPromise();

    if(currentUser.status.includes('suspended'))
        return router.createUrlTree(['/suspended']);

    return true;

};

export const canViewSuspendedPage = async (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {

    const usersService = inject(UsersService);
    const router = inject(Router);
    const currentUser = await usersService.currentUser$.pipe(take(1)).toPromise();
    if(currentUser.status.includes('suspended'))
        return true;

    return router.createUrlTree(['/lobby']);
};