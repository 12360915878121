import { Component } from '@angular/core';
import { ModalsService } from "../../../../../common/src/lib/services/modals.service";
import {ItemsService} from "../../services/items.service";
import {Item} from "../../models/item.model";
import { map } from "rxjs/operators";

@Component({
  selector: 'app-workflow-item-info-mobile',
  templateUrl: './workflow-item-info-mobile.component.html',
  styleUrls: ['./workflow-item-info-mobile.component.scss']
})
export class WorkflowItemInfoMobileComponent {

  edited = false;
  warning = false;

  itemStatus$ = this.itemsService.itemObservable(this.data.proposalItem.name).pipe(
    map(item => {
      if (!item || !item.name)
        return 'warning';

      if (
        item.description !== this.data.proposalItem.description
        || item.price !== this.data.proposalItem.price
        || item.cost !== this.data.proposalItem.cost
        || item.taxable !== this.data.proposalItem.taxable
      )
        return 'edited';

      return 'empty';
    })
  );

  get data(): any {
    return this.modalsService.data;
  }

  get taxPercentage() {
    return this.modalsService.data.salesTax;
  }

  constructor(
      private modalsService: ModalsService,
      private itemsService: ItemsService
  ) { }

  async close() {
    await this.modalsService.close();
  }

  getMarkup(): number {
    if (!this.data.proposalItem.cost)
      return 100;

    return ((this.data.proposalItem.price-this.data.proposalItem.cost)/this.data.proposalItem.cost)*100;
  }

  getTaxAmount(): number {
    if (!this.data.proposalItem.taxable)
      return 0;

    const discountedPrice = this.data.proposalItem.discountAmount
        ? this.data.proposalItem.price - this.data.proposalItem.discountAmount
        : this.data.proposalItem.price;
    return discountedPrice*this.taxPercentage/100;
  }

  getTotal(): number {
    const discounted = this.data.proposalItem.discountAmount
        ? this.data.proposalItem.price - this.data.proposalItem.discountAmount
        : this.data.proposalItem.price;

    return this.data.proposalItem.taxable
      ? discounted + (discounted*this.taxPercentage/100)
      : discounted;
  }
}
