import { Component, Input } from '@angular/core';
import { timer } from "rxjs";
import { map, startWith } from "rxjs/operators";

export interface TimeData {
  startTime: Date;
  endTime: Date;
}

@Component({
  selector: 'app-base-tile-time',
  templateUrl: './base-tile-time.component.html',
  styleUrls: ['./base-tile-time.component.scss']
})
export class BaseTileTimeComponent {

  readonly timeFormat = 'hh:mm a';

  @Input() timeData!: TimeData;
  @Input() dimPast = true;
  @Input() hideTitles = false;
  @Input() unsetWidth = false;

  reverseTimeOrder$ = timer(1000).pipe(
      startWith(0),
      map(_ => {
        return this.timeData.endTime.getTime() < Date.now();
      })
  );
  startTitle$ = timer(1000).pipe(
      startWith(0),
      map(_ => {
        return this.timeData.startTime.getTime() < Date.now() ? 'Started' : 'Starts';
      })
  );
  endTitle$ = timer(1000).pipe(
      startWith(0),
      map(_ => {
        return this.timeData.endTime.getTime() < Date.now() ? 'Ended' : 'Ends';
      })
  );
  isPast$ = timer(1000).pipe(
      startWith(0),
      map(_ => {
        return this.timeData.endTime.getTime() < Date.now();
      })
  );
}
