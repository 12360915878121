import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-text-toggle',
  templateUrl: './text-toggle.component.html',
  styleUrls: ['./text-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TextToggleComponent
    }
  ]
})
export class TextToggleComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() selectedItemIndex?: number;
  @Input() items!: string[];
  @Input() highlightSelected = false;
  @Input() showDividers = false;

  @Input() dayWasClicked!: boolean;
  @Input() disabled = false;

  @Output() onSelected = new EventEmitter<string>();

  touched = false;

  _selectedItemIndex!: number;

  onChange = (value: any) => {};
  onTouched = () => {};

  ngOnInit() {
    this._selectedItemIndex = this.selectedItemIndex ?? 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedItemIndex) {
      this._selectedItemIndex = changes.selectedItemIndex.currentValue;
    }
  }

  changeItem(index: number) {
    this.markAsTouched();
    this.writeValue(this.items[index]);
    this.onChange(this.items[index]);
  }

  writeValue(value: string) {
    const index = this.items.indexOf(value);
    if (index === -1) {
      return;
    }
    this.onSelected.emit(value);
    this._selectedItemIndex = index;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
