import { Component, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AssignTileComponent } from "../../components/assign-tile/assign-tile.component";
import { DynamicListComponent } from "../../components/dynamic-list/dynamic-list.component";
import { map } from "rxjs/operators";
import { AssignmentService } from "../../services/assignment.service";
import { Assignment } from "../../models/assignment.model";
import { dateString } from 'projects/common/src/public-api';
import { Router } from "@angular/router";

function assignmentItemsToPaginationList(items: Assignment[]) {
  return items.map(item => ({
    componentClass: AssignTileComponent,
    header: dateString(item.outStartTime),
    args: { item, tileType: 'Assignment' }
  }));
}

@Component({
  selector: 'app-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.scss']
})
export class AssignmentComponent {

  @ViewChild('list') dynamicList!: DynamicListComponent<AssignTileComponent<'Assignment'>>;

  items$ = this.assignmentService.items$;
  listItems$ = this.items$.pipe(
      map((items, index) => {
        if(index === 0)
          this.loading$.next(false);
        if(items.length === 0)
          this.router.navigate(['/lobby']);
        return assignmentItemsToPaginationList(items);
      })
  );
  loading$ = new BehaviorSubject<boolean>(true);

  constructor(
      private assignmentService: AssignmentService,
      private router: Router,
  ) { }

}
