<div class="image-viewer-button d-flex justify-content-center align-items-center">
    <ng-container *ngIf="src === 'zoom-out'">
        <ng-container *ngTemplateOutlet="zoomOut"></ng-container>
    </ng-container>
    <ng-container *ngIf="src === 'close'">
        <ng-container *ngTemplateOutlet="close"></ng-container>
    </ng-container>
    <ng-container *ngIf="src === 'zoom-in'">
        <ng-container *ngTemplateOutlet="zoomIn"></ng-container>
    </ng-container>
    <ng-container *ngIf="src === 'rotate'">
        <ng-container *ngTemplateOutlet="rotate"></ng-container>
    </ng-container>
    <ng-container *ngIf="src === 'download'">
        <ng-container *ngTemplateOutlet="download"></ng-container>
    </ng-container>
</div>

<ng-template #close>
    <app-icon src="close"></app-icon>
</ng-template>
<ng-template #zoomOut>
    <app-icon src="zoom_out"></app-icon>
</ng-template>

<ng-template #zoomIn>
    <app-icon src="zoom_in"></app-icon>
</ng-template>

<ng-template #rotate>
    <app-icon src="rotate"></app-icon>
</ng-template>

<ng-template #download>
    <app-icon src="download"></app-icon>
</ng-template>