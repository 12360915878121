export const SCOPE_OPEN_CHAR = String.fromCharCode(0x200B);
export const SCOPE_CLOSE_CHAR = String.fromCharCode(0x200C);

interface Span {
    content: string;
    start: number;
    end: number;
}

function findSpans(input: string): Span[] {
    let index = 0;
    const spans: Span[] = [];
    while (index < input.length) {
        const openSpanIndex = input.indexOf('<span', index);
        const closeSpanIndex = input.indexOf('span>', index) + 5;

        if (openSpanIndex === -1) {
            // Exit
            index = input.length;
        } else {
            spans.push({
                content: input.substring(openSpanIndex, closeSpanIndex),
                start: openSpanIndex,
                end: closeSpanIndex,
            });
            index = closeSpanIndex;
        }

    }
    return spans;
    // const spanRegex = /<span[^>]*>.*?<\/span>/g;
    // const spanRegex = /<span[^>]*>.*?<\/span>/g;
    // let match: RegExpExecArray | null;
    // const spans: Span[] = [];
    //
    // while ((match = spanRegex.exec(input)) !== null) {
    //     spans.push({
    //         content: match[0],
    //         start: match.index,
    //         end: match.index + match[0].length
    //     });
    // }
    //
    // return spans;
}

function filterInvalidSpans(input: string, spans: Span[]): Span[] {
    return spans.filter(span => {
        const before = input[span.start - 1];
        const after = input[span.end];
        // Check if the span is correctly enclosed by scope characters
        return before !== SCOPE_OPEN_CHAR || after !== SCOPE_CLOSE_CHAR;
    });
}

function removeInvalidSpansFromInput(input: string, invalidSpans: Span[]): string {
    if(invalidSpans.length === 0)
        return input;

    let result = input.substring(0, invalidSpans[0].start - 1);
    for (let i = 0; i < invalidSpans.length; i++) {
        const from = invalidSpans[i].end;
        const to = invalidSpans[i+1] ? invalidSpans[i+1].start - 1 : undefined;
        result += input.substring(from, to);
    }

    return result;
}

function clearLeftovers(input: string) {
    let index = 0;
    while (index < input.length) {
        if(input[index] === SCOPE_OPEN_CHAR) {
            const test = input.substring(index + 1, index + 6);
            if(test !== '<span') {
                input = input.substring(0, index) + input.substring(index + 1);
            }else
                index++;
        } else if(input[index] === SCOPE_CLOSE_CHAR) {
            const test = input.substring(index - 5, index);
            if(test !== 'span>') {
                input = input.substring(0, index) + input.substring(index + 1);
            }else
                index++;
        } else {
            index++;
        }
    }

    return input;
}

export function removeInvalidSpans(input: string): string {
    const spans = findSpans(input);
    const invalidSpans = filterInvalidSpans(input, spans);
    const result = removeInvalidSpansFromInput(input, invalidSpans);
    return clearLeftovers(result);
}
