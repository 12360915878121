<div class="d-flex flex-column">

    <app-section-divider text="Info" [alignedText]="true"></app-section-divider>

    <app-profile-field
        class="info"
        fieldTitle="First name:"
        [value]="(firstName$ | async)!"
        [onSave]="saveFirstName.bind(this)"
        [initialError]="showNameErrors?.firstName ? 'First name is required' : undefined"
    ></app-profile-field>

    <app-profile-field
        class="info"
        fieldTitle="Last name:"
        [value]="(lastName$ | async)!"
        [onSave]="saveLastName.bind(this)"
        [initialError]="showNameErrors?.lastName ? 'Last name is required' : undefined"
    ></app-profile-field>
    

    <app-profile-field
        class="info"
        fieldTitle="Email:"
        [value]="(email$ | async)!"
        [alternativeEdit]="true"
        (onEdit)="changeEmail()"
    ></app-profile-field>

    <app-profile-field
        class="info"
        fieldTitle="Password:"
        [value]="(hasEmailProvider$ | async) ? '******' : null"
        [alternativeEdit]="true"
        (onEdit)="changePassword()"
    ></app-profile-field>

    <app-profile-field
        class="info"
        fieldTitle="Direct phone number:"
        inputmode="tel"
        [value]="(directPhoneNumber$ | async)!"
        [checkValue]="(showDirectPhoneNumber$ | async)!"
        (changeVisibility)="changeVisibility($event)"
        [largeField]="true"
        (onChange)="directPhoneNumberSubject.next($event)"
        [onSave]="saveDirectPhoneNumber.bind(this)"
        mask="(000) 000-0000"
        tooltipMessage="Show this number together with the businesses phone number on estimates, proposals, jobs and invoices"
        [tooltipPosition]="'above'"
        [tooltipClass]="'direct-phone-number__tooltip'"
        [initialError]="directPhoneNumberError"
    ></app-profile-field>

</div>
