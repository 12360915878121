import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { fromEvent, BehaviorSubject } from 'rxjs';
import { debounce, distinctUntilChanged } from 'rxjs/operators';
import { SCREEN_BREAKPOINTS } from "../directives/SCREEN_BREAKPOINTS";
import { Breakpoint, UtilsService } from "./utils.service";

export interface PreviousModalMetadata {
  componentClass?: ComponentType<any>;
  data?: any;
  breakpoint?: Breakpoint;
  modalBehavior?: ModalBehavior;
}

export type ModalConfig = MatDialogConfig & { behavior?: ModalBehavior, breakpoint?: Breakpoint, prevComponentMetadata?: PreviousModalMetadata };

export enum ModalBehavior {
  Dialog,
  FullPage,
  Auto
}

@Injectable({
  providedIn: 'root'
})
export class ModalsService {

  private modalComponentSubject = new BehaviorSubject<ComponentType<any> | null>(null);
  modalComponent$ = this.modalComponentSubject.pipe(
    distinctUntilChanged(),
  );

  private dialogRef?: MatDialogRef<any>;

  resizeObservable$ = fromEvent(this.window, 'resize').pipe(
    debounce(async (event: any) => {
      if(this.behavior === ModalBehavior.Auto) {
        if(event.target.innerWidth >= this.breakpoint) {
          if(this.modalComponentSubject.value) {

            if(this.data?.beforeResize)
              this.data.beforeResize();

            this.open(this.component!, this._config);

            if(this.data?.afterResize)
              this.data.afterResize();
          }
        } else if(this.dialogRef) {

          if(this.data?.beforeResize)
            this.data.beforeResize();

          this.open(this.component!, this._config);

          if(this.data?.afterResize)
            this.data.afterResize();
        }
      }
    })
  );

  private component?: ComponentType<any>;
  private _config?: ModalConfig;
  get data() {
    return this._config?.data;
  }
  get behavior() {
    return this._config?.behavior ?? ModalBehavior.Dialog
  }

  readjustingSize = false;
  breakpoint = SCREEN_BREAKPOINTS['sm'];

  constructor(
    private matDialog: MatDialog,
    private utilsService: UtilsService,
    private window: Window,
  ) {}

  updateData(data: any) {
    // TODO: Check this if statement and how it affect everything. Maybe instead of that we should find why it's undefined at some points.
    // Happened when created an estimate
    if(!this._config) {
      this._config = {};
    }
    this._config.data = {
      ...this._config.data,
      ...data
    }
  }

  dataToRestore(): PreviousModalMetadata | undefined {
    if(!this.component)
      return undefined;
    return {
      componentClass: this.component,
      data: this.data,
      breakpoint: this.breakpoint,
      modalBehavior: this.behavior
    };
  }

  async open<T>(component: ComponentType<T>, config: ModalConfig = {}, resize = false) {

    document.body.classList.add('global-scrollblock');
    if(config.behavior === undefined) {
      config.behavior = ModalBehavior.Dialog
    }
    
    if(this.dialogRef || this.modalComponentSubject.value !== null) {
      await this.close(!!config.prevComponentMetadata);
      this.open(component, config);
    } else {
      this.breakpoint = (typeof config?.breakpoint === 'string') ? SCREEN_BREAKPOINTS[config.breakpoint] : (config?.breakpoint ?? 576);
      this.component = component;
      this._config = config;
      if(config.behavior === ModalBehavior.Dialog || (config.behavior === ModalBehavior.Auto && this.window.innerWidth >= this.breakpoint)) {
        this.dialogRef = this.matDialog.open(component, config);
        this.dialogRef.afterClosed().toPromise().then(this.onDialogClose.bind(this));
        this.modalComponentSubject.next(null);
      } else {
        this.modalComponentSubject.next(component);
        this.dialogRef = undefined;
      }
    }
  }

  async onDialogClose(shouldClose?: boolean) {
    this.modalComponentSubject.next(null);
    if(shouldClose) {
      this._config = undefined;
      this.dialogRef?.close();
    }
    this.component = undefined;
    this.dialogRef = undefined;
  }

  async close(reopenPrevious = false) {
    let afterClose;
    if(this.dialogRef) {
      afterClose = this.dialogRef.afterClosed().toPromise();
    }
    if(reopenPrevious && this._config?.prevComponentMetadata?.componentClass) {
      const component = this._config.prevComponentMetadata.componentClass;
      const config = {
        data: this._config.prevComponentMetadata.data,
        behavior: this._config.prevComponentMetadata.modalBehavior,
        breakpoint: this._config.prevComponentMetadata.breakpoint
      };
      this.open(component, config);
    } else {
      this.onDialogClose(true);
      document.body.classList.remove('global-scrollblock');
    }
    return afterClose;
  }
}
