<div class="page w-100 h-100 d-flex flex-column">
    <div class="header"></div>
    <div class="d-flex flex-column flex-fill w-100">
        <lib-support-chat
            [currentUser]="currentUser" 
            [businessId]="businessProfile.businessId"
            [ticket]="ticket$ | async"
            [showMessages]="(ticket$ | async) !== null && (ticket$ | async)!.status === 'opened'"
        >
            <ng-container *ngIf="(ticket$ | async) let ticket">
                <lib-rating 
                    *ngIf="ticket.status === 'pending_close'" 
                    [ticket]="ticket"
                ></lib-rating>
            </ng-container>
        </lib-support-chat>
    </div>
    <app-send-message (sendEvent)="sendMessage($event)" (addFileEvent)="onFileChange($event)"></app-send-message>
</div>
