import { Inject, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { filter, switchMap, shareReplay, map, take } from 'rxjs/operators';
import { UserProfile, UserRole } from '../../../../common/src/lib/models/user-profile.model';
import { UserCommission } from '../models/user-commissions.model';
import { CallResult } from '../models/call-result.model';
import { showSnackbar } from 'projects/common/src/lib/components/snackbar/snackbar.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { combineLatest, Observable, of } from 'rxjs';
import { BusinessService } from './business.service';
import { Router } from "@angular/router";
import { REPLACE_UPCOMING_CHANGES, rpcFilter, SupabaseService } from "./supabase.service";
import { timestampsToDate } from "../../../../common/src/lib/services";
import { ModalsService } from "../../../../common/src/lib/services/modals.service";

interface AddUserResult extends CallResult {
  invitationLink?: string
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  users$ = this.businessService.selectedBusiness$.pipe(
    switchMap(businessProfile => {
      if(!businessProfile)
        return of(null);

      return this.supabaseService.rpc<UserProfile[]>(
        {
          cName: 'users',
          fn: 'get_users',
          schema: businessProfile.businessId,
          tables: ['user']
        },
        REPLACE_UPCOMING_CHANGES,
      );
    }),
    map((users) => {
      if(!users)
        return null;
      for(const user of users) {
        timestampsToDate(user)
      }
      return users.sort((a, b) => {
        if(a.role === 'owner')
          return 1;
        if(b.role === 'owner')
          return -1;
        if(a.role === 'admin')
          return 1;
        if(b.role === 'admin')
          return -1;
        return 0;
      }) ?? null;
    }),
    shareReplay(1)
  );

  activeUsers$ = this.users$.pipe(
    map((users) => {
      if(!users)
        return null;
      return users.filter((user) => user.status !== 'inactive' && user.status !== 'suspended' && user.status !== 'inactive_suspended');
    })
  );

  currentUser$ = combineLatest([this.users$, this.authService.user$]).pipe(
    filter(([users, user]) => !!users && users.length > 0 && !!user),
    map(([users, user]) => {
      const currentUser = users!.find(u => u.userUuid === user!.uid)!;
      if (currentUser.status.includes('suspended')) {
        this.modalsService.close();
        this.router.navigate(['suspended']);
      } else if(this.router.url === '/suspended') {
        this.router.navigate(['lobby']);
      }

      return currentUser as UserProfile;
    }),
    shareReplay(1)
  );

  assigneesList$ = this.activeUsers$;
  // assigneesList$ = combineLatest([this.activeUsers$, this.currentUser$]).pipe(
  //   map(([users, currentUser]) => currentUser.role === 'admin' || currentUser.role === 'owner' ? users : [currentUser])
  // );

  jobAssigneesList$ = this.assigneesList$;

  constructor(
    @Inject('DOMAIN') private domain: string,
    private authService: AuthService,
    private businessService: BusinessService,
    private supabaseService: SupabaseService,
    private modalsService: ModalsService,
    private snackbar: MatSnackBar,
    private router: Router
  ) {}

  onAppLoad() {
    const isAfterTransfer = localStorage.getItem('afterTransfer');
    if(isAfterTransfer === 'true') {
      localStorage.removeItem('afterTransfer');
      showSnackbar(this.snackbar, {
        message: 'Account has been transferred',
        duration: 60000
      });
    }
  }

  userObservable(userId: number, businessId?: string): Observable<UserProfile | null> {
    if(businessId)
      return this.supabaseService.rpc<UserProfile>(
          {
            cName: 'user_'+userId,
            fn: 'get_user_by_id',
            schema: businessId,
            tables: [{ table: 'user', filter: rpcFilter('id', 'eq', userId)}],
            options: { inId: +userId }
          }
      );

    return this.businessService.selectedBusiness$.pipe(
        filter(businessProfile => !!businessProfile),
        switchMap(businessProfile => {
          if(!businessProfile)
            return of(null);
          return this.userObservable(userId, businessProfile.businessId);
        })
    )
  }

  async addUser(
      email: string,
      firstName: string,
      lastName: string,
      role: UserRole,
      permissions: string[],
      permissionsAllowedByOwner: string[],
      jobCommissionValue: UserCommission['value'],
      jobCommissionType: UserCommission['type'],
      jobCommissionAfterMaterials: UserCommission['afterMaterials'],
      salesCommissionValue: UserCommission['value'],
      salesCommissionType: UserCommission['type'],
      salesCommissionAfterMaterials: UserCommission['afterMaterials'],
  ) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    const user = await this.currentUser$.pipe(take(1)).toPromise();
    const data = {
      email,
      firstName,
      lastName,
      role,
      status: 'inactive',
      permissions,
      permissionsAllowedByOwner,
      invitedBy: user.id,
      jobCommissionValue,
      jobCommissionType,
      jobCommissionAfterMaterials,
      salesCommissionValue,
      salesCommissionType,
      salesCommissionAfterMaterials
    };
    await this.supabaseService.insert(business!.businessId, 'user', data);
    const users = await this.users$.pipe(
        filter(users => !!users && !!users.find(user => user.email === data.email)),
        take(1)
    ).toPromise();
    return `${this.domain}/join/${business!.businessId}-${users!.find(user => user.email === data.email)!.id}?email=${email}`;
  }

  async updateUser(updateData: Partial<UserProfile>, user?: UserProfile) {
    if(!user)
      user = await this.currentUser$.pipe(take(1)).toPromise();
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    return this.supabaseService.update(business!.businessId, 'user', user.id, updateData);
  }

  async suspendUser(user: UserProfile) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    await this.supabaseService.update(business!.businessId, 'user', {
      key: 'email',
      value: user.email
    }, {
      status: user.status === 'active' ? 'suspended' : 'inactive_suspended'
    });

    return true;
  }

  async unsuspendUser(user: UserProfile) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    await this.supabaseService.update(business!.businessId, 'user', {
      key: 'email',
      value: user.email
    }, {
      status: user.status === 'suspended' ? 'active' : 'inactive'
    });

    return true;
  }

  async transferOwnership(userId: number) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    await this.supabaseService.rpcFunc(business!.businessId, 'transfer_ownership', {
      inToUserId: userId
    });

    return true;
  }

  async resendInvitation(userId: number) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    try {
      const res = await this.supabaseService.edgeFunc('resend-user-invitation-email', {
        businessId: business!.businessId,
        userId: userId,
        domain: this.domain
      })
      return true;
    } catch (e) {
      console.log(e);
    }
    return false;
  }

  async deleteUser(user: UserProfile) {
    if(!user.status.includes('inactive')) {
      throw new Error('Active user cannot be deleted');
    }
    const business = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!;
    await this.supabaseService.delete(business.businessId, 'user_working_time', { key: 'user_id', value: user.id });
    return this.supabaseService.delete(business.businessId, 'user', user.id);
  }

}
