import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import {
  pageNameToPath,
  ReportsPages,
  reportsPages,
  ReportsPaymentsTabs,
  reportsPaymentsTabs
} from "../../models/navigation.model";
import { ActivatedRoute, Router } from "@angular/router";
import { UtilsService } from "../../../../../common/src/lib/services";
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { NavigationService } from '../../services/navigation.service';
import { ReportsService } from '../../services/reports.service';
import { DateFilter } from '../../models/date-filter.model';
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: 'app-reports-payments',
  templateUrl: './reports-payments.component.html',
  styleUrls: ['./reports-payments.component.scss']
})
export class ReportsPaymentsComponent implements OnDestroy {

  @ViewChild('download') download!: ElementRef;
  report$ = this.reportsService.report$;
  dateFilter$ = this.reportsService.selectedDateRangeForEvent$;
  tabs = Array.from(reportsPaymentsTabs);
  pages = Array.from(reportsPages);

  downloading$ = this.reportsService.downloading$;

  nav$ = combineLatest([
    this.navigationService.page$,
    this.navigationService.tab$
  ]).pipe(
    map(([page, tab]) => {
      return {
        page,
        tab
      };
    })
  );

  get selectedPaymentIds() {
    return this.reportsService.selectedPaymentIds;
  }

  constructor(
    private navigationService: NavigationService,
    private reportsService: ReportsService,
    private route: ActivatedRoute,
    private router: Router,
    private utilsService: UtilsService,
    private snackBar: MatSnackBar
  ) { }

  get isMobile() {
    return this.utilsService.isMobile();
  }

  onTabSelected(tab: string) {
    const page = pageNameToPath(tab as ReportsPaymentsTabs);
    this.reportsService.selectedPaymentIds.clear();
    this.router.navigate([page], {relativeTo: this.route});
  }

  onPageSelected(page: string) {
    const chosenPage = pageNameToPath(page as ReportsPages);
    this.reportsService.selectedPaymentIds.clear();
    this.router.navigateByUrl('reports/' + [chosenPage]);
  }

  selectedRangeChange(event: [Date, Date] | null) {
    this.reportsService.setSelectedDateRange(event ? DateFilter.from(event) : null);
  }

  getSelectedTab(selectedTab: string | null) {
    if (selectedTab)
      if (selectedTab === 'All')
        return 'paymentsAll';
      else if (selectedTab === 'Paid')
        return 'paymentsPaid';
      else
        return 'paymentsPending';

    return '';
  }

  async reportDownload() {
    if (this.isMobile) {
      showSnackbar(this.snackBar, {
        message: 'Allowed only on desktop',
        duration: 3000
      });
    } else {
      this.reportsService.downloading$.next(true);
      const rawData = await this.reportsService.downloadExport();
      // const selectedReportsArray = Array.from(this.reportDownloadService.selectedReports, jsonStr => JSON.parse(jsonStr));
      const formattedData = await this.reportsService.exportPaymentsForDownload(rawData);
      //this.reportDownloadService.createExcelFile(formattedData);
      this.reportsService.downloading$.next(false);
      this.reportsService.selectedPaymentIds.clear();
    }
  }

  ngOnDestroy() {
    this.reportsService.selectedPaymentIds.clear();
  }

  checkAllSelected(checked: boolean, allIds: number[]) {
    this.reportsService.selectedPaymentIds = checked
      ? new Set<number>(allIds)
      : new Set<number>();
  }

  isAllSelected(allIds: number[]) {
    return allIds.length > 0 && allIds.length === this.selectedPaymentIds.size
      && allIds.every((x) => this.selectedPaymentIds.has(x));
  }
}
