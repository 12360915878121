<mat-form-field class="form-field" appearance="outline"
    appFormFieldFilled
    [class.show-clear-button]="showClearButton"
    [class.focused]="focus" [class.filled]="multipleAssigneeControl.value"
    (click)="onClick($event)">
    <img matPrefix src="../../../assets/icons/search.svg">
    <input matInput
           class="flex-fill"
           [matAutocomplete]="auto"
           [value]="''"
           #searchInput
           #trigger="matAutocompleteTrigger"
           (keyup)="handleChange($event)"
           placeholder="Search by name"
           [formControl]="multipleAssigneeControl"
           (focus)="onFocus()"
           (focusout)="onFocusOut()"
    />
    <div matSuffix class="clear-btn-wrapper">
        <app-icon display="inline-flex" src="close"
                  class="se-icon-button no-bg close-button"
                  *ngIf="showClearButton"
                  (click)="clear($event)"
        ></app-icon>
    </div>

    <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let user of filteredUsers" [value]="userFullName(user)" (click)="assignUser(user)">
                {{ userFullName(user) }}
            </mat-option>
            <mat-option class="no-results-option"
                        *ngIf="searchedOnce && filteredUsers.length === 0"
                        (click)="trigger.openPanel()" disabled>No results
            </mat-option>
    </mat-autocomplete>
</mat-form-field>
