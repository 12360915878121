import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { BasicEvent, dateString, separateItemsByDate, UtilsService } from 'projects/common/src/public-api';
import { map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { UserProfile } from '../../../../../common/src/lib/models/user-profile.model';
import { DateRangeComponent } from '../../../../../common/src/lib/components/date-range/date-range.component';
import { UsersService } from '../../services/users.service';
import { TimetableService } from '../../services/timetable.service';
import { AvailabilityService } from '../../services/availability.service';
import moment from 'moment';
import { Availability } from "../../models/availability.model";
import { UserSearchBarComponent } from "../../components/user-search-bar/user-search-bar.component";
import { DateFilter } from "../../models/date-filter.model";
import { EstimateStatus, EstimateTileData } from "../../models/estimate.model";
import { JobStatus, JobTileData } from "../../models/jobs.model";
import { ScheduleItem } from "../../models/schedule.model";
import { scheduleToList } from "../../services/schedule.service";

@Component({
  selector: 'app-availability-modal',
  templateUrl: './availability-modal.component.html',
  styleUrls: ['./availability-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AvailabilityModalComponent implements AfterViewInit {

  dialogTitle = 'Assign to';
  tabs = ['List', 'Calendar view'];
  currentTab = this.tabs[0];
  showAssignButton!: boolean;
  @Input() singleAssigneeInList = false;
  @Input() hideAssignees: number[] = [];
  // selectedUserName!: string;

  assigneesList$ = this.usersService.assigneesList$.pipe(
      map(users => users!.filter(user => !this.hideAssignees.includes(user.id)))
  );
  assignedUsersSubject = new BehaviorSubject<UserProfile[]>([]);

  selectedDateRange$ = new BehaviorSubject<[Date, Date] | null>(null);
  selectedUserEvents$ = combineLatest([this.assignedUsersSubject, this.selectedDateRange$]).pipe(
      switchMap(async ([users, dateFilter]) => {
        if(users.length === 0)
          return null;
        const dateRanges = dateFilter ? new DateFilter(dateFilter[0], dateFilter[1]) : undefined;
        return this.availabilityService.getScheduleForUsers(users.map(u => u.id), dateRanges);
      }),
      separateItemsByDate((item: ScheduleItem) => dateString(item.startTime, item.endTime)),
      map(separatedItems => {
        return Object.keys(separatedItems).map(date => [date, scheduleToList(separatedItems[date])]) as [string, (Partial<EstimateTileData> | Partial<JobTileData> | Availability)[]][];
      })
  );

  @Input() assignedUsers: UserProfile[] = []
  @Output() close = new EventEmitter();
  @Output() usersAssigned = new EventEmitter<UserProfile[]>();
  @ViewChild('userSearch') userSearch!: UserSearchBarComponent;
  @ViewChild('dateRange') dateRange!: DateRangeComponent;

  // @ViewChild('userSelect') userSelect!: UserSelectComponent;

  smallScreen$ = this.utilsService.onScreenBreakpointChange('md').pipe(
      map(large => !large)
  );

  dateFilterMinDate = (): Date => {
    return new Date();
  };

  dateFilterMaxDate = (): Date => {
    return moment().add(10, 'days').toDate();
  };

  constructor(
    private utilsService: UtilsService,
    public timetableService: TimetableService,
    public usersService: UsersService,
    private availabilityService: AvailabilityService,
    private cd: ChangeDetectorRef
  ) { }

  eventsSort = (a: BasicEvent, b: BasicEvent): number => {
    if (a.startTime.getTime() === b.startTime.getTime() && a.endTime.getTime() === b.endTime.getTime()) {
      if (a.docType && b.docType) {
        return b.docType.localeCompare(a.docType); // Estimates-Jobs, replace a with b to swap Jobs-Estimates
      } else return 0
    } else if (a.startTime.getTime() === b.startTime.getTime()) {
      return a.endTime.getTime() - b.endTime.getTime();
    } else {
      return a.startTime.getTime() - b.startTime.getTime();
    }
  };

  assignedUsersCheck(selectedUser: UserProfile ) {
      this.showAssignButton = !this.assignedUsers.find(user => user.id === selectedUser.id)
  }

  onAssigneesChange(users: UserProfile[]) {
    this.assignedUsers = users;
    this.assignedUsersSubject.next(users);
  }

  // userSelected(user: UserProfile) {
  //   // this.selectedUserSubject.next(user);
  //   if (user)
  //     this.selectedUserName = user.firstName;
  // }

  ngAfterViewInit(): void {
    this.assignedUsersSubject.next(this.assignedUsers);
    // this.selectedDateRange$.nexr = new BehaviorSubject<[Date, Date] | null>(this.dateRange.selected);
    // this.selectedUserEvents$ = combineLatest([this.selectedUser$, this.selectedDateRange$, this.usersService.assigneesList$]).pipe(
    //   // filter events by assignee id
    //   map(([user, separatedEvents, dateFilter, assigneesList]) => {
    //     if (!separatedEvents || !user) {
    //       return null;
    //     }
    //     separatedEvents = {...separatedEvents};
    //     if (user !== 'All Accounts') {
    //       for (const date in separatedEvents) {
    //         separatedEvents[date] = separatedEvents[date].filter((event) => {
    //           if ((event as any).userId && (event as any).userId === user.id)
    //             return true;
    //           if (isJobEventTimeRange(event))
    //             return Object.keys(event.assignees).includes(user.id+'');
    //           if (isEstimateEventTimeRange(event))
    //             return event.assignee === user.id;
    //           return false;
    //         });
    //       }
    //     }
    //     return [separatedEvents, dateFilter] as [SeparatedItems<BasicEvent>, [Date, Date] | null];
    //   }),
    //   // filter events by date
    //   map((params) => {
    //     if (!params) {
    //       return null;
    //     }
    //     const [separatedEvents, dateFilter] = params;
    //     if (!separatedEvents) {
    //       return null;
    //     }
    //     if (dateFilter) {
    //       let [fromDate, toDate] = dateFilter;
    //       for (const date in separatedEvents) {
    //         separatedEvents[date] = separatedEvents[date].filter((event) => fromDate && toDate ? event.startTime >= fromDate && event.startTime <= toDate : true);
    //       }
    //     }
    //     return separatedEvents as SeparatedItems<BasicEvent>;
    //   }),
    //   // convert to array and sort events by date
    //   map((separatedEvents) => {
    //     if (!separatedEvents) {
    //       return [];
    //     }
    //     const separatedEventsArray: [string, BasicEvent[]][] = [];
    //     for (const date in separatedEvents) {
    //       if (separatedEvents[date].length !== 0) {
    //         separatedEventsArray.push([date, separatedEvents[date].sort(this.eventsSort)]);
    //       }
    //     }
    //     // sort date groups by date
    //     return separatedEventsArray.sort((a, b) => a[1][0].startTime > b[1][0].startTime ? 1 : -1);
    //   }),
    // );

    this.cd.detectChanges();
  }

  handleClearButtonClick() {
    // const request = (this.data.request ?? this.data.initialRequest)!
    // request.keepInLoop = false;
  }

  get isMobile(): boolean {
    return this.utilsService.isMobile();
  }

  get isIOS(): boolean {
    return this.utilsService.isIOS();
  }

  tabSelected(tab: string): void {
    this.currentTab = tab;
  }

  async submit() {
    this.usersAssigned.emit(this.assignedUsersSubject.value);
    this.close.emit()
  }

  unAssignUser(assignedUser: UserProfile): void {
    this.assignedUsers = this.assignedUsers.filter(user => user.id !== assignedUser.id);
    this.assignedUsersSubject.next(this.assignedUsers);
  }
}
