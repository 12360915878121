<div class="dialog wrapper">
    <div class="header">
        <h3>Email change</h3>
        <app-icon class="close se-icon-button" (click)="close()" src="close"></app-icon>
    </div>
    <mat-stepper class="content" #stepper *ngIf="showReauthentication else onlyEmailChangeForm">
        <mat-step>
            <ng-container *ngIf="(providers$ | async)!.includes('Email')" [ngTemplateOutlet]="passwordConfirmation"></ng-container>
            <div class="separator" *ngIf="showSeparator$ | async">
                <div></div>
                <p>Or</p>
                <div></div>
            </div>
            <ng-container *ngIf="(providers$ | async)!.includes('Google')" [ngTemplateOutlet]="googleConfirmation"></ng-container>
        </mat-step>
        <mat-step>
            <ng-container [ngTemplateOutlet]="emailChangeForm"></ng-container>
        </mat-step>
    </mat-stepper>
    <ng-template #onlyEmailChangeForm>
        <mat-stepper class="content">
            <mat-step>
                <ng-container [ngTemplateOutlet]="emailChangeForm"></ng-container>
            </mat-step>
        </mat-stepper>
    </ng-template>
</div>

<ng-template #passwordConfirmation>
    <form class="d-flex flex-column align-items-stratch" [formGroup]="form">
        <p class="subtitle">Enter your current password</p>
        <div class="d-flex justify-content-between">
            <h4>Password</h4>
            <h4 class="simple-text-button" (click)="openForgotPassword()" data-cy="forgotPasswordButton">Forgot password?</h4>
        </div>
        <mat-form-field class="" appearance="outline" appFormFieldFilled>
            <input matInput #passwordInput
                   data-cy="passwordInput"
                   formControlName="password"
                   autocomplete="off"
                   [type]="showPassword ? 'text' : 'password'"
                   placeholder="Enter your password"
                   tabindex="2"
                   enterkeyhint="go"
            />
            <img *ngIf="form.get('password')!.value.length > 0" matSuffix [src]="'../../../assets/icons/password-' + (showPassword ? 'hide' : 'show') +'.svg'"
                 (click)="showPassword = !showPassword">
            <mat-error *ngIf="form.get('password')!.hasError('required') && form.get('password')!.touched">
                Password is required
            </mat-error>
            <mat-error *ngIf="form.get('password')!.hasError('password') && form.get('password')!.touched">
                Invalid password. Re-enter
            </mat-error>
        </mat-form-field>
        <div class="buttons w-100">
            <loader-button [loading]="loading" text="Confirm" (onClick)="validatePassword()" data-cy="confirmButton"></loader-button>
            <button mat-stroked-button (click)="close()" data-cy="cancelButton">Close</button>
        </div>
    </form>
</ng-template>

<ng-template #googleConfirmation>
    <div class="google-confirmation">
        <app-providers-row type="reauthenticate" (onSuccess)="stepper.next()"></app-providers-row>
    </div>
</ng-template>

<ng-template #emailChangeForm>
    <form class="d-flex flex-column align-items-stretch" [formGroup]="form">
        <p class="subtitle">Enter a new email address</p>
        <h4>Email</h4>
        <mat-form-field appearance="outline" appFormFieldFilled>
            <input matInput #emailInput
                    inputmode="email"
                    data-cy="emailInput"
                    formControlName="email"
                    autocomplete="email"
                    placeholder="Enter a new email address"
                    enterkeyhint="next"
                    tabindex="1"
            />
            <mat-error *ngIf="form.get('email')!.hasError('required') && form.get('email')!.touched">
                Email is required
            </mat-error>
            <mat-error *ngIf="form.get('email')!.hasError('email') && form.get('email')!.touched">
                Invalid email
            </mat-error>
            <mat-error *ngIf="form.get('email')!.hasError('emailInUse') && form.get('email')!.touched">
                Email already in use
            </mat-error>
        </mat-form-field>

        <h4>Password</h4>
        <mat-form-field appearance="outline" appFormFieldFilled>
            <input matInput #passwordInput
                inputmode="password"
                data-cy="passwordInput"
                formControlName="password"
                autocomplete="password"
                [type]="showPassword ? 'text' : 'password'"
                placeholder="Create a password"
                enterkeyhint="go"
                tabindex="2"
            />
            <img *ngIf="form.get('password')!.value.length > 0" matSuffix [src]="'../../../assets/icons/password-' + (showPassword ? 'hide' : 'show') +'.svg'"
                 (click)="showPassword = !showPassword">
            <mat-error *ngIf="form.get('password')!.hasError('required') && form.get('password')!.touched">
                Password is required
            </mat-error>
            <mat-error *ngIf="form.get('password')!.hasError('minlength') && form.get('password')!.touched">
                At least 6 characters 
            </mat-error>
            <mat-error *ngIf="form.get('password')!.hasError('samePassword') && form.get('password')!.touched">
                Enter a different password from the old one
            </mat-error>
        </mat-form-field>

        <div class="buttons w-100">
            <loader-button [loading]="loading" text="Change" (onClick)="changeEmail()" data-cy="changeInput"></loader-button>
            <button mat-stroked-button (click)="close()" data-cy="cancelInput">Cancel</button>
        </div>

        <div class="separator">
            <div></div>
            <p>Or</p>
            <div></div>
        </div>

        <app-providers-row type="link" (onStart)="onGoogleLinkStart()" (onSuccess)="close()" (onFailed)="onGoogleLinkFailed($event)"></app-providers-row>
    </form>
</ng-template>