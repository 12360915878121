import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { UtilsService } from "../../services";

export function clamp(num: number, min: number, max: number) {
  return Math.min(Math.max(num, min), max);
}

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit, OnDestroy {

  @ViewChild('image') image!: ElementRef<HTMLImageElement>;
  @ViewChild('imageContainer') imageContainer!: ElementRef;

  @Input() imageUrl!: string | SafeUrl;
  @Input() showDownload?: boolean;

  @Output() onClose = new EventEmitter();

  imageTransform = '';
  imageContainerTransform = '';

  panning = false;
  initialPanPosition = {
    x: 0,
    y: 0,
  };

  translateX = 0;
  translateY = 0;

  zoom = 1;
  translateXLimit = 0;
  translateYLimit = 0;
  rotation = 0;

  originalImageRect!: DOMRect;

  get isMobile() {
    return this.utilsService.isMobile();
  }

  get smallerSide() {
    return this.image.nativeElement.clientWidth < this.imageContainer.nativeElement.clientWidth ? 'Width' : 'Height';
  }

  get imageWidth() {
    return this.image.nativeElement.clientWidth;
  }

  get imageHeight() {
    return this.image.nativeElement.clientHeight;
  }

  constructor(
    private meta: Meta,
    private utilsService: UtilsService,
  ) { }

  ngOnInit(): void {
    this.meta.addTag({
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0, maximum-scale=5.0, minimum-scale=1.0'
    });
  }

  ngOnDestroy(): void {
    this.meta.updateTag({
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0'
    }, "name='viewport'");
  }

  onMouseDown(event: MouseEvent) {
    event.preventDefault();
    this.panning = true;
    this.initialPanPosition = {
      x: event.clientX - this.translateX,
      y: event.clientY - this.translateY,
    };
  }

  onMouseMove(event: MouseEvent) {
    event.preventDefault();
    if(this.panning) {
      this.translateX = -this.initialPanPosition.x + event.clientX;
      this.translateY = -this.initialPanPosition.y + event.clientY;
      this.setStyle();
    }
  }

  onMouseUp(event: MouseEvent) {
    event.preventDefault();
    this.panning = false;
  }

  axisPosition(mousePosition: number, axis: 'x' | 'y') {
    const sizeName = axis === 'x' ? 'width' : 'height';
    if(this.imageIsBiggerThanContainer(axis)) {
      const positionInPercent = mousePosition / this.imageContainer.nativeElement.getBoundingClientRect()[sizeName];
      const adjustedPosition = (2 * positionInPercent) - 1;
      const clampedPosition = clamp(adjustedPosition, -1, 1);
      
      const margin = this.imageContainer.nativeElement.getBoundingClientRect()[sizeName] - this.image.nativeElement.getBoundingClientRect()[sizeName];
      return (margin * clampedPosition) + 'px';
    }
    
    return '';
  }


  imageIsBiggerThanContainer(axis: 'x' | 'y' | 'both') {
    const image = this.image.nativeElement.getBoundingClientRect();
    const imageContainer = this.imageContainer.nativeElement.getBoundingClientRect();

    if (axis === 'both')
      return image.height > imageContainer.height || image.width > imageContainer.width;
      
    if (axis === 'x')
      return image.width > imageContainer.width;

    return image.height > imageContainer.height;
  }

  calcMousePositionOnImage(event: MouseEvent): [number, number] {
    
    const rect = this.imageContainer.nativeElement.getBoundingClientRect();
    const imageHeight = rect.height;
    const imageWidth = rect.width;
    const originX = rect.x;
    const originY = rect.y;

    const mouseXPosition = event.clientX;
    const mouseYPosition = event.clientY;

    const actualXPosition = Math.min(mouseXPosition - originX, imageWidth);
    const actualYPosition = Math.min(mouseYPosition - originY, imageHeight);

    return [actualXPosition, actualYPosition];
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(evt: KeyboardEvent) {
    this.onClose.emit();
  }

  calculateLimits() {
    this.translateXLimit = (this.zoom - 1) * this.imageWidth / this.zoom / 2;
    this.translateYLimit = (this.zoom - 1) * this.imageHeight / this.zoom / 2;
  }

  zoomIn() {
    this.zoom += 0.3;
    if(this.zoom > 5) {
      this.zoom = 5;
    }
    this.calculateLimits();
    
    this.setStyle();
  }

  zoomOut() {
    this.zoom -= 0.3;
    if (this.zoom < 1)
      this.zoom = 1;

    this.calculateLimits();

    this.setStyle();
  }

  rotate() {
    this.rotation += 90;
    if(this.rotation === 360) {
      this.rotation = 0;
    }
    this.setStyle();
  }

  setStyle() {
    if(this.zoom === 1) {
      this.translateX = 0;
      this.translateY = 0;
    }
    
    this.imageTransform = `scale(${this.zoom},${this.zoom}) translateX(${this.translateX}px) translateY(${this.translateY}px) rotate(${this.rotation}deg)`;

  }

}
