import { Component } from '@angular/core';
import { ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { BehaviorSubject } from "rxjs";
import { ItemExampleComponent } from "../item-example/item-example.component";
import * as XLSX from "xlsx";
import { Item } from "../../models/item.model";
import { ItemsService } from "../../services/items.service";
import {tap} from "rxjs/operators";

type ModalStates = {
  title: 'Upload Items' | 'Uploaded Items' | 'Upload failed' | 'Uploading...',
  status: 'init' | 'success' | 'fail' | 'loading',
  unsupportedFormat?: boolean,
  createdItemsCount?: number,
  error?: string
};

const supportedFormats = ['text/csv', 'csv', 'xlsx', 'xls'];

@Component({
  selector: 'app-item-upload',
  templateUrl: './item-upload.component.html',
  styleUrls: ['./item-upload.component.scss']
})
export class ItemUploadComponent {

  fileOverDrag = false;

  private modalState$ = new BehaviorSubject<ModalStates>(
    {
      title: "Upload Items",
      status: "init"
    }
  );
  _modalState$ = this.modalState$.asObservable();

  get data() {
    return this.modalsService.data;
  }

  constructor(
    private modalsService: ModalsService,
    private itemsService: ItemsService
  ) { }

  async close() {
    await this.modalsService.close();
  }

  async openExample() {
    await this.modalsService.open(ItemExampleComponent, {
      disableClose: true
    });
  }

  async fileUploaded(file: File) {
    if (!file) {
      return;
    }

    this.modalState$.next(
      {
        title: "Uploading...",
        status: "loading"
      }
    );
    const format = file.name?.split('.')?.pop()!.toLowerCase();
    if (supportedFormats.includes(format))
      await this.processFile(file, format);
    else
      this.modalState$.next(
        {
          title: "Upload Items",
          status: "init",
          unsupportedFormat: true
        }
      );
  }

  private async processFile(file: File, format: string) {
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = async (e: any) => {
      if (format === 'csv' || format === 'text/csv')
        await this.processCsv(e.target.result);
      else if (format === 'xls' || format === 'xlsx')
        await this.processXls(e.target.result);
    }
  }

  private async processCsv(raw: any) {
    try {
      let [keys, ...rest] = raw
        .trim()
        .split("\n")
        .map((item: any) => item.split(','))
      keys = keys.map((key: any) => {
        return key.toLowerCase();
      });

      if (this.validKeys(keys)) {
        const items: Item[] = rest.map((item: any) => {
          const object: any = {};
          keys.forEach((key: any, index: any) => (object[key] = item.at(index)));
          return object;
        });

        if (this.validItems(items))
          await this.createItems(items);
        else
          this.modalState$.next({
            title: 'Upload failed',
            status: 'fail',
            error: 'Incorrect format'
          });
      }
    } catch (e) {
      this.modalState$.next({
        title: 'Upload failed',
        status: 'fail',
        error: 'Incorrect format'
      });
    }
  }

  private async processXls(raw: any) {
    try {
      const binaryString: string = raw;
      const wb: XLSX.WorkBook = XLSX.read(binaryString, {type: 'binary'});
      const ws: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[0]];
      if (this.validKeys(this.getXlsHeaders(ws))) {
        const items: (Item | any)[] | any = XLSX.utils.sheet_to_json(ws);
        this.truncateKeys(items);
        if (this.validItems(items))
          await this.createItems(items);
        else
          this.modalState$.next({
            title: 'Upload failed',
            status: 'fail',
            error: 'Incorrect format'
          });
      }
    } catch (e) {
      this.modalState$.next({
        title: 'Upload failed',
        status: 'fail',
        error: 'Incorrect format'
      });
    }
  }

  private getXlsHeaders(sheet: any) {
    try {
      let headers: any[] = [];
      let range = XLSX.utils.decode_range(sheet['!ref']);
      let C, R = range.s.r;
      for (C = range.s.c; C <= range.e.c; ++C) {
        let cell = sheet[XLSX.utils.encode_cell({c:C, r:R})]
        let hdr = "UNKNOWN " + C;
        if (cell && cell.t)
          hdr = XLSX.utils.format_cell(cell);

        if (
          hdr === 'name (required)'
          || hdr === 'name(required)'
          || hdr === 'name *'
          || hdr === 'name*'
        )
          hdr = 'name';
        if (
          hdr === 'price (required)'
          || hdr === 'price(required)'
          || hdr === 'price *'
          || hdr === 'price*'
        )
          hdr = 'price';

        headers.push(hdr);
      }
      return headers;
    } catch (e) {
      this.modalState$.next({
        title: 'Upload failed',
        status: 'fail',
        error: 'Incorrect format'
      });
      return [];
    }
  }

  private validKeys(keys: string[]): boolean {
    let nameCount = 0;
    let descriptionCount = 0;
    let priceCount = 0;
    let costCount = 0;
    let taxableCount = 0;
    let validKeys = true;

    for (const key of keys) {
      switch (key) {
        case 'name':
          nameCount++;
          break;
        case 'description':
          descriptionCount++;
          break;
        case 'price':
          priceCount++;
          break;
        case 'cost':
          costCount++;
          break;
        case 'taxable':
          taxableCount++;
          break;
      }
      if (
        key !== 'name'
        && key !== 'description'
        && key !== 'price'
        && key !== 'cost'
        && key !== 'taxable'
      )
        validKeys = false;
    }

    let validCount = true;
    if (
      nameCount !== 1
      || priceCount !== 1
      || descriptionCount > 1
      || costCount > 1
      || taxableCount > 1
    )
      validCount = false;

    if (!validKeys || !validCount) {
      this.modalState$.next({
        title: 'Upload failed',
        status: 'fail',
        error: 'Incorrect format'
      });
    }
    return validKeys && validCount;
  }

  private validItems(items: Item[]): boolean {
    for (let i = 0; i < items.length; i++) {
      if (!items[i].name || items[i].name.toString().trim() === '')
        return false;
      else
        items[i].name = items[i].name.toString();
      if (!items[i].price)
        return false;
      if (items[i].price < 0)
        return false;
      if (isNaN(items[i].price))
        return false;
    }

    return true;
  }

  private async createItems(items: Item[]) {
    try {
      for (let i = 0; i < items.length; i++) {
        if (items[i].cost) {
          if (isNaN(items[i].cost!))
            delete items[i].cost;
          if (items[i] && items[i].cost)
            if (items[i].cost! < 0)
              delete items[i].cost;
        }
        if (!Object.keys(items[i]).includes('taxable'))
          items[i].taxable = false;
        else
          items[i].taxable = (items[i].taxable as any).trim().toLowerCase() === 'yes';

        let counter = 1;
        const originalName = items[i].name.trim();
        let res: any = null;

        do {
          try {
            res = await this.itemsService.createItem(items[i], null, true);
          } catch (e: any) {
            res = e.error;
            counter ++;
            items[i].name = '#' + counter + ' ' + originalName;
          }
        } while (res.startsWith('duplicate key'));

      }
      this.modalState$.next({
        title: 'Uploaded Items',
        status: 'success',
        createdItemsCount: items.length
      });
    } catch (e) {
      this.modalState$.next({
        title: 'Upload failed',
        status: 'fail',
        error: 'Incorrect format'
      });
    }
  }

  truncateKeys(items: (Item | any)[]) {
    if (items && items.length > 0)
      for (const item of items)
        for (const key in item) {
          if (Object.prototype.hasOwnProperty.call(item, key)) {
            if (
              key === 'name (required)'
              || key === 'name(required)'
              || key === 'name *'
              || key === 'name*'
            ) {
              const newValue = item[key];
              delete item[key];
              item['name'] = newValue;
            }
            if (
              key === 'price (required)'
              || key === 'price(required)'
              || key === 'price *'
              || key === 'price*'
            ) {
              const newValue = item[key];
              delete item[key];
              item['price'] = newValue;
            }
          }
        }
  }
}
