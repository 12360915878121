import { Pipe, PipeTransform } from '@angular/core';
import { AddressComponents } from "../models/address-comments.model";

export function componentsToString(components: AddressComponents) {
  return `${components.streetNumber ?? ''} ${components.streetName ?? ''}, ${components.city ?? ''}, ${components.state ?? ''} ${components.zip ?? ''}`;
}

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(value: any) {
    if (!value) return '';
    return componentsToString(value);
  };

}