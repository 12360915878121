<div class="dialog wrapper">
    <div class="title-container">
        <h3>{{ (hasEmailProvider$ | async) ? 'Password change' : 'Create password' }}</h3>
        <app-icon class="close se-icon-button no-bg" (click)="close()" src="close" ></app-icon>
    </div>
    <form class="content d-flex flex-column align-items-stratch" [formGroup]="form">

        <p *ngIf="hasEmailProvider$ | async" class="subtitle">Enter current password and create a new one</p>
        <p *ngIf="!(hasEmailProvider$ | async)" class="subtitle">Create a password in order to log in with this email address and a password</p>

        <div class="d-flex justify-content-between" *ngIf="showReauthentication && (hasEmailProvider$ | async)">
            <h4>Password</h4>
            <h4 class="simple-text-button" (click)="openForgotPassword()" data-cy="forgotPasswordButton">Forgot password?</h4>
        </div>
        <mat-form-field appearance="outline" appFormFieldFilled *ngIf="showReauthentication && (hasEmailProvider$ | async)">
            <input matInput #passwordInput
                data-cy="passwrodInput"
                formControlName="password"
                autocomplete="off"
                name="avoidSearch"
                placeholder="Enter your password"
                [type]="showPassword ? 'text' : 'password'"
                tabindex="1"
                enterkeyhint="next"
                (focus)="passwordFocused = true"
                (focusout)="passwordFocused = false"
            />
            <img *ngIf="form.get('password')!.value.length > 0" matSuffix [src]="'../../../assets/icons/password-' + (showPassword ? 'hide' : 'show') +'.svg'" (click)="showPassword = !showPassword">
            <mat-error *ngIf="form.get('password')!.hasError('required') && form.get('password')!.touched">
                Password is required
            </mat-error>
            <mat-error *ngIf="form.get('password')!.hasError('password') && form.get('password')!.touched">
                Invalid password. Re-enter
            </mat-error>
        </mat-form-field>

        <h4>New password</h4>
        <mat-form-field class="" appearance="outline" appFormFieldFilled>
            <input matInput #newPasswordInput
                data-cy="newPasswrodInput"
                formControlName="newPassword"
                autocomplete="password"
                placeholder="At least 6 characters"
                [type]="showNewPassword ? 'text' : 'password'"
                tabindex="2"
                enterkeyhint="go"
            />
            <img *ngIf="form.get('newPassword')!.value.length > 0" matSuffix [src]="'../../../assets/icons/password-' + (showNewPassword ? 'hide' : 'show') +'.svg'" (click)="showNewPassword = !showNewPassword">
            <mat-error *ngIf="form.get('newPassword')!.hasError('required') && form.get('newPassword')!.touched">
                New password is required
            </mat-error>
            <mat-error *ngIf="form.get('newPassword')!.hasError('minlength') && form.get('newPassword')!.touched">
                at least 6 characters
            </mat-error>
            <mat-error *ngIf="form.get('newPassword')!.hasError('samePassword') && form.get('newPassword')!.touched">
                Enter a different password from the old one
            </mat-error>
        </mat-form-field>
        <div class="buttons d-flex flex-column align-items-stretch">
            <loader-button [loading]="loading" [text]="(hasEmailProvider$ | async) ? 'Change' : 'Create'" (onClick)="changePassword()" data-cy="changeButton"></loader-button>
            <button mat-stroked-button (click)="close()" data-cy="cancelButton">Cancel</button>
        </div>
    </form>
</div>