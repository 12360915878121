<div class="h-100 w-100 d-flex flex-column">
    <div class="content flex-fill overlay-scrollbar">
        <ng-container *ngFor="let header of headers; let i = index">

            <app-section-divider [text]="header" [scheduleToday]="true" [id]="items[header][0].endDate"
                             class="items-divider"
            ></app-section-divider>
            
            <div class="items">
                <app-customer-tile
                        *ngFor="let item of items[header]; let i = index;"
                        [item]="item"
                        [showUser]="showUser"
                ></app-customer-tile>
            </div>
        </ng-container>

    </div>
</div>
