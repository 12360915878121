import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Proposal, ProposalVersion } from "../../models/proposal.model";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { isEmpty } from "../../helpers/forms"
import { DepositValues } from "../../../../../common/src/lib/models/clientProposal.model";
import { BusinessService } from "../../services/business.service";
import moment from 'moment';
import { UtilsService } from "../../../../../common/src/lib/services";

@Component({
  selector: 'app-proposal-preview-and-send',
  templateUrl: './proposal-preview-and-send.component.html',
  styleUrls: ['./proposal-preview-and-send.component.scss']
})
export class ProposalPreviewAndSendComponent implements OnInit, OnChanges {

  protected readonly isEmpty = isEmpty;

  @Input() proposal!: Proposal;
  @Input() currentProposalVersion!: ProposalVersion;
  @Input() sentMode? = false;

  paidAmount = 0;

  dateSubject = new BehaviorSubject<Date>(new Date());
  date$ = this.dateSubject.asObservable().pipe(
    map(date => {
      return {
        createdDate: moment(date).format('MM/DD/YYYY'),
        createdTime: moment(date).format('h:mmA')
      }
    })
  );

  badge?: 'preview' | 'not-opened' | 'pending' | 'pending-incomplete' | 'declined' | 'won' | 'canceled';

  subtotal: number = 0;
  discount: number = 0;
  salesTax: number = 0;
  total: number = 0;

  business$ = this.businessService.selectedBusiness$;

  previewScaleFactor$ = this.utilsService.viewportWidth$.pipe(
      map(viewportWidth => {
        if((viewportWidth - 50) > 525) {
          return 1;
        }
        return (viewportWidth - 50) / 525;
      })
  );

  constructor(
    private businessService: BusinessService,
    private utilsService: UtilsService
  ) { }

  async ngOnInit() {
    if (!this.sentMode) {
      this.badge = 'preview';
      setInterval(() => {
        this.dateSubject.next(new Date());
      }, 1000);
    } else {
      this.dateSubject.next(this.currentProposalVersion.clientSentAt!);
    }

    this.calculatePreviewSummary();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if ('currentProposalVersion' in changes) {
      this.calculatePreviewSummary();
      this.paidAmount = this.proposal.sPaidAmount!;
      this.setBadge();
      // if ('clientProposal' in changes)
      //   if (this.clientProposal) {
      //     this.now.next(this.clientProposal.createdAt!);
      //
      //   if (this.sentMode) {
      //     this.now.next(this.currentProposalVersion.clientSentAt!);
      //     this.uidData = this.currentProposalVersion.clientUid!;
      //   }
      // }
      
      if (this.sentMode)
        this.dateSubject.next(this.currentProposalVersion.clientSentAt!);

      if (this.proposal.canceledFromPayment)
        this.badge = 'canceled';
    }
  }

  setBadge() {
    if (!this.currentProposalVersion.opened && this.currentProposalVersion.clientStatus !== 'canceled' && this.currentProposalVersion.clientStatus !== 'declined') {
      this.badge = 'not-opened';
      return;
    }
    switch (this.currentProposalVersion.clientStatus) {
      case 'pending':
        this.badge = 'pending';
        return;
      case 'pending_incomplete':
        this.badge = 'pending-incomplete';
        return;
      case 'paid':
        this.badge = 'won';
        return;
      case 'canceled':
        this.badge = 'canceled';
        return;
      case 'accepted':
        this.badge = 'won';
        return;
      case 'declined':
        this.badge = 'declined';
        return;
      case 'in_progress':
        this.badge = 'won';
        return;
    }
  }

  nextSubUid() {
    return Math.max(...this.proposal.versions.map(v => v.subUid ?? 0)) + 1;
  }

  getItemTaxAmount(price: number, taxable: boolean, discountAmount: number | null): number {
    if (!taxable)
      return 0;

    let discountedPrice = price;
    if (discountAmount)
      discountedPrice -= discountAmount;
    return discountedPrice*this.currentProposalVersion.clientSalesTaxPercentage!/100;
  }

  getItemTotal(price: number, taxable: boolean, discountAmount: number | null, quantity: number | null): number {
    const discountedPrice = discountAmount
        ? (price - discountAmount)
        : price;
    const taxed = taxable
        ? discountedPrice + (discountedPrice*this.currentProposalVersion.clientSalesTaxPercentage!/100)
        : discountedPrice;

    return quantity ? quantity*taxed : taxed;
  }

  calculatePreviewSummary() {
    this.subtotal = 0;
    this.discount = 0;
    this.salesTax = 0;
    this.total = 0;

    this.currentProposalVersion.items?.forEach(item => {
      let discounted = 0;
      let taxed = 0;

      this.subtotal += (item.quantity ?? 0) * item.price;
      this.discount += (item.discountAmount ?? 0) * (item.quantity ?? 0);

      discounted = item.price - (item.discountAmount ?? 0);

      taxed = !item.taxable
        ? discounted
        : discounted + (discounted * (this.currentProposalVersion.clientSalesTaxPercentage ?? 0) / 100);

      if (item.quantity && item.taxable && item.price)
        this.salesTax += parseFloat(((taxed - discounted) * item.quantity).toFixed(2));

      if (item.quantity)
        this.total += parseFloat((taxed * item.quantity).toFixed(2));
    });
  }

  getDepositValues(): DepositValues | null {
    if (!this.proposal.sBalanceDue || !this.currentProposalVersion.depositAmount)
      return null;

    return {
      full: this.currentProposalVersion.depositAmount,
      paid: this.proposal.sPaidAmount ?? 0,
      pending: this.proposal.sPaidAmount ? this.currentProposalVersion.depositAmount - this.proposal.sPaidAmount : 0
    };
  }

  getBadgeCopy(badgeId: string): string | null {
    switch (badgeId) {
      case 'preview':
        return 'Preview';
      case 'not-opened':
        return 'Not Opened';
      case 'pending':
        return 'Pending';
      case 'pending-incomplete':
        return 'Pending';
      case 'won':
        return 'Won';
      case 'canceled':
        return 'Canceled';
      case 'declined':
        return 'Declined';
      default:
        return null;
    }
  }
}
