<div class="page main h-100 w-100 d-flex flex-column justify-content-start" style="overflow: hidden" *ngIf="user$ | async let user" fadeIn="500" [fadeInDelay]="200">
    <app-toolbar type="menu" leftAction="Back" (backClick)="backClick()"></app-toolbar>
    <div class="flex-fill d-flex flex-column reports-main">
        <div class="container reports_header d-flex flex-column">

            <div class="d-flex justify-content-center align-items-center show-after-sm">
                <app-tabs
                        linePosition="bottom"
                        alignment="center"
                        [tabs]="pages"
                        [activeTab]="(page$ | async)!"
                        (onTabSelect)="onPageSelected($event)"
                ></app-tabs>

            </div>
        </div>

        <div class="flex-fill reports-content">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
