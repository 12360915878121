<dynamic-list #list class="h-100" [style.display]="(dateFilter$ | async) ? 'none' : 'block'"
    (loadMoreTop)="loadItems('top', $event)"
    (loadMoreBottom)="loadItems('bottom', $event)"
    (itemEvent)="onItemEvent($event)"
    [initialTopItems]="initialTopItems"
    [initialBottomItems]="initialBottomItems"
    [firstHeader]="firstHeader$ | async"
>
    <app-empty-state
        tab="Unavailable"
        page="Schedule"
        [upcoming]="(list.topCount$ | async)! > 0"
    ></app-empty-state>
</dynamic-list>
<dynamic-list class="h-100" #dateFilterList
    *ngIf="dateFilter$ | async"
    (loadMoreTop)="loadDateFilterItems('top', $event)"
    (loadMoreBottom)="loadDateFilterItems('bottom', $event)"
    (itemEvent)="onItemEvent($event)"
    [initialTopItems]="initialFilterTopItems"
    [initialBottomItems]="initialFilterBottomItems"
    [canLoadMoreTop]="initialFilterCanLoadMoreTop"
    [canLoadMoreBottom]="initialFilterCanLoadMoreBottom"
    [firstHeader]="filterFirstHeader$ | async"
    [hasDateFilter]="true"
>
    <app-empty-state
        filter="Range"
    ></app-empty-state>
</dynamic-list>