export interface TimeRange {
    id?: number;
    rangeId?: number;
    startTime: Date;
    endTime: Date;
}

export function sameRanges(a: TimeRange[], b: TimeRange[]) {
    if(a.length !== b.length)
        return false;
    for(const range of a) {
        if(!b.find(r => r.startTime.getTime() === range.startTime.getTime() && r.endTime.getTime() === range.endTime.getTime()))
            return false;
    }
    return true;
}