import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PaymentReportItem } from "../../models/reports.model";
import { PaymentsReportTileComponent } from "../payments-report-tile/payments-report-tile.component";
import { DynamicListComponent } from "../dynamic-list/dynamic-list.component";
import { Pagination } from "../../services/pagination.service";
import { BehaviorSubject } from "rxjs";
import { shareReplay, take, tap } from "rxjs/operators";
import { ReportsService } from "../../services/reports.service";
import { BusinessService } from "../../services/business.service";
import { SupabaseService } from "../../services/supabase.service";
import { ViewAsService } from "../../services/view-as.service";
import { BusinessProfile } from "../../../../../common/src/lib/models/business-profile.model";
import { dateString } from "../../../../../common/src/lib/services";
import { limitStep } from "../../services/reports-service-extras";

function paymentItemsToPaginationList(items: PaymentReportItem[], kind: 'All' | 'Pending' | 'Paid') {
  return items.map(item => ({
    componentClass: PaymentsReportTileComponent,
    header: dateString(item.createdAt),
    args: { item, kind }
  }));
}

@Component({
  selector: 'app-payments-report-items',
  templateUrl: './payments-report-items.component.html',
  styleUrls: ['./payments-report-items.component.scss']
})
export class PaymentsReportItemsComponent implements OnInit, OnDestroy {

  @Input() current!: 'All' | 'Pending' | 'Paid';

  @ViewChild('allList') allList!: DynamicListComponent<PaymentsReportTileComponent>;
  @ViewChild('allDateFilterList') allDateFilterList!: DynamicListComponent<PaymentsReportTileComponent>;
  @ViewChild('pendingList') pendingList!: DynamicListComponent<PaymentsReportTileComponent>;
  @ViewChild('pendingDateFilterList') pendingDateFilterList!: DynamicListComponent<PaymentsReportTileComponent>;
  @ViewChild('paidList') paidList!: DynamicListComponent<PaymentsReportTileComponent>;
  @ViewChild('paidDateFilterList') paidDateFilterList!: DynamicListComponent<PaymentsReportTileComponent>;

  allPagination!: Pagination<PaymentReportItem>;
  allDateFilterPagination!: Pagination<PaymentReportItem>;
  pendingPagination!: Pagination<PaymentReportItem>;
  pendingDateFilterPagination!: Pagination<PaymentReportItem>;
  paidPagination!: Pagination<PaymentReportItem>;
  paidDateFilterPagination!: Pagination<PaymentReportItem>;

  loading$ = new BehaviorSubject<boolean>(true);

  dateFilter$ = this.reportsService.selectedDateRangeForEvent$.pipe(
    tap(async dates => {
      if (dates) {
        this.loadItems('All', 0, undefined, dates, true);
        this.loadItems('Pending', 0, undefined, dates, true);
        this.loadItems('Paid', 0, undefined, dates, true);
      } else {
        this.allDateFilterList?.clear();
        this.pendingDateFilterList?.clear();
        this.paidDateFilterList?.clear();
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true})
  );

  constructor(
    private reportsService: ReportsService,
    private businessService: BusinessService,
    private supabaseService: SupabaseService,
    private viewAsService: ViewAsService,
    private cd: ChangeDetectorRef
  ) { }

  async ngOnInit() {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();

    this.allPagination = this.createPagination(business.businessId, 'payments_all_report', 'p_all')
      .on(
        '*',
        'payment',
        _ => this.handleChange(this.allList, 'All')
      );
    this.allDateFilterPagination = this.createPagination(business.businessId, 'payments_all_report', 'p_all_d')
      .on(
        '*',
        'payment',
        _ => this.handleChange(this.allDateFilterList, 'All')
      );
    this.pendingPagination = this.createPagination(business.businessId, 'payments_pending_report', 'p_pen')
      .on(
        '*',
        'payment',
        _ => this.handleChange(this.pendingList, 'Pending')
      );
    this.pendingDateFilterPagination = this.createPagination(business.businessId, 'payments_pending_report', 'p_pen_d')
      .on(
        '*',
        'payment',
        _ => this.handleChange(this.pendingDateFilterList, 'Pending')
      );
    this.paidPagination = this.createPagination(business.businessId, 'payments_paid_report', 'p_paid')
      .on(
        '*',
        'payment',
        _ => this.handleChange(this.paidList, 'Paid')
      );
    this.paidDateFilterPagination = this.createPagination(business.businessId, 'payments_paid_report', 'p_paid_d')
      .on(
        '*',
        'payment',
        _ => this.handleChange(this.paidDateFilterList, 'Paid')
      );

    await this.loadItems('All', 0, business, undefined, false);
    await this.loadItems('Pending', 0, business, undefined, false);
    await this.loadItems('Paid', 0, business, undefined, false);
    this.loading$.next(false);
  }

  ngOnDestroy() {
    this.allPagination?.destroy();
    this.allDateFilterPagination?.destroy();
    this.pendingPagination?.destroy();
    this.pendingDateFilterPagination?.destroy();
    this.paidPagination?.destroy();
    this.paidDateFilterPagination?.destroy();
  }

  async loadItems(
    type: 'All' | 'Pending' | 'Paid',
    inOffset = 0,
    business?: BusinessProfile,
    dates?: [Date, Date] | null,
    dateList?: boolean
  ) {
    if (!business)
      business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();

    if (dateList && !dates)
      dates = await this.dateFilter$.pipe(take(1)).toPromise();
    if (!dates)
      dates = [business.createdAt, new Date()];

    const selectedUsers = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();

    const inLimit = limitStep;

    const pagination = type === 'Pending'
      ? !dateList
        ? this.pendingPagination
        : this.pendingDateFilterPagination
      : type === 'Paid'
        ? !dateList
          ? this.paidPagination
          : this.paidDateFilterPagination
        : !dateList
          ? this.allPagination
          : this.allDateFilterPagination;

    const list =
      type === 'Pending' ? !dateList
        ? this.pendingList
        : this.pendingDateFilterList
      : type === 'Paid'
        ? !dateList
          ? this.paidList
          : this.paidDateFilterList
        : !dateList
          ? this.allList
          : this.allDateFilterList;

    const items = await pagination.get({
      startTime: dates[0],
      endTime: dates[1],
      rowLimit: inLimit,
      offsetCount: inOffset,
      selectedUsers: selectedUsers ?? []
    });

    if (items.length < inLimit && list.canLoadMoreBottom)
      list.canLoadMoreBottom = false;

    list?.addAtBottom(paymentItemsToPaginationList(items, type));
  }

  async handleChange(list: DynamicListComponent<PaymentsReportTileComponent>, type: string) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();

    list.clear();
    this.cd.detectChanges();
    this.loadItems(
      type as 'All' | 'Pending' | 'Paid',
      list.getBottomCount,
      business
    );
  }

  createPagination(businessId: string, fn: string, cname: string) {
    return new Pagination<PaymentReportItem>(
      this.supabaseService,
      fn,
      businessId,
      cname
    );
  }
}
