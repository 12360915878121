import { ElementRef, Injectable } from "@angular/core";
import { ReplaySubject, timer } from "rxjs";
import { switchMap, debounce } from "rxjs/operators";
import { AddressComponents } from "../models/address-comments.model";

export const searchFieldDebounce = 0;

export interface BusinessAddressComponents extends AddressComponents {
    name: string;
}

export interface PlaceResult extends google.maps.places.PlaceResult {
    sessionToken: google.maps.places.AutocompleteSessionToken;
    type: TypeName;
}

interface AutocompleteTypes {
    address: AddressComponents,
    establishment: BusinessAddressComponents,
};

export type TypeName = keyof AutocompleteTypes;

@Injectable({
    providedIn: 'root'
  })
export class PlacesService {

    appComponentElement!: HTMLDivElement

    constructor() {}

    async init(appComponentElement: HTMLDivElement) {
        this.appComponentElement = appComponentElement;
        const res = await (google.maps as any).importLibrary("places");
    }

    async getPlaceAutocomplete<T extends TypeName>(element: ElementRef<HTMLInputElement>, type: T) {
        const autocompleteService = new google.maps.places.AutocompleteService();
        const sessionToken = new google.maps.places.AutocompleteSessionToken();
        const inputSubject = new ReplaySubject<string>();
        element.nativeElement.addEventListener('input', (e) => {
            inputSubject.next((e.target as any).value);
        });
        const limit = type === 'establishment' ? 1 : 7;

        return inputSubject.pipe(
            debounce(value => {
                if(value && value.length <= limit) {
                    return timer(0);
                } 
                return timer(500);
            }),
            switchMap(async (value) => {
                if(value && value.length >= limit) {
                    return new Promise<google.maps.places.AutocompletePrediction[] | null>(resolve => {
                        // this.placesAnalyticsService.logPlaceSearch(sessionToken, type, 'autocomplete');
                        autocompleteService.getPlacePredictions({
                            types: [type],
                            input: value,
                            sessionToken: sessionToken
                            }, (a, b) => {
                                if(!a)
                                    resolve(null);
                                else
                                    resolve(a.map(p => {
                                        return {
                                            ...p,
                                            sessionToken,
                                            type,
                                            toString: () => p.description
                                        };
                                    }));
                            });
                    });
                }
                return null;
            })
        );
    }

    getPlaceDetails<T = AddressComponents | BusinessAddressComponents>(placeId: string, sessionToken: google.maps.places.AutocompleteSessionToken, type: TypeName) {
        const autocompleteService = new google.maps.places.PlacesService(this.appComponentElement);
        const request = {
            placeId,
            sessionToken,
            fields: type === 'establishment' ? ['address_components', 'name'] : ['address_components']
        };
        return new Promise<T>(resolve => {
            // this.placesAnalyticsService.logPlaceSearch(sessionToken, type, 'details');
            autocompleteService.getDetails(request, (place) => {
                const components: any = {
                    streetNumber: place!.address_components?.find(c => c.types.includes('street_number'))?.long_name,
                    streetName: place!.address_components?.find(c => c.types.includes('route'))?.long_name,
                    city: place!.address_components?.find(c => c.types.includes('locality'))?.long_name,
                    state: place!.address_components?.find(c => c.types.includes('administrative_area_level_1'))?.long_name,
                    country: place!.address_components?.find(c => c.types.includes('country'))?.short_name,
                    zip: place!.address_components?.find(c => c.types.includes('postal_code'))?.long_name,
                };

                if(place!.name)
                    (components as any).name = place!.name;

                resolve(components as T);
                
            });
        })
    }

}
