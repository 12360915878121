<div class="page bg">

    <app-toolbar type="menu" [hideLobby]="!(showBackButton$ | async)"></app-toolbar>
  
    <div class="d-flex flex-column flex-fill justify-content-start align-items-stretch container">
  
      <div class="header">
        <app-icon
            *ngIf="showBackButton$ | async"
            src="back"
            class="se-icon-button"
            (click)="back()"></app-icon>
      </div>
  
      <div class="d-flex flex-fill justify-content-center align-items-center">
  
        <mat-card class="mat-elevation-z3">
            <mat-card-title><h2 class="text-center">Let's get started!</h2></mat-card-title>
            <mat-card-subtitle><p class="text-center">Tell us a bit about your business</p></mat-card-subtitle>
          
            <form class="d-flex flex-column align-items-stretch profile-form" [formGroup]="form">
              <h4>Business Name</h4>
              <mat-form-field appearance="outline" appFormFieldFilled>
                <input matInput #businessInput id="input_businessName"
                       formControlName="businessName"
                       autocomplete="off"
                       name="avoidSearch"
                       [matAutocomplete]="businessNameAutocomplete"
                       placeholder="Example: Servicename, LLC"
                       (focus)="businessNameFocused = true"
                       (focusout)="businessNameFocused = false"
                       enterkeyhint="next"
                       tabindex="1"
                />
                <mat-autocomplete #businessNameAutocomplete="matAutocomplete">
                  <mat-option (placeOptionClick)="onBusinessNameOptionSelected($event)" *ngFor="let option of businessNameAutocompleteOptions$ | async" [value]="option">
                    {{ option.structured_formatting.main_text }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="form.get('businessName')!.hasError('required') && form.get('businessName')!.touched">
                  Business name is required
                </mat-error>
              </mat-form-field>
          
              <h4>Address</h4>
              <mat-form-field appearance="outline" appFormFieldFilled>
                <input #addressInput matInput id="input_address"
                       formControlName="address"
                       type="text"
                       placeholder="Enter at least 7 characters to for autocomplete"
                       autocomplete="off"
                       name="avoidSearch"
                       [matAutocomplete]="addressAutocomplete"
                       (focus)="addressFocused = true"
                       (focusout)="addressFocused = false"
                       enterkeyhint="next"
                       tabindex="2"
                />
                <mat-autocomplete #addressAutocomplete="matAutocomplete">
                  <mat-option (placeOptionClick)="onAddressOptionSelected($event)" *ngFor="let option of addressAutocompleteOptions$ | async" [value]="option">
                    {{ option.description }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="form.get('address')!.hasError('required') && form.get('address')!.touched">
                  Address is required
                </mat-error>
                <mat-error *ngIf="form.get('address')!.hasError('addressIsString') && form.get('address')!.touched">
                  Select a full address option from the dropdown 
                </mat-error>
                <mat-error *ngIf="form.get('address')!.hasError('missingComponents') && form.get('address')!.touched">
                  Select a full address option from the dropdown 
                </mat-error>
              </mat-form-field>
          
              <h4>Phone number</h4>
              <mat-form-field appearance="outline" appFormFieldFilled>
                <input #phoneNumberInput matInput id="input_phoneNumber"
                       formControlName="phoneNumber"
                       type="tel"
                       placeholder="Enter phone number"
                       autocomplete="off"
                       name="avoidSearch"
                       (focus)="phoneNumberFocused = true"
                       (focusout)="phoneNumberFocused = false"
                       enterkeyhint="next"
                       tabindex="3"
                       mask="(000) 000-0000"
                />
                <mat-error
                  *ngIf="!phoneNumberFocused && form.get('phoneNumber')!.hasError('mask') && form.get('phoneNumber')!.touched">
                  Invalid phone number. Re-enter
                </mat-error>
                <mat-error *ngIf="form.get('phoneNumber')!.hasError('required') && form.get('phoneNumber')!.touched">
                  Phone number is required
                </mat-error>
              </mat-form-field>
  
              <h4>First name</h4>
              <mat-form-field appearance="outline" appFormFieldFilled>
                <input matInput #firstNameInput id="input_firstName"
                       formControlName="firstName"
                       type="text"
                       placeholder="Enter your first name"
                       inputmode="firstName"
                       autocomplete="off"
                       name="avoidSearch"
                       (focus)="firstNameFocused = true"
                       (focusout)="firstNameFocused = false"
                       enterkeyhint="next"
                       tabindex="5"
                />
                <mat-error *ngIf="form.get('firstName')!.hasError('required') && form.get('firstName')!.touched">
                  First name is required
                </mat-error>
              </mat-form-field>
          
              <h4>Last name</h4>
              <mat-form-field appearance="outline" appFormFieldFilled>
                <input matInput #lastNameInput id="input_lastName"
                       formControlName="lastName"
                       type="text"
                       placeholder="Enter your last name"
                       inputmode="lastName"
                       autocomplete="off"
                       name="avoidSearch"
                       (focus)="firstNameFocused = true"
                       (focusout)="firstNameFocused = false"
                       enterkeyhint="next"
                       tabindex="6"
                />
                <mat-error *ngIf="form.get('lastName')!.hasError('required') && form.get('lastName')!.touched">
                  Last name is required
                </mat-error>
              </mat-form-field>
          
              <div class="footer">
                <loader-button [adjustWidth]="false" class="flex-fill" id="button_next"
                  [loading]="creating" text="Create"
                  (click)="create()"
                ></loader-button>
              </div>
            </form>
        </mat-card>
      </div>
    </div>
  </div>
  