import { Component } from '@angular/core';
import { ReportsService } from '../../services/reports.service';
import { DateFilter } from '../../models/date-filter.model';
import { Router } from "@angular/router";
import { NavigationService } from "../../services/navigation.service";
import { pageNameToPath, ReportsPages, reportsPages } from "../../models/navigation.model";
import { currencify } from "../../helpers/common";
import { BehaviorSubject, Observable } from "rxjs";
import { CompareReport } from "../../models/reports.model";

@Component({
  selector: 'app-reports-compare',
  templateUrl: './reports-compare.component.html',
  styleUrls: ['./reports-compare.component.scss']
})
export class ReportsCompareComponent {

  protected readonly currencify = currencify;

  datePickerLeftIsEmpty$ =  new BehaviorSubject<boolean>(true);
  datePickerRightIsEmpty$ =  new BehaviorSubject<boolean>(true);

  dateFilterLeft$ = this.reportsService.selectedDateRangeForEventCompareLeft$ ;
  dateFilterRight$ = this.reportsService.selectedDateRangeForEventCompareRight$ ;
  pages = Array.from(reportsPages);
  page$ = this.navigationService.page$;

  report$: Observable<CompareReport> = this.reportsService.report$ as Observable<CompareReport>;

  constructor(
    private reportsService: ReportsService,
    private navigationService: NavigationService,
    private router: Router
  ) { }

  onPageSelected(page: string) {
    const chosenPage = pageNameToPath(page as ReportsPages);
    this.router.navigateByUrl('reports/' + [chosenPage]);
  }

  calculateColumnHeight(mainValue: number, secondValue: number): string {
    const maxValue = Math.max(mainValue, secondValue);
    const maxHeight = window.innerWidth < 768
      ? 184
      : window.innerWidth > 991
        ? 276
        : 196;

    if (maxValue === 0)
      return '0px';
    else {
      const height = (mainValue / maxValue) * maxHeight;
      return `${height}px`;
    }
  }

  calculatePercentageDifference(mainValue: number, secondValue: number): { percent: string, isPositive: boolean } {
    if (mainValue === 0 && secondValue === 0)
      return { percent: '', isPositive: false };

    const difference = ((mainValue - secondValue) / Math.max(mainValue, secondValue)) * 100;
    const isPositive = difference > 0;
    const percent = isPositive ? `+${difference.toFixed(2)}%` : `${difference.toFixed(2)}%`;

    return { percent, isPositive };
  }

  selectedRangeChange(event: [Date, Date] | null, side: string) {
    if (side === 'left') {
      this.datePickerLeftIsEmpty$.next(!event);
      this.reportsService.setSelectedDateRangeCompareLeft(event ? DateFilter.from(event) : null);
    } else {
      this.datePickerRightIsEmpty$.next(!event);
      this.reportsService.setSelectedDateRangeCompareRight(event ? DateFilter.from(event) : null);
    }
  }
}
