import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule as NGCommonModule } from '@angular/common';
import { NoteComponent } from './components/note/note.component';
import { CommonModule } from 'projects/common/src/public-api';
import { ReactiveFormsModule } from '@angular/forms';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { TextFieldModule } from '@angular/cdk/text-field';

import { NotesComponent } from './components/notes/notes.component';
import { MaterialModule } from '../../../../../common/src/lib/material.module';
import { MobileModalHeaderComponent } from './components/mobile-modal-header/mobile-modal-header.component';
import { AppRoutingModule } from '../../app-routing.module';

@NgModule({
  declarations: [
    NoteComponent,
    NotesComponent,
    MobileModalHeaderComponent,
  ],
  exports: [
    NoteComponent,
    CommonModule,
    NotesComponent,
    MobileModalHeaderComponent,
    TextFieldModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  imports: [
    NGCommonModule,
    CommonModule,
    ReactiveFormsModule,
    DragDropModule,
    TextFieldModule,
    ReactiveFormsModule,
    MaterialModule,
    AppRoutingModule,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule { }
