<div class="d-flex justify-content-between align-items-center" *ngIf="(smallScreen$ | async) else smallScreen">
  <div class="d-flex justify-content-between align-items-center gap--16">
    <p class="name" [class.inactive]="user.status === 'inactive' || user.status === 'inactive_suspended'">{{ capitalizeFirstChar(user.firstName + ' ' + user.lastName) }}</p>

    <div style="width: 70px">
      <app-role-label style="display: inline-block;" [role]="user.role" [status]="user.status"></app-role-label>
    </div>

    <div class="info-icon-wrapper" *ngIf="showNotificationsToggle">
      <p>Notifications:</p>
      <div class="icon" maskImageSrc="../../assets/icons/question-mark-tooltip.svg" matTooltip="Turn on to follow this user's notifications" matTooltipPosition="above" [hideTooltipOnScroll]="tooltip" #tooltip="matTooltip" (click)="tooltip.show()"></div>
    </div>
    <div class="notifications-switch" *ngIf="showNotificationsToggle">
      <mat-slide-toggle color="primary" [checked]="notificationsState$ | async" (change)="updateUserNotifications($event)"></mat-slide-toggle>
    </div>
  </div>

  <app-icon *ngIf="!hideMenu" class="clickable dots3-icon" src="3dots" [matMenuTriggerFor]="menu"></app-icon>
</div>

<ng-template #smallScreen>
  <div class="d-flex flex-column gap--20">
    <div class="d-flex justify-content-between align-items-center">
      <p class="name" [class.inactive]="user.status === 'inactive' || user.status === 'inactive_suspended'">{{ capitalizeFirstChar(user.firstName + ' ' + user.lastName) }}</p>
      <app-icon class="clickable dots3-icon" src="3dots" [matMenuTriggerFor]="menu"></app-icon>
    </div>

    <div class="d-flex align-items-center" [class.justify-content-between]="showNotificationsToggle" [class.justify-content-end]="!showNotificationsToggle">
      <div class="d-flex align-items-center gap--4" *ngIf="showNotificationsToggle">
        <div class="info-icon-wrapper">
          <p>Notifications:</p>
          <div class="icon" maskImageSrc="../../assets/icons/question-mark-tooltip.svg" matTooltip="Turn on to follow this user's notifications" [hideTooltipOnScroll]="tooltip" #tooltip="matTooltip" (click)="tooltip.show()"></div>
        </div>
        <div class="notifications-switch">
          <mat-slide-toggle color="primary" [checked]="notificationsState$ | async" (change)="updateUserNotifications($event)"></mat-slide-toggle>
        </div>
      </div>

      <app-role-label [role]="user.role" [status]="user.status"></app-role-label>
    </div>
  </div>
</ng-template>

<mat-menu #menu>
  <mat-option (click)="editUser()" *ngIf="!user.status.includes('inactive') && !user.status.includes('suspended')">Edit</mat-option>
  <mat-option (click)="resendInvitation()" *ngIf="user.status.includes('inactive')">Resend</mat-option>
  <mat-option *ngIf="!hideSuspend && !user.status.includes('inactive')" (click)="user.status === 'suspended' || user.status === 'inactive_suspended' ? unsuspendUser() : suspendUser()">{{user.status === 'suspended' || user.status === 'inactive_suspended' ? 'Restore' : 'Suspend'}}</mat-option>
  <mat-option (click)="deleteUser()" *ngIf="user.status.includes('inactive')">Delete</mat-option>
</mat-menu>
