export interface AddressComponents {
    streetNumber?: string;
    streetName?: string;
    city?: string;
    state?: string;
    country?: string;
    zip?: string;
}

export function addressComponentsEmpty(address: AddressComponents): boolean {
    return !address.streetNumber || !address.streetName || !address.city || !address.state || !address.country || !address.zip;

}