import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from "../../services/auth.service";
import { map, shareReplay, switchMap, tap } from "rxjs/operators";
import { DateFilterService } from '../../services/date-filter.service';
import { DateFilter } from '../../models/date-filter.model';
import { Router } from '@angular/router';
import { UsersService } from '../../services/users.service';
import { ClientItemsResult, ClientsService } from "../../services/clients.service";
import { dateString, SeparatedItems } from "../../../../../common/src/lib/services";
import { combineLatest, of } from 'rxjs';
import { ViewAsService } from '../../services/view-as.service';

type UserNames = { [id: string]: string };

function assigneeName(userNames: UserNames, assignees: number[]) {
  if(assignees.length === 1) {
    return userNames[assignees[0]];
  }
  return assignees.length + ' assignees';
}

function conformItems<T>(type: string, items: T[], userNames: UserNames, dateGetter: (item: T) => Date, assigneesGetter: (item: T) => number[]) {
  return items.map(item => {
    return {
      ...item,
      type,
      assigneeName: assigneeName(userNames, assigneesGetter(item)),
      ts: dateGetter(item)
    }
  }); 
}

function filterAndConformItems<T>(type: string, items: T[], userNames: UserNames, dateFilter: DateFilter, dateGetter: (item: T) => Date, assigneesGetter: (item: T) => number[]) {
  const results: (T & {ts: Date})[] = [];
  for(const item of items) {
    const ts = dateGetter(item);
    if(dateFilter.from <= ts && ts <= dateFilter.to) {
      results.push({
        ...item,
        type,
        assigneeName: assigneeName(userNames, assigneesGetter(item)),
        ts
      });
    }
  }
  return results;
}

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit, OnDestroy {
  user$ = this.authService.user$;
  // userNames$ = this.usersService.users$.pipe(
  //   map(users => {
  //     const userNames: UserNames = {};
  //     for(const user of (users ?? [])) {
  //       userNames[user.id] = `${user.firstName} ${user.lastName}`;
  //     }
  //     return userNames;
  //   })
  // );
  //
  // dateFilterService = new DateFilterService();
  dateFilter$ = this.dateFilterService.selectedDateRange$;
  dateFilterForPicker$ = this.dateFilterService.selectedDateRangeForEvent$;
  //
  // itemResults$ = this.route.paramMap.pipe(
  //   switchMap(params => {
  //     const [ firstName, lastName ] = params.get('clientName')?.split('+')!;
  //     return this.searchService.resultsForName(firstName, lastName);
  //   })
  // );
  //
  // isEmpty$ = this.items$.pipe(
  //   map(items => {
  //     for(const array of Object.values(items)) {
  //       if(array.length > 0)
  //         return false;
  //     }
  //     return true;
  //   })
  // );
  //

  // hasItems!: boolean;
  // items!: SeparatedItems<(ClientItemsResult<any>)>;

  customerList$ = combineLatest([
    this.clientsService.currentClientDataSubject,
    this.viewAsService.selectedUsersIds$,
    this.dateFilterService.selectedDateRange$
  ]).pipe(
    switchMap(([args, ids, dateFilter]) => {
      if (!args)
        return of(null);
      this.loading = true;
      const inStartDate = dateFilter ? dateFilter.from : undefined;
      const inEndDate = dateFilter ? dateFilter.to : undefined;
      return this.clientsService.getItemsForClient({ ...args, inUserIds: ids ?? undefined, inStartDate, inEndDate });
    }),
    tap(_ => this.loading = false),
    shareReplay(1)
  );

  items$ = this.customerList$.pipe(
    map(items => {
      if (!items || !items.length)
        return null;

      const separated: SeparatedItems<ClientItemsResult<any>> = {};
      for (const item of items) {
        const date = dateString(item.startDate);
        if (!separated[date]) {
          separated[date] = [];
        }
        separated[date].push(item);
      }
      return separated;
    })
  );

  hasItems$ = this.customerList$.pipe(map(items => !!items?.length));
  showUser$ = this.viewAsService.selectedUsersIds$.pipe(map(ids => !ids || ids.length > 1));

  loading = false;

  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private clientsService: ClientsService,
    private router: Router,
    private viewAsService: ViewAsService,
    private dateFilterService: DateFilterService
  ) {
    // const navigation = this.router.getCurrentNavigation();
    // const data = navigation?.extras.state as { items: ClientItemsResult<any>[] };
    // if(!data || !data.items || data.items.length === 0) {
    //   router.navigate(['/lobby']);
    // } else {
    //   this.hasItems = data.items.length > 0;
    //   const separated: SeparatedItems<ClientItemsResult<any>> = {};
    //   for (const item of (data.items)) {
    //     const date = dateString(item.startDate);
    //     if (!separated[date]) {
    //       separated[date] = [];
    //     }
    //     separated[date].push(item);
    //   }

    //   this.items = separated;
    //   console.log(this.items);
    // }
    // if(navigation)
    //   delete navigation.extras.state;
  }

  ngOnInit() {
    if(!this.clientsService.currentClientDataSubject.value) {
      this.router.navigate(['/lobby']);
    }
  }

  selectedRangeChange(event: [Date, Date] | null) {
    this.dateFilterService.setSelectedDateRange(event ? DateFilter.from(event) : null);
  }

  ngOnDestroy() {
    this.clientsService.currentClientDataSubject.next(null);
  }

}
