import { Injectable } from '@angular/core';
import { UsersService } from './users.service';
import { combineLatest, of } from 'rxjs';
import { map, take, switchMap, shareReplay } from 'rxjs/operators';
import { UserTimetable, UserTimetableEntry } from '../models/user-timetable.model';
import { BusinessService } from './business.service';
import { TimeRange } from 'projects/common/src/lib/models/time-range.model';
import { rpcFilter, SupabaseService } from "./supabase.service";
import moment from "moment";
import { AvailabilityService } from "./availability.service";

@Injectable({
    providedIn: 'root'
})
export class TimetableService {
    defaultAvailableTimetableStart = new Date(new Date(0).setHours(9, 0, 0));
    defaultAvailableTimetableEnd = new Date(new Date(0).setHours(17, 0, 0));

    usersTimetables$ = combineLatest([this.businessService.selectedBusiness$, this.usersService.activeUsers$]).pipe(
        switchMap(([businessProfile, users]) => {
            if (!businessProfile || !users) {
                return of(null);
            }
            return combineLatest(users.map(user => this.userTimetableObservable(user.id))).pipe(
                map(timetables => {
                    return timetables.reduce((acc, curr) => {
                        acc[curr.userId] = curr;
                        return acc;
                    }, {} as { [uid: number]: UserTimetable })
                })
            );
        }),
        shareReplay(1),
    );

    currentUserTimetable$ = this.usersService.currentUser$.pipe(
        switchMap((currentUser) => {
            return this.userTimetableObservable(currentUser.id)
        }),
        shareReplay(1)
    );

    userTimetableObservable(userId: number) {
        return this.businessService.selectedBusiness$.pipe(
            switchMap(business => {
                return this.supabaseService.rpc<UserTimetableEntry[]>({
                    cName: 'user_timetable_' + userId,
                    fn: 'get_user_working_time_by_user_id',
                    schema: business.businessId,
                    tables: [{table: 'user_working_time', filter: rpcFilter('user_id', 'eq', userId)}],
                    options: {
                        in_user_id: userId
                    }
                });
            }),
            map(timetableEntries => {
                const userTimetable: UserTimetable = {
                    userId: timetableEntries[0].userId,
                    ranges: {},
                    ids: {},
                };
                for (const entry of timetableEntries) {
                    userTimetable.ranges[entry.dayOfWeek] = entry.ranges?.map(range => ({
                        startTime: moment(range.startTime, ['H:m:s']).toDate(),
                        endTime: moment(range.endTime, ['H:m:s']).toDate(),
                    })) ?? null;
                    userTimetable.ids[entry.dayOfWeek] = entry.id
                }
                return userTimetable;
            }),
            shareReplay({bufferSize: 1, refCount: true})
        );
    }

    constructor(
        private usersService: UsersService,
        private supabaseService: SupabaseService,
        private businessService: BusinessService,
        private availabilityService: AvailabilityService,
    ) {
    }

    get emptyTimetable() {
        return {userId: -1, ranges: {}, ids: {}, availability: []};
    }

    async updateTimetable(updateData: UserTimetable) {
        const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
        const promises: Promise<any>[] = [];
        for (const dayOfWeek of Object.keys(updateData.ranges)) {
            promises.push(
                this.supabaseService.update(business.businessId, 'user_working_time', updateData.ids[+dayOfWeek], {
                    ranges: updateData.ranges[+dayOfWeek]
                })
            );
        }
        return Promise.all(promises);
    }


    async fixEventActivities(eventRanges: TimeRange[], userIds: number[]) {
        this.availabilityService
        for(const range of eventRanges) {

        }
        const timetables = await this.usersTimetables$.pipe(take(1)).toPromise();
        if (!timetables) {
            return;
        }
        for (const userId of userIds) {
            // TODO: fix
            // const timetable = timetables[userId];
            // timetable.availability = timetable.availability.filter((range) => eventRanges.every((eventRange) => range.endTime <= eventRange.startTime || range.startTime >= eventRange.endTime));
            // timetable.availability.sort((range1, range2) => range1.startTime > range2.startTime ? 1 : -1);
            // await this.updateTimetable(timetable);
        }
    }
}
