import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dots-spinner',
  templateUrl: './dots-spinner.component.html',
  styleUrls: ['./dots-spinner.component.scss']
})
export class DotsSpinnerComponent {

  @Input() size!: 'small' | 'big' | 'x-small';
  @Input() color?: 'white';

}
