import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[maskImageSrc]'
})
export class MaskImageSrcDirective implements OnInit {
  
  @Input() maskImageSrc!: string;

  constructor(
    private renderer: Renderer2,
    private element: ElementRef
  ) {}

  ngOnInit(): void {
    this.renderer.setStyle(this.element.nativeElement, '-webkit-mask-image', `url('${this.maskImageSrc}')`);
    this.renderer.setStyle(this.element.nativeElement, '-webkit-mask-size', 'contain');
    this.renderer.setStyle(this.element.nativeElement, 'mask-image', `url('${this.maskImageSrc}')`);
    this.renderer.setStyle(this.element.nativeElement, 'mask-size', '100%');
    this.renderer.setStyle(this.element.nativeElement, 'mask-repeat', 'space');
  }

}
