<div class="dialog">
  <div class="header">
    <h3>Example</h3>
    <app-icon class="close se-icon-button" (click)="close()" src="close" ></app-icon>
  </div>
  <div class="dialog-content">
    <h3>Fill out the table with items</h3>
    <p class="first-p">Fields marked with an asterisk (*) are required</p>
    <img class="example-table-pic" src="assets/item-example-table.png" alt="item-example-table">
    <p class="second-p">Download a ready made table</p>
    <a #download class="download-button" (click)="mobileDownload()">Download</a>
  </div>
</div>
