import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { SupportChatService } from "../../services/support-chat.service";

@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.scss']
})
export class SendMessageComponent implements OnInit, OnChanges {

  @ViewChild('attachmentInput') attachmentInput!: ElementRef<HTMLInputElement>;

  @Input() messageInput = this.chatService.messageInput;
  @Input() disabled = false;

  @Output() sendEvent = new EventEmitter<string>();
  @Output() addFileEvent = new EventEmitter<File>();

  constructor(
    private chatService: SupportChatService,
  ) { }

  ngOnInit(): void {
    if (this.disabled)
      this.chatService.messageInput.disable();
    else
      this.chatService.messageInput.enable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('disabled' in changes) {
      if (changes.disabled.currentValue)
        this.chatService.messageInput.disable();
      else
        this.chatService.messageInput.enable();
    }
  }

  sendMessage() {
    if(this.messageInput.value && this.messageInput.value !== '') {
      this.sendEvent.emit(this.messageInput.value);
      this.chatService.messageInput.setValue('');
    }
  }

  addFile() {
    const file = this.attachmentInput.nativeElement.files?.item(0);
    if (this.messageInput.value! !== '')
      this.chatService.messageInput.setValue(this.messageInput.value!);
    if(file)
      this.addFileEvent.emit(file);
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.shiftKey && event.key === 'Enter' && this.messageInput.value!.trim().length > 0) {
      this.sendMessage();
      event.preventDefault();
    }
  }
}
