<div loggerKeybinding class="h-100" [class.isIOS]="isIOS" *ngIf="!loading">
    <div class="h-100" [style.background-color]="'white'" *ngIf="(modalComponent$ | async) let component else routerTemplate">
        <ng-container *ngComponentOutlet="component"></ng-container>
    </div>

    <ng-template #routerTemplate>
        <router-outlet></router-outlet>
    </ng-template>
</div>

<div #appComponent></div>
