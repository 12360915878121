<div class="mobile-header" *ngIf="(show$ | async)">
    <div class="mobile-header-content" [class.justify-content-end]="!leftButton">
        <p *ngIf="leftButton" class="text-button" (click)="onLeftButtonClick.emit()">{{ leftButton }}</p>
        <div *ngIf="rightButton">
            <p *ngIf="isTextButton() else menuButton" class="text-button" (click)="onRightButtonClick.emit()">{{ getRightButtonTitle() }}</p>
        </div>
        <div *ngIf="showMainMenu" class="d-flex align-items-center" style="gap: 28px; z-index: 2;">
            <app-view-as-button></app-view-as-button>
            <app-icon class="se-icon-button" [matMenuTriggerFor]="mainMenu" src="menu"></app-icon>
        </div>
        <p class="mobile-header-title">{{ titleText }}</p>
    </div>
</div>

<ng-template #menuButton>
    <p class="text-button" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" [hideMenuOnScreenSizeChange]="menuTrigger">{{ getRightButtonTitle() }}</p>
    <mat-menu #menu="matMenu">
        <button mat-menu-item *ngFor="let title of getActions() ?? []" (click)="onRightButtonClick.emit(title)">{{ title }}</button>
    </mat-menu>
</ng-template>

<mat-menu #mainMenu="matMenu" class="hide-active" xPosition="before">
    <mat-option *ngFor="let item of (menuItems$ | async)" [routerLink]="item.link" routerLinkActive="active" [attr.data-title]="item.title">{{ item.title }}</mat-option>
    <mat-option data-title="Sign out" id="button_signOut" (click)="logout()">Sign out</mat-option>
</mat-menu>
