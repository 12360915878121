<div *ngIf="item" class="tpr-item clickable" (click)="openPayment(item.wId, item.pId)">
  <div class="d-flex align-items-center">
    <mat-checkbox class="checkbox"
                  *ngIf="kind === 'Pending'" [color]="'primary'"
                  (change)="check($event.checked, item.id)" (click)="$event.stopPropagation()"
                  [checked]="checked"
    ></mat-checkbox>

    <div class="tpr-item-left">
      <div class="name">{{ item.name }}</div>
      <div class="jt">{{ item.jobType ?? 'N/A' }}</div>
    </div>
  </div>

  <div class="tpr-item-right">
    {{ currencify(item.amount) }}
  </div>
</div>

<div *ngIf="dividerNeed" class="tpr-item-divider"></div>
