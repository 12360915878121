<div class="dialog wrapper d-flex flex-column">

    <div class="header d-flex justify-content-between align-items-center">
        <h3>User added</h3>
        <app-icon style="scale: 1.2" class="se-icon-button no-bg" (click)="close()" src="close"></app-icon>
    </div>

    <div class="content">
        <p>
            {{ data.addedUserName }} was added as a {{ data.addedUserType }}. An email with the invitation was sent to his email
        </p>
    </div>

    <div class="footer d-flex justify-content-between align-items-center">
        <p class="se-button" [cdkCopyToClipboard]="data.invitationLink" (click)="onCopyClick()">Copy link</p>

        <loader-button [adjustWidth]="true" text="Done" (onClick)="close()"></loader-button>
    </div>
</div>