import { createClient, RealtimeChannel, RealtimePostgresChangesPayload, SupabaseClient } from "@supabase/supabase-js";
import { Observable, Subscriber } from "rxjs";
import { finalize, shareReplay } from "rxjs/operators";
import { TransformOptions } from "@supabase/storage-js/src/lib/types";
import { frontToServerTranslation, serverToFrontTranslation } from "projects/common/src/lib/services/supabase.service";
import { NgZone } from "@angular/core";

async function getItems<T>(supabase: SupabaseClient, subscriber: Subscriber<T | null>, schema: string, fn: string, options?: {
    [key: string]: any
}) {
    const res = await supabase.schema(schema).rpc(fn, options ? frontToServerTranslation(options) : options);
    const data = serverToFrontTranslation(res.data);
    if (res.error) {
        subscriber.next(null);
        subscriber.unsubscribe();
    } else
        subscriber.next(data);
    return data as T;
}

export function REPLACE_UPCOMING_CHANGES<T extends ({ id: number } & {
    [p: string]: any
})>(changes: RealtimePostgresChangesPayload<any>, items: T[]) {
    let newChanges = changes.new ? serverToFrontTranslation(changes.new) : null;
    switch (changes.eventType) {
        case 'INSERT':
            items.push(newChanges);
            break;
        case 'UPDATE':
            items.splice(
                items.findIndex(i => i.id === newChanges.id),
                1,
                newChanges
            );
            break;
        case 'DELETE':
             items.splice(
                items.findIndex(i => i.id === changes.old.id),
                1
             );
             break;
    }
    return items;
}

export function rpcFilter(column: string, operator: string, value: unknown) {
    if(Array.isArray(value))
        value = `("${value.join('","')}")`;
    return `${column}=${operator}.${value}`;
}

interface RPCConfig {
    cName: string,
    fn: string,
    schema: string,
    tables: (string | { table: string, filter?: string })[],
    options?: { [key: string]: any },
}

export class SupabaseService {

    public supabase!: SupabaseClient;

    constructor(
        config: { supabaseUrl: string, supabaseKey: string },
        private zone: NgZone
    ) {
        this.supabase = createClient(config.supabaseUrl, config.supabaseKey);
    }

    async insert<T>(schema: string, table: string, data: Partial<T>) {
        const res = await this.supabase
            .schema(schema)
            .from(table)
            .insert(frontToServerTranslation(data));
        if (res.error)
            return Promise.reject(res.error);
        return res.data;
    }

    async update<T>(schema: string, table: string, id: number | { key: string, value: any }, data: Partial<T>) {
        const eqKey = typeof id === 'number' ? 'id' : id.key;
        const eqValue = typeof id === 'number' ? id : id.value;
        const res = await this.supabase
            .schema(schema)
            .from(table)
            .update(frontToServerTranslation(data))
            .eq(eqKey, eqValue);
        if (res.error)
            return Promise.reject(res.error);
        return res.data;
    }

    async delete(schema: string, table: string, id: number | { key: string, value: any }) {
        const eqKey = typeof id === 'number' ? 'id' : id.key;
        const eqValue = typeof id === 'number' ? id : id.value;
        const res = await this.supabase
            .schema(schema)
            .from(table)
            .delete()
            .eq(eqKey, eqValue);
        if (res.error)
            return Promise.reject(res.error);
        return res.data;
    }

    async edgeFunc<T = string>(fn: string, options?: { [key: string]: any }) {
        const data = options ? {
            body: JSON.stringify(options)
        } : undefined;
        const res = await this.supabase.functions.invoke(fn, data);
        if (res.error)
            return Promise.reject(res.error);
        if (typeof res.data === 'string') {
            return res.data as T;
        }
        return serverToFrontTranslation(res.data) as T;
    }

    async rpcFunc<T>(schema: string, fn: string, options?: { [key: string]: any }) {
        const res = await this.supabase.schema(schema).rpc(fn, options ? frontToServerTranslation(options) : options);
        if (res.error)
            return Promise.reject(res.error);
        return serverToFrontTranslation(res.data) as T;
    }

    rpc<T>(
        config: RPCConfig,
    ): Observable<T>;
    rpc<TArr extends { [key: string]: any }[]>(
        config: RPCConfig,
        onChanges: (changes: RealtimePostgresChangesPayload<TArr>, items: TArr) => TArr | null,
        edgeCase?: 'progress_bar' | 'payment' | 'report' | 'items' | null
    ): Observable<TArr>;

    rpc<T>(
        config: RPCConfig,
        onChanges?: (changes: RealtimePostgresChangesPayload<{ [key: string]: any }>, items: T) => T | null,
        edgeCase?: 'progress_bar' | 'payment' | 'report' | 'items' | null
    ) {
        let channel: RealtimeChannel;
        const {cName, fn, schema, tables, options} = config;

        return new Observable<T>(subscriber => {
            let items: T;
            getItems(this.supabase, subscriber, schema, fn, options).then(i => items = i as T);
            channel = this.supabase.channel(cName);
            const setSubscriber = (value: T) => this.zone.run(() => subscriber.next(value));

            for (const tableC of tables) {

                const table = typeof tableC === 'string' ? tableC : tableC.table;
                const filter = typeof tableC !== 'string' ? tableC.filter : undefined;

                channel = channel.on(
                    'postgres_changes',
                    {event: '*', schema, table: table, filter},
                    (changes) => {
                        if (!edgeCase) {
                            if (onChanges) {
                                const resItems = onChanges(changes, items as T);
                                if (resItems) {
                                    items = resItems;
                                    setSubscriber(items);
                                    return;
                                }
                            } else if (!Array.isArray(items)) {
                                switch (changes.eventType) {
                                    case 'INSERT':
                                    case 'UPDATE':
                                        items = changes.new as T;
                                        setSubscriber(items);
                                        return;
                                    case 'DELETE':
                                        setSubscriber(null as any);
                                }
                                return;
                            }
                            getItems(this.supabase, subscriber, schema, fn, options).then(i => items = i as T);
                        } else if (
                          edgeCase === 'progress_bar'
                          || edgeCase === 'payment'
                          || edgeCase === 'report'
                          || edgeCase === 'items'
                        ) {
                            getItems(this.supabase, subscriber, schema, fn, options).then(i => items = i as T);
                        }
                    }
                );
            }

            channel.subscribe();
        }).pipe(
            shareReplay({ bufferSize: 1, refCount: true }),
            finalize(() => {
                if (channel)
                    this.supabase.removeChannel(channel);
            })
        );
    }

    multiSchemaRPC<T>(
        config: {
            cName: string,
            fn: string,
            schema: string,
            options?: { [key: string]: any }
        },
        tablesGenerator: (data: T) => {
            schema: string,
            table: string,
            filter?: string
        }[],
        onChanges?: (changes: RealtimePostgresChangesPayload<{ [key: string]: any }>, data: T) => T | null,
    ): Observable<T> {
        let channel: RealtimeChannel;
        const {cName, fn, schema, options} = config;

        return new Observable<T>(subscriber => {
            const setSubscriber = (value: T) => this.zone.run(() => subscriber.next(value));
            
            getItems(this.supabase, subscriber, schema, fn, options).then(res => {
                const tables = tablesGenerator(res);
                channel = this.supabase.channel(cName);

                for (const table of tables) {
                    channel = channel.on(
                        'postgres_changes',
                        {event: '*', schema: table.schema, table: table.table, filter: table.filter},
                        (changes) => {
                            if (onChanges) {
                                const data = onChanges(changes, res);
                                if (data) {
                                    res = data;
                                    setSubscriber(res);
                                    return;
                                }
                            }
                            getItems(this.supabase, subscriber, schema, fn, options).then(data => res = data);
                        }
                    );
                }

                channel.subscribe();
            })
        }).pipe(
            finalize(() => {
                if (channel)
                    this.supabase.removeChannel(channel);
            })
        );
    }

    async uploadFile(bucketName: string, fileName: string, file: File) {
        return this.supabase
            .storage
            .from(bucketName)
            .upload(fileName, file, {
                cacheControl: '3600',
                contentType: file.type,
                upsert: true
            }).then(async (res) => this.supabase.storage.from(bucketName).getPublicUrl(fileName));
    }

    async downloadFile(bucketName: string, fileName: string, options?: { transform?: TransformOptions }) {
        return this.supabase
            .storage
            .from(bucketName)
            .download(fileName, options)
            .then(res => res.data ? new File([res.data], fileName, { type: res.data.type }) : null);
    }

    fileUrl(bucketName: string, fileName: string, options?: { transform?: TransformOptions }) {
        return this.supabase
            .storage
            .from(bucketName)
            .getPublicUrl(fileName, options).data.publicUrl;
    }

    async deleteFile(bucketName: string, ...fileNames: string[]) {
        return this.supabase
            .storage
            .from(bucketName)
            .remove(fileNames);
    }

}
