<div class="tile-wrapper" (click)="tileClick.emit(lead)">
    <app-base-tile
        [data]="data"
        [hideOnSmallScreens]="false"
    >
        <div class="info" trailing>
            <div class="se-button delete-button" (click)="onDeleteClick($event)">Delete</div>
            <div class="creation-time-owner-info">
                <h4 class="created-at">{{ timePassed$ | async }}</h4>
                <small *ngIf="showOwner$ | async" class="assignee">{{ lead.ownerFirstName }}&nbsp;{{ lead.ownerLastName[0].toUpperCase() }}.</small>
            </div>
        </div>
    </app-base-tile>
</div>