import { Injectable } from '@angular/core';
import { Client, Lead as LegacyLead, MakeOptional } from 'projects/common/src/public-api';
import { BusinessService } from './business.service';
import { ViewAsService } from './view-as.service';
import { LobbyService } from './lobby.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PaymentService } from './payment.service';
import { UsersService } from './users.service';
import { SupabaseService } from "./supabase.service";
import { map, shareReplay, switchMap, take } from "rxjs/operators";
import { Note } from "../models/note.model";
import { DateFilter } from "../models/date-filter.model";
import { PaginationOptions } from "./pagination.service";
import { Lead } from "../../../../common/src/lib/models/lead.model";
import { combineLatest } from "rxjs";
import { LeadClientRestore } from "../../../../common/src/lib/models/client-transform";
import { frontToServerTranslation } from 'projects/common/src/lib/services/supabase.service';

export interface LeadCreate {
    inOwnerId: number;
    inClient: MakeOptional<Client, 'salesTaxPercentage' | 'email'>;
    inWorkflowId?: number;
    inJobType?: string;
    inNotes?: Note[];
}

export interface LeadUpdate {
    inId: number;
    inOwnerId?: number;
    inClient?: MakeOptional<Client, 'salesTaxPercentage' | 'email'>;
    inJobType?: string;
}

export interface LeadRestore {
    inId: number;
    inCreatedAt: Date;
    inOwnerId: number;
    inClient: LeadClientRestore;
    inWorkflowId?: number;
    inJobType?: string;
    inNotes?: Note[];
}

@Injectable({
  providedIn: 'root'
})
export class LeadsService {

    hasLeads$ = combineLatest([this.businessService.selectedBusiness$, this.viewAsService.selectedUsersIds$]).pipe(
        switchMap(([business, userIds]) => {
            return this.supabaseService.rpc<Lead[]>({
                cName: 'has_leads',
                schema: business.businessId,
                fn: 'get_leads',
                tables: ['lead'],
                options: { inLimit: 1, inOffset: 0, inUserIds: userIds }
            })
        }),
        map(leads => {
            return leads.length > 0;
        }),
        shareReplay(1)
    );
    
    constructor(
        protected businessService: BusinessService,
        protected supabaseService: SupabaseService,
        protected viewAsService: ViewAsService,
        protected paymentService: PaymentService,
        protected usersService: UsersService,
        protected lobbyService: LobbyService,
        protected snackbar: MatSnackBar,
    ) {}

    async getLeadsForDateRange(businessId: string, range: DateFilter, users: number[] | null, direction: 'future' | 'past') {
        const options: PaginationOptions = {
            inDirection: direction,
            inStartDate: frontToServerTranslation(range.from),
            inEndDate: frontToServerTranslation(range.to)
        };
        if(users)
            options.inUserIds = users;
        return this.supabaseService.rpcFunc<Lead[]>(businessId, 'get_leads', options);
    }

    async getLead(id: number) {
        const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
        const lead = (await this.supabaseService.rpcFunc<Lead[]>(business.businessId, 'get_lead_by_id', { inId: id }))[0];
        lead.docType = 'Lead';
        return lead;
    }
    
    async createLead(data: LeadCreate) {
        const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
        return this.supabaseService.rpcFunc(business.businessId, 'create_lead', frontToServerTranslation(data));
    }

    async updateLead(data: LeadUpdate) {
        const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
        return this.supabaseService.rpcFunc(business.businessId, 'update_lead', data);
    }

    async restoreLead(data: LeadRestore) {
        const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
        return this.supabaseService.rpcFunc(business.businessId, 'restore_lead', data);
    }

    async deleteLead(id: number) {
        const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
        return this.supabaseService.delete(business.businessId, 'lead', id);
    }

}
