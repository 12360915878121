<div *ngIf="item" class="c-item clickable" (click)="openPayment(item.wId, item.pId)">
  <div class="d-flex align-items-center">
    <mat-checkbox class="checkbox"
                  *ngIf="kind === 'Pending'" [color]="'primary'"
                  (change)="check($event.checked, item.id)" (click)="$event.stopPropagation()"
                  [checked]="checked"
    ></mat-checkbox>

    <div class="c-item-left">
      <div class="name">{{ item.name }}</div>
      <div class="jt">{{ item.jobType ?? 'N/A' }}</div>
    </div>
  </div>

  <div class="c-item-right"
       [class.overpay]="item.overpay && item.overpay < 0"
       [class.sales-amount]="item.kind === 'sales'"
       [class.job-amount]="item.kind === 'job'"
  >
    {{ currencify(item.amount) }}
  </div>
</div>

<div *ngIf="dividerNeed" class="c-item-divider"></div>
