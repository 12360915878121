<div class="view-as-button" [class.user-selected]="isUserSeleceted$ | async" [class.open]="isMenuOpen"
     #menuTrigger="matMenuTrigger" [hideMenuOnScreenSizeChange]="menuTrigger"
     [matMenuTriggerFor]="menu" *ngIf="enabled$ | async else disabled" (menuOpened)="isMenuOpen = true"
     (menuClosed)="isMenuOpen = false">
  <p>{{ buttonText$ | async }}</p>
  <app-icon *ngIf="showArrow$ | async" class="se-icon-button no-bg no-hover" src="cheveron_down"></app-icon>
</div>

<ng-template #disabled>
    <div class="view-as-button disabled">
        <p>{{ buttonText$ | async }}</p>
    </div>
</ng-template>






<mat-menu #menu class="view-as-menu-wrapper" xPosition="before">
    <div (click)="onMenuClick($event)" class="view-as-menu" *ngIf="changePrep$ | async let selectedUsers">

        <div class="top-section">
            <app-user-button *ngIf="currentUser$ | async let user"
                [name]="userNameForTile(user)"
                [type]="user.role"
                [selected]="isUserSelected(user, selectedUsers)"
                [disallowRemove]="(disallowRemove$ | async)!"
                (click)="onButtonClicked(user)"
                (onRemove)="onRemoveClicked(user)"
            ></app-user-button>
            <div class="user-button all-accounts-button selection-tile" [class.selected]="selectedUsers === 'All'" (click)="onAllClick()">
                <h4>All accounts</h4>
                <app-icon *ngIf="selectedUsers === 'All'" src="close" class="se-icon-button no-bg" (click)="unselectAllAccounts($event)"></app-icon>
            </div>
        </div>

        <mat-form-field class="search-field" appearance="outline" appFormFieldFilled>
            <input
                matInput
                class="flex-fill"
                placeholder="Search by name"
                [formControl]="searchFormControl"
            />
            <app-icon matPrefix src="search"></app-icon>
            <app-icon matSuffix src="close" class="se-icon-button fix-icon" *ngIf="searchFormControl.value && searchFormControl.value !== ''" (click)="clearSearchField()"></app-icon>
        </mat-form-field>

        <div class="users-list">
            <app-user-button *ngFor="let user of displayUsers$ | async"
                [name]="userNameForTile(user)"
                [status]="user.status"
                [type]="user.role"
                [selected]="isUserSelected(user, selectedUsers)"
                (click)="onButtonClicked(user)"
                (onRemove)="onRemoveClicked(user)"
            ></app-user-button>
        </div>

        <ng-container *ngIf="{ val: hasChanges$ | async } let hasChanges">
            <div class="d-flex justify-content-end" *ngIf="hasChanges.val">
                <button mat-flat-button color="primary" (click)="apply()">Apply</button>
            </div>
        </ng-container>

        <div *ngIf="(isSingleUserPage$ | async)!">
            <div class="single-user-banner" *ngIf="showSingleUserBanner">
                <h4>For 1 account view only</h4>
            </div>
        </div>
    </div>
</mat-menu>
