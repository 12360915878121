import { Component } from '@angular/core';
import { ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { Clipboard } from '@angular/cdk/clipboard';
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { PaymentService } from "../../services/payment.service";

@Component({
  selector: 'app-refund-payment',
  templateUrl: './refund-payment.component.html',
  styleUrls: ['./refund-payment.component.scss']
})
export class RefundPaymentComponent {

  nuveiUrl = 'https://merchantdashboard.nuvei.com/';

  get transactionNumber() {
    return this.modalsService.data?.transactionNumber ?? null;
  }

  constructor(
    private modalsService: ModalsService,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    private paymentService: PaymentService
  ) { }

  async close() {
    await this.modalsService.close();
  }

  copyTransactionNumber() {
    this.clipboard.copy(this.transactionNumber);
    showSnackbar(this.snackBar, {
      message: "Copied to the clipboard"
    });
  }

  async goToNuvei() {
    //window.open(this.nuveiUrl, '_blank');

    // todo: delete temporary block, bring the nuvei window open back

    await this.paymentService.addRefund(
      this.modalsService.data.amount,
      this.modalsService.data.transactionNumber,
      this.modalsService.data.workflowId,
      this.modalsService.data.paymentId
    );

    await this.paymentService.updatePayment(this.modalsService.data.workflowId, {
      // refundeds: arrayUnion({
      //   amount: this.modalsService.data.amount,
      //   paidAt: this.modalsService.data.paidAt,
      //   refundedAt: new Date(),
      //   type: 'refunded',
      //   transactionNumber: this.transactionNumber,
      //   lastDigits: this.modalsService.data.lastDigits,
      // })
    });
    await this.close();
  }
}
