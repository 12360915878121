import { CdkStepper } from '@angular/cdk/stepper';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { FormsService } from '../../../../../common/src/lib/services/forms.service';
import { UtilsService } from 'projects/common/src/public-api';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessService } from '../../services/business.service';
import { map, startWith, switchMap, take } from 'rxjs/operators';
import { NavigationService } from "../../services/navigation.service";
import { combineLatest, Observable } from "rxjs";
import { UsersService } from "../../services/users.service";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  providers: [{provide: CdkStepper}],
})
export class SignupComponent {

  @ViewChild("emailInput") emailInput!: ElementRef;
  @ViewChild("passwordInput") passwordInput!: ElementRef;

  params$ = this.route.params.pipe(
    map(params => {
      const split = (params['id'] as string).split('-');
      const userId = split.pop()!;
      const businessId = split.join('-');
      return [businessId, userId];
    })
  );

  business$ = this.params$.pipe(
    switchMap(([businessId, _]) => {
      return this.businessService.businessData(businessId);
    })
  );

  user$ = this.params$.pipe(
      switchMap(([businessId, userId]) => {
        return this.usersService.userObservable(+userId, businessId);
      })
  );

  state$: Observable<'ready' | 'not-found' | null> = combineLatest([this.business$, this.user$, this.navigationService.url$]).pipe(
      map(([business, user, url]) => {
        if(!business || !user || user.role !== 'owner')
          return 'not-found';

        return 'ready';
      }),
      startWith(null)
  );

  showPassword = false;
  loading = false;

  accountForm = this.formBuilder.group({
    email: new UntypedFormControl('', [Validators.email]),
    password: new UntypedFormControl('', [Validators.minLength(6)]),
  });

  emailFocused = false;

  constructor(
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private formsService: FormsService,
    private route: ActivatedRoute,
    private router: Router,
    private utilsService: UtilsService,
    private businessService: BusinessService,
    private usersService: UsersService,
    private navigationService: NavigationService,
  ) { }

  async next() {

    if (this.utilsService.isMobile()) {
      if (this.emailFocused) {
        this.passwordInput.nativeElement.focus();
        return;
      }
    }

    this.loading = true;
    this.formsService.trim(this.accountForm);
    if (this.accountValid()) {
      const {email, password} = this.accountForm.value;

      try {
        await this.authService.signUpWithEmailAndPassword(email, password);
        this.router.navigate(['/join', this.route.snapshot.params['id']]);
      } catch (error: any) {
        console.log(`signup.component.ts > SignUpComponent > next()`, error.code);

        if (error.code === 'auth/email-already-in-use') {
          this.accountForm.get('email')!.setErrors({
            emailInUse: true
          });
        }

        if (error.code === 'auth/invalid-email') {
          this.accountForm.get('email')!.setErrors({
            email: true
          });
        }
      }
    } else {
      this.accountForm.markAllAsTouched();
      this.unfocusAll();
    }
    this.loading = false;
  }

  accountValid() {
    const valid = this.formsService.validateRequired(
      this.accountForm.get('email')!, this.accountForm.get('password')!
    );

    return valid;
  }

  async saveInvitation() {
    const params = await this.params$.pipe(take(1)).toPromise();
    this.businessService.saveInvitation(params[0] + '-' + params[1]);
  }

  private unfocusAll() {
    this.emailInput.nativeElement.blur();
    this.passwordInput.nativeElement.blur();
  }
}
