import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import {
  AcceptanceStatus,
  ClientVisitEvent,
  Lead,
  MultipleAssignees,
  UserPersonalEvent,
  UtilsService
} from 'projects/common/src/public-api';
import { combineLatest, Observable, Subscription, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { EstimatesService } from '../../services/estimates.service';
import { ModalBehavior, ModalsService } from '../../../../../common/src/lib/services/modals.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialog } from '../../../../../common/src/lib/modals/confirmation-dialog/confirmation-dialog.component';
import { EstimatesTab } from "../../models/navigation.model";
import { ScheduleService } from "../../services/schedule.service";
import { NotesService } from '../../services/notes.service';
import { RequestInvitationModal } from "../../modals/request-invitation/request-invitation.component";
import { DatePipe } from "@angular/common";
import { TimetableDialogComponent } from "../../modals/timetable-dialog/timetable-dialog.component";
import { NavigationService } from '../../services/navigation.service';
import { JobsService } from '../../services/jobs.service';
import { LeadsService } from '../../services/leads.service';
import { UsersService } from "../../services/users.service";

export function isMultipleAssignees<T>(value: any): value is MultipleAssignees<T> {
  return !!value?.assignees;
}

export function isClientVisitEvent(value: any): value is ClientVisitEvent {
  return !!value?.assigneeId;
}

function isUserPersonalEvent(value: any): value is UserPersonalEvent {
  return !!value?.available;
}

function isLead(value: any): value is Lead {
  return !value?.startTime;
}

@Component({
  selector: 'app-event-tile',
  templateUrl: './event-tile.component.html',
  styleUrls: ['./event-tile.component.scss']
})
export class EventTileComponent implements OnInit, OnDestroy {
  @Input() estimateTab!: EstimatesTab;
  @Input() eventsAndLeads!: ClientVisitEvent | MultipleAssignees<ClientVisitEvent> | UserPersonalEvent | Lead;
  @Input() jobType!: string;

  page$ = this.navigationService.page$;
  tab$ = this.navigationService.tab$;
  currentUser$ = this.usersService.currentUser$;

  endsText = 'N/A';
  readonly dateFormat = 'MM/DD/YY';
  readonly timeFormat = 'hh:mm a';
  hover = false;
  loading = false;
  currentUserId!: number;
  users$ = this.usersService.users$;
  assigneeUserFullName!: string;
  assigneesUsersFullName!: string[];
  acceptanceStatus!: AcceptanceStatus;

  timeToEvent$ = timer(0, 1000).pipe(
    map(_ => {
      const timeToEvent = new Date(0, 0, 0);
      const time = (this.eventsAndLeads as ClientVisitEvent).startTime!.getTime();
      timeToEvent.setMilliseconds(Math.abs(time - Date.now()));
      return moment(timeToEvent).format('mm:ss');
    }),
  );

  largeScreen$ = this.utilsService.onScreenBreakpointChange('md');

  statusFilter$ = combineLatest([this.scheduleService.filterJobStatus$, this.scheduleService.filterEstimateStatus$, this.tab$]).pipe(
    map(([jobStatus, estimateStatus, tab]) => {
      const selectedTab = this.getSelectedTab(tab);
      if (
        selectedTab === 'Jobs' && (jobStatus === 'Canceled' || jobStatus === 'Pending')
        || selectedTab === 'Estimates' && estimateStatus === 'Canceled'
      ) {
        return false;
      } else {
        return true;
      }
    })
  )

  get isMobile() {
    return this.utilsService.isMobile();
  }

  private timerSubscription!: Subscription;

  constructor(
    private estimatesService: EstimatesService,
    private jobsService: JobsService,
    private leadsService: LeadsService,
    private utilsService: UtilsService,
    private navigationService: NavigationService,
    private modalsService: ModalsService,
    private router: Router,
    private scheduleService: ScheduleService,
    private notesService: NotesService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private usersService: UsersService
  ) { }

  showKeepInLoop() {
    if (!isUserPersonalEvent(this.eventsAndLeads)) {
      const isAssignedByEqualCurrentUserId = this.eventsAndLeads.assignedBy === this.currentUserId;

      if (isMultipleAssignees(this.eventsAndLeads)) {
        const hasOneAssignee = (Object.keys(this.eventsAndLeads.assignees || {})).length === 1;
        const firstAssigneeKey = hasOneAssignee ? +(Object.keys(this.eventsAndLeads.assignees || {}))[0] : -1;
        if (hasOneAssignee) {
          return firstAssigneeKey !== this.eventsAndLeads.assignedBy;
        } else {
          return isAssignedByEqualCurrentUserId;
        }
      } else if (isClientVisitEvent(this.eventsAndLeads)) {
        return isAssignedByEqualCurrentUserId && this.eventsAndLeads.assigneeId !== this.eventsAndLeads.assignedBy
      }
    }
    return false;
  }

  async getAssignee() {
    const users = await this.users$.pipe(take(1)).toPromise();
    if (!isUserPersonalEvent(this.eventsAndLeads)) {
      if (isMultipleAssignees(this.eventsAndLeads)) {
        const assigneesIds = Object.keys(this.eventsAndLeads.assignees).map(k => +k);
        this.assigneesUsersFullName = assigneesIds.map(assigneeId => {
          const assignee = users?.find(user => user.id === assigneeId);
          return assignee ? `${assignee.firstName} ${assignee.lastName}` : '';
        });
      } else if (isClientVisitEvent(this.eventsAndLeads)) {
        const assigneeId = this.eventsAndLeads.assigneeId;
        const assignee = users?.find(user => user.id === assigneeId);
        this.assigneeUserFullName = assignee ? `${assignee.firstName} ${assignee.lastName}` : '';
      }
    }
    return null;
  }

  getSelectedTab(selectedTab: string | null) {
    if (selectedTab) {
      if (selectedTab === 'All') {
        return 'All';
      } else if (selectedTab === 'Jobs') {
        return 'Jobs';
      } else if (selectedTab === 'Estimates') {
        return 'Estimates';
      }
    }
    return false;
  }

  isEventOver(): boolean {
    const currentDate = new Date();
    if (this.eventsAndLeads.docType === 'Job' || this.eventsAndLeads.docType === 'Estimate' || this.eventsAndLeads.docType === 'Personal') {
      return this.eventsAndLeads.endTime < currentDate;
    }
    return false;
  }

  isEventStartPassed(): boolean {
    const currentDate = new Date();
    if (this.eventsAndLeads.docType === 'Job' || this.eventsAndLeads.docType === 'Estimate' || this.eventsAndLeads.docType === 'Personal') {
      return this.eventsAndLeads.startTime < currentDate;
    }
    return false;
  }

  getClientName() {
    if (this.eventsAndLeads.docType === 'Personal') {
      return '';
    }

    let name = '';
    if (this.eventsAndLeads.client?.firstName) {
      name += this.trimName(this.eventsAndLeads.client.firstName, 15);
    }

    if (this.eventsAndLeads.client?.lastName) {
      const trimmedLastName = this.trimName(this.eventsAndLeads.client.lastName, 10);
      if (name.length > 0 && this.eventsAndLeads.client?.lastName.length > 10) {
        name += ` ${trimmedLastName.charAt(0)}.`;
      } else {
        name += ` ${trimmedLastName}`;
      }
    }

    return name;
  }

  trimName(value: string, maxLength: number): string {
    if (value.length > maxLength) {
      return value.substring(0, maxLength - 3) + '...';
    }
    return value;
  }

  getShortClientName() {
    if (this.eventsAndLeads.docType === 'Personal') {
      return '';
    }
    const name: string[] = [];
    if (this.eventsAndLeads.client?.firstName)
      name.push(this.eventsAndLeads.client.firstName);
    if (this.eventsAndLeads.client?.lastName.length > 10) {
      name.push(this.eventsAndLeads.client.lastName.slice(0, 1) + '.');
    } else {
      name.push(this.eventsAndLeads.client.lastName)
    }
    return name.join(' ');
  }

  // getEventType(): 'starts-in' | 'overdue' | undefined {
  //   if (this.eventsAndLeads.docType !== 'Lead') {
  //     if (!this.eventsAndLeads.start) {
  //       return undefined;
  //     }
  //     const secondsLeft = this.estimatesService.secondsToEstimate(this.eventsAndLeads.start);

  //     if (secondsLeft > (15 * 60) || secondsLeft < -(15 * 60)) {
  //       return undefined;
  //     }
  //     if (secondsLeft > 0) {
  //       return 'starts-in';
  //     }
  //   }
  //   return
  // }

  getSliceJobType() {
    if (this.jobType.length > 40) {
      return this.jobType.slice(0, 30) + '...';
    }
    return this.jobType;
  }

  async ngOnInit() {
    const currentUserProfile = await this.currentUser$.pipe(take(1)).toPromise();
    this.currentUserId = currentUserProfile.id;
    // this.trackEventTimeStatus();
  }

  async onTileClick(event: Event) {
    event.stopImmediatePropagation();
    if (this.eventsAndLeads.docType === 'Personal' && this.isEventOver()) {
      return;
    }
    if (this.eventsAndLeads.docType !== 'Personal' && this.eventsAndLeads.docType !== 'Lead') {
      const tab = (await this.navigationService.tab$.pipe(take(1)).toPromise()).toLowerCase();
      await this.router.navigate([`/${this.eventsAndLeads.docType.toLocaleLowerCase()}s`, this.eventsAndLeads.id], tab !== 'all' ? {queryParams: { from: tab }} : {});
    } else if (this.eventsAndLeads.docType === 'Lead') {
      this.editRequest(this.eventsAndLeads);
    } else if (this.eventsAndLeads.docType === 'Personal') {
      const date = this.eventsAndLeads.startTime;
      const formattedDate = {
        year: date.getFullYear(),
        month: date.getMonth(),
        date: date.getDate()
      };
      const momentDate = moment(new Date(formattedDate.year, formattedDate.month, formattedDate.date));

      await this.modalsService.open(TimetableDialogComponent, {
        behavior: ModalBehavior.Dialog,
        disableClose: true,
        autoFocus: false,
        restoreFocus: false,
        data: {
          setUnavailableTab: true,
          selectedDate: momentDate
        }
      });
    }
  }

  editRequest(request: Lead, createNew?: 'Job' | 'Proposal') {
    this.modalsService.open(RequestInvitationModal, {
      behavior: ModalBehavior.Auto,
      breakpoint: RequestInvitationModal.BREAKPOINT,
      disableClose: true,
      data: {
        initialRequest: request,
        isEdit: !createNew,
        route: this.route,
        dialogType: 'Lead'
      }
    });
  }

  async primaryClick(event: Event) {
    event.stopImmediatePropagation();
    try {
      this.loading = true;
    } catch (e) {
      this.loading = false;
    }
  }

  deleteEvent(event: Event) {
    event.stopImmediatePropagation();
    const type = this.eventsAndLeads.docType;
    if (type === 'Personal') {
      return;
    }
    const id = this.eventsAndLeads.id;
    this.modalsService.open(ConfirmationDialog, {
      data: {
        title: `Delete`,
        message: `Are you sure you want to delete this ${type.toLocaleLowerCase()}?`,
        actionTitle: 'Delete',
        action: async () => {
          if (type === 'Estimate')
            await this.estimatesService.deleteEstimate(id);
          else if (type === 'Job')
            await this.jobsService.deleteJob(id);
          else
            await this.leadsService.deleteLead(id);
        },
        actionColor: 'warn',
      }
    });
  }

  getEndDateText(startDate: Date, endDate: Date) {
    function toDate(date: Date, incDate: number = 0): number {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate() + incDate).getTime();
    }

    if (!endDate) {
      return 'Ends:';
    }
    const today = toDate(new Date());
    const tomorrow = toDate(new Date(), 1);
    const compareStartDate = toDate(startDate);
    const compareEndDate = toDate(endDate);
    if (compareStartDate === compareEndDate) {
      return 'Ends:';
    }
    if (compareStartDate !== today && compareEndDate === today) {
      return 'Today:'
    }
    return compareEndDate === tomorrow ? 'Tomorrow:' : `${moment(endDate).format(this.dateFormat)}:`;
  }

  formatTimeSinceCreation(createdAt: Date): string {
    const now = new Date();
    const diffMs = now.getTime() - createdAt.getTime();
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));

    if (diffDays >= 1) {
      return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
    } else if (diffDays === 0) {
      const formattedTime = this.datePipe.transform(createdAt, 'h:mm a', 'en-US');
      return `${formattedTime}, Today`;
    } else {
      return 'Invalid Date';
    }
  }

  getStartTime() {
    return moment((this.eventsAndLeads as ClientVisitEvent).startTime).format('hh:mm');
  }

  getStartAMPM() {
    return moment((this.eventsAndLeads as ClientVisitEvent).startTime).format('A');
  }

  getEndAMPM() {
    return moment((this.eventsAndLeads as ClientVisitEvent).endTime).format('A');
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }
}
