<div class="toolbar" *ngIf="show">
    <div class="container h-100 d-flex align-items-center justify-content-between">

        <div class="flex-fill" *ngIf="!leftAction">
            <app-icon class="se-icon-button mobile-search" src="search" *ngIf="!searchingOnMobile" (click)="searchingOnMobile = true"></app-icon>
            <div *ngIf="searchingOnMobile">
                <app-search
                    [fullWidth]="true"
                    [showCloseButtonAlways]="true"
                    [autofocus]="true"
                    (focusChange)="mobileSearchFocusChanged($event)"
                    (onQuerySuggestionClick)="onSearchSuggestionClick($event)"
                    [control]="searchControl"
                ></app-search>
            </div>
        </div>

        <!-- <img *ngIf="!showSearch" class="logo" src="../../../assets/logo.svg" (click)="logoClick()"> -->
        <p *ngIf="!!leftAction" class="se-button" (click)="onBackClick()">{{ leftAction }}</p>


        <div class="d-flex align-items-center" style="gap: 12px">
            <app-select-business-button *ngIf="!hideViewAsAndBusinessSelect"></app-select-business-button>
            <app-view-as-button *ngIf="!hideViewAsAndBusinessSelect"></app-view-as-button>
            <app-notifications-button *ngIf="!hideViewAsAndBusinessSelect"></app-notifications-button>
            <app-icon class="se-icon-button" *ngIf="showMenu && !hideElements" [class.hidden]="hideElements" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
              [hideMenuOnScreenSizeChange]="menuTrigger" src="menu"></app-icon>
        </div>

    </div>

</div>

<mat-menu #menu="matMenu" class="hide-active no-margin" xPosition="before">
    <mat-option *ngIf="showLobbyOption" routerLink="/lobby">Lobby</mat-option>
    <mat-option *ngFor="let item of (menuItems$ | async)" [routerLink]="item.link" routerLinkActive="active">{{ item.title }}</mat-option>
    <mat-option id="button_signOut" class="dimmed" (click)="logout()">Sign out</mat-option>
</mat-menu>
