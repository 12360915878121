<div class="account-info d-flex flex-column flex-fill">

    <app-section-divider text="Users"></app-section-divider>

    <div class="d-flex flex-column users-list">

        <app-user-tile [user]="(currentUser$ | async)!" [businessId]="businessProfile.businessId" [hideSuspend]="true"></app-user-tile>
        <app-user-tile *ngFor="let user of usersList$ | async" [user]="user" [businessId]="businessProfile.businessId" [hideMenu]="user.role === 'owner'"></app-user-tile>

        <div class="add-user-container">
            <div class="d-flex justify-content-start icon-button" (click)="addUser()">
                <app-icon class="se-icon-button" src="plus"></app-icon>
                <p>Add User</p>
            </div>
            <h4 *ngIf="missingNameError" class="missing-name-error">{{ missingNameError }}</h4>
        </div>
    </div>
</div>
