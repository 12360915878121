import { Component } from '@angular/core';
import { ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { PaymentService } from "../../services/payment.service";
import { take } from "rxjs/operators";
import { numbersOnly } from "../../helpers/forms";

@Component({
  selector: 'app-pay-commission',
  templateUrl: './pay-commission.component.html',
  styleUrls: ['./pay-commission.component.scss']
})
export class PayCommissionComponent {

  protected readonly numbersOnly = numbersOnly;

  title = !this.data.paid ? 'Pay' : 'Edit';
  actionButton = !this.data.paid ? 'Pay' : 'Save';
  loading = false;

  overAmount = false;
  inputAmount: string = this.data.commission.paid === 0
    ? null
    : this.data.commission.paid;

  get data() {
    return this.modalsService.data;
  }

  constructor(
    private modalsService: ModalsService,
    private paymentService: PaymentService,
  ) { }

  async close() {
    await this.modalsService.close();
  }

  async save() {
    if (!this.loading) {
      this.loading = true;
      if (this.validForm()) {
        await this.paymentService.updateCommissionAmount(this.data.commission.id, +this.inputAmount);

        await this.close();
      }

      this.loading = false;
    }
  }

  validForm(): boolean {
    let amountUnder = true;

    if (+this.inputAmount > this.data.commission.amount)
      amountUnder = false;

    this.overAmount = !amountUnder;

    return amountUnder;
  }
}
