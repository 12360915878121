<div class="tile-wrapper" (click)="onTileClick($event)">
    <app-base-tile
            [data]="data"
    >
        <app-base-tile-time leading
            [timeData]="timeData"
            [dimPast]="false"
            [unsetWidth]="true"
        ></app-base-tile-time>

        <div class="assign-info" trailing>
            <div [class]="'event-type ' + tileType?.toLowerCase()" [class.overdue]="isEventStarted()">
                <h4>{{ capitalizeFirstChar(item.type) }}</h4>
            </div>
            <div class="assignee-fullName" *ngIf="!hover">
                <small [class.denied]="denied">
                    {{ usersTag }}
                </small>

            </div>
        </div>
    </app-base-tile>
</div>

