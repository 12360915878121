import { AfterViewInit, Directive, HostListener, Inject, Input, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatLegacyTooltip as MatTooltip } from '@angular/material/legacy-tooltip';

@Directive({
  selector: '[hideTooltipOnScroll]'
})
export class HideTooltipOnScrollDirective implements AfterViewInit, OnDestroy {

  @Input() hideTooltipOnScroll!: MatTooltip;

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) { }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any) {
    this.onScroll();
  }

  ngAfterViewInit(): void {
    const content = this.document.querySelector('.mat-dialog-container');
    content?.addEventListener('scroll', this.onScroll.bind(this), true);
  }

  ngOnDestroy() {
    const content = this.document.querySelector('.mat-dialog-container');
    content?.removeEventListener('scroll', this.onScroll.bind(this), true);
  }

  onScroll() {
    if(this.hideTooltipOnScroll._isTooltipVisible()) {
      this.hideTooltipOnScroll.hide();
    }
  }
}
