import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'skeleton-img',
  templateUrl: './skeleton-img.component.html',
  styleUrls: ['./skeleton-img.component.scss']
})
export class SkeletonImgComponent implements OnInit {

  @Input() src?: string | SafeUrl;
  @ViewChild('wrapper') wrapper!: ElementRef;

  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
  }

  onLoad() {
    this.renderer.removeClass(this.wrapper.nativeElement, 'skeleton');
  }
}
