import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UsersService } from '../../services/users.service';
import { UserProfile } from '../../../../../common/src/lib/models/user-profile.model';
import { BehaviorSubject, combineLatest, of, ReplaySubject, Subscription } from 'rxjs';
import { map, take, switchMap, distinctUntilChanged, tap, startWith } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { ViewAsComponent, ViewAsService } from '../../services/view-as.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { UtilsService } from 'projects/common/src/public-api';
import { FormControl } from '@angular/forms';
import { NavigationService } from '../../services/navigation.service';

function filterUsersBySearchTerm(users: UserProfile[], searchTerm: string) {
  const parts = searchTerm.toLowerCase().split(' ');
  if(parts.length === 1)
    return users.filter(user => user.firstName.toLowerCase().includes(parts[0]) || user.lastName.toLowerCase().includes(parts[0]))
  return users.filter(
    user => user.firstName.toLowerCase().includes(parts[0])
    || user.firstName.toLowerCase().includes(parts[1])
    || user.lastName.toLowerCase().includes(parts[0])
    || user.lastName.toLowerCase().includes(parts[1])
  );
}

@Component({
  selector: 'app-view-as-button',
  templateUrl: './view-as-button.component.html',
  styleUrls: ['./view-as-button.component.scss']
})
export class ViewAsButtonComponent implements OnInit, OnDestroy, ViewAsComponent {
  
  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;

  searchFormControl = new FormControl('');

  changePrep$ = this.viewAsService.changePrep$;
  currentUser$ = this.usersService.currentUser$;
  selectedUsersWithoutCurrent$ = combineLatest([this.currentUser$, this.changePrep$]).pipe(
    map(([currentUser, selectedUsers]) => selectedUsers === 'All' ? selectedUsers : selectedUsers.filter(user => user.id !== currentUser.id))
  );
  largeScreen$ = this.utilsService.onScreenBreakpointChange('lg');

  isUserSeleceted$ = combineLatest([this.currentUser$, this.changePrep$]).pipe(
    map(([user, selectedUsers]) => {
      if(selectedUsers === 'All')
        return true;
      
      if(selectedUsers.length !== 1 || user.email !== selectedUsers[0].email)
        return true;

      return false;
    })
  );

  buttonText$ = combineLatest([this.changePrep$, this.largeScreen$]).pipe(
    map(([users, largeScreen]) => {
      if(users.length === 0)
        return null;
      return this.userNameForButton(users, largeScreen);
    })
  );

  showArrow$ = combineLatest([this.usersService.users$, this.largeScreen$]).pipe(
    map(([users, largeScreen]) => largeScreen && (!users ? false : users.length > 1))
  );
  
  enabled$ = combineLatest([this.usersService.currentUser$, this.usersService.users$]).pipe(
    map(([user, users]) => {
      if(user.role !== 'owner' && user.role !== 'admin')
        return false;

      return !users ? false : users.length > 1;
    })
  );

  owner$ = combineLatest([this.authService.user$, this.usersService.users$]).pipe(
    map(([user, users]) => {
      if(!users)
        return null;
      const ownerProfile = users.find(u => u.role === 'owner')!;
      return {
        ...ownerProfile,
        type: user?.email === ownerProfile.email ? 'My account' : 'Owner'
      };
    })
  );

  users$ = combineLatest([
    this.usersService.currentUser$, this.usersService.users$]).pipe(
    map(([currentUser, users]) => {
      if(!currentUser || !users)
        return null;
      return users.filter(u => u.id !== currentUser.id && !u.status.includes('inactive'));
    })
  );

  // useSearchbar$ = this.users$.pipe(
  //   map(users => !users? false : (users.length > 3))
  // );

  disallowRemove$ = this.changePrep$.pipe(
    map(users => users !== 'All' && users.length === 1)
  );


  displayUsers$ = combineLatest([this.users$, this.searchFormControl.valueChanges.pipe(startWith(''))]).pipe(
    map(([users, searchTerm]) => {
      if(!users || !searchTerm || searchTerm === '')
        return [];
      return filterUsersBySearchTerm(users, searchTerm ?? '');
    }),
    switchMap(users => combineLatest([
      this.selectedUsersWithoutCurrent$.pipe(
        map(sUsers => {
          return sUsers === 'All' ? [] : sUsers;
        })
      ),
      of(users)
    ]).pipe(
      map(([selectedUsers, users]) => [...selectedUsers, ...users.filter(user => !selectedUsers.find(u => u.id === user.id))])
    ))
  );

  isMenuOpen = false;

  showSingleUserBanner = false;
  isSingleUserPage = false;
  isSingleUserPage$ = combineLatest([this.navigationService.isSingleUserPage$, this.currentUser$]).pipe(
    tap(([isSingleUserPage, currentUser]) => {
      this.showSingleUserBanner = false;
      this.isSingleUserPage = isSingleUserPage;
    })
  );

  hasChanges$ = this.viewAsService.hasChanges$;

  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private viewAsService: ViewAsService,
    private utilsService: UtilsService,
    private navigationService: NavigationService,
  ) {}

  ngOnInit() {
    this.viewAsService.viewAsButtonComponent = this;
  }

  onNavigationToSingleUserPage() {
    this.showSingleUserBanner = true;
    this.menuTrigger.openMenu();
  }

  userNameForButton(users: UserProfile[] | 'All', largeScreen: boolean) {
    
    if(users === 'All')
      return 'All';

    if(users.length > 1) {
      if(largeScreen)
        return users.length + ' Users';
      return users.length + '';
    }
    if(largeScreen)
      return users[0].firstName + ' ' + users[0].lastName[0] + '.';
    return users[0].firstName[0] + (users[0].firstName[1] ?? '');
  }

  userNameForTile(user: UserProfile) {
    return user.firstName + ' ' + user.lastName[0] + '.';
  }

  isUserSelected(user: UserProfile, selectedUsers: UserProfile[] | string) {
    if(typeof selectedUsers === 'string')
      return false;
    for(const u of selectedUsers) {
      if(u.id === user.id)
        return true;
    }
    return false;
  }

  async onButtonClicked(button: UserProfile) {
    this.searchFormControl.setValue('');
    const curr = this.changePrep$.value;
    if(!Array.isArray(curr)) {
      this.changePrep$.next([button]);
    } else if(curr.find(u => u.id === button.id)) {
      return;
    } else {
      const currentUser = await this.currentUser$.pipe(take(1)).toPromise();
      if(curr.length === 1 && curr[0].id === currentUser.id) {
        curr.pop(); 
      }
      curr.push(button);
      this.changePrep$.next(curr);
    }
  }

  async onRemoveClicked(button: UserProfile) {
    const users = this.changePrep$.value as UserProfile[];
    const index = users.findIndex(user => user.id === button.id);
    if(index !== -1) {
      users.splice(index, 1);
      if(users.length === 0) {
        const currentUser = await this.currentUser$.pipe(take(1)).toPromise();
        users.push(currentUser);
      }
      this.changePrep$.next(users);
    }
  }

  onAllClick() {
    this.searchFormControl.setValue('');
    this.changePrep$.next('All');
  }

  async unselectAllAccounts(event: Event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    const currentUser = await this.currentUser$.pipe(take(1)).toPromise();
    this.changePrep$.next([currentUser]);
  }

  onMenuClick(event: Event) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  clearSearchField() {
    this.searchFormControl.setValue('');
  }

  apply() {
    if(this.isSingleUserPage && (this.changePrep$.value === 'All' || this.changePrep$.value.length > 1) ) {
      this.showSingleUserBanner = true;
    } else {
      this.viewAsService.applyChanges();
      this.menuTrigger.closeMenu();
    }
  }

  ngOnDestroy() {
    this.viewAsService.viewAsButtonComponent = undefined;
    this.viewAsService.clearChanges();
  }

}
