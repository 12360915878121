import { AfterViewInit, Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[fadeIn]'
})
export class FadeInDirective implements OnInit, AfterViewInit {

  @Input() fadeIn = '500';
  @Input() fadeInDelay = 2;

  constructor(
    private element: ElementRef<HTMLElement>
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.element.nativeElement.style.opacity = '0';
    setTimeout((() => {
      this.element.nativeElement.style.transition = `opacity ${this.fadeIn}ms`;
    }).bind(this), 0);

    setTimeout((() => {
      this.element.nativeElement.style.opacity = '1';
    }).bind(this), this.fadeInDelay);
  }

}
