import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Item } from "../../models/item.model";

@Component({
  selector: 'app-item-tile',
  templateUrl: './item-tile.component.html',
  styleUrls: ['./item-tile.component.scss']
})
export class ItemTileComponent {

  @Input() item!: Item;

  @Output() itemClick = new EventEmitter<Item>();
  @Output() deleteItem = new EventEmitter<Item>();

  constructor(
      private element: ElementRef
  ) {
    element.nativeElement.addEventListener('click', (e: Event) => {
      this.itemClick.emit(this.item);
    });
  }

  getMarkup(price: number, cost: number) {
    if (!price || !cost) {
      return 0;
    }
    const fullMarkup = (price-cost)/cost * 100;
    return Math.round(fullMarkup * 100) / 100;
  }

  onDeleteClick(event: Event) {
    event.stopPropagation();
    this.deleteItem.emit(this.item);
  }

}
