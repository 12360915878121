import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LobbyPage, pageNameToPath, lobbyPages } from '../../models/navigation.model';
import { NavigationService } from '../../services/navigation.service';
import { UsersService } from '../../services/users.service';
import { AssignedService } from '../../services/assigned.service';
import { Observable, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AssignmentService } from '../../services/assignment.service';
import { BusinessService } from '../../services/business.service';
import { Assigned } from "../../models/assigned.model";
import { Assignment } from "../../models/assignment.model";
import { ScheduleService } from "../../services/schedule.service";
import { LobbyService } from "../../services/lobby.service";

@Component({
    selector: 'app-lobby',
    templateUrl: './lobby.component.html',
    styleUrls: ['./lobby.component.scss']
})
export class LobbyComponent {

    loading$ = this.businessService.selectedBusiness$.pipe(
        map(b => !b),
        startWith(true)
    );

    bookOnboardinglInk$ = this.businessService.bookOnboardingUrl$;
    isOnboardingBooked$ = this.businessService.isOnboardingBooked$;

    pages$ = combineLatest([this.assignedService.items$, this.assignmentService.items$]).pipe(
        map(([assigned, assignment]) => {
            let pages = Array.from(lobbyPages);
            pages = pages.filter(p => p !== 'Assigned');
            pages = pages.filter(p => p !== 'Assignment');
            if (assignment.length > 0) {
                if(this.hasDeniedOrOverdueAssignment(assignment))
                    (pages as any).unshift({tab: 'Assignment', class: 'red'});
                else
                    pages.unshift('Assignment')
            }
            if (assigned.length > 0) {
                if(this.hasDeniedOrOverdueAssigned(assigned))
                    (pages as any).unshift({tab: 'Assigned', class: 'red'});
                else
                    pages.unshift('Assigned')
            }
            return pages as LobbyPage[];
        })
    );

    page$ = this.navigationService.page$;

    user$ = this.authService.user$;

    constructor(
        private authService: AuthService,
        private businessService: BusinessService,
        private navigationService: NavigationService<LobbyPage>,
        public usersService: UsersService,
        public scheduleService: ScheduleService,
        public assignedService: AssignedService,
        public assignmentService: AssignmentService,
        public lobbyService: LobbyService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    hasDeniedOrOverdueAssigned(items: Assigned[]) {
        for(const item of items) {
            if(item.outStartTime.getTime() < Date.now())
                return true;
            if(item.type === 'estimate')
                return item.denied;
            if(!!item.assignedToUsers!.find(user => user.status === 'denied'))
                return true;
        }
        return false;
    }

    hasDeniedOrOverdueAssignment(items: Assignment[]) {
        for(const item of items) {
            if(item.denied || item.outStartTime.getTime() < Date.now())
                return true;
        }
        return false;
    }

    onTabSelected(tab: string) {
        const page = pageNameToPath(tab as LobbyPage);
        this.scheduleService.showCalendarSubject.next(false);
        this.lobbyService.setSelectedDateRange(null);
        this.router.navigate([page], {relativeTo: this.route});
    }

}
