import { Injectable } from '@angular/core';
import { map, shareReplay, switchMap, take } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { Item } from "../models/item.model";
import { BusinessService } from "./business.service";
import { isEmpty } from "../helpers/forms";
import { rpcFilter, SupabaseService } from "./supabase.service";

export const limitStep = 10;

@Injectable({
  providedIn: 'root'
})
export class ItemsService {

  total$ = this.businessService.selectedBusiness$.pipe(
    switchMap(business => this.supabaseService.rpc<any>(
      {
        cName: 'items_total',
        schema: business.businessId,
        fn: 'get_items_total',
        tables: [
          { table: 'item' },
        ]
      },
      _ => null,
      'items'
    )),
    shareReplay(1)
  );

  items$ = this.businessService.selectedBusiness$.pipe(
      switchMap(business => {
        if(!business)
          return of([]);
        return this.supabaseService.rpc<Item[]>({
          cName: 'items',
          fn: 'get_items',
          schema: business.businessId,
          tables: ['item']
        });
      }),
      shareReplay(1)
  );

  constructor(
    private businessService: BusinessService,
    private supabaseService: SupabaseService,
  ) { }

  itemObservable(name: string) {
    return this.businessService.selectedBusiness$.pipe(
      switchMap(business => this.supabaseService.rpc<Item>({
        cName: 'item_' + name,
        schema: business.businessId,
        fn: 'get_item_by_name',
        tables: [{ table: 'item', filter: rpcFilter('name', 'eq', name)}],
        options: {
          inName: name
        }
      }))
    );
  }

  async itemByName(name: string | null) {
    if (!name)
      return null;
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    const res = await this.supabaseService.supabase
        .schema(business.businessId)
        .from('item')
        .select()
        .like('name', `${name}`)
        .maybeSingle();
    return res.data as Item;
  }

  async itemsByName(name: string | null, orderBy? : 'name' | 'date') {
    if (!name)
      return null;

    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    const builder = this.supabaseService.supabase
        .schema(business.businessId)
        .from('item')
        .select()
        .ilike('name', `%${name}%`);
    if (orderBy) {
      const ascending = orderBy === 'name';
      const column = orderBy === 'date' ? 'created_at' : 'name';
      builder.order(column, { ascending });
    }
    const res = await builder;
    return res.data as Item[];
  }

  async createItem(item: Omit<Item, 'id' | 'createdAt'>, oldDate?: Date | null, validated?: boolean) {
    const businessId = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())?.businessId;

    await this.validateItem(item as Item);

    (item as Item).createdAt = oldDate ?? new Date();
    item.name = item.name.trim();
    await this.supabaseService.insert(businessId, 'item', item);
  }

  async deleteItem(id: number) {
    const businessProfile = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    return this.supabaseService.delete(businessProfile.businessId, 'item', id);
  }

  async updateItem(id: number, data: Partial<Item>) {
    const businessId = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())?.businessId;

    if (data.name)
      data.name = (data.name as string).trim();

    if (data.name)
      await this.validateItem(data as Item)

    return this.supabaseService.update(businessId, 'item', id, data);
  }

  private async validateItem(newItem: Item) {
    if (isEmpty(newItem.price)) {
      throw new Error("Item price is required.");
    }

    if (Number.isNaN(newItem.price)) {
      throw new Error("Invalid price format.");
    }

    if (Number.isNaN(newItem.cost)) {
      throw new Error("Invalid cost format.");
    }
  }
}
