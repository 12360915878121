<div class="dialog wrapper d-flex flex-column">
    <!-- <app-mobile-modal-header
      [titleText]="mobileHeaderTitle"
      hideOnBreakpoint="sm"
      [leftButton]="mobileHeaderLeftButtonCaption"
      (onLeftButtonClick)="onMobileHeaderButtonClick(mobileHeaderLeftButtonCaption)"
      [rightButton]="mobileHeaderRightButtonCaption"
      (onRightButtonClick)="onMobileHeaderButtonClick(mobileHeaderRightButtonCaption)"
    ></app-mobile-modal-header> -->

    <div class="header d-flex justify-content-between align-items-center">
        <h3>Summary Preview</h3>
        <app-icon class="se-icon-button no-bg se-icon-button-close" (click)="close()" src="close"></app-icon>
    </div>

    <div class="content-wrapper overlay-scrollbar">
        <div class="content d-flex flex-column justify-content-between" *ngIf="business$ | async let business">
            <div>
                <p class="bold letter-start">Dear {{ name }},</p>
                <p>{{ assigneeFullName }} from {{ business.businessName }}
                    <ng-container *ngIf="!isReschedule && !isReassign">
                        {{ isEstimate ? 'scheduled an estimate to assess the job' : 'scheduled the job' }}.
                    </ng-container>
                    <ng-container *ngIf="isReschedule && isReassign; else rescheduleOrReassign">
                        was <span class="bold">reassigned</span> and <span class="bold">rescheduled</span>
                        the {{ data.formData.dialogType | lowercase}}.
                    </ng-container>
                    <ng-template #rescheduleOrReassign>
                        <ng-container *ngIf="isReschedule || isReassign">
                            <span *ngIf="isReassign" class="bold">was reassigned to</span><span *ngIf="isReschedule"
                                                                                                class="bold">rescheduled</span>
                            the {{ data.formData.dialogType | lowercase }}.
                        </ng-container>
                    </ng-template>
                </p>

                <p class="section-title">Schedule:</p>
                <ng-container *ngFor="let ranges of rangesByDate">
                    <p class="bold no-margin">{{ ranges[0].startTime| date: dateFormat }}</p>
                    <div *ngFor="let range of ranges; let last = last">
                        <p [class.no-margin]="!last">{{ range.startTime | date: timeFormat }}
                            - {{ range.endTime | date: timeFormat }}</p>
                    </div>
                </ng-container>

                <p class="section-title">Location:</p>
                <p>{{ address | address }}
                    <ng-container *ngIf="unit">, {{ unit }}</ng-container>
                </p>
                <p>
                    For any questions or rescheduling, please contact {{ business.businessName }}
                    at {{ formatPhoneNumber(business.phoneNumber) }}<span
                        *ngIf="(showDirectPhoneNumber$ | async) && (directPhoneNumber$ | async) let directPhoneNumber"> or {{ assigneeFullName }}
                    at {{ formatPhoneNumber(directPhoneNumber) }}</span>.
                </p>
            </div>
            <p class="no-margin">Best regards,
                <br>Slick Estimates team
            </p>
        </div>
    </div>
</div>
