import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { FormsService } from '../../../../../common/src/lib/services/forms.service';
import { ModalsService } from '../../../../../common/src/lib/services/modals.service';
import { UtilsService } from 'projects/common/src/public-api';
import { ForgotPasswordModal } from '../forgot-password/forgot-password.component';
import { map, take } from "rxjs/operators";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  form = this.formBuilder.group({
    password: new UntypedFormControl(''),
    newPassword: new UntypedFormControl('', [Validators.minLength(6)])
  });

  showPassword = false;
  showNewPassword = false;
  loading = false;

  passwordFocused = false;

  hasEmailProvider$ = this.authService.user$.pipe(
      map(user => user?.providers.includes('Email'))
  );

  @ViewChild('passwordInput') passwordInput!: ElementRef;
  @ViewChild('newPasswordInput') newPasswordInput!: ElementRef;

  showReauthentication = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private formsService: FormsService,
    private authService: AuthService,
    private utilsService: UtilsService,
    private modalsService: ModalsService,
  ) { }

  async ngOnInit() {
    // const user = await this.authService.user$.pipe(take(1)).toPromise();
    // const fiveMinutes = 5 * 60 * 1000;
    // if(Date.now() - user!.lastSignIn.getTime() < fiveMinutes) {
    //   this.showReauthentication = false;
    // }
  }

  async changePassword() {

    if(this.utilsService.isMobile() && this.passwordFocused) {
      this.newPasswordInput.nativeElement.focus();
      return;
    }

    if(this.loading) {
      return;
    }

    // const fiveMinutes = 5 * 60 * 1000;
    const user = await this.authService.user$.pipe(take(1)).toPromise();
    const hasEmailProvider = user?.providers.includes('Email');

    const requiredValid = this.showReauthentication && hasEmailProvider
        ? this.formsService.validateRequired(this.form.get('password')!, this.form.get('newPassword')!)
        : this.formsService.validateRequired(this.form.get('newPassword')!);

    if(requiredValid && this.form.valid) {
      this.loading = true;

      const { password, newPassword } = this.form.value;
      try {

        if(this.showReauthentication && hasEmailProvider) {
          await this.authService.reauthenticateWithPassword(password);
        }
        await this.authService.changePassword(newPassword);
        this.close();
      } catch (e) {
        console.log(e);

        if(e === 'Invalid login credentials') {
          this.form.get('password')!.setErrors({
            password: true
          });
        }

        if(e === 'New password should be different from the old password.') {
          this.form.get('newPassword')!.setErrors({
            samePassword: true
          });
        }
      }

    } else {
      this.form.markAllAsTouched();
    }

    this.passwordInput?.nativeElement?.blur();
    this.newPasswordInput.nativeElement.blur();
    this.loading = false;
  }

  openForgotPassword() {
    this.modalsService.open(ForgotPasswordModal, {
      data: {
        backComponent: ChangePasswordComponent
      }
    });
  }

  close() {
    this.modalsService.close();
  }

}
