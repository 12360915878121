<div class="page d-flex flex-column justify-content-start" *ngIf="user$ | async let user" fadeIn="500" [fadeInDelay]="200">
    <app-toolbar class="not-mobile" type="menu" leftAction="Back" (backClick)="backClick()"></app-toolbar>
    <app-mobile-toolbar class="only-mobile" [showMenu]="true" leftAction="Back" (backClick)="backClick()">
        <app-icon matTooltip="Back" matTooltipPosition="right" class="no-outline se-icon-button" src="back" routerLink="/estimate"></app-icon>
    </app-mobile-toolbar>


    <div class="d-flex flex-column align-items-center flex-fill mt--34" *ngIf="profile$ | async; let profile" [class.container]="selectedTab !== 'Help' || (tabletScreen$ | async)">
        <app-text-toggle [selectedItemIndex]="tabIndex" [showDividers]="true" [items]="(tabs$ | async)!" (onSelected)="onTabChange($event)"></app-text-toggle>

        <ng-container [ngSwitch]="selectedTab">
            <app-personal *ngSwitchCase="'Personal'" class="w-100" [profile]="profile" [user]="user"></app-personal>
            <app-business *ngSwitchCase="'Business'" class="w-100" [profile]="profile" [user]="user"></app-business>
            <app-users *ngSwitchCase="'Users'" class="w-100" [businessProfile]="profile"></app-users>
            <app-help *ngSwitchCase="'Help'" [businessProfile]="profile" class="mb--20"></app-help>
        </ng-container>
    </div>

</div>
