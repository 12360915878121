import { Component } from '@angular/core';

@Component({
  selector: 'app-overlook',
  templateUrl: './overlook.component.html',
  styleUrls: ['./overlook.component.scss']
})
export class OverlookComponent {

}
