<div class="any-item" [id]="'itemRow'+index">
  <ng-container *ngIf="itemType === 'items-cost'" [ngTemplateOutlet]="itemsCost"></ng-container>
  <ng-container *ngIf="itemType === 'paid'" [ngTemplateOutlet]="paid"></ng-container>
  <ng-container *ngIf="itemType === 'tech_part'" [ngTemplateOutlet]="techPart"></ng-container>
  <ng-container *ngIf="itemType === 'expense'" [ngTemplateOutlet]="expense"></ng-container>
  <ng-container *ngIf="itemType === 'refunded'" [ngTemplateOutlet]="refunded"></ng-container>
  <ng-container *ngIf="itemType === 'commission'" [ngTemplateOutlet]="commission"></ng-container>
  <ng-container *ngIf="itemType === 'chargeback'" [ngTemplateOutlet]="chargeback"></ng-container>
  <ng-container *ngIf="itemType === 'sales-tax'" [ngTemplateOutlet]="salesTax"></ng-container>
</div>

<ng-template #commission>
  <ng-container *ngIf="{ pen: ($any(itemData).amount - $any(itemData).paid) } as pending">
    <ng-container *ngIf="{ ref: pending.pen < 0 } as refund">
      <div class="commission w-100">
        <div>
          <div class="d-flex justify-content-between">
            <div class="left">
              <div class="title">{{ capitalize($any(itemData).kind) }} Commission</div>
              <div class="payment-date">({{ $any(itemData).firstName[0] }}. {{ $any(itemData).lastName }})</div>
            </div>
            <div class="d-flex gap--12">
              <div class="amount-display col-black mobile-hide"
                   [class.items-cost]="canceled && rounder($any(itemData).paid) <= 0"
              >
                -{{ currencify($any(itemData).amount) }}
              </div>
              <app-icon class="clickable dots3-icon"
                        src="3dots"
                        [matMenuTriggerFor]="payCommission"
                        [matMenuTriggerData]="{ edit: refund.ref, paid: $any(itemData).paid && $any(itemData).paid !== 0 }"
                        *ngIf="!canceled || rounder($any(itemData).paid) > 0"
              ></app-icon>
            </div>
          </div>
          <div class="amount-display col-black mobile-show mt--12">-{{ currencify($any(itemData).amount) }}</div>
        </div>
        <div class="d-flex justify-content-between w-100" *ngIf="$any(itemData).paid">
          <div class="left">
            <div class="title">Paid</div>
          </div>
          <div class="amount-display paid items-cost">{{ currencify($any(itemData).paid) }}</div>
        </div>
        <div class="d-flex justify-content-between w-100" *ngIf="pending.pen">
          <div class="left">
            <div class="title">{{ refund.ref ? 'Refund' : 'Pending' }}</div>
          </div>
          <div class="amount-display items-cost"
               [class.tech-part]="!refund.ref"
               [class.refund]="refund.ref"
          >
            {{ currencify(getPendingAmount($any(itemData).amount, $any(itemData).paid)) }}
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #itemsCost>
  <div class="left">
    <div class="title">Items</div>
    <app-icon src="question-mark-tooltip"
              matTooltip="Comes from the cost of the items"
              [matTooltipShowDelay]="150"
              class="scale--85 mt--3"
    ></app-icon>
  </div>
  <div class="amount-display items-cost">-{{ currencify($any(itemData)) }}</div>
</ng-template>

<ng-template #salesTax>
  <div class="left">
    <div class="title">Sales Tax</div>
  </div>
  <div class="amount-display items-cost">-{{ currencify($any(itemData)) }}</div>
</ng-template>

<ng-template #paid>
  <div class="left">
    <div class="d-flex justify-content-between align-items-center w-100">
      <div class="title">Payment</div>
      <app-icon class="clickable dots3-icon mobile-show"
                src="3dots"
                [matMenuTriggerFor]="type === 'cc' ? ccTypeMenu : notCcTypeMenu"
                *ngIf="type !== 'cc' || !($any(itemData).isRefunded)"
      ></app-icon>
      <div *ngIf="type === 'cc' && $any(itemData).isRefunded" class="width--20 mobile-show"></div>
    </div>
    <div class="payment-date mobile-hide">{{ getPaymentDate($any(itemData).createdAt) }}</div>
  </div>
  <div class="d-flex align-items-center gap--12">
    <div class="paid-right">
      <div class="payment-badge">{{ getBadgeCopy($any(itemData).type) }}</div>
      <div class="amount-display paid">{{ currencify($any(itemData).amount) }}</div>
    </div>
    <app-icon class="clickable dots3-icon mobile-hide"
              src="3dots"
              [matMenuTriggerFor]="type === 'cc' ? ccTypeMenu : notCcTypeMenu"
              *ngIf="type !== 'cc' || !($any(itemData).isRefunded)"
    ></app-icon>
    <div *ngIf="type === 'cc' && $any(itemData).isRefunded" class="width--20 mobile-hide"></div>
  </div>
</ng-template>

<ng-template #refunded>
  <div class="left">
    <div class="d-flex justify-content-between align-items-center w-100">
      <div class="title">Refunded</div>
    </div>
    <div class="payment-date">{{ getPaymentDate($any(itemData).createdAt) }}</div>
  </div>
  <div class="d-flex align-items-center gap--12">
    <div class="paid-right">
      <div class="amount-display items-cost">{{ currencify(-($any(itemData).amount)) }}</div>
    </div>
  </div>
</ng-template>

<ng-template #chargeback>
  <div class="left">
    <div class="d-flex justify-content-between align-items-center w-100">
      <div class="title">Chargeback</div>
    </div>
    <div class="payment-date">{{ getPaymentDate($any(itemData).paidAt) }}</div>
  </div>
  <div class="d-flex align-items-center gap--12">
    <div class="paid-right">
      <div class="payment-badge">{{ getBadgeCopy('cc') }}</div>
      <div class="amount-display items-cost refund" [class.resolved]="$any(itemData).resolved">
        {{ currencify($any(itemData).resolved ? $any(itemData).amount : -($any(itemData).amount)) }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #expense>
  <div class="left">
    <div class="d-flex justify-content-between align-items-center w-100">
      <div class="title">{{ getExpenseName($any(itemData).name) }}</div>
      <app-icon class="clickable dots3-icon mobile-show"
                src="3dots"
                [matMenuTriggerFor]="expenseMenu"
      ></app-icon>
    </div>
  </div>
  <div class="d-flex align-items-center gap--12" [id]="'itemRight'+index">
    <div class="amount-display">-{{ currencify($any(itemData).amount) }}</div>
    <app-icon class="clickable dots3-icon mobile-hide"
              src="3dots"
              [matMenuTriggerFor]="expenseMenu"
    ></app-icon>
  </div>
</ng-template>

<ng-template #techPart>
  <div class="left">
    <div class="d-flex justify-content-between align-items-center w-100">
      <div class="d-flex justify-content-between align-items-center gap--8">
        <div class="title">{{ getExpenseName($any(itemData).name) }}</div>
        <div class="payment-date">({{ $any(itemData).creatorFirstName[0] }}. {{ $any(itemData).creatorLastName }})</div>
      </div>
      <app-icon class="clickable dots3-icon mobile-show"
                src="3dots"
                [matMenuTriggerFor]="techPartMenu"
      ></app-icon>
    </div>
  </div>
  <div class="tech-part-right" [id]="'itemRight'+index">
    <div class="payment-badge">Tech Part</div>
    <div class="amount-display tech-part" [class.paid-tech-part]="$any(itemData).paid">
      -{{ currencify($any(itemData).amount) }}
    </div>
    <app-icon class="clickable dots3-icon mobile-hide"
              src="3dots"
              [matMenuTriggerFor]="techPartMenu"
    ></app-icon>
  </div>
</ng-template>

<mat-menu #ccTypeMenu xPosition="before" class="no-margin">
  <ng-template matMenuContent>
    <mat-option (click)="refund()">Refund</mat-option>
  </ng-template>
</mat-menu>

<mat-menu #notCcTypeMenu xPosition="before" class="no-margin">
  <ng-template matMenuContent>
    <mat-option (click)="edit()">Edit</mat-option>
    <mat-option (click)="delete()">Delete</mat-option>
  </ng-template>
</mat-menu>

<mat-menu #expenseMenu xPosition="before" class="no-margin">
  <ng-container *ngIf="expenseAccess">
    <ng-template matMenuContent>
      <mat-option (click)="edit()">Edit</mat-option>
      <mat-option (click)="delete()">Delete</mat-option>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="!expenseAccess">
    <ng-template matMenuContent>
      <div class="not-allowed-mark-as-unpaid">
        <div class="title">Edit</div>
        <div class="subtitle">You don’t have permissions to edit this</div>
      </div>
    </ng-template>
  </ng-container>
</mat-menu>

<mat-menu #techPartMenu xPosition="before" class="no-margin">
  <ng-container *ngIf="techPartAccess as access">

    <mat-option (click)="markTechPart()" *ngIf="access.onlyMark">
      Mark as {{ $any(itemData).paid ? 'Unpaid' : 'Paid' }}
    </mat-option>

    <ng-container *ngIf="access.handle">
      <mat-option (click)="edit()" *ngIf="!($any(itemData).paid)">Edit</mat-option>
      <mat-option (click)="delete()" *ngIf="!($any(itemData).paid)">Delete</mat-option>
    </ng-container>

    <ng-container *ngIf="access.noPermission">
      <div class="not-allowed-mark-as-unpaid">
        <div class="title">Mark as Unpaid</div>
        <div class="subtitle">You don’t have permissions to mark this</div>
      </div>
    </ng-container>

  </ng-container>
</mat-menu>

<mat-menu #payCommission xPosition="before" class="no-margin">
  <ng-template matMenuContent let-edit="edit" let-paid="paid">
    <mat-option (click)="commissionAction(edit, paid)">{{ !paid ? 'Pay' : 'Edit' }}</mat-option>
  </ng-template>
</mat-menu>
