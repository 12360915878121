import { Component, Output, EventEmitter, ViewChild, AfterViewInit, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UtilsService } from 'projects/common/src/public-api';
import { combineLatest, Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { Router } from "@angular/router";
import { ClientSearchRequest, ClientsService } from "../../services/clients.service";
import { ClientSearchResult } from "../../models/client-search-result.model";
import { ViewAsService } from "../../services/view-as.service";
import { NavigationService } from "../../services/navigation.service";
import { DateFilterService } from '../../services/date-filter.service';

type SearchResult = { name: string, objectID: string };

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, AfterViewInit {

  focus = false;

  @Input() control!: FormControl<string>;
  @Input() fullWidth?: boolean;
  @Input() autofocus?: boolean;
  @Input() showCloseButtonAlways?: boolean;

  @ViewChild('input') input: any;

  @Output() focusChange = new EventEmitter<boolean>();

  searchedOnce = false;

  options$!: Observable<ClientSearchResult[]>;
  loading$!: Observable<boolean>;

  constructor(
    private utilsService: UtilsService,
    private navigationService: NavigationService,
    private router: Router,
    private clientsService: ClientsService,
    private viewAsService: ViewAsService,
    private dateFilterService: DateFilterService
  ) {}

  ngOnInit() {
    if(!this.control) {
      this.control = new FormControl<string>('', { nonNullable: true });
    }
    if(this.clientsService.currentClientDataSubject.value) {
      const { inFirstName, inLastName } = this.clientsService.currentClientDataSubject.value;
      this.control.setValue(inFirstName + ' ' + inLastName);
    }
    this.options$ = combineLatest([this.control.valueChanges, this.viewAsService.selectedUsersIds$]).pipe(
      switchMap(async ([term, ids]) => {
        if(!term || term === '')
          return [];

        const data: ClientSearchRequest = {
          inTerm: term,
          inUserIds: ids
        };
        return this.clientsService.clientSearch(data);
      })
    );
    this.loading$ = this.options$.pipe(
      map(items => items === null)
    );
  }

  ngAfterViewInit(): void {
    if(this.autofocus) {
      if(this.utilsService.isIOS()) {
        this.focusInput();
      } else {
        setTimeout(this.focusInput.bind(this), 0)
      }
    }
  }

  focusInput() {
    this.input.nativeElement.focus();
  }

  async optionClicked(option: ClientSearchResult) {
    this.input.nativeElement.blur();
    this.control.setValue(option.outFirstName + ' ' + option.outLastName, { emitEvent: false });
    const data = {
      inFirstName: option.outFirstName,
      inLastName: option.outLastName,
      inBusinessName: option.outBusinessName,
      inEmail: option.outEmail,
      inPhoneNumber: option.outPhoneNumber,
    };
    const split = this.router.url.split('/');

    if(split[1] !== 'customer-list') {
      this.clientsService.currentClientDataSubject.next(data);
      this.dateFilterService.setSelectedDateRange(null);
      await this.router.navigate(['/customer-list']);
      return;
    }
    
    this.clientsService.currentClientDataSubject.next(data);
  }

  onClick(event: any) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.focusInput();
  }

  clear(event: Event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    if(this.router.url.includes('lobby')) {
      this.control.setValue('', { emitEvent: true });
      this.input.nativeElement.blur();
    } else {
      this.clientsService.currentClientDataSubject.next(null);
      this.router.navigate(['lobby']);
    }
  }
}
