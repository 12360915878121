import { Component } from '@angular/core';
import {
  ReportsPages,
  pageNameToPath,
  reportsPages,
  reportsPaymentsTabs,
  ReportsPaymentsTabs,
  reportsCommissionsTab,
  reportsTechPartsTab,
  ReportsCommissionsTab,
  ReportsTechPartsTab
} from "../../models/navigation.model";
import { AuthService } from "../../services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NavigationService } from '../../services/navigation.service';
import { Observable } from "rxjs";
import { ReportsService } from "../../services/reports.service";
import { take } from "rxjs/operators";

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent {
  user$ = this.authService.user$;

  page$ = this.navigationService.page$;
  tab$ = this.navigationService.tab$;

  pages = Array.from(reportsPages);
  tabs$!: Observable<ReportsPaymentsTabs[] | ReportsCommissionsTab[] | ReportsTechPartsTab[] | []>;
  internalTabs = Array.from(reportsPaymentsTabs);
  selectedOption = 'All';

  filters: {
    [tab: string]: {
      status: string
    }
  } = {};

  constructor(
    private reportsService: ReportsService,
    private navigationService: NavigationService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  backClick() {
    this.router.navigate(['lobby']);
  }

  onPageSelected(page: string) {
    const chosenPage = pageNameToPath(page as ReportsPages);
    this.router.navigate([chosenPage], { relativeTo: this.route });
  }

  onTabSelected(tab: string) {
    const activePage = this.navigationService.page$.pipe(take(1)).toPromise();

    activePage.then((page) => {
      let chosenTab = '';
      let routePath = '';
      if (page === 'Payments') {
        chosenTab = pageNameToPath(tab as ReportsPaymentsTabs)
        routePath = 'reports/payments/';
      } else if (page === 'Commissions') {
        chosenTab = pageNameToPath(tab as ReportsCommissionsTab)
        routePath = 'reports/commissions/';
      } else if (page === 'Tech Parts') {
        chosenTab = pageNameToPath(tab as ReportsTechPartsTab)
        routePath = 'reports/tech-parts/';
      }

      this.router.navigateByUrl(routePath + [chosenTab]);
    });
  }

  async onInternalTabSelected (tab: string) {
    const useTab = tab.toLowerCase();
    this.router.navigate([useTab], { relativeTo: this.route });
  }
}
