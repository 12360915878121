import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import { ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { UtilsService } from "../../../../../common/src/lib/services";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";

@Component({
  selector: 'app-item-example',
  templateUrl: './item-example.component.html',
  styleUrls: ['./item-example.component.scss']
})
export class ItemExampleComponent implements AfterViewInit {

  @ViewChild('download') download!: ElementRef;

  constructor(
    private modalsService: ModalsService,
    private utilsService: UtilsService,
    private snackBar: MatSnackBar
  ) { }

  ngAfterViewInit() {
    if (!this.isMobile) {
      this.download.nativeElement.href = 'assets/example.xlsx';
    }
  }

  get isMobile() {
    return this.utilsService.isMobile();
  }

  async close() {
    await this.modalsService.close();
  }

  mobileDownload() {
    if (this.isMobile) {
      showSnackbar(this.snackBar, {
        message: 'Allowed only on desktop',
        duration: 2000
      });
    }
  }
}
