import { Injectable } from '@angular/core';
import { Client, MakeOptional, dateToISOStringWithTimezone } from 'projects/common/src/public-api';
import { BusinessService } from './business.service';
import { ViewAsService } from './view-as.service';
import { LobbyService } from './lobby.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PaymentService } from './payment.service';
import { UsersService } from './users.service';
import { TimeRange } from 'projects/common/src/lib/models/time-range.model';
import { rpcFilter, SupabaseService } from "./supabase.service";
import { map, shareReplay, switchMap, take } from "rxjs/operators";
import { Note } from "../models/note.model";
import { Estimate, EstimateTileData } from "../models/estimate.model";
import { PaginationOptions } from "./pagination.service";
import { EstimateClientRestore } from "../../../../common/src/lib/models/client-transform";
import { UserProfile } from "../../../../common/src/lib/models/user-profile.model";
import { RequestInvitationFormData } from "../modals/request-invitation/request-invitation.model";

export interface EstimateCreate {
    inOwnerId: number;
    inClient: MakeOptional<Client, 'salesTaxPercentage'>;
    inRanges: TimeRange[];
    inAssignee: number | null;
    inWorkflowId?: number;
    inJobType?: string;
    inNotes?: Note[];
}

export interface EstimateRestore {
    inId: number;
    inCreatedAt: Date;
    inOwnerId: number;
    inClient: EstimateClientRestore;
    inRanges: TimeRange[];
    inStatus: Estimate['status'];
    inDenied: boolean;
    inAssignee?: number;
    inWorkflowId?: number;
    inJobType?: string;
    inNotes?: Note[];
}

interface BaseEstimateUpdate {
    inId: number;
    inOwnerId?: number | null;
    inStatus?: Estimate['status'];
    inClient?: MakeOptional<Client, 'salesTaxPercentage'>;
    inRanges?: TimeRange[];
    inJobType?: string;
}

interface EstimateUpdateWithAssigneeData {
    inAssignee?: number | null;
    inDenied?: boolean;
}

export interface EstimateEmail {
    schemaName?: string,
    itemId: number,
    timeRanges: { start: string, end: string }[],
    organizerName: string,
    businessEmail: string,
    businessName: string,
    clientName: string,
    businessPhoneNumber: string,
    clientEmailAddress: string,
    reschedule: boolean
}

export type EstimateUpdate = BaseEstimateUpdate | (BaseEstimateUpdate & EstimateUpdateWithAssigneeData);

@Injectable({
  providedIn: 'root'
})
export class EstimatesService {

    constructor(
        protected businessService: BusinessService,
        protected viewAsService: ViewAsService,
        protected supabaseService: SupabaseService,
        protected paymentService: PaymentService,
        protected usersService: UsersService,
        protected lobbyService: LobbyService,
        protected snackbar: MatSnackBar,
    ) {}

    estimateObservable(id: number, pref = '') {
        return this.businessService.selectedBusiness$.pipe(
            switchMap(business => {
                return this.supabaseService.rpc<Estimate[]>({
                    cName: pref + '_estimate_' + id,
                    schema: business!.businessId,
                    fn: 'get_estimate_by_id',
                    tables: [
                        { table: 'estimate', filter: rpcFilter('id', 'eq', id) },
                        { table: 'estimate_client', filter: rpcFilter('estimate_id', 'eq', id) },
                        { table: 'estimate_range', filter: rpcFilter('estimate_id', 'eq', id) },
                        { table: 'workflow', filter: rpcFilter('estimate_id', 'eq', id) }
                    ],
                    options: { inId: id }
                }).pipe(
                    map(estimates => {
                        if(!estimates || estimates.length === 0)
                            return null;
                        const estimate = estimates[0];
                        estimate.docType = 'Estimate';
                        return estimate;
                    })
                );
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    estimatesObservable(options: PaginationOptions, pref = '') {
        return this.businessService.selectedBusiness$.pipe(
            // TODO: This can be improved by adding the changes handler to handle each change separately and not to pull the list again.
            switchMap(business => {
                return this.supabaseService.rpc<EstimateTileData[]>({
                    cName: pref + '_estimates',
                    schema: business!.businessId,
                    fn: 'get_estimates',
                    tables: [
                        { table: 'estimate' },
                        { table: 'estimate_client' },
                        { table: 'estimate_range' },
                        { table: 'workflow' }
                    ],
                    options
                });
            }),
            map(estimates => {
                if(!estimates)
                    return [];
                for(const estimate of estimates)
                    estimate.docType = 'Estimate';
                return estimates;
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    async getEstimate(id: number) {
        const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
        const estimate = (await this.supabaseService.rpcFunc<Estimate[]>(business!.businessId, 'get_estimate_by_id', { inId: id }))[0];
        estimate.docType = 'Estimate';
        return estimate;
    }

    async createEstimate(data: EstimateCreate) {
        const business = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!;
        const users = await this.usersService.users$.pipe(take(1)).toPromise();
        const estimateId = await this.supabaseService.rpcFunc<number>(business.businessId, 'create_estimate', data);
        const businessOwner = users!.find(user => user.role === 'owner')!;
        const organizerId = data.inAssignee ?? data.inOwnerId;
        const organizer = users!.find(user => user.id === organizerId)!;
        this.sendEmail({
            schemaName: business.businessId,
            itemId: estimateId,
            timeRanges: data.inRanges.map(range => ({
                start: dateToISOStringWithTimezone(range.startTime),
                end: dateToISOStringWithTimezone(range.endTime),
            })),
            organizerName: organizer.firstName,
            businessEmail: businessOwner.email,
            businessName: business.businessName,
            clientName: data.inClient.firstName,
            businessPhoneNumber: business.phoneNumber,
            clientEmailAddress: data.inClient.email,
            reschedule: false
        });
        return estimateId;
    }

    async updateEstimate(data: EstimateUpdate) {
        const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
        return this.supabaseService.rpcFunc(business!.businessId, 'update_estimate', data);
    }

    async restoreEstimate(data: EstimateRestore) {
        const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
        return this.supabaseService.rpcFunc(business!.businessId, 'restore_estimate', data);
    }

    async deleteEstimate(id: number, showUndoSnackbar?: boolean) {
        const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
        return this.supabaseService.delete(business!.businessId, 'estimate', id);
    }

    async sendEmail(data: MakeOptional<EstimateEmail, 'schemaName' | 'businessEmail' | 'businessName' | 'businessPhoneNumber'>) {
        if(!data.schemaName) {
            const business = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!;
            data.schemaName = business.businessId;
            data.businessName = business.businessName;
            data.businessPhoneNumber = business.phoneNumber;
        }
        if(!data.businessEmail) {
            const users = await this.usersService.users$.pipe(take(1)).toPromise();
            const owner = users!.find(user => user.role === 'owner')!;
            data.businessEmail = owner.email;
        }
        return this.supabaseService.edgeFunc('send-estimate-email', data);
    }

    async sendEmailForEstimate(estimate: Estimate, reschedule: boolean) {
        const business = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!;
        const users = await this.usersService.users$.pipe(take(1)).toPromise();
        const owner = users!.find(user => user.role === 'owner')!;
        const data = {
            schemaName: business.businessId,
            itemId: estimate.id,
            timeRanges: estimate.ranges.map(range => ({
                start: dateToISOStringWithTimezone(range.startTime),
                end: dateToISOStringWithTimezone(range.endTime),
            })),
            organizerName: estimate.assigneeFirstName ?? estimate.ownerFirstName,
            businessEmail: owner.email,
            businessName: business.businessName,
            clientName: estimate.firstName,
            businessPhoneNumber: business.phoneNumber,
            clientEmailAddress: estimate.email,
            reschedule
        }
        return this.sendEmail(data);
    }

    async sendEmailForFormData(formData: Required<RequestInvitationFormData>, original: Estimate, reschedule: boolean) {
        const business = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!;
        const users = await this.usersService.users$.pipe(take(1)).toPromise();
        const owner = users!.find(user => user.role === 'owner')!;

        const data = {
            schemaName: business.businessId,
            itemId: original.id,
            timeRanges: formData.ranges.map(range => ({
                start: dateToISOStringWithTimezone(range.startTime),
                end: dateToISOStringWithTimezone(range.endTime),
            })),
            organizerName: await this._getOrganizerName(formData, original, users!),
            businessEmail: owner.email,
            businessName: business.businessName,
            clientName: formData.client.firstName ?? original.firstName,
            businessPhoneNumber: business.phoneNumber,
            clientEmailAddress: formData.client.email ?? original.email,
            reschedule
        }
        return this.sendEmail(data);
    }

    private async _getOrganizerName(formData: Required<RequestInvitationFormData>, original: Estimate, users: UserProfile[]) {
        if(formData.assignees && formData.assignees[0])
            return users!.find(user => user.id === formData.assignees[0].id)!.firstName;
        if(original.assigneeFirstName)
            return original.assigneeFirstName;
        return users!.find(user => user.id === original.ownerId)!.firstName;
    }
}
