import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { UserProfile } from '../../../../common/src/lib/models/user-profile.model';
import { UsersService } from './users.service';
import { take, distinctUntilChanged, map, filter, shareReplay } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";

export const canOpenSingleUserPage = async (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
  const viewAsService = inject(ViewAsService);
  const selectedUsers = await viewAsService.selectedUsers$.pipe(take(1)).toPromise();
  if(Array.isArray(selectedUsers) && selectedUsers.length === 1)
    return true;

  viewAsService.viewAsButtonComponent?.onNavigationToSingleUserPage();
  return false;
}

export interface ViewAsComponent {
  onNavigationToSingleUserPage: () => void
}

@Injectable({
  providedIn: 'root'
})
export class ViewAsService {

  private _selectedUserSubject = new BehaviorSubject<number[] | 'All'>([]);
  changePrep$ = new BehaviorSubject<UserProfile[] | "All">([]);
  selectedUsers$ = combineLatest([
    this._selectedUserSubject.pipe(
        distinctUntilChanged((a, b) => {
          if(a === 'All' && b === 'All')
            return true;
          if(a === 'All' || b === 'All')
            return false;
          if(a.length !== b.length)
            return false;
          for(const v of a)
            if(!b.includes(v))
              return false;
          return true;
        }),
    ),
    this.usersService.users$.pipe(filter(users => !!users))
  ]).pipe(
    map(([selectedUserIds, users]) => {
      if(selectedUserIds === 'All') {
        return 'All';
      }
      return users!.filter(user => selectedUserIds.find(uid => uid === user.id));
    }),
    filter(users => users === 'All' || users.length > 0),
    shareReplay(1)
  );

  selectedUsersIds$ = this.selectedUsers$.pipe(
    map(users => {
      if (users === 'All')
        return null;

      return users.map(user => user.id);
    })
  );

  hasChanges$ = combineLatest([
    this.selectedUsers$,
    this.changePrep$
  ]).pipe(
      map(([users, selected]) => {
        if(users === 'All' && selected === 'All')
          return false;

        if(users === 'All' || selected === 'All')
          return true;

        if(users.length !== selected.length)
          return true;

        for (const user of users) {
          if(!selected.find(u => u.id === user.id)) {
            return true;
          }
        }
        return false;
      }),
  );

  viewAsButtonComponent?: ViewAsComponent;

  constructor(
    private usersService: UsersService,
    private router: Router,
  ) {
    this._prepObservable();
    this.usersService.currentUser$
      .pipe(distinctUntilChanged((x: UserProfile ,y: UserProfile) => x.id === y.id))
      .subscribe(user => {
        this.changePrep$.next([user]);
        this.setUsers([user]);
      });
  }

  private async _prepObservable() {
    const user = await this.usersService.currentUser$.pipe(take(1)).toPromise();
    this.changePrep$.next([user])
    this.setUsers([user!]);
  }

  setUsers(users: UserProfile[] | 'All') {
    this._selectedUserSubject.next(users === 'All' ? 'All' : users.map(user => user.id));
  }

  addUser(user: UserProfile) {
    let value = this._selectedUserSubject.value;
    if(value === 'All') {
      value = [user.id];
    } else {
      if(!!value.find(uid => uid === user.id))
        return;  
      value.push(user.id);
    }
    this._selectedUserSubject.next(value);
  }

  removeUser(user: UserProfile) {
    const value = this._selectedUserSubject.value;
    if(value === 'All') {
      return;
    }
    const index = value.findIndex(uid => uid === user.id);
    if (index > -1) {
      value.splice(index, 1);
    }
    this._selectedUserSubject.next(value);
  }

  applyChanges() {
    let users = this.changePrep$.value === 'All' ? this.changePrep$.value : Array.from(this.changePrep$.value);
    this.setUsers(users);

    if(this.router.url.startsWith('/lobby')) {
      const url = this.router.url.startsWith('/lobby') ? this.router.url : '/lobby';
      this.router.navigateByUrl('/loading', {skipLocationChange: true}).then(() => {
        this.router.navigate([`/${url}`]);
      });
    } else if (!this.router.url.startsWith('/customer-list')) {
      this.router.navigate([`/lobby`]);
    }
  }

  async clearChanges() {
    let users = await this.selectedUsers$.pipe(take(1)).toPromise();
    if(users !== 'All')
      users = Array.from(users);
    this.changePrep$.next(users);
  }
}
