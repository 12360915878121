import { Injectable } from '@angular/core';
import { EstimateCreate, EstimateRestore, EstimatesService } from "./estimates.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import {
    BasicEvent,
    Client,
    ClientVisitEvent,
    MultipleAssignees,
} from "../../../../common/src/lib/models";
import { map, shareReplay, switchMap, take } from "rxjs/operators";
import { ScheduleTab } from "../models/navigation.model";
import { TimetableService } from './timetable.service';
import { CalendarView } from "angular-calendar";
import { NotesService } from './notes.service';
import { JobCreate, JobRestore, JobsService, JobUserRestore } from './jobs.service';
import { BusinessService } from './business.service';
import { ViewAsService } from './view-as.service';
import { PaymentService } from './payment.service';
import { UsersService } from './users.service';
import { LobbyService } from './lobby.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DateFilter } from '../models/date-filter.model';
import { dateString, MakeOptional } from "../../../../common/src/lib/services";
import { showSnackbar } from "../../../../common/src/lib/components/snackbar/snackbar.component";
import { ProposalCreate, ProposalsService } from "./proposals.service";
import { LeadsService } from "./leads.service";
import { Job, JobStatus, JobTileData, jobTileData, JobTimeRange } from '../models/jobs.model';
import { DynamicListComponent, DynamicListEvent, Predicate } from '../components/dynamic-list/dynamic-list.component';
import { JobTileComponent } from '../components/job-tile/job-tile.component';
import { TimeRange } from 'projects/common/src/lib/models/time-range.model';
import moment from 'moment';
import { RealtimePostgresChangesPayload } from '@supabase/supabase-js';
import { Workflow } from '../models/workflow.model';
import {
    Estimate,
    EstimateStatus,
    EstimateTileData,
    estimateTileData,
    EstimateTimeRange
} from '../models/estimate.model';
import { EstimateTileComponent } from '../components/estimate-tile/estimate-tile.component';
import { Availability, AvailabilityTileData } from '../models/availability.model';
import { AvailabilityTileComponent } from '../components/availability-tile/availability-tile.component';
import { AvailabilityService } from './availability.service';
import { Note } from "../models/note.model";
import { Router } from "@angular/router";
import {
    ConfirmationDialog
} from "../../../../common/src/lib/modals/confirmation-dialog/confirmation-dialog.component";
import { ModalBehavior, ModalsService } from "../../../../common/src/lib/services/modals.service";
import { TimetableDialogComponent } from "../modals/timetable-dialog/timetable-dialog.component";
import { SupabaseService } from "./supabase.service";
import { PaginationOptions } from "./pagination.service";
import { ScheduleItem } from "../models/schedule.model";
import { clientRestoreFromDocument } from "../../../../common/src/lib/models/client-transform";
import { RequestInvitationFormData } from "../modals/request-invitation/request-invitation.model";

export type ScheduleTileData = EstimateTileData | JobTileData | AvailabilityTileData;

export function sortSchedule(scheduleType: ScheduleTab) {
    return (source$: Observable<BasicEvent[] | null>) => {
        return source$.pipe(
            map(schedule => {
                if (!schedule)
                    return null;
                schedule.sort((a, b) => a.startTime.getTime() - b.startTime.getTime());
                return schedule;
            })
        ) as Observable<BasicEvent[] | null>;
    }
}

export function filterEventsByStatus<T extends ClientVisitEvent | MultipleAssignees<ClientVisitEvent>>(sort: (a: T, b: T) => number) {
    return function (source$: Observable<[T[] | null, EventStatusFilter | null]>): Observable<T[] | null> {
        return source$.pipe(
            map(([events, filterStatus]) => {
                if (!events)
                    return null;
                if (!filterStatus) 
                    return events.sort(sort);
                let filteredEvents = events;
                if (filterStatus !== 'All') {
                    filteredEvents = events.filter((event) => event.submitStatus === filterStatus);
                }
                return filteredEvents.sort(sort);
            }),
        );
    }
}
export function scheduleToList(schedule: ScheduleItem[]) {
    return schedule.map(item => {
        let document: Partial<EstimateTileData> | Partial<JobTileData> | Availability;
        if (item.itemType === 'estimate') {
            document = {
                docType: 'Estimate',
                id: item.id,
                businessName: item.businessName,
                firstName: item.firstName,
                lastName: item.lastName,
                startTime :item.startTime,
                endTime: item.endTime,
                jobType: item.jobType,
                ownerFirstName: item.ownerFirstName,
                ownerLastName: item.ownerLastName,
                rangeId: item.rangeId,
                phoneNumber: item.phoneNumber,
                workflowId: item.workflowId,
                status: item.status as EstimateStatus,
            };
        } else if (item.itemType === 'job') {
            document = {
                docType: 'Job',
                id: item.id,
                businessName: item.businessName,
                firstName: item.firstName,
                lastName: item.lastName,
                startTime: item.startTime,
                endTime: item.endTime,
                jobType: item.jobType,
                rangeId: item.rangeId,
                phoneNumber: item.phoneNumber,
                workflowId: item.workflowId,
                status: item.status as JobStatus,
                users: item.users,
            };
        } else {
            document = {
                docType: 'Personal',
                id: item.id,
                userId: item.userId,
                firstName: item.userFirstName,
                lastName: item.userLastName,
                startTime: item.startTime,
                endTime: item.endTime,
                available: item.available,
                description: item.description,
            };
        }
        return document;
    });
}

export const eventStatusFilters = ['All', 'Pending', 'Canceled'] as const;
type EventStatusFilter = typeof eventStatusFilters[number];

@Injectable({
    providedIn: 'root'
})
export class ScheduleService {

    protected _type: any = '';
    itemsListFilter!: DateFilter | null;
    calendarViewFilter!: DateFilter | null;

    dateFilter$ = this.lobbyService.selectedDateRange$;
    filterEstimateStatusSubject = new BehaviorSubject<Exclude<EventStatusFilter, 'Pending'>>('All');
    filterEstimateStatus$ = this.filterEstimateStatusSubject.asObservable();
    filterJobStatusSubject = new BehaviorSubject<EventStatusFilter>('All');
    filterJobStatus$ = this.filterJobStatusSubject.asObservable();

    private isLeadsViewSubject = new BehaviorSubject<boolean>(false);

    isLeadsView$ = this.isLeadsViewSubject.asObservable();

    setLeadsView(isLeadsView: boolean) {
        this.isLeadsViewSubject.next(isLeadsView);
    }

    private changeCalendarViewSubject = new Subject<CalendarView>();

    view$ = this.changeCalendarViewSubject.asObservable();
    showCalendarSubject = new BehaviorSubject(false);

    currentUser$ = this.usersService.currentUser$;

    setNewCalendarView(view: CalendarView) {
        if (view) {
            this.changeCalendarViewSubject.next(view);
        }
    }

    constructor(
        private leadsService: LeadsService,
        private estimatesService: EstimatesService,
        private jobsService: JobsService,
        private timetableService: TimetableService,
        private availabilityService: AvailabilityService,
        private proposalsService: ProposalsService,
        private notesService: NotesService,
        protected businessService: BusinessService,
        protected viewAsService: ViewAsService,
        protected paymentService: PaymentService,
        protected usersService: UsersService,
        protected lobbyService: LobbyService,
        protected supabaseService: SupabaseService,
        protected snackbar: MatSnackBar,
        protected router: Router,
        protected modalsService: ModalsService,
    ) {}

    allScheduleObservable(options: PaginationOptions, pref = '') {
        return this.businessService.selectedBusiness$.pipe(
            // TODO: This can be improved by adding the changes handler to handle each change separately and not to pull the list again.
            switchMap(business => {
                return this.supabaseService.rpc<ScheduleItem[]>({
                    cName: pref + '_all_schedule_',
                    schema: business.businessId,
                    fn: 'get_schedule',
                    tables: [
                        { table: 'estimate' },
                        { table: 'estimate_client' },
                        { table: 'estimate_range' },
                        { table: 'job' },
                        { table: 'job_client' },
                        { table: 'job_range' },
                        { table: 'job_user' },
                        { table: 'workflow' },
                        { table: 'unavailability' },
                    ],
                    options
                });
            }),
            map(scheduleItems => {
                return scheduleToList(scheduleItems);
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    async createLead(data: Required<RequestInvitationFormData>) {
        const assignees = data.assignees;
        if(assignees.length === 0) {
            const currentUser = await this.currentUser$.pipe(take(1)).toPromise();
            assignees.push(currentUser);
        }
        const notes = this.notesService.getLocalNotes();
        const imagePromises = this.notesService.prepareImagesForUpload(notes);
        await Promise.all([
            ...imagePromises,
            this.leadsService.createLead({
                inOwnerId: assignees[0].id,
                inClient: data.client as MakeOptional<Client, 'salesTaxPercentage' | 'email'>,
                inJobType: data.jobType,
                inNotes: notes as Note[]
            })
        ]);
        showSnackbar(this.snackbar, {
            message: "Lead assigned"
        });
    }

    async createEstimate(data: Required<RequestInvitationFormData>, fromWorkflowId?: number) {
        const currentUser = await this.currentUser$.pipe(take(1)).toPromise();
        const assignees = data.assignees;
        let assigneeId = assignees.length === 0 ? null : assignees[0].id;
        if(assigneeId === currentUser.id) {
            assigneeId = null;
        }

        const promises: Promise<any>[] = [];
        const createData: EstimateCreate = {
            inOwnerId: currentUser.id,
            inClient: data.client as MakeOptional<Client, 'salesTaxPercentage'>,
            inRanges: data.ranges,
            inAssignee: assigneeId,
        }

        if(fromWorkflowId !== undefined) {
            createData.inWorkflowId = fromWorkflowId;
        } else {
            const notes = this.notesService.getLocalNotes();
            const imagePromises = this.notesService.prepareImagesForUpload(notes);
            promises.push(...imagePromises);
            createData.inJobType = data.jobType
            createData.inNotes = notes as Note[];
        }

        promises.push(this.estimatesService.createEstimate(createData))
        await Promise.all(promises);
    }

    async createProposal(data: Required<RequestInvitationFormData>, fromWorkflowId?: number) {
        const currentUser = await this.currentUser$.pipe(take(1)).toPromise();

        const promises: Promise<any>[] = [];
        const createData: ProposalCreate = {
            inCreatedBy: currentUser.id,
            inClient: data.client as Client,
        }

        if(fromWorkflowId !== undefined) {
            createData.inWorkflowId = fromWorkflowId;
        } else {
            const notes = this.notesService.getLocalNotes();
            const imagePromises = this.notesService.prepareImagesForUpload(notes);
            promises.push(...imagePromises);
            createData.inJobType = data.jobType
            createData.inNotes = notes as Note[];
        }

        promises.unshift(this.proposalsService.createProposal(createData))
        const [ res ] = await Promise.all(promises);

        this.router.navigateByUrl(`proposals/${res.workflowId}/${res.proposalId}`);
    }

    async createJob(data: Required<RequestInvitationFormData>, fromWorkflowId?: number) {
        const currentUser = await this.currentUser$.pipe(take(1)).toPromise();
        let assignees: number[] | null = data.assignees ? data.assignees.map(user => user.id) : null;
        if(assignees && (assignees.length === 0 || assignees.length === 1 && assignees[0] === currentUser.id))
            assignees = null;

        const promises: Promise<any>[] = [];
        const createData: JobCreate = {
            inCreatedBy: currentUser.id,
            inClient: data.client as MakeOptional<Client, 'salesTaxPercentage'>,
            inRanges: data.ranges,
            inJobUserIds: assignees,
        }

        if(fromWorkflowId !== undefined) {
            createData.inWorkflowId = fromWorkflowId;
        } else {
            const notes = this.notesService.getLocalNotes();
            const imagePromises = this.notesService.prepareImagesForUpload(notes);
            promises.push(...imagePromises);
            createData.inJobType = data.jobType
            createData.inNotes = notes as Note[];
        }

        promises.push(this.jobsService.createJob(createData))
        await Promise.all(promises);
    }

    async createDocument(data: Required<RequestInvitationFormData>, fromWorkflowId?: number) {
        try {
            switch (data.dialogType) {
                case "Lead":
                    await this.createLead(data);
                    break;
                case "Estimate":
                    await this.createEstimate(data, fromWorkflowId);
                    break;
                case "Proposal":
                    await this.createProposal(data, fromWorkflowId);
                    break;
                case "Job":
                    await this.createJob(data, fromWorkflowId);
            }
            return null;
        } catch (e) {
            //TODO: Check if there are any errors that are needed to be provided here
            return {};
        }
    }

//---------------------- Tile click handlers -----------------------

    onItemEvent(event: DynamicListEvent<ScheduleTileData>, fromAll = false) {
        switch (event.value.docType) {
            case "Estimate":
                this.handleEstimateEvent(event as DynamicListEvent<EstimateTileData>, fromAll);
                break;
            case "Job":
                this.handleJobEvent(event as DynamicListEvent<JobTileData>, fromAll);
                break;
            case "Personal":
                this.handleAvailabilityEvent(event as DynamicListEvent<AvailabilityTileData>);
                break;
        }
    }

    handleEstimateEvent(event: DynamicListEvent<EstimateTileData>, fromAll = false) {
        switch(event.eventEmitterName) {
            case 'tileClick':
                const queryParams = fromAll ? {} : { from : 'estimates' };
                this.router.navigate(['/estimates', event.value.workflowId, event.value.id], { queryParams });
                break;
            case 'deleteEstimate':
                this.deleteEstimate(event.value.id, event.value.workflowId);
                break;
        }
    }

    async deleteEstimate(estimateId: number, workflowId: number) {
        const notes = await this.notesService.notesObservable(workflowId).pipe(take(1)).toPromise();
        const estimate = await this.estimatesService.getEstimate(estimateId);
        const restoreData: EstimateRestore = {
            inId: estimate.id,
            inCreatedAt: estimate.createdAt,
            inClient: await clientRestoreFromDocument(estimate),
            inOwnerId: estimate.ownerId,
            inRanges: estimate.ranges,
            inStatus: estimate.status,
            inDenied: estimate.denied,
            inWorkflowId: estimate.workflowId,
            inJobType: estimate.jobType,
            inNotes: notes!
        };
        if(estimate.assigneeId)
            restoreData.inAssignee = estimate.assigneeId;

        this.modalsService.open(ConfirmationDialog, {
            behavior: ModalBehavior.Dialog,
            disableClose: true,
            data: {
                title: "Delete",
                message: "Are you sure you want to delete this estimate?",
                actionTitle: "Delete",
                actionColor: 'warn',
                action: async () => {
                    await this.estimatesService.deleteEstimate(estimateId);
                    showSnackbar(this.snackbar, {
                        message: 'Estimate deleted',
                        duration: 10000,
                        actionText: 'Undo',
                        action: async () => {
                            await this.estimatesService.restoreEstimate(restoreData);
                        }
                    });
                }
            }
        });
    }

    handleJobEvent(event: DynamicListEvent<JobTileData>, fromAll = false) {
        switch(event.eventEmitterName) {
            case 'tileClick':
                const queryParams = fromAll ? {} : { from : 'jobs' };
                this.router.navigate(['jobs/', event.value.workflowId, event.value.id], { queryParams });
                break;
            case 'deleteJob':
                this.deleteJob(event.value.id, event.value.workflowId);
                break;
        }
    }

    async deleteJob(jobId: number, workflowId: number) {
        const notes = await this.notesService.notesObservable(workflowId).pipe(take(1)).toPromise();
        const job = await this.jobsService.getJob(jobId);
        const restoreData: JobRestore = {
            inId: job.id,
            inCreatedAt: job.createdAt,
            inCreatedBy: job.createdBy,
            inClient: await clientRestoreFromDocument(job),
            inRanges: job.ranges,
            inStatus: job.status,
            inJobUsers: job.users.map(user => ({
                id: user.id,
                assigneeUserId: user.userId,
                assignedByUserId: user.assignedBy,
                acceptanceStatus: user.acceptanceStatus
            }) as JobUserRestore),
            inWorkflowId: job.workflowId,
            inJobType: job.jobType,
            inNotes: notes!
        };

        if(job.statusStoreForCancel)
            restoreData.inStatusStoreForCancel = job.statusStoreForCancel;

        this.modalsService.open(ConfirmationDialog, {
            behavior: ModalBehavior.Dialog,
            disableClose: true,
            data: {
                title: "Delete",
                message: "Are you sure you want to delete this job?",
                actionTitle: "Delete",
                actionColor: 'warn',
                action: async () => {
                    await this.jobsService.deleteJob(jobId);
                    showSnackbar(this.snackbar, {
                        message: 'Job deleted',
                        duration: 10000,
                        actionText: 'Undo',
                        action: async () => {
                            return this.jobsService.restoreJob(restoreData);
                        }
                    });
                }
            }
        });
    }

    handleAvailabilityEvent(event: DynamicListEvent<AvailabilityTileData>) {
        switch(event.eventEmitterName) {
            case 'tileClick':
                const date = event.value.startTime
                this.openAvailabilityDialogWithSelectedDate(date);
                break;
        }
    }

    openAvailabilityDialogWithSelectedDate(date: Date) {
        this.modalsService.open(TimetableDialogComponent, {
            behavior: ModalBehavior.Dialog,
            disableClose: true,
            autoFocus: false,
            restoreFocus: false,
            data: {
                setUnavailableTab: true,
                selectedDate: date
            }
        })
    }

//---------------------- Realtime events handlers -----------------------

    private async jobFulfillsFilters(job: Job, rangeId: number | null = null, currentStatusFilter: EventStatusFilter, handleDateFilter = false) {
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        const dateFilter = await this.dateFilter$.pipe(take(1)).toPromise();
        const range = rangeId === null ? null : (job.ranges.find(r => r.rangeId === rangeId) ?? null);
        return  (
                currentStatusFilter === 'All' || 
                currentStatusFilter === 'Canceled' && job.status === 'canceled' || 
                currentStatusFilter === 'Pending' && job.status === 'pending'
            ) &&
            (!userIds || job.users.some(user => userIds.includes(user.userId) && ['accepted', 'looped_in'].includes(user.acceptanceStatus))) &&
            (!handleDateFilter || range === null || handleDateFilter && dateFilter && moment(range.startTime).isBetween(dateFilter.from, dateFilter.to, undefined, '[]'));
    }

    private async estimateFulfillsFilters(estimate: Estimate, rangeId: number | null = null, currentStatusFilter: Exclude<EventStatusFilter, 'Pending'>, handleDateFilter = false) {
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        const dateFilter = await this.dateFilter$.pipe(take(1)).toPromise();
        const range = rangeId === null ? null : (estimate.ranges.find(r => r.rangeId === rangeId) ?? null);
        return  (
                currentStatusFilter === 'All' || 
                currentStatusFilter === 'Canceled' && estimate.status === 'canceled'
            ) &&
            estimate.assigneeId === null &&
            (!userIds || userIds.includes(estimate.ownerId)) &&
            (!handleDateFilter || range === null || handleDateFilter && dateFilter && moment(range.startTime).isBetween(dateFilter.from, dateFilter.to, undefined, '[]'));
    }

    private async unavailableFulfillsFilters(unavailable: Availability, handleDateFilter = false) {
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        const dateFilter = await this.dateFilter$.pipe(take(1)).toPromise();
        return (!userIds || userIds.includes(unavailable.userId)) &&
            (!handleDateFilter || handleDateFilter && dateFilter && moment(unavailable.startTime).isBetween(dateFilter.from, dateFilter.to, undefined, '[]'));
    }

    private async insertJob(
        list: DynamicListComponent<JobTileComponent, any>, 
        job: Job, 
        firstHeaderRangeSubject: BehaviorSubject<TimeRange | null>,
        range: TimeRange | null = null, 
        isDateFilterList = false,
        showDocType = false
    ) {
        const insertRange = async (range: TimeRange) => {
            const rangeId = range.rangeId ?? range.id;
            const dateFilter = await this.dateFilter$.pipe(take(1)).toPromise();
            const lastComponent = list.getLastComponent();
            const firstComponent = list.getFirstComponent();
            if (
                list.includesItem((_, item) => 
                    item?.instance.document.docType === 'Job' && 
                    item?.instance.document.id === job.id &&
                    // !!item.instance.document.users.find(u => u.userId === user.userId) &&
                    item.instance.document.rangeId === rangeId
                ) ||
                isDateFilterList && dateFilter && !moment(range.startTime).isBetween(dateFilter.from, dateFilter.to, undefined, '[]') ||
                list.canLoadMoreBottom && lastComponent && range.startTime > lastComponent.instance.document.startTime || 
                list.canLoadMoreTop && firstComponent && range.startTime < firstComponent.instance.document.startTime
            )
                return;

            const now = new Date();
            list.insertItem(
                (prev, current) => {
                    if (!prev && range.startTime < current.instance.document.startTime)
                        return true;

                    return prev ? moment(range.startTime).isBetween(prev.instance.document.startTime, current.instance.document.startTime, undefined, '[]') : false;
                },
                {
                    componentClass: JobTileComponent,
                    header: dateString(range.startTime, range.endTime),
                    args: { document: jobTileData(job, rangeId!), showDocType }
                },
                range.startTime > now ? 'bottom' : 'top',
                range.startTime > now ? !list.canLoadMoreBottom : !list.canLoadMoreTop
            );
            if (range.startTime > new Date()) {
                if (!firstHeaderRangeSubject.value || range.startTime <= firstHeaderRangeSubject.value.startTime) {
                    firstHeaderRangeSubject.next({ startTime: range.startTime, endTime: range.endTime });
                }
            }
        }

        if (range === null) {
            for (const range of job.ranges)
                await insertRange(range);
            return;
        }
        await insertRange(range);
    }

    private async insertEstimate(
        list: DynamicListComponent<EstimateTileComponent, any>, 
        estimate: Estimate, 
        firstHeaderRangeSubject: BehaviorSubject<TimeRange | null>,
        range: TimeRange | null = null, 
        isDateFilterList = false,
        showDocType = false
    ) {
        const insertRange = async (range: TimeRange) => {
            const rangeId = range.rangeId ?? range.id;
            const dateFilter = await this.dateFilter$.pipe(take(1)).toPromise();
            const lastComponent = list.getLastComponent();
            const firstComponent = list.getFirstComponent();
            if (
                list.includesItem((_, item) =>
                    item?.instance.document.docType === 'Estimate' && 
                    item?.instance.document.id === estimate.id && 
                    item.instance.document.rangeId === rangeId
                ) ||
                isDateFilterList && dateFilter && !moment(range.startTime).isBetween(dateFilter.from, dateFilter.to, undefined, '[]') ||
                list.canLoadMoreBottom && lastComponent && range.startTime > lastComponent.instance.document.startTime || 
                list.canLoadMoreTop && firstComponent && range.startTime < firstComponent.instance.document.startTime
            )
                return;

            const now = new Date();
            list.insertItem(
                (prev, current) => {
                    if (!prev && range.startTime < current.instance.document.startTime)
                        return true;

                    return prev ? moment(range.startTime).isBetween(prev.instance.document.startTime, current.instance.document.startTime, undefined, '[]') : false;
                },
                {
                    componentClass: EstimateTileComponent,
                    header: dateString(range.startTime, range.endTime),
                    args: { document: estimateTileData(estimate, rangeId!), showDocType }
                },
                range.startTime > now ? 'bottom' : 'top',
                range.startTime > now ? !list.canLoadMoreBottom : !list.canLoadMoreTop
            );
            if (range.startTime > new Date()) {
                if (!firstHeaderRangeSubject.value || range.startTime <= firstHeaderRangeSubject.value.startTime) {
                    firstHeaderRangeSubject.next({ startTime: range.startTime, endTime: range.endTime });
                }
            }
        }
        
        if (range === null) {
            for (const range of estimate.ranges)
                await insertRange(range);
            return;
        }
        await insertRange(range);
    }

    private async insertUnavailable(
        list: DynamicListComponent<AvailabilityTileComponent, any>, 
        unavailable: Availability, 
        firstHeaderRangeSubject: BehaviorSubject<TimeRange | null>,
        isDateFilterList = false,
        showDocType = false
    ) {
        const dateFilter = await this.dateFilter$.pipe(take(1)).toPromise();
        const lastComponent = list.getLastComponent();
        const firstComponent = list.getFirstComponent();
        if (
            list.includesItem((_, item) => item?.instance.document.docType === 'Personal' && item.instance.document.id === unavailable.id) ||
            isDateFilterList && dateFilter && !moment(unavailable.startTime).isBetween(dateFilter.from, dateFilter.to, undefined, '[]') ||
            list.canLoadMoreBottom && lastComponent && unavailable.startTime > lastComponent.instance.document.startTime || 
            list.canLoadMoreTop && firstComponent && unavailable.startTime < firstComponent.instance.document.startTime
        )
            return;

        const now = new Date();
        list.insertItem(
            (prev, current) => {
                if (!prev && unavailable.startTime < current.instance.document.startTime)
                    return true;

                return prev ? moment(unavailable.startTime).isBetween(prev.instance.document.startTime, current.instance.document.startTime, undefined, '[]') : false;
            },
            {
                componentClass: AvailabilityTileComponent,
                header: dateString(unavailable.startTime, unavailable.endTime),
                args: { document: { ...unavailable, docType: 'Personal' }, showDocType }
            },
            unavailable.startTime > now ? 'bottom' : 'top',
            unavailable.startTime > now ? !list.canLoadMoreBottom : !list.canLoadMoreTop
        );
        if (unavailable.startTime > new Date()) {
            if (!firstHeaderRangeSubject.value || unavailable.startTime <= firstHeaderRangeSubject.value.startTime) {
                firstHeaderRangeSubject.next({ startTime: unavailable.startTime, endTime: unavailable.endTime });
            }
        }
    }

    private commonRemoveEvent(
        list: DynamicListComponent<JobTileComponent | EstimateTileComponent | AvailabilityTileComponent>, 
        predicate: Predicate<any>, 
        firstHeaderRangeSubject: BehaviorSubject<TimeRange | null>, 
        docType: 'Job' | 'Estimate' | 'Personal'
    ) {
        const result = list.removeItem('auto', (v, i) => v.instance.document.docType === docType ? predicate(v, i) : false);
        if (!firstHeaderRangeSubject.value)
            return result;
        const item = list.getItem(item => item.instance.document.startTime > new Date()) as JobTileComponent | EstimateTileComponent;
        firstHeaderRangeSubject.next(item ? { startTime: item.document.startTime, endTime: item.document.endTime } : null);
        return result;
    }

    private commonRemoveEvents(
        list: DynamicListComponent<JobTileComponent | EstimateTileComponent | AvailabilityTileComponent>, 
        predicate: Predicate<any>,
        firstHeaderRangeSubject: BehaviorSubject<TimeRange | null>, 
        docType: 'Job' | 'Estimate' | 'Personal'
    ) {
        let found = false;
        do {
            found = this.commonRemoveEvent(list, predicate, firstHeaderRangeSubject, docType);
        } while (found);
    }

    private commonHandleUpdate(
        list: DynamicListComponent<JobTileComponent | EstimateTileComponent | AvailabilityTileComponent>, 
        docType: ('Job' | 'Estimate' | 'Personal') | ('Job' | 'Estimate' | 'Personal')[],
        data: any,
        dataIdProp: string,
        tileIdProp = 'id'
    ) {
        list.updateItems(component => {
            const doc = component.instance.document;
            if (Array.isArray(docType) && !docType.includes(doc.docType) || !Array.isArray(docType) && doc.docType !== docType)
                return false;

            if ((doc as any)[tileIdProp] === data[dataIdProp]) {
                for (const prop in doc) {
                    if (prop === 'type' && !['business', 'personal'].includes(data[prop]))
                        continue;
                    if (prop in data && !(['id', 'createdAt'].includes(prop)))
                        (doc as any)[prop] = data[prop];
                }
                component.instance.refreshData();
            }
            return false;
        });
    }

    private async commonHandleJobRangeChanges(
        list: DynamicListComponent<JobTileComponent>, 
        changes: RealtimePostgresChangesPayload<JobTimeRange>, 
        firstHeaderRangeSubject: BehaviorSubject<TimeRange | null>, 
        currentStatusFilter: EventStatusFilter,
        handleDateFilter = false,
        showDocType = false
    ) {
        if (changes.eventType === 'DELETE') {
            this.commonRemoveEvents(list, item => item.instance.document.rangeId === changes.old.id, firstHeaderRangeSubject, 'Job');
            return;
        }
        const range = changes.new;
        const job = await this.jobsService.getJob(range.jobId);

        if (changes.eventType === 'UPDATE') {
            this.commonRemoveEvent(list, item => item.instance.document.rangeId === range.id, firstHeaderRangeSubject, 'Job');
        }
        if (await this.jobFulfillsFilters(job, range.id, currentStatusFilter, handleDateFilter)) {
            await this.insertJob(list, job, firstHeaderRangeSubject, range, handleDateFilter, showDocType);
        }
    }

    private async commonHandleEstimateRangeChanges(
        list: DynamicListComponent<EstimateTileComponent>, 
        changes: RealtimePostgresChangesPayload<EstimateTimeRange>, 
        firstHeaderRangeSubject: BehaviorSubject<TimeRange | null>, 
        currentStatusFilter: Exclude<EventStatusFilter, 'Pending'>,
        handleDateFilter = false,
        showDocType = false
    ) {
        if (changes.eventType === 'DELETE') {
            this.commonRemoveEvent(list, item => item.instance.document.rangeId === changes.old.id, firstHeaderRangeSubject, 'Estimate');
            return;
        }
        const range = changes.new;
        const estimate = await this.estimatesService.getEstimate(range.estimateId);

        if (changes.eventType === 'UPDATE') {
            this.commonRemoveEvent(list, item => item.instance.document.rangeId === range.id, firstHeaderRangeSubject, 'Estimate');
        }
        if (await this.estimateFulfillsFilters(estimate, range.id, currentStatusFilter, handleDateFilter)) {
            await this.insertEstimate(list, estimate, firstHeaderRangeSubject, range, handleDateFilter, showDocType);
        }
    }

    private async commonHandleJobChanges(
        list: DynamicListComponent<JobTileComponent>, 
        changes: RealtimePostgresChangesPayload<Job>, 
        firstHeaderRangeSubject: BehaviorSubject<TimeRange | null>, 
        currentStatusFilter: EventStatusFilter,
        handleDateFilter = false,
        showDocType = false
    ) {
        const jobId = (changes.new as Job).id;
        const job = await this.jobsService.getJob(jobId);

        const fulfillsFilters = await this.jobFulfillsFilters(job, null, currentStatusFilter, handleDateFilter);
        if (!fulfillsFilters) {
            this.commonRemoveEvents(list, item => item?.instance.document.id === jobId, firstHeaderRangeSubject, 'Job');
        } else if (fulfillsFilters && !list.includesItem((_, item) => item?.instance.document.id === jobId)) {
            await this.insertJob(list, job, firstHeaderRangeSubject, null, handleDateFilter, showDocType);
        }
        this.commonHandleUpdate(list, 'Job', changes.new, 'id');
    }

    private async commonHandleEstimateChanges(
        list: DynamicListComponent<EstimateTileComponent>, 
        changes: RealtimePostgresChangesPayload<Estimate>, 
        firstHeaderRangeSubject: BehaviorSubject<TimeRange | null>, 
        currentStatusFilter: Exclude<EventStatusFilter, 'Pending'>,
        handleDateFilter = false,
        showDocType = false
    ) {
        const estimateId = (changes.new as Estimate).id;
        const estimate = await this.estimatesService.getEstimate(estimateId);
        const fulfillsFilters = await this.estimateFulfillsFilters(estimate, null, currentStatusFilter, handleDateFilter);

        if (!fulfillsFilters) {
            this.commonRemoveEvents(list, item => item?.instance.document.id === estimateId, firstHeaderRangeSubject, 'Estimate');
            return;
        } else if (fulfillsFilters && !list.includesItem((_, item) => item?.instance.document.id === estimateId)) {
            for (const range of estimate.ranges) {
                await this.insertEstimate(list, estimate, firstHeaderRangeSubject, range, handleDateFilter, showDocType);
            }
            return;
        }
        this.commonHandleUpdate(list, 'Estimate', changes.new, 'id');
    }

    private async commonHandleJobUserChanges(
        list: DynamicListComponent<JobTileComponent>, 
        changes: RealtimePostgresChangesPayload<any>, 
        firstHeaderRangeSubject: BehaviorSubject<TimeRange | null>, 
        currentStatusFilter: EventStatusFilter,
        handleDateFilter = false,
        showDocType = false
    ) {
        const workflowId = changes.new.workflowId;
        const acceptanceStatus = changes.new.acceptanceStatus;
        if (
            (changes.eventType === 'INSERT' || changes.eventType === 'UPDATE') && 
            (acceptanceStatus === 'accepted' || acceptanceStatus === 'looped_in') && 
            !list.includesItem((_, item) => item?.instance.document.workflowId === workflowId)
        ) {
            const job = await this.jobsService.getJob(changes.new.jobId);
            if (await this.jobFulfillsFilters(job, null, currentStatusFilter, handleDateFilter)) {
                await this.insertJob(list, job, firstHeaderRangeSubject, null, handleDateFilter, showDocType);
            }
        } else if (changes.eventType === 'DELETE') {
            this.commonRemoveEvents(list, item => item.instance.document.jobUserId === changes.old.id, firstHeaderRangeSubject, 'Job');
        }
    }
    
    private async commonHandleUnavailabilityChanges(
        list: DynamicListComponent<AvailabilityTileComponent>, 
        changes: RealtimePostgresChangesPayload<Availability>, 
        firstHeaderRangeSubject: BehaviorSubject<TimeRange | null>, 
        handleDateFilter = false, 
        showDocType = false
    ) {
        if (changes.eventType === 'DELETE') {
            this.commonRemoveEvent(list, item => item.instance.document.id === changes.old.id, firstHeaderRangeSubject, 'Personal');
            return;
        }
        const unavailable = changes.new;

        if (changes.eventType === 'UPDATE') {
            if (list.includesItem((_, item) => {
                    const doc = item.instance.document;
                    return doc.docType === 'Personal' &&
                        doc.id === changes.new.id && 
                        moment(doc.startTime).isSame(changes.new.startTime) &&
                        moment(doc.endTime).isSame(changes.new.endTime);
                })
            ) {
                this.commonHandleUpdate(list, 'Personal', changes.new, 'id');
                return;
            }
            this.commonRemoveEvent(list, item => item.instance.document.id === unavailable.id, firstHeaderRangeSubject, 'Personal');
        }
        if (await this.unavailableFulfillsFilters(unavailable, handleDateFilter)) {
            const new_unavailable = await this.availabilityService.getUnavailability(unavailable.id);
            await this.insertUnavailable(list, new_unavailable, firstHeaderRangeSubject, handleDateFilter, showDocType);
        }
    }

    handleJobChanges(
        list: DynamicListComponent<JobTileComponent>, 
        changes: RealtimePostgresChangesPayload<Job>, 
        firstHeaderRangeSubject: BehaviorSubject<TimeRange | null>,
        currentStatusFilter: EventStatusFilter = 'All',
        showDocType = false
    ) {
        this.commonHandleJobChanges(list, changes, firstHeaderRangeSubject, currentStatusFilter, false, showDocType);
    }

    handleJobClientChanges(
        list: DynamicListComponent<JobTileComponent>, 
        changes: RealtimePostgresChangesPayload<Client>
    ) {
        this.commonHandleUpdate(list, 'Job', changes.new, 'jobId');
    }

    handleJobRangeChanges(
        list: DynamicListComponent<JobTileComponent>, 
        changes: RealtimePostgresChangesPayload<JobTimeRange>,
        firstHeaderRangeSubject: BehaviorSubject<TimeRange | null>,
        currentStatusFilter: EventStatusFilter = 'All',
        showDocType = false
    ) {
        this.commonHandleJobRangeChanges(list, changes, firstHeaderRangeSubject, currentStatusFilter, false, showDocType);
    }

    handleWorkflowChanges(
        list: DynamicListComponent<JobTileComponent | EstimateTileComponent>,
        changes: RealtimePostgresChangesPayload<Workflow>
    ) {
        this.commonHandleUpdate(list, ['Estimate', 'Job'], changes.new, 'id', 'workflowId');
    }

    handleJobUserChanges(
        list: DynamicListComponent<JobTileComponent>, 
        changes: RealtimePostgresChangesPayload<any>,
        firstHeaderRangeSubject: BehaviorSubject<TimeRange | null>, 
        currentStatusFilter: EventStatusFilter = 'All',
        showDocType = false
    ) {
        this.commonHandleJobUserChanges(list, changes, firstHeaderRangeSubject, currentStatusFilter, false, showDocType);
    }

    async handleDateFilterJobChanges(
        list: DynamicListComponent<JobTileComponent>, 
        changes: RealtimePostgresChangesPayload<Job>, 
        firstHeaderRangeSubject: BehaviorSubject<TimeRange | null>,
        currentStatusFilter: EventStatusFilter = 'All',
        showDocType = false
    ) {
        const dateFilter = await this.dateFilter$.pipe(take(1)).toPromise();
        if (!dateFilter)
            return;
        this.commonHandleJobChanges(list, changes, firstHeaderRangeSubject, currentStatusFilter, true, showDocType);
    }

    async handleDateFilterJobClientChanges(
        list: DynamicListComponent<JobTileComponent>,
        changes: RealtimePostgresChangesPayload<Client>
    ) {
        const dateFilter = await this.dateFilter$.pipe(take(1)).toPromise();
        if (!dateFilter)
            return;
        this.commonHandleUpdate(list, 'Job', changes.new, 'jobId');
    }

    async handleDateFilterJobRangeChanges(
        list: DynamicListComponent<JobTileComponent>, 
        changes: RealtimePostgresChangesPayload<JobTimeRange>,
        firstHeaderRangeSubject: BehaviorSubject<TimeRange | null>,
        currentStatusFilter: EventStatusFilter = 'All',
        showDocType = false
    ) {
        const dateFilter = await this.dateFilter$.pipe(take(1)).toPromise();
        if (!dateFilter || changes.eventType === 'INSERT' && !moment((changes.new as any).startTime).isBetween(dateFilter.from, dateFilter.to, undefined, '[]'))
            return;
        this.commonHandleJobRangeChanges(list, changes, firstHeaderRangeSubject, currentStatusFilter, true, showDocType);
    }

    async handleDateFilterWorkflowChanges(
        list: DynamicListComponent<JobTileComponent | EstimateTileComponent>,
        changes: RealtimePostgresChangesPayload<Workflow>
    ) {
        const dateFilter = await this.dateFilter$.pipe(take(1)).toPromise();
        if (!dateFilter)
            return;
        this.commonHandleUpdate(list, ['Estimate', 'Job'], changes.new, 'workflowId');
    }

    async handleDateFilterJobUserChanges(
        list: DynamicListComponent<JobTileComponent>, 
        changes: RealtimePostgresChangesPayload<any>,
        firstHeaderRangeSubject: BehaviorSubject<TimeRange | null>, 
        currentStatusFilter: EventStatusFilter = 'All',
        showDocType = false
    ) {
        const dateFilter = await this.dateFilter$.pipe(take(1)).toPromise();
        if (!dateFilter)
            return;
        this.commonHandleJobUserChanges(list, changes, firstHeaderRangeSubject, currentStatusFilter, true, showDocType);
    }

    handleEstimateChanges(
        list: DynamicListComponent<EstimateTileComponent>, 
        changes: RealtimePostgresChangesPayload<Estimate>, 
        firstHeaderRangeSubject: BehaviorSubject<TimeRange | null>,
        currentStatusFilter: Exclude<EventStatusFilter, 'Pending'> = 'All',
        showDocType = false
    ) {
        this.commonHandleEstimateChanges(list, changes, firstHeaderRangeSubject, currentStatusFilter, false, showDocType);
    }

    handleEstimateClientChanges(
        list: DynamicListComponent<EstimateTileComponent>, 
        changes: RealtimePostgresChangesPayload<Client>
    ) {
        this.commonHandleUpdate(list, 'Estimate', changes.new, 'estimateId');
    }

    handleEstimateRangeChanges(
        list: DynamicListComponent<EstimateTileComponent>, 
        changes: RealtimePostgresChangesPayload<EstimateTimeRange>,
        firstHeaderRangeSubject: BehaviorSubject<TimeRange | null>,
        currentStatusFilter: Exclude<EventStatusFilter, 'Pending'> = 'All',
        showDocType = false
    ) {
        this.commonHandleEstimateRangeChanges(list, changes, firstHeaderRangeSubject, currentStatusFilter, false, showDocType);
    }

    async handleDateFilterEstimateChanges(
        list: DynamicListComponent<EstimateTileComponent>, 
        changes: RealtimePostgresChangesPayload<Estimate>, 
        firstHeaderRangeSubject: BehaviorSubject<TimeRange | null>,
        currentStatusFilter: Exclude<EventStatusFilter, 'Pending'> = 'All',
        showDocType = false
    ) {
        const dateFilter = await this.dateFilter$.pipe(take(1)).toPromise();
        if (!dateFilter)
            return;
        this.commonHandleEstimateChanges(list, changes, firstHeaderRangeSubject, currentStatusFilter, true, showDocType);
    }

    async handleDateFilterEstimateClientChanges(
        list: DynamicListComponent<EstimateTileComponent>,
        changes: RealtimePostgresChangesPayload<Client>
    ) {
        const dateFilter = await this.dateFilter$.pipe(take(1)).toPromise();
        if (!dateFilter)
            return;
        this.commonHandleUpdate(list, 'Estimate', changes.new, 'estimateId');
    }

    async handleDateFilterEstimateRangeChanges(
        list: DynamicListComponent<EstimateTileComponent>, 
        changes: RealtimePostgresChangesPayload<EstimateTimeRange>,
        firstHeaderRangeSubject: BehaviorSubject<TimeRange | null>,
        currentStatusFilter: Exclude<EventStatusFilter, 'Pending'> = 'All',
        showDocType = false
    ) {
        const dateFilter = await this.dateFilter$.pipe(take(1)).toPromise();
        if (!dateFilter || changes.eventType === 'INSERT' && !moment((changes.new as any).startTime).isBetween(dateFilter.from, dateFilter.to, undefined, '[]'))
            return;
        this.commonHandleEstimateRangeChanges(list, changes, firstHeaderRangeSubject, currentStatusFilter, true, showDocType);
    }

    handleUnavailabilityChanges(
        list: DynamicListComponent<AvailabilityTileComponent>, 
        changes: RealtimePostgresChangesPayload<Availability>, 
        firstHeaderRangeSubject: BehaviorSubject<TimeRange | null>,
        showDocType = false
    ) {
        this.commonHandleUnavailabilityChanges(list, changes, firstHeaderRangeSubject, false, showDocType);
    }

    async handleDateFilterUnavailabilityChanges(
        list: DynamicListComponent<AvailabilityTileComponent>, 
        changes: RealtimePostgresChangesPayload<Availability>, 
        firstHeaderRangeSubject: BehaviorSubject<TimeRange | null>,
        showDocType = false
    ) {
        const dateFilter = await this.dateFilter$.pipe(take(1)).toPromise();
        if (!dateFilter || changes.eventType === 'INSERT' && !moment((changes.new as any).startTime).isBetween(dateFilter.from, dateFilter.to, undefined, '[]'))
            return;
        this.commonHandleUnavailabilityChanges(list, changes, firstHeaderRangeSubject, true, showDocType);
    }

}
