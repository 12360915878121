<div class="dialog wrapper">
    <div class="content">
        <h3 class="text-center">{{ data.title }}</h3>
        <p class="subtitle text-center">{{ data.message }} </p>
        <form class="d-flex flex-column align-items-stretch" [formGroup]="form" *ngIf="(providers$ | async)!.includes('Email')">

            <div class="d-flex justify-content-between">
                <h4>Password</h4>
                <h4 class="simple-text-button" (click)="openForgotPassword()" data-cy="forgotPasswordButton">Forgot password?</h4>
            </div>
            <mat-form-field appearance="outline" appFormFieldFilled>
                <input matInput #passwordInput
                    data-cy="passwordInput"
                    formControlName="password"
                    [type]="showPassword ? 'text' : 'password'"
                    placeholder="Enter your password"
                    enterkeyhint="go"
                />
                <img *ngIf="form.get('password')!.value.length > 0" matSuffix [src]="'../../../assets/icons/password-' + (showPassword ? 'hide' : 'show') +'.svg'" (click)="showPassword = !showPassword">
                <mat-error *ngIf="form.get('password')!.hasError('required') && form.get('password')!.touched">
                    Password is required
                </mat-error>
                <mat-error *ngIf="form.get('password')!.hasError('password') && form.get('password')!.touched">
                    Invalid password. Re-enter
                </mat-error>
            </mat-form-field>

            <loader-button [loading]="loading" text="Confirm" (onClick)="reauthenticate()" data-cy="confirmButton"></loader-button>
            <button mat-stroked-button (click)="close()" data-cy="cancelButton">Cancel</button>
        </form>

        <app-providers-row *ngIf="(providers$ | async)!.includes('Google')" type="reauthenticate" (onStart)="loading = true" (onSuccess)="onReauthentication()" (onFailed)="loading = false"></app-providers-row>

    </div>
    <app-icon class="close no-bg se-icon-button" (click)="close()" src="close" style="scale: 1.2"></app-icon>
</div>