import { ChatService } from "../../services/chat.service";
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject } from "rxjs";
import { Chat } from "../../models/chat.model";
import moment from "moment";
import { debounceTime, filter } from "rxjs/operators";

const scrollThreshold = 100;

@Component({
  selector: 'app-chats-list',
  templateUrl: './chats-list.component.html',
  styleUrls: ['./chats-list.component.scss']
})
export class ChatsListComponent implements OnInit {
  @Output() openChat = new EventEmitter<Chat>();
  @ViewChild('chatListView') chatListView!: ElementRef<HTMLElement>;

  protected readonly moment = moment;
  private prevScroll = 0;
  private scrollDebouncer = new Subject<void>();

  unseenChatsCount$!: Observable<number>;
  chatList = this.chatService.chatList;
  scrollPos = 0;

  constructor(
      private chatService: ChatService
  ) {
    this.scrollDebouncer
    .pipe(
      debounceTime(100),
      filter(_ => !this.chatService.loadingChats)
    )
    .subscribe(() => this.loadMore());
  }

  async ngOnInit() {
    this.unseenChatsCount$ = this.chatService.unseenChatsCount$;
    this.chatService.loadingChats = true;
    await this.chatService.getChats();
    this.chatService.loadingChats = false;
    this.chatListView.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
  }

  menuStateChange(opened: boolean, showingChatList: boolean) {
    if(showingChatList && opened) {
      const scrollElement = this.chatListView.nativeElement!;
      scrollElement.scrollTo({ top: this.scrollPos });
    }
  }

  async loadMore() {
    if (!this.chatList.canLoadMore)
      return;
    this.chatService.loadingChats = true;
    await this.chatService.getChats(this.chatList.chats?.length);
    this.chatService.loadingChats = false;
  }
  
  private onScroll() {
    const scrollElement = this.chatListView.nativeElement!;
    this.scrollPos = scrollElement.scrollTop
    if (this.chatService.loadingChats || !this.chatList.canLoadMore) 
      return;
    const scroll = scrollElement.scrollTop;
    const height = scrollElement.clientHeight;
    const scrollHeight = scrollElement.scrollHeight;
    if (this.chatList.canLoadMore && scroll > this.prevScroll && scroll + height > scrollHeight - scrollThreshold)
      this.scrollDebouncer.next();
    this.prevScroll = scroll;
  }

  markAllAsRead() {
    this.chatService.markAllChatsSeen();
  }

}
