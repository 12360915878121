import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ChatService } from "../../services/chat.service";
import { Observable, of } from "rxjs";
import { MatMenuTrigger } from "@angular/material/menu";
import { Chat } from "../../models/chat.model";
import { shareReplay } from 'rxjs/operators';
import { ChatsListComponent } from "../chats-list/chats-list.component";
import { ChatComponent } from "../chat/chat.component";

@Component({
  selector: 'app-notifications-button',
  templateUrl: './notifications-button.component.html',
  styleUrls: ['./notifications-button.component.scss']
})
export class NotificationsButtonComponent implements OnInit {

  @ViewChild('menuTrigger') trigger!: MatMenuTrigger;
  @ViewChild('chatsList') chatsList!: ChatsListComponent;
  @ViewChild('chat') chat!: ChatComponent;

  isMenuOpen = true;
  selectedTab = 'Notifications';

  chatTabs = ['Chats', 'New'];
  selectedChatTab = 'Chats';

  notificationsCount$ = of(0);
  unseenChatsCount$!: Observable<number>;

  chatList = this.chatService.chatList;
  chat$: Observable<Chat | null> = of(null);

  constructor(
      private chatService: ChatService
  ) {}

  async ngOnInit() {
    this.unseenChatsCount$ = this.chatService.unseenChatsCount$;
  }

  @HostListener('window:popstate', ['$event'])
  onBrowserBackBtnClose(event: Event) {
    if(this.isMenuOpen) {
      event.preventDefault();
      event.stopImmediatePropagation();
      window.history.pushState(null, '', window.location.pathname);
      this.trigger.closeMenu();
    }
  }

  menuOpenChange(open: boolean) {
    this.isMenuOpen = open;
    if(this.chatsList)
      this.chatsList.menuStateChange(open, this.selectedTab === 'Chat' && this.selectedChatTab === 'Chats');
    if(this.chat)
      this.chat.menuStateChange(open, this.selectedTab === 'Chat' && this.selectedChatTab !== 'Chats');
  }

  onChatTabSelected(tab: string) {
    this.selectedChatTab = tab;
    if(tab === 'Chats') {
      this.chat$ = of(null);
      this.chatTabs = ['Chats', 'New'];
    }
  }

  openChat(id: number) {
    this.chat$ = this.chatService.chatObservable(id).pipe(shareReplay(1));
    this.chatTabs = ['Chats', 'Current'];
    this.onChatTabSelected('Current');
  }

  onMenuClick(event: Event) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }

}
