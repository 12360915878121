import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

export interface Offset {
  left?: number,
  right?: number,
  top?: number,
  bottom?: number,
}

@Component({
  selector: 'app-section-divider',
  templateUrl: './section-divider.component.html',
  styleUrls: ['./section-divider.component.scss']
})
export class SectionDividerComponent implements OnInit, AfterViewInit {
  @Input() scheduleToday: boolean = false;
  @Input() text!: string;
  @Input() texts!: string[];
  @Input() type: 'danger' | '' = '';
  @Input() padding?: Offset;
  @Input() alignedText: boolean = false;

  @ViewChild('sectionDivider') sectionDivider!: ElementRef;

  todayBefore = '';
  headerDate = '';
  todayAfter = '';

  constructor(
  ) { }

  ngOnInit(): void {
    if (this.scheduleToday) {
      if (!this.text.includes('Today') || this.text === 'Today') {
        this.scheduleToday = false
      } else {
        if (this.text.substring(0, 5) === 'Today') {
          this.todayBefore = 'Today';
          this.headerDate = this.text.substring(5, this.text.length)
        } else {
          this.todayAfter = 'Today';
          this.headerDate = this.text.substring(0, this.text.length - 5)
        }
      }
    }

  }

  ngAfterViewInit(): void {
    if (this.padding?.left) {
      this.sectionDivider.nativeElement.style.paddingLeft = this.padding?.left + 'px';
    }
    if (this.padding?.right) {
      this.sectionDivider.nativeElement.style.paddingRight = this.padding?.right + 'px';
    }
    if (this.padding?.top) {
      this.sectionDivider.nativeElement.style.paddingTop = this.padding?.top + 'px';
    }
    if (this.padding?.bottom) {
      this.sectionDivider.nativeElement.style.paddingBottom = this.padding?.bottom + 'px';
    }
  }
}
