import { EventEmitter, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {

    isActionSnackbar$ = new BehaviorSubject<boolean>(false);
    isDelayedLogout$ = new BehaviorSubject<boolean>(false);

    doLogout = new EventEmitter();

}