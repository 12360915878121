import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { UsersService } from "../../../../business-slick-estimates/src/app/services/users.service";
import { UserProfile } from "../models/user-profile.model";
import { take } from "rxjs/operators";

export const CHAT_MESSAGE_FORMAT = /@\{\d+\}/g;
export const USER_MENTION_CLASS = 'user-mention';

@Directive({
  selector: '[chatMessage]'
})
export class ChatMessageDirective implements OnInit {

  private users!: UserProfile[];

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private usersService: UsersService
  ) {}

  async ngOnInit() {
    this.users = (await this.usersService.users$.pipe(take(1)).toPromise())!;
    this.replacePattern(this.el.nativeElement.textContent);
  }

  replacePattern(text: string): void {
    let newText = text.replace(CHAT_MESSAGE_FORMAT, (match) => {
      const idMatch = match.match(/(\d+)/);
      const id = +idMatch![0];
      const user = this.users.find(u => u.id === id)!;
      const replacement = `${user.firstName} ${user.lastName}`;
      return `<span class="${USER_MENTION_CLASS}">${replacement}</span>`;
    });

    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', newText);
  }

}
