import { Component, Input } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'lib-chat-date-header',
  templateUrl: './chat-date-header.component.html',
  styleUrls: ['./chat-date-header.component.scss']
})
export class ChatDateHeaderComponent {
  @Input() date!: Date | string;

  get text() {
    if (typeof this.date === 'string')
      return this.date;
    if (moment(this.date).startOf('day').isSame(moment().startOf('day')))
      return 'Today';
    return moment(this.date).format(moment(this.date).isSame(moment(), 'year') ? 'DD MMMM' : 'DD MMMM, yyyy');
  }
}
