import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { UtilsService } from '../../services';
import { map } from 'rxjs/operators';
import { SupabaseService } from '../../services/supabase.service';

interface BaseMessage {
  id: number | null;
  createdAt: Date;
}

interface BaseAttachment {
  path: string;
}

interface MessageAttachment extends BaseAttachment {
  bucket: string;
}

interface SentMessageAttachment extends BaseAttachment {
  file: File;
}

export type ChatMessage = BaseMessage & (
  (
    {
      id: number;
    } &
    (
      {
        content: string;
        attachment?: MessageAttachment | null;
      } | {
        content?: string | null;
        attachment: MessageAttachment;
      }
    ) & (
      {
        status: 'delivered' | 'seen';
      } | {
        status: 'foreign';
        firstName: string;
        lastName: string;
      }
    )
  ) | (
    {
      status: 'sent';
    } & (
      {
        content: string;
        attachment?: SentMessageAttachment | null;
      } | {
        content?: string | null;
        attachment: SentMessageAttachment;
      }
    )
  )
);

const mediaTypes = ['video', 'audio', 'image'] as const;
type MediaType = typeof mediaTypes[number];

@Component({
  selector: 'lib-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  @Input() message!: ChatMessage;
  @Input() supportChat = false;
  
  @Output() imageClicked = new EventEmitter<string>();
  
  @ViewChild('mediaElement') mediaElement!: ElementRef<HTMLAudioElement>;
  
  readonly timeFormat = 'hh:mm a';
  readonly playerTime = 'mm:ss';
  
  file?: File;
  playing = false;
  seeking = false;
  durationMs = 0;
  durationTime = new Date(0);

  src?: string;
  type?: MediaType;
  width?: number;
  height?: number;

  startProgressPos!: number;
  startProgressTime!: Date;
  progress$?: Observable<number>;
  progressTime$?: Observable<Date>;

  constructor(
    private utilsService: UtilsService,
    @Inject('SupabaseService') private supabaseService: SupabaseService
  ) {}

  async ngOnInit() {
    if (!this.message.attachment)
      return;

    const { path, type, width, height, duration } = this.parseAttachment(this.message.attachment.path);
    if (type)
      this.type = type as MediaType;
    if (width && height) {
      this.width = width;
      this.height = height;
    } else if (duration) {
      this.durationMs = duration;
      this.durationTime = new Date(duration);
    }

    this.file = this.message.status === 'sent' 
      ? this.message.attachment.file
      : (await this.supabaseService.downloadFile(this.message.attachment.bucket, path))!;

    if (!this.type)
      this.type = this.file.type.split('/')[0] as MediaType;

    this.src = URL.createObjectURL(this.file);
    if (this.type === 'audio') {
      await this.utilsService.delay(10);
      const el = this.mediaElement.nativeElement;
      const durationS = this.durationMs / 1000;
      this.progressTime$ = of(this.durationTime);

      el.addEventListener('play', e => {
        this.playing = true;
        this.startProgressPos = el.currentTime ?? 0;
        this.startProgressTime = new Date();
        this.progress$ = timer(0, 100).pipe(
          map(_ => {
            const time = this.startProgressPos + ((Date.now() - this.startProgressTime.getTime()) / 1000);
            return Math.round((time / durationS) * 100);
          })
        );

        this.progressTime$ = timer(0, 500).pipe(
          map(_ => new Date(el.currentTime * 1000))
        );
      });

      el.addEventListener('pause', e => {
        this.playing = false;
        this.progress$ = of(Math.round((el.currentTime / durationS) * 100));
        this.progressTime$ = of(new Date(el.currentTime * 1000));
      });
    }
  }

  get aspectRatio() {
    return this.width && this.height ? this.width / this.height : 'auto';
  }

  get avatarText() {
    return this.message.status === 'foreign' ? this.message.firstName.slice(0, 2) : '';
  }

  get isImage() {
    return this.type === 'image';
  }

  get isVideo() {
    return this.type === 'video';
  }

  get isAudio() {
    return this.type === 'audio';
  }

  get isForeign() {
    return this.message.status === 'foreign';
  }

  parseAttachment(attachment: string) {
    const [ path, type, data ] = attachment.split(';');
    if (type === 'audio')
      return { path, type, duration: +data };
    
    let width = '';
    let height = '';
    if (data)
      [ width, height ] = data.split('x');
    return { path, type, width: +width, height: +height };
  }

  seek(event: MouseEvent, element: HTMLMediaElement) {
    if (!this.durationMs)
      return;
    event.preventDefault();
    event.stopImmediatePropagation();
    const durationS = this.durationMs / 1000;
    const percentage = ( event.offsetX / (event.target as HTMLProgressElement).clientWidth );
    element.currentTime = durationS * percentage;
    this.startProgressPos = element.currentTime ?? 0;
    this.startProgressTime = new Date();
    if(!this.playing) {
      this.progress$ = of(Math.round((element.currentTime / durationS) * 100));
      this.progressTime$ = of(new Date(element.currentTime * 1000));
    }
  }

  mouseMove(event: MouseEvent, element: HTMLMediaElement) {
    if(this.seeking) {
      this.seek(event, element);
    }
  }

}
