<div *ngIf="(currentUser$ | async) let user" [class.content-hidden]="initializing" #contentView class="chat-wrapper">
  <div class="chat-content">
    <ng-container *ngFor="let date of dateHeaders$ | async; let last = last">
      <lib-chat-date-header [date]="last ? (lastDateHeader$ | async)! : date" class="date-header"></lib-chat-date-header>
      <ng-container *ngFor="let item of (contentByDate$ | async)![date]">
        <lib-message 
          *ngIf="isMessage(item) || isSentMessage(item); else logItem"
          [message]="convertMessage(item, user)"
          [attr.data-id]="isMessage(item) ? item.id : ''"
          class="message"
          (imageClicked)="showImageViewer($event)"
        ></lib-message>
        <ng-template #logItem>
          <div *ngIf="isLog(item)" class="log">{{ getLogText(item, user.id) }}</div>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>
</div>

<lib-messages-indicator 
  *ngIf="chat && (unseenCount$ | async)! > 0 && !initializing && !hideIndicator"
  [messageCounter]="(unseenCount$ | async)!"
  (click)="scrollToFirstOtherUnseen(true)"
></lib-messages-indicator>
