import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../services/auth.service";
import { take } from "rxjs/operators";

@Component({
  selector: 'app-google-auth-result',
  template: '',
})
export class GoogleAuthResultComponent implements OnInit {

  constructor(
      private authService: AuthService
  ) {}

  async ngOnInit() {

    const params = window.location.href;
    const user = await this.authService.user$.pipe(take(1)).toPromise();
    if (window.opener) {
      window.opener.postMessage(!!user ? 'success' : 'failed');
    }
    window.close();
  }

}
