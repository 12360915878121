import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { getScrollParent } from '../services';

@Directive({
  selector: '[anchorAutocompleteOnScroll]'
})
export class AnchorAutocompleteOnScrollDirective implements OnInit, OnDestroy {
  @Input('anchorAutocompleteOnScroll') trigger?: MatLegacyAutocompleteTrigger;

  @HostListener('focus') onFocus() {
    this.openAfterScroll = true;
  }

  @HostListener('blur') onBlur() {
    this.openAfterScroll = false;
  }

  scrollElement?: Element;
  timer: NodeJS.Timeout | null = null;
  openAfterScroll = false;

  constructor(
    private element: ElementRef
  ) { }

  ngOnInit(): void {
    this.scrollElement = getScrollParent(this.element.nativeElement);
    if (this.trigger && this.scrollElement)
      this.scrollElement?.addEventListener('scroll', this.scrollHandler.bind(this));
  }

  ngOnDestroy(): void {
    this.scrollElement?.removeEventListener('scroll', this.scrollHandler.bind(this));
  }

  elementInView() {
    const threshold = 10;
    const elementTop = (this.element.nativeElement as Element).getBoundingClientRect().top;
    const elementBottom = (this.element.nativeElement as Element).getBoundingClientRect().bottom;
    const scrollElementTop = this.scrollElement!.getBoundingClientRect().top;
    const scrollElementBottom = this.scrollElement!.getBoundingClientRect().bottom;
    return elementBottom > scrollElementTop + threshold && elementTop < scrollElementBottom - threshold;
  }

  scrollHandler() {
    if (!this.trigger)
      return;

    const setTimer = () => {
      this.timer = setTimeout(() => {
        this.timer = null;
        if (this.elementInView())
          this.trigger!.openPanel();
      }, 200);
    }

    if (this.timer) {
      clearTimeout(this.timer);
      setTimer();
      return;
    }

    if (this.elementInView()) {
      if (this.trigger.panelOpen) {
        this.trigger.updatePosition();
        return;
      }
    } else if (this.trigger.panelOpen) {
      this.trigger.closePanel();
      setTimer();
      return;
    }

    if (this.openAfterScroll)
      setTimer();
  }

}
