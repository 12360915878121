<div class="sales-container">
    <div class="sales big-screen" *ngIf="data" [class.no-border]="loading$ | async">
        <div class="skeleton" *ngIf="loading$ | async"></div>
        <ng-container *ngFor="let tabLabel of infoToFill[selectedTab]; let last = last">
            <div class="total-sales">
                <ng-container *ngIf="data">
                    <h3 *ngIf="tabLabel.id !== 'p.paid.avg_j_d' && tabLabel.id !== 'p.pending.nc' && tabLabel.id !==  'c.pending.jobs' && tabLabel.id !==  'c.paid.jobs'"
                        [class.overpay]="redAmount(tabLabel.id, data)"
                    >
                        {{currencify(data[tabLabel.property])}}
                    </h3>
                    <h3 *ngIf="(tabLabel.id === 'p.paid.avg_j_d' || tabLabel.id ===  'c.pending.jobs' || tabLabel.id ===  'c.paid.jobs' || tabLabel.id ===  't.pending.jobs') && tabLabel.id !== 'p.pending.nc'">
                        {{round(data[tabLabel.property])}}
                    </h3>
                    <h3 *ngIf="tabLabel.id === 'p.pending.nc'">
                        {{data[tabLabel.property]}}
                    </h3>
                </ng-container>
                <small>{{ tabLabel.text }}</small>
            </div>
            <div class="divider-line" *ngIf="!last"></div>
        </ng-container>
    </div>
    <div class="sales small-screen" [class.expand-animation]="showAll" *ngIf="data">
        <div class="skeleton" *ngIf="loading$ | async"></div>
        <ng-container *ngFor="let tabLabel of infoToFill[selectedTab]; let last = last; let i = index">
            <div class="total-sales" *ngIf="!tabLabel.onlyExpand">
                <small>{{ tabLabel.text }}</small>
                <ng-container *ngIf="data">
                    <h3 *ngIf="tabLabel.id !== 'p.paid.avg_j_d' && tabLabel.id !== 'p.pending.nc' && tabLabel.id !==  'c.pending.jobs' && tabLabel.id !==  'c.paid.jobs' && tabLabel.id !==  't.pending.jobs'">
                        {{currencify(data[tabLabel.property])}}
                    </h3>
                    <h3 *ngIf="(tabLabel.id === 'p.paid.avg_j_d' || tabLabel.id ===  'c.pending.jobs' || tabLabel.id ===  'c.paid.jobs' || tabLabel.id ===  't.pending.jobs') && tabLabel.id !== 'p.pending.nc'">
                        {{round(data[tabLabel.property])}}
                    </h3>
                    <h3 *ngIf="tabLabel.id === 'p.pending.nc'">
                        {{data[tabLabel.property]}}
                    </h3>
                </ng-container>
            </div>
            <div class="total-sales" *ngIf="tabLabel.onlyExpand && showAll">
                <small>{{ tabLabel.text }}</small>
                <ng-container *ngIf="data">
                    <h3 *ngIf="tabLabel.id !== 'p.paid.avg_j_d' && tabLabel.id !== 'p.pending.nc' && tabLabel.id !==  'c.pending.jobs' && tabLabel.id !==  'c.paid.jobs' && tabLabel.id !==  't.pending.jobs'">
                        {{currencify(data[tabLabel.property])}}
                    </h3>
                    <h3 *ngIf="(tabLabel.id === 'p.paid.avg_j_d' || tabLabel.id ===  'c.pending.jobs' || tabLabel.id ===  'c.paid.jobs' || tabLabel.id ===  't.pending.jobs') && tabLabel.id !== 'p.pending.nc'">
                        {{round(data[tabLabel.property])}}
                    </h3>
                    <h3 *ngIf="tabLabel.id === 'p.pending.nc'">
                        {{data[tabLabel.property]}}
                    </h3>
                </ng-container>
            </div>
        </ng-container>
        <app-icon class="se-icon-button no-bg no-hover" [class.transform]="showAll" src="cheveron_down" (click)="toggleShowAll()"></app-icon>
    </div>
</div>
