<div class="wrapper" (click)="onClick()">
    <div class="content">
        <div class="flex-fill d-flex flex-column w-100 data-section">
            <h4>{{ item.jobType }}</h4>
        </div>

        <ng-container *ngIf="showUser">
            <h4 *ngIf="!item.assigneeCount || item.assigneeCount === 1; else withTooltip" class="assignee-name">{{ users }}</h4>
            <ng-template #withTooltip>
                <h4
                    class="assignee-name"
                    #tooltip="matTooltip"
                    [matTooltip]="assigneeNames"
                    matTooltipPosition="above"
                    matTooltipClass="mat-tooltip-left-text"
                    [hideTooltipOnScroll]="tooltip"
                >{{ users }}</h4>
            </ng-template>
        </ng-container>
        <app-label [color]="item.statusColor">{{ item.itemType | titlecase }}</app-label>
    </div>
</div>
