<div *ngIf="initialTimetable$ | async let initialTimetable" class="dialog wrapper d-flex flex-column w-100 h-100">
    <div class="tabs-wrapper">
        <app-tabs
            class="app-tabs"
            linePosition="bottom"
            alignment="center"
            [tabs]="tabs"
            [activeTab]="(selectedTabSubject | async)!"
            (onTabSelect)="selectedTabSubject.next($event)"
        ></app-tabs>
    </div>
    <app-icon *ngIf="(tabletScreen$ | async)" class="close-icon se-icon-button no-bg se-icon-button-close" (click)="close()" src="close"></app-icon>

    <div class="position-relative overflow-hidden flex-grow-1" [style.padding-top]="(selectedTabSubject | async) === 'Unavailable' ? '34px' : '48px'">
        <form
            [formGroup]="formWorkingHours"
            class="content overlay-scrollbar working-hours-form"
            [class.d-none]="(selectedTabSubject | async) !== 'Available'"
        >
            <ng-container *ngFor="let day of namesOfDays; let dayIndex = index; let last = last">
                <div [formGroupName]="dayIndex" class="tile d-flex flex-column align-items-start justify-content-start py--8" [class.px--12]="tabletScreen$ | async">
                    <div class="day-name-checkbox d-flex align-items-center justify-content-between w-100" 
                        [class.cursor-pointer]="checkbox.checked && !(tabletScreen$ | async)"
                        (click)="toggleExpand(dayIndex); $event.stopPropagation()"
                    >
                        <div class="d-flex align-items-center">
                            <mat-checkbox formControlName="checkbox" #checkbox color="primary" class="mr--12" (click)="$event.stopPropagation()" (change)="availableCheckboxChange($event, dayIndex)"></mat-checkbox>
                            <p class="day-name">{{ day | uppercase }}</p>
                        </div>
                        <mat-icon *ngIf="checkbox.checked; else unavailable" class="tile-expand-icon suffix rotate" [class.do]="expandStates[dayIndex]">expand_more</mat-icon>
                        <ng-template #unavailable>
                            <p class="text-unavailable ml--24">Unavailable</p>
                        </ng-template>
                    </div>
                    <div *ngIf="checkbox.checked"
                        formArrayName="ranges"
                        class="time-block"
                        [class.justify-content-between]="!(tabletScreen$ | async)" 
                        [class.d-none]="!expandStates[dayIndex] && !(tabletScreen$ | async)"
                    >
                        <div *ngFor="let timeGroup of getAvailableRanges(dayIndex); let timeGroupIndex = index; let rangesCount = count" class="range-item">
                            <div [formGroup]="timeGroup" class="time-range" [class.justify-content-between]="hasMultipleAvailableRanges()" [class.gap--8]="rangesCount > 1">
                                <div class="time-inputs">
                                    <app-time-input 
                                        formControlName="start"
                                        (changed)="!timeGroup.get('end')!.hasError('required') ? timeGroup.get('end')!.setErrors(null) : null"
                                    ></app-time-input>
                                    <app-time-input 
                                        formControlName="end" 
                                        (changed)="!timeGroup.get('start')!.hasError('required') ? timeGroup.get('start')!.setErrors(null) : null"
                                    ></app-time-input>
                                </div>
                                <div class="time-buttons d-flex align-items-center gap--7">
                                    <app-icon *ngIf="getAvailableRanges(dayIndex).length > 1" class="icon-button p-0" src="trash" (click)="deleteAvailableRange(dayIndex, timeGroupIndex)"></app-icon>
                                    <app-icon class="icon-button p-0" src="plus2" (click)="setAvailableRange(dayIndex, timeGroupIndex)"></app-icon>
                                </div>
                            </div>
                            <mat-error *ngIf="timeGroup.get('start')!.hasError('laterTime') && timeGroup.get('end')!.hasError('laterTime')">
                                Enter time that’s later than the start time
                            </mat-error>
                        </div>
                    </div>
                </div>
                <mat-divider *ngIf="!last" class="my--12"></mat-divider>
            </ng-container>
        </form>

        <form 
            [formGroup]="formAvailability"
            class="content overlay-scrollbar availability-form"
            [class.d-none]="(selectedTabSubject | async) !== 'Unavailable'"
        >
            <div class="d-flex justify-content-center overflow-hidden">
                <div class="unavailable-form-content">
                    <h4 class="select-dates-caption">Select the date(s)</h4>
                    <mat-calendar
                        [startAt]="startDate"
                        #calendar
                        class="calendar"
                        (click)="refreshCalendar()"
                        (selectedChange)="onSelectedChange($event)"
                        [selected]="selectedDate"
                        [minDate]="minDate"
                        [dateClass]="dateClass"
                    ></mat-calendar>

                    <div class="unavailable-form-items" *ngIf="(selectedDate && availabilityControls?.length)">
                        <h4 class="availability-items-caption pt--16 pb--20">What hours are you {{ isDateAvailable(selectedDate) ? 'unavailable' : 'available' }}?</h4>
                        <div formArrayName="items" class="availability-items">
                            <ng-container *ngFor="let item of availabilityControls; let index = index; let last = last; let count = count">
                                <div [formGroup]="item" class="availability-item">
                                    <div class="time-range justify-content-between gap--3">
                                        <div class="time-inputs">
                                            <app-time-input 
                                                formControlName="start" 
                                                (changed)="!item.get('end')!.hasError('required') ? item.get('end')!.setErrors(null) : null"
                                            ></app-time-input>
                                            <app-time-input 
                                                formControlName="end" 
                                                (changed)="!item.get('start')!.hasError('required') ? item.get('start')!.setErrors(null): null"
                                            ></app-time-input>
                                        </div>
                                        <div class="time-buttons d-flex align-items-center gap--7">
                                            <app-icon class="icon-button p-0" src="trash" (click)="deleteAvailabilityItemClick(index)"></app-icon>
                                            <app-icon class="icon-button p-0" src="plus2" (click)="newAvailabilityItem(index)"></app-icon>
                                        </div>
                                    </div>
                                    <mat-error *ngIf="item.get('start')!.hasError('laterThankCurrentTime')">
                                        Enter a time that is later than the current time
                                    </mat-error>
                                    <mat-error *ngIf="item.get('start')!.hasError('laterTime') && item.get('end')!.hasError('laterTime')">
                                        Enter time that’s later than the start time
                                    </mat-error>
                                    <mat-error *ngIf="item.get('start')!.hasError('rangeOverlap') && item.get('end')!.hasError('rangeOverlap')">
                                        This time slot is overlapping with your schedule
                                    </mat-error>
                                    <mat-error *ngIf="item.get('start')!.hasError('timetableOverlap') && item.get('end')!.hasError('timetableOverlap')">
                                        This time slot is overlapping with your schedule
                                    </mat-error>
                                    <mat-form-field *ngIf="isDateAvailable(selectedDate)" appearance="outline" appFormFieldFilled class="description w-100" [class.pt--12]="count > 1">
                                        <input
                                            matInput
                                            formControlName="description"
                                            placeholder="Write description..."
                                            autocomplete="off"
                                            maxlength="40"
                                        >
                                    </mat-form-field>
                                    <mat-divider *ngIf="!last" class="my--20"></mat-divider>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <footer class="footer d-flex">
        <button
            mat-stroked-button
            class="footer-button"
            (click)="close()"
        >
            Close
        </button>
        <loader-button
            class="footer-button"
            [loading]="saving"
            text="Apply"
            (onClick)="apply(initialTimetable)"
        ></loader-button>
    </footer>
</div>
