import { Directive, HostListener, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { SCREEN_BREAKPOINTS } from './SCREEN_BREAKPOINTS';

@Directive({
  selector: "[forScreenSize]"
})
export class ForScreenSizeDirective implements OnInit {

  @Input('forScreenSize') size!: number | string;
  @Input('forScreenSizeShow') show: 'above' | 'below' = 'above';
  @Input('forScreenSizeElse') elseTemplate: TemplateRef<any> | null = null;

  showing = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private template: TemplateRef<any>,
    private window: Window,
  ) {}

  ngOnInit() {

    if(typeof this.size === 'string')
      this.size = (SCREEN_BREAKPOINTS as any)[this.size];

    if(this.window.innerWidth >= this.size)
      this.movedAbove();
    else
      this.movedBelow();
  }

  @HostListener('window:resize', ['$event'])
  private onResize(_: Event) {
    
    if(this.window.innerWidth < this.size)
      this.movedBelow();

    else if(this.window.innerWidth >= this.size)
      this.movedAbove();
  }

  private movedAbove() {
    if (this.show === 'above')
      this._show();
    else
      this._hide();
  }

  private movedBelow() {
    if (this.show === 'above')
      this._hide();
    else
      this._show();
  }

  private _show() {
    if(this.showing)
      return;
      
    this.showing = true;
    this.viewContainerRef.clear();
    this.viewContainerRef.createEmbeddedView(this.template);
  }

  private _hide() {
    if(!this.showing)
      return
      
    this.showing = false;
    this.viewContainerRef.clear();
    if(this.elseTemplate)
      this.viewContainerRef.createEmbeddedView(this.elseTemplate);
  }

}
