import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-messages-indicator',
  templateUrl: './messages-indicator.component.html',
  styleUrls: ['./messages-indicator.component.scss']
})
export class MessagesIndicatorComponent {

  @Input() messageCounter!: number;

}
