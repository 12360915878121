<div class="tile-wrapper">
    <app-base-tile
        [data]="{
            title: item.name,
            description: item.description
        }"
        [hideOnSmallScreens]="false"
    >
        <div class="item-price-actions" trailing>
            <div class="item-price-block">
                <p class="price-value">$ {{item.price.toFixed(2)}}</p>
                <div class="items-divider"></div>
                <p class="d-flex justify-content-center gap--5 w-100">
                    <span class="markup-value">{{getMarkup(item.price!, item.cost!)}}%</span>
                    <app-icon *ngIf="getMarkup(item.price!, item.cost!) !== 0" 
                        [src]="getMarkup(item.price!, item.cost!) > 0 ? 'graph_up' : 'graph_down'"
                        height="24px"
                        width="24px"
                    ></app-icon>
                </p>
            </div>
            <div class="se-button hide-mobile item-actions-block delete-button" (click)="onDeleteClick($event)">Delete</div>
        </div>
    </app-base-tile>
</div>