<div class="dialog wrapper d-flex flex-column">
    <div class="header d-flex justify-content-between align-items-center">
        <h3>{{ dialogTitle }}</h3>
        <app-icon class="se-icon-button no-bg se-icon-button-close" (click)="close.emit()" src="close"></app-icon>
    </div>

    <div class="content flex-fill content-availability d-flex flex-column" [class.isIOS]="isIOS" [class.mobile-device]="isMobile">
        <!-- <app-text-toggle [items]="tabs" (onSelected)="tabSelected($event)"></app-text-toggle> -->
        <div class="schedule-of d-flex align-items-center">
            <h4 class="schedule-of-text">Schedule</h4>
<!--            <app-user-select-->
<!--                #userSelect-->
<!--                [showAllAccounts]="true"-->
<!--                (usersSelected)="assignedUsersCheck($event)"-->
<!--            ></app-user-select>-->
            <app-user-search-bar
                #userSearch
                (onChange)="onAssigneesChange($event)"
                (clearButtonClicked)="handleClearButtonClick()"
                [singleAssigneeInList]="false"
                [assignedUsers]="assignedUsers"
                [users]="(assigneesList$ | async)!"
            ></app-user-search-bar>
        </div>
        <div class="d-flex align-items-center mt--20" *ngIf="assignedUsers.length > 0">
            <h4 class="assignee-wrapper__title">Assignees:</h4>
            <div class="assignee-container">
                <ng-container *ngFor="let assignedUser of assignedUsers">
                    <div class="assignee-wrapper">
                        <h4>{{ assignedUser.firstName + ' ' + assignedUser.lastName[0]}}.</h4>
                        <app-icon class="unassign-icon se-icon-button no-bg" (click)="unAssignUser(assignedUser)" src="close"></app-icon>
                    </div>
                </ng-container>
            </div>
        </div>
        <app-date-range class="date-range mt--24" [min]="dateFilterMinDate()" (change)="selectedDateRange$.next($event)" #dateRange></app-date-range>

        <div [class.d-none]="currentTab !== tabs[0]" class="events flex-fill overlay-scrollbar pl-0 flex-grow-1"
             [class.isIOS]="isIOS" [class.mobile-device]="isMobile">
            <div *ngIf="(selectedUserEvents$ | async)?.length; else emptyEvents" class="tab-content">
                <ng-container *ngFor="let events of selectedUserEvents$ | async">
                    <app-section-divider class="event-date">{{ events[0] }}</app-section-divider>
                    <div *ngFor="let event of events[1]; let last = last; let count = count" class="event-date-wrapper">

                        <app-base-tile>
                            <app-base-tile-time leading
                                [timeData]="{startTime: event.startTime!, endTime: event.endTime!}"
                                [hideTitles]="(smallScreen$ | async)!"
                                [unsetWidth]="true"
                            ></app-base-tile-time>
                            <div class="info" trailing>
                                <app-label class="label" color="grey">{{ event.docType }}</app-label>
                            </div>
                        </app-base-tile>
                        <div *ngIf="!last && count > 1" class="height--20"></div>
                    </div>
                </ng-container>
            </div>
            <ng-template #emptyEvents>
                <div class="tab-content tab-content-empty">
                    <p class="empty-info-text">{{ (assignedUsers.length === 0) ? 'Choose a user' : 'User is available' }}</p>
                </div>
            </ng-template>
        </div>

        <div [class.d-none]="currentTab !== tabs[1]" class="flex-grow-1" [class.isIOS]="isIOS"
             [class.mobile-device]="isMobile">
            <div class="tab-content tab-content-empty">
                <p class="empty-info-text">Coming soon</p>
            </div>
        </div>
        <div *ngIf="assignedUsers.length === 1 || (assignedUsers.length > 1 && !singleAssigneeInList)">
            <button
                    *ngIf="currentTab === tabs[0]"
                    mat-flat-button
                    class="assign-button"
                    color="primary"
                    (click)="submit()"
            >
                {{ 'Assign ' + ((!singleAssigneeInList && assignedUsers.length > 1) ? (assignedUsers.length + ' users') : assignedUsers[0].firstName) }}
            </button>
        </div>
    </div>
</div>
