<div class="page bg">

  <app-toolbar type="signup"></app-toolbar>

  <div class="container d-flex flex-fill justify-content-center w-100">

    <mat-card class="mat-elevation-z3">
      <div class="content">
        <mat-card-title><h2 class="text-center">Welcome Back!</h2></mat-card-title>

        <app-providers-row></app-providers-row>

        <div class="separator">
          <div></div>
          <p>Or</p>
          <div></div>
        </div>

        <form class="d-flex flex-column align-items-stretch" [formGroup]="form">
          <h4>Email</h4>
          <mat-form-field appearance="outline" appFormFieldFilled>
            <input matInput #emailInput id="input_email"
                   formControlName="email"
                   type="email"
                   inputmode="email"
                   placeholder="Enter your email"
                   enterkeyhint="next"
                   tabindex="1"
            />
            <mat-error *ngIf="form.get('email')!.hasError('required') && form.get('email')!.touched">
              Email is required
            </mat-error>
            <mat-error *ngIf="form.get('email')!.hasError('email') && form.get('email')!.touched">
              Invalid email. Re-enter
            </mat-error>
            <mat-error *ngIf="form.get('email')!.hasError('emailOrPassword') && form.get('email')!.touched">
              Invalid email or password. Re-enter
            </mat-error>
          </mat-form-field>
          <div class="d-flex justify-content-between align-items-center">
            <h4>Password</h4>
            <h4 class="simple-text-button" routerLink="/reset-password" [queryParams]="passEmailProp()">Forgot
              Password?</h4>
          </div>
          <mat-form-field appearance="outline" appFormFieldFilled>
            <input matInput #passwordInput id="input_password"
                   formControlName="password"
                   [type]="showPassword ? 'text' : 'password'"
                   placeholder="Enter your password"
                   tabindex="2"
                   enterkeyhint="go"
            />
            <img *ngIf="form.get('password')!.value.length > 0" matSuffix
                 [src]="'../../../assets/icons/password-' + (showPassword ? 'hide' : 'show') +'.svg'"
                 (click)="showPassword = !showPassword">
            <mat-error *ngIf="form.get('password')!.hasError('required') && form.get('password')!.touched">
              Password is required
            </mat-error>
            <mat-error *ngIf="form.get('password')!.hasError('emailOrPassword') && form.get('password')!.touched">
              Invalid email or password. Re-enter
            </mat-error>
          </mat-form-field>

          <loader-button class="footer" [loading]="loading" text="Sign in" id="button_login"
                         (onClick)="emailSignIn()"></loader-button>
        </form>
      </div>
    </mat-card>
  </div>
</div>
