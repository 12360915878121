<div class="page main h-100 w-100 d-flex flex-column justify-content-start" *ngIf="!(loading$ | async)">

    <app-mobile-toolbar class="only-mobile-sm" [showMenu]="true"></app-mobile-toolbar>

    <app-toolbar type="menu" class="not-mobile-sm"></app-toolbar>

    <div class="flex-fill d-flex flex-column lobby-main" fadeIn="500" [fadeInDelay]="200">
        <div *ngIf="bookOnboardinglInk$ | async; let link" class="onboarding-banner">
            <p>Book your onboarding session with a name of sales person</p>
            <a class="d-flex gap--5 align-items-center" [href]="link" target="_blank">Book Onboarding<app-icon class="booking-arrow se-icon-button no-bg no-hover" src="cheveron_down"></app-icon></a>
        </div>

        <div *ngIf="isOnboardingBooked$ | async" class="onboarding-banner justify-content-center">
            <p>Your onboarding is booked</p>
        </div>

        <div class="container lobby_header d-flex flex-column">
            <div class="d-flex justify-content-center align-items-center">
                <app-tabs
                    linePosition="bottom"
                    [tabs]="(pages$ | async)!"
                    [activeTab]="(page$ | async)!"
                    (onTabSelect)="onTabSelected($event)"
                ></app-tabs>
            </div>
        </div>

        <div class="flex-fill lobby-content">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
