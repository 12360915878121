<div class="current-proposal-version-block" *ngIf="currentProposalVersion">
  <div class="proposal-note" *ngIf="(currentProposalVersion.items ?? []).length > 0">
    <mat-form-field appearance="outline" class="textarea w-100" appFormFieldFilled>
      <textarea matInput cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                placeholder="Proposal Note"
                (focus)="this.noteFocused = true; this.currentNote = currentProposalVersion.note ?? ''; editableInFocus.emit(true)"
                (focusout)="noteFocusout(currentProposalVersion.id, currentProposalVersion.note); editableInFocus.emit(false)"
                [(ngModel)]="currentProposalVersion.note"
      ></textarea>
    </mat-form-field>
    <div class="note-border-bottom" [class.focused]="noteFocused"></div>
  </div>
  <app-proposal-items-list [proposalVersion]="currentProposalVersion"
                           [proposal]="proposal"
                           [scrollPosition]="scrollPosition"
                           (itemsListFocus)="editableInFocus.emit($event)"
  ></app-proposal-items-list>
</div>
