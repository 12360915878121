import { Component, EventEmitter, Output, Input } from '@angular/core';
import { UsersService } from '../../../services/users.service';
import { ModalBehavior, ModalsService } from '../../../../../../common/src/lib/services/modals.service';
import { AddUserComponent } from '../../../modals/add-user/add-user.component';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { BusinessProfile } from '../../../../../../common/src/lib/models/business-profile.model';
import { UserProfile } from '../../../../../../common/src/lib/models/user-profile.model';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent {

  @Input() businessProfile!: BusinessProfile;

  currentUser$ = this.usersService.currentUser$;

  usersList$ = combineLatest([this.usersService.users$, this.currentUser$]).pipe(
    map(([users, user]) => {
      if(!users)
        return null;
      return users!.filter(u => u.email !== user!.email).sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
    }),
    map(users => {
      if(!users)
        return null;
      return [
        users.find(u => u.role === 'owner'),
        ...users.filter(u => u.role === 'admin').sort((a, b) => a.firstName.localeCompare(b.firstName)),
        ...users.filter(u => u.role === 'user').sort((a, b) => a.firstName.localeCompare(b.firstName)),
      ].filter(u => !!u)
      .sort((a, b) => {
        if(a!.status === 'active')
          return -1;
        if(b!.status === 'active')
          return 1;
        if(a!.status.includes('suspended'))
          return -1;
        if(b!.status.includes('suspended'))
          return 1;
        return 0;
      }) as UserProfile[];
    })
  );

  missingNameError: string | null = null;

  @Output() onShowNameErrorChange = new EventEmitter<{ firstName: boolean, lastName: boolean}>();

  constructor(
    private usersService: UsersService,
    private modalsService: ModalsService,
  ) {}

  async addUser() {
    this.modalsService.open(AddUserComponent, {
      behavior: ModalBehavior.Auto
    });
  }
}
