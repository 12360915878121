import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { AddEditPaymentComponent } from "../../modals/add-edit-payment/add-edit-payment.component";
import { BehaviorSubject, timer } from "rxjs";
import { debounce } from "rxjs/operators";
import { ConfirmationDialog } from "../../../../../common/src/lib/modals/confirmation-dialog/confirmation-dialog.component";
import { PaymentService } from "../../services/payment.service";
import { ProposalsService } from "../../services/proposals.service";
import { InvoicesService } from "../../services/invoices.service";
import { JobsService } from '../../services/jobs.service';
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { Payment } from "../../models/payment.model";
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";
import { UserProfile } from "../../../../../common/src/lib/models/user-profile.model";

@Component({
  selector: 'app-payment-actions',
  templateUrl: './payment-actions.component.html',
  styleUrls: ['./payment-actions.component.scss']
})
export class PaymentActionsComponent implements OnChanges {

  @Input() cancelVisible!: boolean;
  @Input() payment!: Payment;
  @Input() currentUser?: UserProfile;
  @Input() workflowCreator?: number;
  @Input() workflowId!: number;

  get expenseAccess(): boolean {
    return this.currentUser?.id === this.workflowCreator;
  }

  _showCancelElement = new BehaviorSubject<boolean>(false);
  showCancelElement$ = this._showCancelElement.asObservable().pipe(
    debounce(show => {
      return !show ? timer(400) : timer(0);
    })
  );

  constructor(
    private modalsService: ModalsService,
    private paymentService: PaymentService,
    private proposalsService: ProposalsService,
    private invoicesService: InvoicesService,
    private jobsService: JobsService,
    private snackBar: MatSnackBar
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if ('cancelVisible' in changes)
      this._showCancelElement.next(this.cancelVisible)
  }

  async addPayment(type: 'payment' | 'expense' | 'tech_part') {
    await this.modalsService.open(AddEditPaymentComponent, {
      disableClose: true,
      data: {
        type,
        balanceDue: this.payment.paymentSummary.sBalanceDue,
        paymentId: this.payment.id,
        workflowId: this.workflowId
      }
    });
  }

  async handleJob() {
    if (this.cancelVisible)
      await this.modalsService.open(ConfirmationDialog, {
        data: {
          title: !this.payment.paymentSummary.canceled ? 'Cancel Job' : 'Restore Job',
          message: !this.payment.paymentSummary.canceled
            ? 'By cancelling this job, proposal, job and invoice will be canceled. Are you sure you want to continue?'
            : 'By restoring the job, proposals, jobs, invoices will be restored. Are you sure you want to continue?',
          actionTitle: 'Yes',
          actionColor: 'warn',
          cancelTitle: 'No',
          action: async () =>  {
            await this.paymentService.cancelOrRestore(this.payment.id, !this.payment.paymentSummary.canceled);

            showSnackbar(this.snackBar, {
              message: `Job ${!this.payment.paymentSummary.canceled ? 'canceled' : 'restored'}`,
              duration: 2000,
            });
          }
        }
      });
  }
}
