export function isEmpty(data: any): boolean {
  return data === null || data === undefined || data === '' || +data === 0;
}

const extraChars = [37, 39, 190, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105];

export function numbersOnly(event: any): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode === 44 || charCode === 188 || charCode === 190 || charCode === 46) {
    if (!event.target.value.includes('.') && event.target.value.length > 0)
      event.target.value += '.';
    return false;
  }
  return !(
    charCode > 31
    && (charCode < 48 || charCode > 57)
    && !extraChars.includes(charCode)
  );
}
