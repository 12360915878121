import { Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { map } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { FormsService } from '../../../../../common/src/lib/services/forms.service';
import { ModalsService } from '../../../../../common/src/lib/services/modals.service';
import { ForgotPasswordModal } from '../forgot-password/forgot-password.component';

interface ReauthenticationDialogData {
  title: string;
  message: string;
  onSuccess: () => void
}

@Component({
  selector: 'app-reauthentication-dialog',
  templateUrl: './reauthentication-dialog.component.html',
  styleUrls: ['./reauthentication-dialog.component.scss']
})
export class ReauthenticationDialog implements OnInit {

  showPassword = false;
  loading = false;

  form = this.formBuilder.group({
    password: new UntypedFormControl('')
  });

  providers$ = this.authService.user$.pipe(
    map(user => user!.providers)
  );

  @ViewChild('passwordInput') passwordInput!: ElementRef;

  get data() {
    return this.dialogData ?? this.modalsService.data;
  }

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: ReauthenticationDialogData,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private formsService: FormsService,
    private modalsService: ModalsService,
  ) { }

  ngOnInit(): void {
  }

  async reauthenticate() {
    if(this.loading) {
      return;
    }
    
    if(this.formsService.validateRequired(this.form.get('password')!)) {
      
      this.loading = true;

      const { password } = this.form.value;
      try {
        await this.authService.reauthenticateWithPassword(password);
        this.onReauthentication();
      } catch (e) {
        this.form.get('password')!.setErrors({
          password: true
        });
      }


    } else {
      this.form.markAllAsTouched();
    }
    
    this.passwordInput.nativeElement.blur();
    this.loading = false;
  }

  openForgotPassword() {
    this.modalsService.open(ForgotPasswordModal, {
      data: {
        backComponent: ReauthenticationDialog,
        backComponentData: this.data
      }
    });
  }


  async onReauthentication() {
    await this.close();
    this.data.onSuccess();
  }

  async close() {
    return this.modalsService.close();
  }

}
