import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { FormControl, NgControl, UntypedFormBuilder, UntypedFormControl } from "@angular/forms";
import { Client } from "../../../../../common/src/lib/models";
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";
import { ProposalCreate, ProposalsService } from "../../services/proposals.service";
import { InvoiceCreate, InvoicesService } from "../../services/invoices.service";
import { Router } from "@angular/router";
import { NotesService } from "../../services/notes.service";
import { PlacesService } from '../../../../../common/src/lib/services/places.service';
import { AddressComponents, addressComponentsEmpty } from 'projects/common/src/lib/models/address-comments.model';
import { capitalizeFirstChar, UtilsService } from 'projects/common/src/public-api';
import { Observable } from 'rxjs';
import { tap, distinctUntilChanged, take, map, startWith } from 'rxjs/operators';
import { NavigationService } from "../../services/navigation.service";
import { componentsToString } from "../../../../../common/src/lib/pipes/address.pipe";
import { UsersService } from "../../services/users.service";
import { ClientSuggestionsService } from "../../services/client-suggestion";
import { ClientsService } from "../../services/clients.service";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { isEmpty } from '../../helpers/forms';
import { applyPhoneNumberMask, removePhoneNumberMask } from "../../../../../common/src/lib/utils/phone-number-mask";

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.scss']
})
export class EditClientComponent implements OnInit {

  @ViewChild('address') addressInput!: ElementRef<HTMLInputElement>;
  @ViewChild('address', {read: NgControl}) addressInputDirective!: NgControl;

  title!: string;

  form = this.formBuilder.group({
    firstName: new UntypedFormControl(''),
    lastName: new UntypedFormControl(''),
    phoneNumber: new UntypedFormControl(''),
    extNumber: new UntypedFormControl(''),
    address: new FormControl<AddressComponents | null>(null),
    email: new UntypedFormControl(''),
    unit: new UntypedFormControl(''),
    businessName: new UntypedFormControl(''),
    type: new UntypedFormControl(''),
    jobType: new UntypedFormControl('')
  });

  firstTabIndexElement: HTMLElement | null = null;
  extInputFocused = false;

  emptyExtInput$: Observable<boolean> | undefined = this.form.get('extNumber')?.valueChanges.pipe(
    map(extValue => isEmpty(extValue)),
    startWith(isEmpty(this.modalsService.data?.client?.extNumber))
  );

  loading: boolean = false;

  get data() {
    return this.modalsService.data;
  }

  get creation(): boolean {
    return this.modalsService?.data?.creation ?? false;
  }

  get proposalsService(): ProposalsService {
    return this.modalsService.data.proposalsService;
  }

  get invoicesService(): InvoicesService {
    return this.modalsService.data.invoicesService;
  }

  largeScreen$ = this.utilsService.onScreenBreakpointChange('sm').pipe(
    distinctUntilChanged(),
    tap(largeScreen => {
      setTimeout((() => {
        this.initAddress();
      }).bind(this), 5);
    })
  );

  firstNameFocused = false;
  lastNameFocused = false;
  phoneNumberFocused = false;
  emailFocused = false;

  autocompleteOptions$!: Observable<google.maps.places.AutocompletePrediction[] | null>;

  clientSuggestionsService = new ClientSuggestionsService(this.clientsService, this.form);

  constructor(
    private modalsService: ModalsService,
    private formBuilder: UntypedFormBuilder,
    private placesService: PlacesService,
    private utilsService: UtilsService,
    private notesService: NotesService,
    private snackBar: MatSnackBar,
    private router: Router,
    private navigationService: NavigationService,
    private usersService: UsersService,
    private clientsService: ClientsService
  ) { }

  ngOnInit() {
    if (!this.data.client.type)
      this.form.get('type')?.setValue('personal');

    if (!this.creation) {
      this.title = Object.keys(this.data.client).length === 0
        ? 'Add Billing info'
        : 'Edit Billing info';
    } else
      this.title = `Create ${this.data.which}`;

    if(this.data.client) {
      const data = this.data.client;
      if(data.phoneNumber) {
        data.phoneNumber = applyPhoneNumberMask(data.phoneNumber);
      }
      this.form.patchValue(data);
    }
    this.firstTabIndexElement = this.data.client.type === 'personal'
      ? document.getElementById('first-name')
      : document.getElementById('business-name');

    (this.data as any).beforeResize = this.saveForm.bind(this);
  }

  unfocusClientSuggestionField() {
    this.clientSuggestionsService.focusedField = null;
  }

  onClientSuggestionSelected(event: MatAutocompleteSelectedEvent) {
    this.clientSuggestionsService.activeOption = null;
    this.clientSuggestionsService.hoveredOption = null;
    if(!event.option)
      return;
    const value = event.option.value;
    value.phoneNumber = applyPhoneNumberMask(value.phoneNumber);
    const address = value.address;
    delete value.address;
    const self = this;
    setTimeout(() => {
      self.form.patchValue(value, { emitEvent: true });
      if(address)
        this.onPlacesOptionSelected(address);
    }, 10);
  }

  onPhoneNumberInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    const maskedValue = applyPhoneNumberMask(value);
    this.form.get('phoneNumber')!.setValue(maskedValue, { emitEvent: false });
  }

  saveForm() {
    this.data.client = this.form.value;
  }

  ngAfterViewInit() {
    this.initAddress();
  }

  async initAddress() {
    this.autocompleteOptions$ = await this.placesService.getPlaceAutocomplete(
      this.addressInput,
      'address'
    );

    if(this.data?.client?.address) {
      this.addressInputDirective.valueAccessor?.writeValue(componentsToString(this.data.client.address));
      this.form.controls.address.setValue(this.data.client.address, { emitModelToViewChange: false });
    }
  }


  onPlacesOptionSelected(place: AddressComponents) {
    this.addressInputDirective.valueAccessor?.writeValue(componentsToString(place));
    this.form.controls.address.setValue(place, { emitModelToViewChange: false });
    
    if(addressComponentsEmpty(place)) {
      this.form.get('address')!.setErrors({ missingComponents: true });
      this.form.get('address')?.markAsTouched();
    } else {
      this.form.controls.address.setErrors(null);
    }
  }

  async close() {
    await this.modalsService.close();
  }

  async create() {
    this.loading = true;

    if (this.validForm()) {
      const user = await this.usersService.currentUser$.pipe(take(1)).toPromise();
      if (this.proposalsService) {
        const data: ProposalCreate = {
          inClient: {
            firstName: this.form.controls.firstName.value,
            lastName: this.form.controls.lastName.value,
            phoneNumber: removePhoneNumberMask(this.form.controls.phoneNumber.value),
            address: this.form.controls.address.value,
            type: this.form.controls.type.value,
            email: this.form.controls.email.value,
            unit: this.form.controls.unit.value,
            extNumber: this.form.controls.extNumber.value,
            businessName: this.form.controls.businessName.value,
          },
          inJobType: this.form.controls.jobType.value,
          inCreatedBy: user.id
        };
        const resData = await this.proposalsService.createProposal(data);
        await this.router.navigateByUrl(`proposals/${resData.workflowId}/${resData.proposalId}`);
      }

      if (this.invoicesService) {
        const data: InvoiceCreate = {
          inCreatedBy: user.id,
          inClient: {
            firstName: this.form.controls.firstName.value,
            lastName: this.form.controls.lastName.value,
            phoneNumber: removePhoneNumberMask(this.form.controls.phoneNumber.value),
            address: this.form.controls.address.value,
            type: this.form.controls.type.value,
            email: this.form.controls.email.value,
            unit: this.form.controls.unit.value,
            extNumber: this.form.controls.extNumber.value,
            businessName: this.form.controls.businessName.value,
          },
          inJobType: this.form.controls.jobType.value,
        };
        const resData = await this.invoicesService.createInvoice(data);
        // TODO: Check navigation
        await this.router.navigateByUrl(`invoices/${resData.workflowId}/${resData.invoiceId}`);
      }

      await this.modalsService.close();
      if (this.data?.which)
        showSnackbar(this.snackBar, {
          message: `${this.data.which} created`,
          duration: 2000
        });
    }

    this.loading = false;
  }

  async save() {
    this.loading = true;
    const snackbarMessage = this.data.snackbarMessage;

    if (this.validForm()) {
      const formValue = this.form.value as Client;
      formValue.phoneNumber = removePhoneNumberMask(formValue.phoneNumber);
      try {
        await this.data.updateFunction(this.data.currentWorkflowVersionId, formValue);
        await this.modalsService.close();
        if (snackbarMessage)
          showSnackbar(this.snackBar, {
            message: snackbarMessage,
            duration: 2000
          });
      } catch (e) {
        console.log(e);
        alert('Something went wrong during client update!');
      }
    }

    this.loading = false;
  }

  private validForm(): boolean {
    let invalid = false;

    if (this.form.get('type')?.value === 'business') {
      if (!this.form.get('businessName')?.value || this.form.get('businessName')?.value.trim() === '') {
        this.form.get('businessName')!.setErrors({ emptyBusinessNameError: true });
        invalid = true;
        invalid
            ? this.form.get('businessName')?.markAsTouched()
            : this.form.get('businessName')?.markAsUntouched();
      }
    }

    if (this.form.get('type')?.value === 'personal') {
      if (!this.form.get('firstName')?.value || this.form.get('firstName')?.value.trim() === '') {
        this.form.get('firstName')!.setErrors({ emptyFirstNameError: true });
        invalid = true;
        invalid
            ? this.form.get('firstName')?.markAsTouched()
            : this.form.get('firstName')?.markAsUntouched();
      }

      if (!this.form.get('lastName')?.value || this.form.get('lastName')?.value.trim() === '') {
        this.form.get('lastName')!.setErrors({ emptyLastNameError: true });
        invalid = true;
        invalid
            ? this.form.get('lastName')?.markAsTouched()
            : this.form.get('lastName')?.markAsUntouched();
      }
    }

    const phoneNumber = removePhoneNumberMask(this.form.get('phoneNumber')?.value)
    if (phoneNumber.length !== 10) {
      if (!phoneNumber || phoneNumber === '') {
        this.form.get('phoneNumber')!.setErrors({ emptyPhoneNumberError: true });
      } else {
        this.form.get('phoneNumber')!.setErrors({ invalidPhoneNumberError: true });
      }
      invalid = true;
      invalid
          ? this.form.get('phoneNumber')?.markAsTouched()
          : this.form.get('phoneNumber')?.markAsUntouched();
    }

    const validEmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (this.form.get('email')?.value.match(validEmailRegex) === null) {
      if (!this.form.get('email')?.value || this.form.get('email')?.value.trim() === '') {
        this.form.get('email')!.setErrors({ emptyEmailError: true });
      } else {
        this.form.get('email')!.setErrors({ invalidEmailError: true });
      }
      invalid = true;
      invalid
          ? this.form.get('email')?.markAsTouched()
          : this.form.get('email')?.markAsUntouched();
    }
    
    if(!this.form.get('address')?.value || this.form.get('address')?.value === '') {
      this.form.get('address')!.setErrors({ emptyAddressError: true });
      invalid = true;
      invalid
          ? this.form.get('address')?.markAsTouched()
          : this.form.get('address')?.markAsUntouched();
    } else if (typeof this.form.get('address')!.value === 'string') {
      this.form.get('address')!.setErrors({ addressIsString: true });
      invalid = true;
      invalid
          ? this.form.get('address')?.markAsTouched()
          : this.form.get('address')?.markAsUntouched();
    } else if (addressComponentsEmpty(this.form.get('address')?.value)) {
      this.form.get('address')!.setErrors({ missingComponents: true });
      invalid = true;
      invalid
          ? this.form.get('address')?.markAsTouched()
          : this.form.get('address')?.markAsUntouched();
    }

    return !invalid;
  }

  toggleType(type: 'personal' | 'business') {
    this.form.get('address')?.setErrors(null);
    this.form.get('email')?.setErrors(null);
    this.form.get('phoneNumber')?.setErrors(null);
    this.form.get('lastName')?.setErrors(null);
    this.form.get('firstName')?.setErrors(null);
    this.form.get('businessName')?.setErrors(null);
    this.form.get('type')?.setValue(type);

    setTimeout(() => {
      this.firstTabIndexElement = type === 'personal'
        ? document.getElementById('first-name')
        : document.getElementById('business-name');
    }, 200);
  }

  unitTab(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.stopPropagation();
      event.preventDefault();
      this.firstTabIndexElement?.focus();
    }
  }

  protected readonly applyPhoneNumberMask = applyPhoneNumberMask;
}
