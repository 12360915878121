<div class="invoice-preview-and-sent" *ngIf="invoice && currentInvoiceVersion">

  <div class="d-flex gap--12 preview-and-sent-header">
    <div class="header-text">INVOICE</div>
    <div class="preview-and-sent-badge"
         *ngIf="badge"
         [class]="badge"
    >
      {{getBadgeCopy(badge)}}
    </div>
  </div>

  <div class="preview-and-sent-content">
    <div class="prepared-by">
      <div>
        <div class="text fs14 ln20 ls1">{{currentInvoiceVersion.creatorBusinessName}}</div>
        <div class="mt--6 d-flex flex-column gap--4">
          <div class="text">{{currentInvoiceVersion.creatorFirstName + ' ' + currentInvoiceVersion.creatorLastName}}</div>
          <div class="text" *ngIf="currentInvoiceVersion.creatorPhoneNumber">{{currentInvoiceVersion.creatorPhoneNumber | mask: '(000) 000-0000'}}</div>
          <div class="text" *ngIf="!sentMode">{{(business$ | async)!.address | address }}</div>
          <div class="text" *ngIf="sentMode">{{ currentInvoiceVersion.creatorAddress | address }}</div>
          <div class="text">{{currentInvoiceVersion.creatorEmail}}</div>
        </div>
      </div>
      <div [class.d-flex]="(business$ | async)!.logo"
           [class.justify-content-center]="(business$ | async)!.logo"
           [class.width--120]="(business$ | async)!.logo"
      >
        <app-profile-logo [enabled]="false" *ngIf="(business$ | async)!.logo" [noBorder]="true"></app-profile-logo>
        <ng-container *ngIf="!(business$ | async)!.logo" [ngTemplateOutlet]="uid"></ng-container>
      </div>
    </div>

    <div class="prepared-for">
      <div>
        <div class="text fs14 ln20 ls1 g700">PREPARED FOR:</div>
        <div class="mt--6 d-flex flex-column gap--4">
          <div class="text">{{currentInvoiceVersion.clientBusinessName}}</div>
          <div class="text">
            {{currentInvoiceVersion.clientFirstName + ' ' + currentInvoiceVersion.clientLastName}}
          </div>
          <div class="text">{{currentInvoiceVersion.clientPhoneNumber! | mask: '(000) 000-0000'}}</div>
          <div class="text">{{currentInvoiceVersion.clientAddress | address }}</div>
          <div class="text" *ngIf="currentInvoiceVersion.clientUnit">
            {{currentInvoiceVersion.clientUnit}}
          </div>
          <div class="text">{{currentInvoiceVersion.clientEmail}}</div>
        </div>
      </div>
      <ng-container *ngIf="(business$ | async)!.logo" [ngTemplateOutlet]="uid"></ng-container>
    </div>

    <div class="preview-and-sent-data">
      <div class="preview-and-sent-note"
           *ngIf="!isEmpty(currentInvoiceVersion.note)"
      >
        <div class="label">Invoice Note</div>
        <div class="note-text">{{currentInvoiceVersion.note}}</div>
      </div>

      <div class="d-flex flex-column gap--12 w-100" [class.mt--12]="!isEmpty(currentInvoiceVersion.note)">
        <div *ngFor="let invoiceItem of currentInvoiceVersion.items" class="preview-and-sent-item">
          <div class="d-flex gap--22 justify-content-between">
            <div class="name-description">
              <div class="fs14 ln20 ls1 fw-600 cb">{{invoiceItem.name}}</div>
              <div class="text fw-400" *ngIf="!isEmpty(invoiceItem.description)">{{invoiceItem.description}}</div>
            </div>
          </div>
          <div class="divider"></div>
          <div class="d-flex justify-content-between">
            <div class="d-flex gap--48">
              <div class="item-data-block">
                <div class="label">Price</div>
                <div class="data">${{invoiceItem.price.toFixed(2)}}</div>
              </div>
              <div class="item-data-block" *ngIf="invoiceItem.discountAmount">
                <div class="label">Discount</div>
                <div class="data">-${{invoiceItem.discountAmount.toFixed(2)}}</div>
              </div>
              <div class="item-data-block">
                <div class="label">Tax</div>
                <div class="data">
                  ${{ getItemTaxAmount(
                  invoiceItem.price,
                  invoiceItem.taxable,
                  invoiceItem.discountAmount ?? null
                ).toFixed(2) }}
                </div>
              </div>
              <div class="item-data-block">
                <div class="label">Qty</div>
                <div class="data tac">{{invoiceItem.quantity}}</div>
              </div>
            </div>
            <div class="item-data-block">
              <div class="label">Amount</div>
              <div class="data tar">
                ${{ getItemTotal(
                invoiceItem.price,
                invoiceItem.taxable,
                invoiceItem.discountAmount ?? null,
                invoiceItem.quantity ?? null
              ).toFixed(2) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt--40 signature-text pl--16" *ngIf="currentInvoiceVersion.signature">Signature</div>
      <div [class.mt--40]="!currentInvoiceVersion.signature"
           [class.mt--8]="currentInvoiceVersion.signature"
           class="d-flex justify-content-between px--16"
      >
        <div>
          <div class="signature-block" *ngIf="currentInvoiceVersion.signature"
               [class.signed]="currentInvoiceVersion.signatureData?.status === 'signed'"
          >
            <div class="signature-text" *ngIf="currentInvoiceVersion.signatureData?.status !== 'signed'">Required</div>
            <ng-container *ngIf="currentInvoiceVersion.signatureData?.status === 'signed'">
              <img [src]="currentInvoiceVersion.signatureData?.signatureUrl" alt="signature" width="196px" height="92px">
            </ng-container>
          </div>
        </div>
        <div class="preview-and-sent-summary">
          <div class="preview-and-sent-summary-data">
            <div class="d-flex flex-column gap--8">
              <div class="d-flex justify-content-between">
                <div class="details">Subtotal</div>
                <div class="details">${{subtotal.toFixed(2)}}</div>
              </div>
              <div class="d-flex justify-content-between">
                <div class="details">Discount</div>
                <div class="details">-${{discount.toFixed(2)}}</div>
              </div>
              <div class="d-flex justify-content-between">
                <div class="details">Sales Tax</div>
                <div class="details">${{salesTax.toFixed(2)}}</div>
              </div>
            </div>
            <div class="divider"></div>
            <div class="d-flex justify-content-between">
              <div class="total fw-600">Total</div>
              <div class="total fw-600">${{total.toFixed(2)}}</div>
            </div>
            <ng-container *ngIf="currentInvoiceVersion.status !== 'canceled'">
              <div class="d-flex justify-content-between mt--8" *ngIf="paid && rounder(paid) > 0">
                <div class="total fw-600">Paid</div>
                <div class="total paid-color fw-600">${{paid?.toFixed(2)}}</div>
              </div>
              <div class="divider" *ngIf="paid"></div>
              <ng-container *ngIf="balanceDue && rounder(balanceDue) >= 0">
                <div class="d-flex justify-content-between" [class.mt--8]="!paid">
                  <div class="total">Balance Due</div>
                  <div class="total pending-color" [class.zero-color]="rounder(balanceDue) === 0">${{balanceDue?.toFixed(2)}}</div>
                </div>
              </ng-container>
              <ng-container *ngIf="balanceDue && rounder(balanceDue) < 0">
                <div class="d-flex justify-content-between" [class.mt--8]="!paid">
                  <div class="total">Balance Due</div>
                  <div class="total zero-color">${{(0).toFixed(2)}}</div>
                </div>
                <div class="d-flex justify-content-between mt--4">
                  <div class="total refund-color">Refund</div>
                  <div class="total refund-color">-${{(-balanceDue)?.toFixed(2)}}</div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<ng-template #uid>
  <div class="d-flex flex-column gap--4">
    <div class="d-flex">
      <div class="uid-label">INVOICE:&nbsp;</div>
      <div class="uid-data">{{ invoice.uid ? invoice.uid + ' - ' + (currentInvoiceVersion.subUid ?? nextSubUid()) : '' }}</div>
    </div>
    <ng-container *ngIf="date$ | async let date">
      <div class="d-flex">
        <div class="uid-label">DATE:&nbsp;</div>
        <div class="uid-data">{{date.createdDate}}</div>
      </div>
      <div class="d-flex">
        <div class="uid-label">TIME:&nbsp;</div>
        <div class="uid-data">{{date.createdTime}}</div>
      </div>
    </ng-container>
  </div>
</ng-template>
