import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
    Component,
    Input,
    AfterViewInit,
    ViewChild,
    Output,
    EventEmitter,
    OnInit,
    OnDestroy,
    OnChanges,
    SimpleChanges,
    ElementRef, ChangeDetectorRef
} from '@angular/core';
import { FormControl, UntypedFormBuilder } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NotesService } from 'projects/business-slick-estimates/src/app/services/notes.service';
import { UtilsService } from 'projects/common/src/public-api';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Note, NoteUpdate } from '../../../../models/note.model';
import { BusinessService } from 'projects/business-slick-estimates/src/app/services/business.service';
import { UserProfile } from "../../../../../../../common/src/lib/models/user-profile.model";

@Component({
    selector: 'app-note',
    templateUrl: './note.component.html',
    styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    @Input() note!: NoteUpdate;
    @Input() focused?: boolean;
    @Input() currentUser!: UserProfile;

    @Output() onTextChange = new EventEmitter<string>();
    @Output() onDeleteClick = new EventEmitter<number>();
    @Output() onImageClick = new EventEmitter<NoteUpdate>();
    @Output() onfocus = new EventEmitter();
    @Output() onunfocus = new EventEmitter();

    @ViewChild('textArea') textArea!: any;
    @ViewChild('autosize') autosize!: CdkTextareaAutosize;
    @ViewChild('wrapper') wrapper?: any;

    formControl = new FormControl<string>('');

    hoverSubject = new BehaviorSubject(false);
    focus = false;

    // showDeleteButton$ = combineLatest([this.hoverSubject, this.noteTextSubject]).pipe(
    //   map(([hover, text]) => {
    //     if(text === '' && !this.note.hasImage)
    //       return false;
    //     // return hover || this.isMobile;
    //     return true;
    //   })
    // );

    // Used to fix the textarea auto-sizing
    resizeObserver?: ResizeObserver;
    isMobile = this.utilsService.isMobile();
    // noteTextSub?: Subscription;

    readonly layoutBreakpoint = 320;

    changesSub!: Subscription;

    imageUrl?: SafeUrl;
    thumbnailUrl?: SafeUrl;

    constructor(
        private formBuilder: UntypedFormBuilder,
        public notesService: NotesService,
        private element: ElementRef,
        private utilsService: UtilsService,
        private businessService: BusinessService,
        private changeDetectorRef: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
    ) {}

    async ngOnInit() {
        this.changesSub = this.formControl.valueChanges
            .pipe(debounceTime(200))
            .subscribe(text => {
                this.onTextChange.emit(text!);
            });

        this.formControl.setValue(this.note.text!, {emitEvent: false});
    }

    async ngAfterViewInit() {

        if (this.focused) {
            this.textArea.nativeElement.focus();
        }

        this.resizeObserver = new ResizeObserver((event) => {
            const noteElement = event[0];
            const tile = noteElement.target.querySelector('.note-tile');
            if (tile && tile.classList.contains('withImage')) {
                if (noteElement.contentRect.width > this.layoutBreakpoint) {
                    tile.classList.add('row-layout');
                } else {
                    tile.classList.remove('row-layout');
                }
            }

            this.autosize.resizeToFitContent(true);
        });
        this.resizeObserver.observe(this.element.nativeElement);

        await this.updateImage();
    }

    async updateImage() {
        if(this.note.image) {
            this.imageUrl = this.getSafeUrl(this.note.image);
            this.thumbnailUrl = this.imageUrl;
        } else if (this.note.imageName) {
            if(this.notesService.localImages[this.note.imageName]) {
                this.imageUrl = this.getSafeUrl(this.notesService.localImages[this.note.imageName]);
                this.thumbnailUrl = this.imageUrl;
            } else {
                const smallImage = await this.notesService.getImageUrl(this.note as Note, true);
                if(smallImage)
                    this.thumbnailUrl = smallImage;

                const image = await this.notesService.getImageUrl(this.note as Note);
                if(image)
                    this.imageUrl = image;
            }
        }
        this.changeDetectorRef.detectChanges();
    }

    getSafeUrl(blob: Blob | null | undefined): SafeUrl | undefined {
        if (!blob) {
            return;
        }

        const url = URL.createObjectURL(blob);
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.focused && this.textArea) {
            this.textArea.nativeElement.focus();
        }
        this.changeDetectorRef.detectChanges();
    }

    onClick() {
        this.textArea.nativeElement.focus();
    }

    onFocus() {
        if (!this.note.inUseBy) {
            this.onfocus.emit();
        }
        this.focus = true;
        this.changeDetectorRef.detectChanges();
    }

    onUnfocus() {
       delete this.note.inUseBy;
        this.onunfocus.emit();
        this.focus = false;
    }

    onTextFieldClick(event: Event) {
        event.preventDefault();
        event.stopImmediatePropagation();
    }

    imageClick(event: Event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.onImageClick.emit();
    }

    deleteClick(event: Event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.onDeleteClick.emit();
    }

    ngOnDestroy() {
        this.resizeObserver?.disconnect();
        this.changesSub?.unsubscribe();
        // this.noteTextSub?.unsubscribe();
    }

}
