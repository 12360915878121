import { Component } from '@angular/core';

@Component({
  selector: 'lib-chat-overlay',
  templateUrl: './chat-overlay.component.html',
  styleUrls: ['./chat-overlay.component.scss']
})
export class ChatOverlayComponent {

}
