import { Component, Inject, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ModalsService } from '../../../../../common/src/lib/services/modals.service';
import { showSnackbar } from 'projects/common/src/lib/components/snackbar/snackbar.component';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';

interface UserAddedData {
  addedUserName: string,
  addedUserType: 'user' | 'admin',
  invitationLink: string,
}

@Component({
  selector: 'app-user-added',
  templateUrl: './user-added.component.html',
  styleUrls: ['./user-added.component.scss']
})
export class UserAddedComponent {

  get data() {
    return this.dialogData ?? {};
  }

  constructor(
    private snackbar: MatLegacySnackBar,
    private modalsService: ModalsService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: UserAddedData
  ) {}
  
  onCopyClick() {
    showSnackbar(this.snackbar, {
      message: "Link copied"
    });
    this.close();
  }

  close() {
    this.modalsService.close();
  }
  
}
