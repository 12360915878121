import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Breakpoint, UtilsService } from "../../services";

export type TabConfig = {
  tab: string;
  class: string;
};

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {

  @Input() tabs!: (string | TabConfig)[];
  @Input() tabItemsCount!: number[] | null;
  @Input() linePosition!: 'top' | 'bottom';
  @Input() alignment?: 'start' | 'around' | 'center';
  @Input() activeTab?: string;
  @Input() redDots?: boolean[];
  @Input() breakpoint?: Breakpoint;
  @Input() counters!: (number | null)[] | null;
  @Output() onTabSelect = new EventEmitter<string>();

  get tabsWithoutSelected() {
    return this.tabs?.filter(tab => tab !== this.activeTab)
  }

  dropdownOpen = false;
  useTabs$!: Observable<boolean>;

  constructor(
    private utilsService: UtilsService,
  ) { }

  onSelect(tab: string) {
    this.activeTab = tab;
    this.onTabSelect.emit(tab);
  }

  tabTitle(tab: string | TabConfig) {
    return typeof tab === 'string' ? tab : tab.tab;
  }

  tabClass(tab: string | TabConfig) {
    if(typeof tab === 'string')
      return 'tab-' + tab;
    return 'tab-' + tab.tab + ' ' + tab.class;
  }

  ngOnInit(): void {
    if (!this.activeTab)
      this.activeTab = Array.isArray(this.tabs) ? this.tabTitle(this.tabs[0]) : undefined;
    this.useTabs$ = !this.breakpoint ? of(true) : this.utilsService.onScreenBreakpointChange(this.breakpoint);
  }

  showRedDot(index: number) {
    if(!this.redDots) {
      return false;
    }
    return this.redDots[index];
  }

}
