<div class="chat-list-wrapper">
    <div class="title-tile">
        <h4>Chats <caption *ngIf="unseenChatsCount$ | async let count" class="unread-count">{{ count }}</caption></h4>
        <h4 class="mark-as-read-button" *ngIf="((unseenChatsCount$ | async) ?? 0) > 0" (click)="markAllAsRead()">Mark all as read</h4>
    </div>
    <div class="chat-list" #chatListView>
        <div class="chat-tile" *ngFor="let chat of chatList.chats" (click)="openChat.emit(chat)">
            <div class="details">
                <small>
                    {{ moment(chat.lastMessageAt).format('MM/DD/YY hh:mm A') }}
                    <div class="red-dot" *ngIf="chat.hasUnseenMessages"></div>
                </small>
                <p>{{ chat.subject }}</p>
            </div>

            <svg class="arrow" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.58579 6L0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683418 12.0976 1.31658 12.0976 1.70711 11.7071L6.70711 6.70711C7.09763 6.31658 7.09763 5.68342 6.70711 5.29289L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L4.58579 6Z" fill="#C4C8CC"/>
            </svg>
        </div>
    </div>
</div>