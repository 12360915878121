import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { FormsService } from '../../../../../common/src/lib/services/forms.service';
import { UtilsService } from 'projects/common/src/public-api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form = this.formBuilder.group({
    email: new UntypedFormControl('', [Validators.email]),
    password: new UntypedFormControl(''),
  });

  showPassword = false;
  loading = false;


  @ViewChild('emailInput') emailInput!: ElementRef;
  @ViewChild('passwordInput') passwordInput!: ElementRef;

  constructor(
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private formsService: FormsService,
    private utilsService: UtilsService,
  ) { }

  ngOnInit(): void {
  }

  async emailSignIn() {
    
    const emailFocused = this.emailInput.nativeElement === document.activeElement;
    if(this.utilsService.isMobile() && emailFocused) {
      this.passwordInput.nativeElement.focus();
      return;
    }

    if(this.loading) {
      return;
    }

    if(this.valid()) {
      const { email, password } = this.form.value;
      try {
        this.loading = true;
        await this.authService.signInWithEmailAndPassword(email, password);
      } catch (e) {
        this.form.get('email')!.setErrors({
          emailOrPassword: true
        });
        this.form.get('password')!.setErrors({
          emailOrPassword: true
        });
        this.loading = false;
      }


    } else {
      this.form.markAllAsTouched();
      this.unfocusAll();
    }

    this.emailInput.nativeElement.blur();
    this.passwordInput.nativeElement.blur();
  }

  valid(): boolean {

    const valid = this.formsService.validateRequired(this.form.get('email')!, this.form.get('password')!);

    this.formsService.resetErrors(this.form, 'emailOrPassword');
    if(!this.form.valid) {
      return false;
    }

    return valid;
  }

  passEmailProp() {
    const emailControl = this.form.get('email')!;
    if(emailControl.value && emailControl.value.length > 0 && !emailControl.hasError('email')) {
      return {
        e: emailControl.value
      };
    }
    return {};
  }

  private unfocusAll() {
    this.emailInput.nativeElement.blur();
    this.passwordInput.nativeElement.blur();
  }

}
