import { AfterViewInit, Component } from '@angular/core';
import { Location } from '@angular/common';
import { UtilsService } from "../../../../../common/src/lib/services";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { debounce, map, shareReplay, startWith, switchMap, take, tap } from "rxjs/operators";
import { PaymentService } from "../../services/payment.service";
import { ProgressBarService } from "../../services/progress-bar.service";
import { UsersService } from "../../services/users.service";
import { BusinessService } from '../../services/business.service';
import { BehaviorSubject, combineLatest, of, timer } from 'rxjs';
import { ChatService, unseenIndicatorDebounceMs } from '../../services/chat.service';

const tabs = ['Payment', 'Notes', 'Chat'] as const;
const sideTabs = tabs.slice(1);
type MainTab = typeof tabs[number];
type SideTab = Exclude<MainTab, 'Payment'>;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements AfterViewInit {

  initInterval!: any;

  user$ = this.authService.user$;

  activeTab: string = 'Payment';

  itemsScrolledToBottom = false;

  openedSummary = false;

  currentUser$ = this.usersService.currentUser$;

  businessId$ = this.businessService.selectedBusiness$.pipe(
    map(business => business.businessId)
  );

  params$ = this.route.params.pipe(
    map(params => {
      return {
        paymentId: +params['paymentId'],
        workflowId: +params['workflowId']
      }
    })
  );

  payment$ = this.params$.pipe(
    switchMap(
      params =>
        this.paymentService.paymentObservable(params.workflowId, params.paymentId).pipe(
          map(payment => ({ payment, workflowId: params.workflowId }))
        )
    ),
    shareReplay(1)
  );

  showSmallScreenView$ = this.utilsService.onScreenBreakpointChange('md').pipe(
    map(above => !above)
  );

  ngAfterViewInit() {
    this.initInterval = setInterval(() => {
      const paymentItems = document.getElementById('payment-items');

      if (paymentItems)
        this.checkItemsScroll(paymentItems, true, true);
    }, 75);
  }

  largeScreen$ = this.utilsService.onScreenBreakpointChange('lg');
  selectedTabSubject = new BehaviorSubject<MainTab>('Payment');
  selectedSideTabSubject = new BehaviorSubject<SideTab>('Notes');
  selectedTab$ = combineLatest([this.largeScreen$, this.selectedTabSubject, this.selectedSideTabSubject]).pipe(
    map(([largeScreen, mainTab, sideTab]) => largeScreen ? sideTab : mainTab)
  );
  
  showInfo$ = combineLatest([this.largeScreen$, this.selectedTab$]).pipe(
    map(([largeScreen, selectedTab]) => {
      return largeScreen || selectedTab === 'Payment';
    })
  );

  showNotes$ = this.selectedTab$.pipe(
    map((selectedTab) => selectedTab === 'Notes')
  );

  showChat$ = this.selectedTab$.pipe(
    map((selectedTab) => selectedTab === 'Chat')
  );

  tabs$ = this.largeScreen$.pipe(
    map(largeScreen => (largeScreen ? sideTabs : tabs) as string[])
  );

  chat$ = this.params$.pipe(
    map(params => params.workflowId),
    switchMap(workflowId => this.chatService.chatObservable(workflowId, true)),
    shareReplay(1)
  );

  tabsRedDots$ = combineLatest([
    this.chat$.pipe(
      map(chat => !!chat?.hasUnseenMessages),
      startWith(false),
      debounce(hasUnseen => timer(hasUnseen ? unseenIndicatorDebounceMs : 0))
    ),
    this.tabs$
  ]).pipe(map(([chatHasUnseenMessages, tabs]) => tabs.map(tab => tab === 'Chat' && chatHasUnseenMessages)));

  chatSubject$ = combineLatest([this.chat$, this.params$.pipe(map(p => p.workflowId))]).pipe(
    switchMap(([chat, workflowId]) => {
      return chat ? of(chat.subject) : this.paymentService.getWorkflowLastDocumentClientName(workflowId).then(cn => `CN: ${cn}`);
    }),
    shareReplay(1)
  );

  async setActiveTab(tab: string) {
    const largeScreen = await this.largeScreen$.pipe(take(1)).toPromise();
    largeScreen ? this.selectedSideTabSubject.next(tab as SideTab) : this.selectedTabSubject.next(tab as MainTab);
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private paymentService: PaymentService,
    private progressBarService: ProgressBarService,
    private usersService: UsersService,
    private businessService: BusinessService,
    private chatService: ChatService,
    private location: Location,
  ) { }

  async back(workflowId: number) {
    if (!this.progressBarService.backRoute)
      await this.router.navigate(['/']);
    else
      this.location.back();
      // await this.progressBarService.openStage({ canOpen: true, route: this.progressBarService.backRoute, id: 1 }, workflowId, 'payment');
  }

  get backRoute() {
    return this.progressBarService.backRoute;
  }

  checkItemsScroll(event: any, inside?: boolean, interval?: boolean) {
    const scrollTop = !inside ? event.target.scrollTop : event.scrollTop;
    const scrollHeight = !inside ? event.target.scrollHeight : event.scrollHeight;
    const clientHeight = !inside ? event.target.clientHeight : event.clientHeight;

    this.itemsScrolledToBottom = clientHeight + scrollTop >= scrollHeight - 16;

    if (interval)
      clearInterval(this.initInterval);
  }
}
