import { Component, OnDestroy, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, timer } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { InvoicesService, InvoiceVersionCreate } from "../../services/invoices.service";
import { debounce, distinctUntilChanged, map, pairwise, shareReplay, startWith, switchMap, take } from "rxjs/operators";
import { Invoice, InvoiceVersion } from "../../models/invoice.model";
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";
import { Client } from "../../../../../common/src/lib/models";
import { ConfirmationDialog } from "../../../../../common/src/lib/modals/confirmation-dialog/confirmation-dialog.component";
import { ModalBehavior, ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { UsersService } from "../../services/users.service";
import { BusinessService } from '../../services/business.service';
import { ChatService, unseenIndicatorDebounceMs } from '../../services/chat.service';
import { UtilsService } from 'projects/common/src/public-api';
import { ChatComponent } from "../../components/chat/chat.component";

const tabs = ['Invoice', 'Notes', 'Chat'] as const;
const sideTabs = tabs.slice(1);
type MainTab = typeof tabs[number];
type SideTab = Exclude<MainTab, 'Invoice'>;

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnDestroy {

  @ViewChild('chat') chat!: ChatComponent;

  differentInFocus$ = new BehaviorSubject<boolean>(false);
  currentInvoiceVersionItemsLength$ = new BehaviorSubject(0);
  user$ = this.authService.user$;
  currentInvoiceVersionId$ = new BehaviorSubject(1);
  scrollPosition: number = 0;
  activeTab: string = 'Invoice';
  preview: boolean = false;
  sendLoading: boolean = false;
  actions = ['New', 'Duplicate'];
  addedNewVersion = false;

  currentUser$ = this.usersService.currentUser$;
  selectedBusiness$ = this.businessService.selectedBusiness$;
  businessId$ = this.selectedBusiness$.pipe(
    map(business => business!.businessId)
  );

  params$ = this.route.params.pipe(
    map(params => {
      return {
        invoiceId: +params['invoiceId'],
        workflowId: +params['workflowId']
      }
    })
  );

  invoice$ = this.params$.pipe(
    switchMap(params => {
      return this.invoicesService.invoiceObservable(params.invoiceId, params.workflowId).pipe(
        startWith(null),
        distinctUntilChanged(),
        pairwise(),
        map(([prevInvoice, currInvoice], index) => {
          if (!currInvoice) {
            if (prevInvoice) {
              this.router.navigate(['/lobby/invoices/paid']);
            }
            return null;
          }

          if (index === 0) {
            const versionId = this.invoicesService.findHighestVersionId(currInvoice!.versions);
            this.currentInvoiceVersionId$.next(versionId);
          }

          return currInvoice;
        })
      );
    }),
    shareReplay(1),
  );

  invoiceVersions$ = this.invoice$.pipe(
    map(invoice => invoice!.versions),
    startWith([] as InvoiceVersion[]),
    pairwise(),
    map(([_, versions]) => versions)
  );

  currentInvoiceVersion$ = this.currentInvoiceVersionId$.pipe(
    switchMap(currentInvoiceVersionId => {
      return this.invoiceVersions$.pipe(
        map(versions => {
          if (this.addedNewVersion) {
            this.addedNewVersion = false;
            const id = this.invoicesService.findHighestVersionId(versions);
            this.currentInvoiceVersionId$.next(id);
          }
          const currentInvoiceVersion = versions.find(invoiceVersion => invoiceVersion.id === currentInvoiceVersionId);
          if (currentInvoiceVersion?.items)
            this.currentInvoiceVersionItemsLength$.next(currentInvoiceVersion!.items.length);

          return currentInvoiceVersion;
        })
      );
    })
  );

  largeScreen$ = this.utilsService.onScreenBreakpointChange('lg');
  selectedTabSubject = new BehaviorSubject<MainTab>('Invoice');
  selectedSideTabSubject = new BehaviorSubject<SideTab>('Notes');
  selectedTab$ = combineLatest([this.largeScreen$, this.selectedTabSubject, this.selectedSideTabSubject]).pipe(
    map(([largeScreen, mainTab, sideTab]) => largeScreen ? sideTab : mainTab)
  );
  
  showInfo$ = combineLatest([this.largeScreen$, this.selectedTab$]).pipe(
    map(([largeScreen, selectedTab]) => {
      return largeScreen || selectedTab === 'Invoice';
    })
  );

  showNotes$ = this.selectedTab$.pipe(
    map((selectedTab) => selectedTab === 'Notes')
  );

  showChat$ = this.selectedTab$.pipe(
    map((selectedTab) => selectedTab === 'Chat')
  );

  tabs$ = this.largeScreen$.pipe(
    map(largeScreen => (largeScreen ? sideTabs : tabs) as string[])
  );

  chat$ = this.params$.pipe(
    map(params => params.workflowId),
    switchMap(workflowId => this.chatService.chatObservable(workflowId, true)),
    shareReplay(1)
  );

  tabsRedDots$ = combineLatest([
    this.chat$.pipe(
      map(chat => !!chat?.hasUnseenMessages),
      startWith(false),
      debounce(hasUnseen => timer(hasUnseen ? unseenIndicatorDebounceMs : 0))
    ),
    this.tabs$
  ]).pipe(map(([chatHasUnseenMessages, tabs]) => tabs.map(tab => tab === 'Chat' && chatHasUnseenMessages)));

  chatSubject$ = combineLatest([this.chat$, this.currentInvoiceVersion$]).pipe(
    map(([chat, version]) => chat ? chat.subject : `CN: ${version?.clientFirstName} ${version?.clientLastName}`)
  );

  async setActiveTab(tab: string) {
    const largeScreen = await this.largeScreen$.pipe(take(1)).toPromise();
    largeScreen ? this.selectedSideTabSubject.next(tab as SideTab) : this.selectedTabSubject.next(tab as MainTab);
  }

  clientUpdateFunction = async (currentInvoiceVersionId: number, form: Client): Promise<any> => {
    await this.invoicesService.updateInvoiceVersionClient(
      currentInvoiceVersionId,
      form
    )
  }

  constructor(
    private authService: AuthService,
    private invoicesService: InvoicesService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private modalsService: ModalsService,
    private usersService: UsersService,
    private businessService: BusinessService,
    private utilsService: UtilsService,
    private chatService: ChatService,
  ) { }

  async ngOnDestroy() {
    const modalComponent = await this.modalsService.modalComponent$.pipe(take(1)).toPromise();
    if (!modalComponent)
      this.params$.pipe(take(1)).toPromise()
        .then(params => this.invoicesService.cleanUpInvoice(params.invoiceId));
  }

  async back() {
    const from = this.route.snapshot.queryParamMap.get('from');
    if (from === 'customer-list') {
      await this.router.navigate([from]);
      return;
    }
    await this.router.navigate(['/lobby/invoices', from?.toLowerCase() ?? 'paid']);
  }

  async handleVersionPickerAction(action: typeof this.actions[number]) {
    switch (action) {
      case 'New':
        const invoice = await this.invoice$.pipe(take(1)).toPromise();
        const versionId = await this.currentInvoiceVersionId$.pipe(take(1)).toPromise();
        await this.createInvoiceVersion(invoice!, versionId);
        break;
      case 'Duplicate':
        await this.duplicateInvoiceVersion();
        break;
      default:
        break;
    }
  }

  async createInvoiceVersion(invoice: Invoice, currentInvoiceVersionId: number) {
    const user = await this.usersService.currentUser$.pipe(take(1)).toPromise();
    const version = invoice.versions.find(v => v.id === currentInvoiceVersionId)!;
    const data: InvoiceVersionCreate = {
      inCreatedBy: user.id,
      inInvoiceId: invoice.id,
      inClient: {
        firstName: version.clientFirstName,
        lastName: version.creatorLastName,
        phoneNumber: version.clientPhoneNumber,
        extNumber: version.clientExtNumber,
        address: version.clientAddress,
        unit: version.clientUnit,
        email: version.clientEmail,
        businessName: version.clientBusinessName,
        type: version.clientType,
        salesTaxPercentage: version.clientSalesTaxPercentage,
      },
    };
    if(version.clientSalesTaxPercentage)
      data.inClient.salesTaxPercentage = version.clientSalesTaxPercentage;
    if(invoice.workflowId)
      data.inWorkflowId = invoice.workflowId;
    if(version.signature)
      data.inSignature = version.signature;
    if(version.note)
      data.inNote = version.note;
    if(version.discountType)
      data.inDiscountType = version.discountType;
    if(version.items)
      data.inItems = version.items;

    const versionId = await this.invoicesService.createInvoiceVersion(data);
    this.currentInvoiceVersionId$.next(versionId);
    showSnackbar(this.snackBar, {
      message: "Invoice created",
      duration: 2000,
    });
  }

  async duplicateInvoiceVersion() {
    const invoice = await this.invoice$.pipe(take(1)).toPromise();
    const currentInvoiceVersion = await this.currentInvoiceVersion$.pipe(take(1)).toPromise();
    await this.invoicesService.duplicateInvoiceVersion(invoice!, currentInvoiceVersion!);
    this.addedNewVersion = true;
    showSnackbar(this.snackBar, {
      message: "Invoice duplicated",
      duration: 2000,
    });
  }

  setCurrentVersion(invoiceVersion: InvoiceVersion) {
    this.preview = false;
    this.currentInvoiceVersionId$.next(invoiceVersion.id);
    this.currentInvoiceVersionItemsLength$.next(invoiceVersion.items?.length ?? 0);
  }

  async sendInvoiceVersion(
    invoice: Invoice,
    currentInvoiceVersion: InvoiceVersion
  ) {
    this.sendLoading = true;
    let sent = false;

    invoice.versions.forEach(version => {
      if (version.status === 'sent' || version.status === 'paid')
        sent = true;
    });

    if (!sent) {
      await this.invoicesService.sendInvoice(currentInvoiceVersion.id);
      this.preview = false;
      showSnackbar(this.snackBar, {
        message: "Invoice sent",
        duration: 2000,
      });
    } else {
      await this.modalsService.open(ConfirmationDialog, {
        behavior: ModalBehavior.Dialog,
        disableClose: true,
        data: {
          title: 'New Version',
          message: 'By sending a new version, you are cancelling the last version. The payment balance will appear in the new invoice. Are you sure you want to proceed?',
          actionTitle: 'Yes',
          actionColor: 'warn',
          cancelTitle: 'No',
          action: async () => {
            await this.invoicesService.sendInvoice(currentInvoiceVersion.id);
            this.preview = false;
            showSnackbar(this.snackBar, {
              message: "Invoice sent",
              duration: 2000,
            });
          }
        }
      });
    }
    this.sendLoading = false;
  }

  sidePanelClick(event: Event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.chat?.onMenuClick(event);
  }
}
