import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EstimateTileComponent } from "../../components/estimate-tile/estimate-tile.component";
import { DynamicListComponent, DynamicListEvent, PaginationListItem } from "../../components/dynamic-list/dynamic-list.component";
import { Pagination, PaginationOptions } from "../../services/pagination.service";
import { BehaviorSubject } from "rxjs";
import { map, shareReplay, switchMap, take } from "rxjs/operators";
import { BusinessService } from "../../services/business.service";
import { SupabaseService } from "../../services/supabase.service";
import { LobbyService } from "../../services/lobby.service";
import { ViewAsService } from "../../services/view-as.service";
import { limitStep } from "../../services/items.service";
import { RealtimePostgresChangesPayload } from "@supabase/supabase-js";
import { Estimate, EstimateTileData, EstimateTimeRange } from "../../models/estimate.model";
import { EstimatesService } from "../../services/estimates.service";
import { ScheduleService, ScheduleTileData } from "../../services/schedule.service";
import { dateString } from "../../../../../common/src/lib/services";
import { Client } from 'projects/common/src/public-api';
import { TimeRange } from 'projects/common/src/lib/models/time-range.model';
import { Router } from "@angular/router";
import { ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { MatLegacySnackBar } from "@angular/material/legacy-snack-bar";
import { Workflow } from '../../models/workflow.model';
import { frontToServerTranslation } from 'projects/common/src/lib/services/supabase.service';

function estimatesToPaginationList(estimates: EstimateTileData[]) {
    return estimates.map(estimate => ({
        componentClass: EstimateTileComponent,
        header: dateString(estimate.startTime, estimate.endTime),
        args: { document: {...estimate, docType: 'Estimate'} }
    }));
}

@Component({
    selector: 'app-estimates',
    templateUrl: './estimates.component.html',
    styleUrls: ['./estimates.component.scss']
})
export class EstimatesComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('list') dynamicList!: DynamicListComponent<EstimateTileComponent>;
    @ViewChild('dateFilterList') dateFilterList!: DynamicListComponent<EstimateTileComponent>;

    pagination!: Pagination<EstimateTileData>;
    dateFilterPagination!: Pagination<EstimateTileData>;
    loading$ = new BehaviorSubject<boolean>(true);

    firstHeaderRangeSubject = new BehaviorSubject<TimeRange | null>(null);
    firstHeader$ = this.firstHeaderRangeSubject.pipe(map(range => range ? dateString(range?.startTime, range?.endTime): null));
    filterFirstHeaderRangeSubject = new BehaviorSubject<TimeRange | null>(null);
    filterFirstHeader$ = this.filterFirstHeaderRangeSubject.pipe(map(range => range ? dateString(range?.startTime, range?.endTime): null));
    initialTopItems!: PaginationListItem<EstimateTileComponent>[];
    initialBottomItems!: PaginationListItem<EstimateTileComponent>[];
    initialFilterTopItems!: PaginationListItem<EstimateTileComponent>[];
    initialFilterBottomItems!: PaginationListItem<EstimateTileComponent>[];
    initialFilterCanLoadMoreTop = true;
    initialFilterCanLoadMoreBottom = true;

    dateFilter$ = this.lobbyService.selectedDateRange$.pipe(
        switchMap(async range => {
            if(!range) 
                return null;
            const inLimit = limitStep;
            const options: PaginationOptions = {
                inLimit, 
                inOffset: 0, 
                inStartDate: frontToServerTranslation(range.from), 
                inEndDate: frontToServerTranslation(range.to), 
            };
            const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
            if(userIds)
                options.inUserIds = userIds;
            const status = this.scheduleService.filterEstimateStatusSubject.value;
            if(status !== 'All')
                (options as any).inStatus = status.toLowerCase();
            
            const [past, future] = await Promise.all([
                this.dateFilterPagination.get({...options, in_direction: 'past'}),
                this.dateFilterPagination.get({...options, in_direction: 'future'})
            ]);
            this.filterFirstHeaderRangeSubject.next(future.length ? { startTime: future[0].startTime, endTime: future[0].endTime } : null);
            this.initialFilterTopItems = estimatesToPaginationList(past);
            this.initialFilterBottomItems = estimatesToPaginationList(future);
            this.initialFilterCanLoadMoreBottom = future.length >= inLimit;
            this.initialFilterCanLoadMoreTop = past.length >= inLimit;
            return range;
        }),
        shareReplay({ bufferSize: 1, refCount: true})
    );

    currentStatusFilter$ = this.scheduleService.filterEstimateStatus$;
    currentStatusFilterSubject = this.scheduleService.filterEstimateStatusSubject;

    constructor(
        private businessService: BusinessService,
        private supabaseService: SupabaseService,
        private estimatesService: EstimatesService,
        private lobbyService: LobbyService,
        private viewAsService: ViewAsService,
        private scheduleService: ScheduleService,
        private modalsService: ModalsService,
        private router: Router,
        private snackbar: MatLegacySnackBar,
    ) {}

    async ngOnInit() {
        const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
        this.pagination = new Pagination<EstimateTileData, Estimate | Client | EstimateTimeRange | Workflow>(this.supabaseService, 'get_estimates', business.businessId, 'estimates')
            .on<Estimate, 'UPDATE'>('UPDATE', 'estimate', this.handleEstimateChanges.bind(this))
            .on<Client, 'UPDATE'>('UPDATE', 'estimate_client', this.handleClientChanges.bind(this))
            .on<EstimateTimeRange>('*', 'estimate_range', this.handleRangeChanges.bind(this))
            .on<Workflow, 'UPDATE'>('UPDATE', 'workflow', this.handleWorkflowChanges.bind(this));

        this.dateFilterPagination = new Pagination<EstimateTileData, Estimate | Client | EstimateTimeRange | Workflow>(this.supabaseService, 'get_estimates', business.businessId, 'filtered_estimates')
            .on<Estimate, 'UPDATE'>('UPDATE', 'estimate', this.handleDateFilterEstimateChanges.bind(this))
            .on<Client, 'UPDATE'>('UPDATE', 'estimate_client', this.handleDateFilterClientChanges.bind(this))
            .on<EstimateTimeRange>('*', 'estimate_range', this.handleDateFilterRangeChanges.bind(this))
            .on<Workflow, 'UPDATE'>('UPDATE', 'workflow', this.handleDateFilterWorkflowChanges.bind(this));

        const inLimit = limitStep;
        const options: PaginationOptions = { inLimit, inOffset: 0 };
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        if(userIds)
            options.inUserIds = userIds;
        const status = this.scheduleService.filterEstimateStatusSubject.value;
        if(status !== 'All')
            (options as any).inStatus = status.toLowerCase();
        
        const [past, future] = await Promise.all([
            this.pagination.get({...options, in_direction: 'past'}),
            this.pagination.get({...options, in_direction: 'future'})
        ]);
        this.firstHeaderRangeSubject.next(future.length ? { startTime: future[0].startTime, endTime: future[0].endTime } : null);
        this.initialTopItems = estimatesToPaginationList(past);
        this.initialBottomItems = estimatesToPaginationList(future);
        this.setInitialCanLoadMore();
        this.loading$.next(false);
    }

    ngAfterViewInit(): void {
        this.setInitialCanLoadMore();
    }
    
    ngOnDestroy(): void {
        this.pagination.destroy();
        this.dateFilterPagination.destroy();
    }

    setInitialCanLoadMore() {
        if (!this.initialTopItems || !this.initialBottomItems || !this.dynamicList)
            return;

        if(this.initialTopItems.length < limitStep)
            this.dynamicList.canLoadMoreTop = false;
        if(this.initialBottomItems.length < limitStep)
            this.dynamicList.canLoadMoreBottom = false;
    }

    async loadItems(at: 'top' | 'bottom', inOffset = 0) {
        const inLimit = limitStep;
        const options: PaginationOptions = { inLimit, inOffset, inDirection: at === 'top' ? 'past' : 'future' };
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        if(userIds)
            options.inUserIds = userIds;
        const status = this.scheduleService.filterEstimateStatusSubject.value;
        if(status !== 'All')
            (options as any).inStatus = status.toLowerCase();

        const estimates = await this.pagination.get(options);
        if(at === 'top') {
            if(estimates.length < inLimit)
                this.dynamicList.canLoadMoreTop = false;
            this.dynamicList.addAtTop(
                estimatesToPaginationList(estimates)
            );
        } else {
            if(estimates.length < inLimit)
                this.dynamicList.canLoadMoreBottom = false;
            this.dynamicList.addAtBottom(
                estimatesToPaginationList(estimates)
            );
        }
    }

    async loadDateFilterItems(at: 'top' | 'bottom', inOffset = 0) {
        const inLimit = limitStep;
        const range = await this.lobbyService.selectedDateRange$.pipe(take(1)).toPromise();
        const options: PaginationOptions = {
            inLimit,
            inOffset,
            inDirection: at === 'top' ? 'past' : 'future',
            inStartDate: frontToServerTranslation(range!.from),
            inEndDate: frontToServerTranslation(range!.to),
        };
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        if(userIds)
            options.inUserIds = userIds;
        const status = this.scheduleService.filterEstimateStatusSubject.value;
        if(status !== 'All')
            (options as any).inStatus = status.toLowerCase();

        const estimates = await this.dateFilterPagination.get(options);
        if(!this.dateFilterList)
            return;

        if(at === 'top') {
            if(estimates.length < inLimit)
                this.dateFilterList.canLoadMoreTop = false;
            this.dateFilterList.addAtTop(
                estimatesToPaginationList(estimates)
            );
        } else {
            if(estimates.length < inLimit)
                this.dateFilterList.canLoadMoreBottom = false;
            this.dateFilterList.addAtBottom(
                estimatesToPaginationList(estimates)
            );
        }
    }

    handleEstimateChanges(changes: RealtimePostgresChangesPayload<Estimate>) {
        this.scheduleService.handleEstimateChanges(this.dynamicList, changes, this.firstHeaderRangeSubject, this.currentStatusFilterSubject.value);
    }

    handleClientChanges(changes: RealtimePostgresChangesPayload<Client>) {
        this.scheduleService.handleEstimateClientChanges(this.dynamicList, changes);
    }

    handleRangeChanges(changes: RealtimePostgresChangesPayload<EstimateTimeRange>) {
        this.scheduleService.handleEstimateRangeChanges(this.dynamicList, changes, this.firstHeaderRangeSubject, this.currentStatusFilterSubject.value);
    }
    
    handleWorkflowChanges(changes: RealtimePostgresChangesPayload<Workflow>) {
        this.scheduleService.handleWorkflowChanges(this.dynamicList, changes);
    }

    handleDateFilterEstimateChanges(changes: RealtimePostgresChangesPayload<Estimate>) {
        this.scheduleService.handleDateFilterEstimateChanges(this.dateFilterList, changes, this.filterFirstHeaderRangeSubject, this.currentStatusFilterSubject.value);
    }

    handleDateFilterClientChanges(changes: RealtimePostgresChangesPayload<Client>) {
        this.scheduleService.handleDateFilterEstimateClientChanges(this.dateFilterList, changes);
    }

    handleDateFilterRangeChanges(changes: RealtimePostgresChangesPayload<EstimateTimeRange>) {
        this.scheduleService.handleDateFilterEstimateRangeChanges(this.dateFilterList, changes, this.filterFirstHeaderRangeSubject, this.currentStatusFilterSubject.value);
    }

    handleDateFilterWorkflowChanges(changes: RealtimePostgresChangesPayload<Workflow>) {
        this.scheduleService.handleDateFilterWorkflowChanges(this.dateFilterList, changes);
    }

    onItemEvent(event: DynamicListEvent<EstimateTileData>) {
        this.scheduleService.onItemEvent(event as DynamicListEvent<ScheduleTileData>);
    }

    openEventInfoPageForEstimate(workflowId: number, estimateId: number) {
        const queryParams = { from : 'estimates' };
        this.router.navigate(['/estimates', workflowId, estimateId], { queryParams });
    }

}
