import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  functionUrl = 'https://us-central1-slick-estimates-dev.cloudfunctions.net/generatePdf';

  constructor(
    private http: HttpClient
  ) { }

  generatePdf(pdfData: any): Observable<Blob | null> {
    return this.http.post(this.functionUrl, pdfData,{ responseType: 'arraybuffer' }).pipe(
      map(buffer => new Blob([buffer], { type: 'application/pdf' })),
      catchError(err => of(null))
    );
  }
}
